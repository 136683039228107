import React from 'react';
import Input from '../../../ui/Input';
import IsSkippable from './IsSkippable';
import { useController } from 'react-hook-form';

const UploadMultiplePictures = ({ register, index, errors, control }) => {
    // const { field: skippableField } = useController({
    //     name: `microtasks.${index}.skippable`,
    //     control,
    // });
    return (
        <div>
            <Input
                {...register(`microtasks.${index}.uploadMultiplePictures_Title`, {
                    required: 'Please enter a title',
                })}
                type="transparent"
                error={errors?.microtasks && errors?.microtasks[index]?.uploadMultiplePictures_Title?.message}
                placeholder={'Title *'}
                transparentTextSize="text-base"
                className={'w-[100%] mb-1 '}
            />
            {/* <Input
                {...register(`microtasks.${index}.uploadMultiplePictures_ButtonText`, {
                    required: 'Please enter Button Text for uploading multiple pictures.',
                })}
                type="transparent"
                error={errors?.microtasks && errors?.microtasks[index]?.uploadMultiplePictures_ButtonText?.message}
                placeholder={'Button Text for uploading multiple pictures...'}
                transparentTextSize="text-base"
                className={'w-[100%] mb-1 mt-2 '}
            /> */}
            <Input
                {...register(`microtasks.${index}.uploadMultiplePictures_Description`, {
                    required: 'Please enter a description ',
                })}
                type="transparent"
                error={errors?.microtasks && errors?.microtasks[index]?.uploadMultiplePictures_Description?.message}
                placeholder={'Description *'}
                transparentTextSize="text-sm"
                transparentFontWeight={'font-normal'}
                className={'w-[100%] mb-1 mt-2 '}
            />
            <div>
                <Input
                    {...register(`microtasks.${index}.uploadMultiplePictures_MinFiles`, {
                        required: 'Please enter minimum number of photos ',
                        min: {
                            value: 1, // Replace 1 with the minimum value you want
                            message: 'Please enter number greater than or equal to 1', // Custom error message for the min rule
                        },
                        max: {
                            value: 6, // Replace 10 with the maximum value you want
                            message: 'Please enter a number less than or equal to 6', // Custom error message for the max rule
                        },
                    })}
                    type="number"
                    error={errors?.microtasks && errors?.microtasks[index]?.uploadMultiplePictures_MinFiles?.message}
                    placeholder={'Minimum number of photos to be uploaded'}
                    // transparentTextSize="text-base"
                    className={'w-[100%] mb-1 mt-4 '}
                />
                <p className="text-end text-secondaryInput text-xs">
                    <span className="font-semibold ">{'Max limit'}</span> : <span>{6}</span>
                </p>
            </div>
            {/* <div className="flex gap-9 my-2 items-center ">
                <IsSkippable
                    selectedOption={skippableField.value}
                    onSelectOption={(value) => {
                        skippableField.onChange(value);
                    }}
                    options={['Yes', 'No']}
                />
            </div> */}
        </div>
    );
};

export default UploadMultiplePictures;
