import { uploaderApi } from '../apis/general/general';

export const uploader = async (image, docVariable = 'image') => {
    const payload = new FormData();
    payload.append(docVariable, image);
    // doc
    try {
        const response = await uploaderApi(payload);

        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
};
