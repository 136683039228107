import * as multisig from '@sqds/multisig';
import { PublicKey } from '@solana/web3.js';

export const executorToFetchThreshold = async (orgMultisig, connection) => {
    const treasurerMultisig = await multisig.accounts.Multisig.fromAccountAddress(
        connection,
        new PublicKey(orgMultisig)
    );

    return treasurerMultisig.threshold;
};
