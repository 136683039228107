export const HomeIcon = ({ fillColor }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.7625 5.51L12.9625 3.51C13.1525 3.19 13.1525 2.8 12.9625 2.48L11.7625 0.48C11.5825 0.18 11.2525 0 10.9025 0H8.4525C8.1025 0 7.7725 0.18 7.5925 0.49L6.3925 2.49C6.2025 2.81 6.2025 3.2 6.3925 3.52L7.5925 5.52C7.7625 5.82 8.0925 6 8.4425 6H10.8925C11.2525 6 11.5825 5.82 11.7625 5.51Z"
                fill={fillColor}
            />
            <path
                d="M7.5825 7.49L6.3825 9.49C6.1925 9.81 6.1925 10.2 6.3825 10.52L7.5825 12.52C7.7625 12.82 8.0925 13.01 8.4425 13.01H10.9025C11.2525 13.01 11.5825 12.83 11.7625 12.52L12.9625 10.52C13.1525 10.2 13.1525 9.81 12.9625 9.49L11.7625 7.49C11.5825 7.18 11.2525 7 10.9025 7H8.4425C8.0925 7 7.7625 7.18 7.5825 7.49Z"
                fill={fillColor}
            />
            <path
                d="M14.6825 9.51H17.1325C17.4825 9.51 17.8125 9.33 17.9925 9.02L19.1925 7.02C19.3825 6.7 19.3825 6.31 19.1925 5.99L17.9925 3.99C17.8125 3.69 17.4825 3.5 17.1325 3.5H14.6825C14.3325 3.5 14.0025 3.68 13.8225 3.99L12.6225 5.99C12.4325 6.31 12.4325 6.7 12.6225 7.02L13.8225 9.02C14.0125 9.33 14.3325 9.51 14.6825 9.51Z"
                fill={fillColor}
            />
            <path
                d="M17.1425 10.51H14.6825C14.3325 10.51 14.0025 10.69 13.8225 11L12.6225 13C12.4325 13.32 12.4325 13.71 12.6225 14.03L13.8225 16.03C14.0025 16.33 14.3325 16.52 14.6825 16.52H17.1425C17.4925 16.52 17.8225 16.34 18.0025 16.03L19.2025 14.03C19.3925 13.71 19.3925 13.32 19.2025 13L18.0025 11C17.8225 10.7 17.4925 10.51 17.1425 10.51Z"
                fill={fillColor}
            />
            <path
                d="M5.5125 9.03L6.7125 7.03C6.9025 6.71 6.9025 6.32 6.7125 6L5.5125 4C5.3325 3.7 5.0125 3.51 4.6625 3.51H2.2025C1.8525 3.51 1.5225 3.7 1.3425 4L0.1425 6C-0.0475 6.32 -0.0475 6.71 0.1425 7.03L1.3425 9.03C1.5225 9.33 1.8525 9.52 2.2025 9.52H4.6525C5.0125 9.51 5.3325 9.33 5.5125 9.03Z"
                fill={fillColor}
            />
            <path
                d="M4.6625 10.51H2.2025C1.8525 10.51 1.5225 10.69 1.3425 11L0.1425 13C-0.0475 13.32 -0.0475 13.71 0.1425 14.03L1.3425 16.03C1.5225 16.33 1.8525 16.52 2.2025 16.52H4.6625C5.0125 16.52 5.3425 16.34 5.5225 16.03L6.7225 14.03C6.9125 13.71 6.9125 13.32 6.7225 13L5.5225 11C5.3325 10.7 5.0125 10.51 4.6625 10.51Z"
                fill={fillColor}
            />
            <path
                d="M7.5825 14.49L6.3825 16.49C6.1925 16.81 6.1925 17.2 6.3825 17.52L7.5825 19.52C7.7625 19.82 8.0925 20.01 8.4425 20.01H10.9025C11.2525 20.01 11.5825 19.83 11.7625 19.52L12.9625 17.52C13.1525 17.2 13.1525 16.81 12.9625 16.49L11.7625 14.49C11.5825 14.19 11.2525 14 10.9025 14H8.4425C8.0925 14 7.7625 14.18 7.5825 14.49Z"
                fill={fillColor}
            />
        </svg>
    );
};

export const FavouriteIcon = ({ fillColor }) => {
    return (
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.55696 13.6975L12.707 16.2075C13.467 16.6675 14.397 15.9875 14.197 15.1275L13.097 10.4075L16.767 7.2275C17.437 6.6475 17.077 5.5475 16.197 5.4775L11.367 5.0675L9.47696 0.6075C9.13696 -0.2025 7.97696 -0.2025 7.63696 0.6075L5.74696 5.0575L0.916957 5.4675C0.0369574 5.5375 -0.323043 6.6375 0.346957 7.2175L4.01696 10.3975L2.91696 15.1175C2.71696 15.9775 3.64696 16.6575 4.40696 16.1975L8.55696 13.6975Z"
                fill={fillColor}
            />
        </svg>
    );
};

export const OrganizationIcon = ({ fillColor }) => {
    return (
        <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                fill={fillColor}
            />
        </svg>
    );
};
export const DownArrowIcon = () => {
    return (
        <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.412691 2.38304L4.02209 5.99244C4.56559 6.53594 5.44356 6.53594 5.98706 5.99244L9.59646 2.38304C10.4605 1.50508 9.8473 1.82089e-09 8.60701 1.66113e-08L1.40214 1.02528e-07C0.147908 1.17485e-07 -0.465272 1.50508 0.412691 2.38304Z"
                fill="#969696"
            />
        </svg>
    );
};
export const UpwardArrowIcon = () => {
    return (
        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.70108 4.81813L6.09168 1.20873C5.54818 0.66523 4.67021 0.66523 4.12671 1.20873L0.517313 4.81813C-0.346714 5.69609 0.266466 7.20117 1.50676 7.20117L8.71163 7.20117C9.96586 7.20117 10.579 5.69609 9.70108 4.81813Z"
                fill="#585757"
            />
        </svg>
    );
};
export const ExploreIcon = ({ fillColor }) => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.0057 8.80503H9.37336L9.14923 8.58891C9.93368 7.67639 10.4059 6.49171 10.4059 5.20297C10.4059 2.32933 8.07662 0 5.20297 0C2.32933 0 0 2.32933 0 5.20297C0 8.07662 2.32933 10.4059 5.20297 10.4059C6.49171 10.4059 7.67639 9.93368 8.58891 9.14923L8.80503 9.37336V10.0057L12.8073 14L14 12.8073L10.0057 8.80503ZM5.20297 8.80503C3.20983 8.80503 1.60091 7.19611 1.60091 5.20297C1.60091 3.20983 3.20983 1.60091 5.20297 1.60091C7.19611 1.60091 8.80503 3.20983 8.80503 5.20297C8.80503 7.19611 7.19611 8.80503 5.20297 8.80503ZM3.46598 7.60435L5.17896 6.37965L6.89194 7.60435L6.22756 5.63522L7.94854 4.43453H5.85134L5.17896 2.40137L4.50658 4.43453H2.40137L4.11435 5.62722L3.46598 7.60435Z"
                fill={fillColor}
            />
        </svg>
    );
};

export const LandscapeIcon = () => {
    return (
        <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.1992 0.405L8.2492 4.335L10.4992 7.335C10.8292 7.775 10.7392 8.405 10.2992 8.735C9.8592 9.065 9.2292 8.985 8.8992 8.535C7.8492 7.135 6.5892 5.465 5.7992 4.395C5.3992 3.865 4.5992 3.865 4.1992 4.395L0.199199 9.725C-0.290801 10.395 0.179199 11.335 0.999199 11.335H18.9992C19.8192 11.335 20.2892 10.395 19.7992 9.735L12.7992 0.405C12.3992 -0.135 11.5992 -0.135 11.1992 0.405Z"
                fill="#585757"
            />
        </svg>
    );
};
export const SettingsIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.1593 10.98C17.1993 10.66 17.2293 10.34 17.2293 10C17.2293 9.66 17.1993 9.34 17.1593 9.02L19.2693 7.37C19.4593 7.22 19.5093 6.95 19.3893 6.73L17.3893 3.27C17.2693 3.05 16.9993 2.97 16.7793 3.05L14.2893 4.05C13.7693 3.65 13.2093 3.32 12.5993 3.07L12.2193 0.42C12.1893 0.18 11.9793 0 11.7293 0H7.72933C7.47933 0 7.26933 0.18 7.23933 0.42L6.85933 3.07C6.24933 3.32 5.68933 3.66 5.16933 4.05L2.67933 3.05C2.44933 2.96 2.18933 3.05 2.06933 3.27L0.0693316 6.73C-0.0606684 6.95 -0.000668393 7.22 0.189332 7.37L2.29933 9.02C2.25933 9.34 2.22933 9.67 2.22933 10C2.22933 10.33 2.25933 10.66 2.29933 10.98L0.189332 12.63C-0.000668393 12.78 -0.0506684 13.05 0.0693316 13.27L2.06933 16.73C2.18933 16.95 2.45933 17.03 2.67933 16.95L5.16933 15.95C5.68933 16.35 6.24933 16.68 6.85933 16.93L7.23933 19.58C7.26933 19.82 7.47933 20 7.72933 20H11.7293C11.9793 20 12.1893 19.82 12.2193 19.58L12.5993 16.93C13.2093 16.68 13.7693 16.34 14.2893 15.95L16.7793 16.95C17.0093 17.04 17.2693 16.95 17.3893 16.73L19.3893 13.27C19.5093 13.05 19.4593 12.78 19.2693 12.63L17.1593 10.98ZM9.72933 13.5C7.79933 13.5 6.22933 11.93 6.22933 10C6.22933 8.07 7.79933 6.5 9.72933 6.5C11.6593 6.5 13.2293 8.07 13.2293 10C13.2293 11.93 11.6593 13.5 9.72933 13.5Z"
                fill="#585757"
            />
        </svg>
    );
};

export const LogoutIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.00359 0C8.45359 0 8.00359 0.45 8.00359 1V9C8.00359 9.55 8.45359 10 9.00359 10C9.55359 10 10.0036 9.55 10.0036 9V1C10.0036 0.45 9.55359 0 9.00359 0ZM14.1436 2.86C13.7536 3.25 13.7636 3.86 14.1336 4.25C15.2636 5.45 15.9636 7.05 16.0036 8.82C16.0936 12.65 12.9236 15.95 9.09359 15.99C5.18359 16.05 2.00359 12.9 2.00359 9C2.00359 7.16 2.71359 5.49 3.87359 4.24C4.24359 3.85 4.24359 3.24 3.86359 2.86C3.46359 2.46 2.81359 2.47 2.43359 2.88C0.983593 4.42 0.0735926 6.47 0.00359261 8.74C-0.136407 13.62 3.83359 17.84 8.71359 17.99C13.8136 18.15 18.0036 14.06 18.0036 8.99C18.0036 6.62 17.0836 4.48 15.5836 2.88C15.2036 2.47 14.5436 2.46 14.1436 2.86Z"
                fill="#585757"
            />
        </svg>
    );
};
