function Tag({
    text,
    textColor = 'text-tagColor',
    bg = '',
    className = '',
    rounded = 'rounded-md',
    textClassName = '',
    icon,
    onClickOnIcon,
    ...props
}) {
    return (
        <p className={`${rounded} flex items-center  w-fit   ${bg} ${textColor}  ${className}`} {...props}>
            {icon && (
                <span className="cursor-pointer mr-1" onClick={onClickOnIcon}>
                    {icon}
                </span>
            )}
            <span className={`${textClassName}`}>{text}</span>{' '}
        </p>
    );
}

export default Tag;
