import { useController, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Input from '../../ui/Input';
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';
import CharacterLimit from '../../ui/CharacterLimit';
import { createProject, getProjects, updateProject } from '../../apis/projects/projects';
import { useLoadingState } from '../../hooks/useLoader';
import Loader from '../../ui/Loader';
import { getProjectsList } from '../../apis/bounties/bountyDraft';

function FormForCreatingProject({
    closeAfterCreation,
    edit,
    projectDetails,
    onUpdation,
    startCreating,
    stopCreating,
    setProjects,
    setProjectsAfterCreatingNewProject,
    selectAfterCreation,
    from,
}) {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm(
        edit
            ? {
                  mode: 'onChange',
                  defaultValues: {
                      projectTitle: projectDetails.title,
                      projectDescription: projectDetails.description,
                  },
              }
            : {
                  mode: 'onChange',
              }
    );
    const { id } = useParams();
    const { loading, startLoading, stopLoading } = useLoadingState();
    const { field: projectTitleField } = useController({
        name: 'projectTitle',
        control,
        rules: {
            required: 'Please enter a title',
            maxLength: {
                value: 100,
                message: 'The maximum length is 100 characters',
            },
        },
    });
    const { field: projectDescriptionField } = useController({
        name: 'projectDescription',
        control,
        rules: {
            required: 'Please enter a description',
            maxLength: {
                value: 1000,
                message: 'The maximum length is 1,000 characters',
            },
        },
    });

    const onSubmit = async (data) => {
        const payload = {
            title: data.projectTitle,
            description: data.projectDescription,
        };

        if (edit) {
            startLoading();
            const response = await updateProject(projectDetails._id, payload);
            stopLoading();
            onUpdation(projectDetails._id, payload);

            if (response === 200) closeAfterCreation();
        }
        if (!edit) {
            startLoading();
            const response = await createProject({ ...payload, organisationId: id });

            stopLoading();
            if (setProjectsAfterCreatingNewProject) {
                const data = await getProjectsList(id);

                setProjectsAfterCreatingNewProject(() => data);
                const foundProject = data.find((project) => project.title === payload.title);

                selectAfterCreation(payload.title, foundProject._id);
            } else {
                try {
                    if (startCreating) {
                        startCreating();
                        const data = await getProjects(id);

                        setProjects(data);
                        stopCreating();
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            if (response === 201) closeAfterCreation();
        }
    };

    return (
        <div className=" flex gap-3 items-start    ">
            <form
                onSubmit={(e) => {
                    e.stopPropagation();
                    handleSubmit(onSubmit)(e);
                }}
                className=" w-[100%] "
            >
                <div className="mb-6 pr-12 ">
                    <Input
                        {...projectTitleField}
                        type="transparent"
                        error={errors?.projectTitle?.message}
                        name="projectTitle"
                        placeholder={'Project Title *'}
                        transparentTextSize={'text-2xl'}
                        className={'w-[100%] '}
                    />
                    <p className=" text-secondaryInput text-xs">
                        <span className="font-semibold ">({'character limit'}</span> :{' '}
                        <span>{projectTitleField.value ? projectTitleField.value.length : 0}/100)</span>
                    </p>
                </div>

                <div className="mb-6">
                    <Heading type="xsmall" className="capitalize  text-primaryInput mb-2">
                        Project Description <sup className="text-lg relative top-[1px]">*</sup>
                    </Heading>

                    <Input
                        {...projectDescriptionField}
                        error={errors?.projectDescription?.message}
                        type="textArea"
                        name="projectDescription"
                        placeholder={"Briefly describe what the project's objective is ?"}
                        className={'w-[100%]'}
                    />
                    <CharacterLimit min={projectDescriptionField?.value?.length} max={1000} />
                </div>
                <div>
                    <Button type="submit" variant="secondary" size="large" width="w-[30%]">
                        {loading ? <Loader /> : edit ? 'Update Project' : 'Create Project'}
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default FormForCreatingProject;
