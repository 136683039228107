import { forwardRef } from 'react';

const WhiteCard = forwardRef(function WhiteCard(
    { children, verticalMargin = 'my-6', shadow = 'shadow-md', className, ...props },
    ref
) {
    return (
        <div {...props} ref={ref} className={`bg-white ${shadow}  ${verticalMargin}  rounded-md ${className} `}>
            {children}
        </div>
    );
});

export default WhiteCard;
