import { useContext } from 'react';
import { BountiesContext } from '../../../contexts/BountiesContext';
import { useBountyFields } from '../../../hooks/useBountyFields';
import mobileFrame from '../../../assets/mobileFrame.png';
import Heading from '../../../ui/Heading';
import SubHeading from '../../../ui/SubHeading';
import { CalendarIcon } from '../../../assets/icons/CalendarIcon';
import { ClockIcon } from '../../../assets/icons/ClockIcon';
import Button from '../../../ui/Button';
function NoPreviewAvailable({ step }) {
    const { state } = useContext(BountiesContext);
    const { titleField } = useBountyFields();

    return (
        <div
            className="relative w-[300px] h-[610px]     "
            style={{
                backgroundImage: `url(${mobileFrame})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            {/* <header className="bg-previewDark absolute text-white  top-[3px] left-[8px] right-[8px] rounded-t-[44px] p-3   h-24  ">
                <div className="flex items-center justify-between px-3 ">
                    <p className="text-sm">9:41</p>
                    <div className="flex gap-1 ">
                        <Networks />
                        <Wifi />
                        <Battery />
                    </div>
                </div>
                <div className="flex gap-2 m-3  items-center justify-between ">
                    <PreviewBack />
                    <p className="flex-1">Bounty</p>
                    <ThreeVerticalDots />
                </div>
            </header> */}
            <main className="absolute  top-28 left-6 right-6 ">
                <div>
                    <p className="text-xs italic mt-2  "> No Preview Available ,add microtask</p>
                </div>
            </main>

            <footer className="absolute bottom-6 left-6 right-6">
                {/* <Button variant="preview" size="roundPreview">
                    Add Deliverables & Tips
                </Button> */}
            </footer>
        </div>
    );
}

export default NoPreviewAvailable;
