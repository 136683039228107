import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Button from '../../../ui/Button';
import Heading from '../../../ui/Heading';
import WhiteCard from '../../../ui/WhiteCard';
import AvatarImage from '../../../ui/AvatarImage';
import CreateProjectModal from '../../projects/CreateProjectModal';
import Modal from '../../../ui/Modal';
import { getOrganizationMembers, getOrganizationStats } from '../../../apis/oragnisations/organisation';
import { useEffect, useState } from 'react';
import Loader from '../../../ui/Loader';
import InviteMemberModal from '../../members/InviteMemberModal';
import { useLoadingState } from '../../../hooks/useLoader';

function LeftSection({ roles, orgMembers, stats }) {
    const navigate = useNavigate();
    const { loading, startLoading, stopLoading } = useLoadingState();
    // const [orgMembers, setOrgMembers] = useState([]);
    // const [stats, setStats] = useState({});
    const { id } = useParams();
    const isAdmin = roles.includes('isAdmin');
    const isBountyManager = roles.includes('isBountyManager');
    const isBountyValidator = roles.includes('isBountyValidator');
    const isTreasurer = roles.includes('isTreasurer');
    // useEffect(
    //     function () {
    //         const fetchData = async () => {
    //             try {
    //                 startLoading();
    //                 const [response5, response1] = await Promise.all([
    //                     getOrganizationStats(id),
    //                     getOrganizationMembers(id),
    //                 ]);

    //                 console.log(response5, response1, 'get fund allocated');
    //                 setStats(response5);
    //                 setOrgMembers(response1);
    //                 stopLoading();
    //             } catch (error) {
    //                 console.error(error, 'erroe');
    //             }
    //         };
    //         fetchData();
    //     },
    //     [id]
    // );

    const { liveBountyCount, liveProjectCount, proposalCount, requestCount, unvalidatedBountyCount } = stats;

    return (
        <div className="">
            {isAdmin && (
                <WhiteCard className="px-6 py-5">
                    {!loading ? (
                        <div>
                            <div className="flex justify-between items-center">
                                <Heading className={'text-primaryInput font-medium'} type={'large'}>
                                    Members & Roles
                                </Heading>

                                <Modal>
                                    <Modal.Button
                                        className="flex gap-2 cursor-pointer items-center"
                                        opens={'invitemember'}
                                    >
                                        <Button size="xsmall"> + Add Member</Button>
                                    </Modal.Button>
                                    <Modal.Window name={'invitemember'}>
                                        <InviteMemberModal orgMembers={orgMembers} />
                                    </Modal.Window>
                                </Modal>
                            </div>
                            <div className="flex justify-between items-end mt-7">
                                <div className="flex -space-x-4 rtl:space-x-reverse">
                                    {orgMembers.slice(0, 4).map((member, index) => {
                                        const relativeLeft = `-left-${4 * index}`;

                                        return (
                                            <div key={member.profileImage + index}>
                                                <img
                                                    className="w-12 h-12 border-2 border-white rounded-full "
                                                    src={member.profileImage}
                                                    alt=""
                                                />
                                            </div>
                                            // <AvatarImage
                                            //     key={member.uid + index + Math.random()}
                                            //     className={`border-2 border-white relative ${relativeLeft} `}
                                            //     w="w-[48px]"
                                            //     h="h-[48px]"
                                            //     url={member.profileImage}
                                            // />
                                        );
                                    })}
                                    {orgMembers.length > 4 && (
                                        <span
                                            onClick={() => navigate('view-members')}
                                            className="flex items-center justify-center w-12 h-12 text-xs font-medium text-white bg-[#06B6D4] cursor-pointer border-2 border-white rounded-full  "
                                            href="#"
                                        >
                                            +{orgMembers.length - 4}
                                        </span>
                                    )}
                                </div>
                                <Button
                                    onClick={() => navigate('view-members')}
                                    className=" w-36 text-end"
                                    size="none"
                                    variant="transparent"
                                >
                                    View Members &gt;{' '}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <Loader />
                    )}
                </WhiteCard>
            )}
            {(isAdmin || isBountyManager) && (
                <WhiteCard className="py-5 px-6">
                    {!loading ? (
                        <>
                            <div className="flex justify-between items-center">
                                <Heading className={'text-primaryInput font-medium'} type={'large'}>
                                    Bounties
                                </Heading>

                                <Button onClick={() => navigate(`/${id}/createBounty`)} size="xsmall">
                                    {' '}
                                    + Add Bounty
                                </Button>
                            </div>
                            <div className="flex justify-between items-end mt-7">
                                <div className="flex  gap-2 flex-col">
                                    <p className="text-primaryInput text-5xl font-medium ">{liveBountyCount}</p>
                                    <p className="text-secondaryInput">Live Bounties</p>
                                </div>
                                <Button
                                    onClick={() => navigate('view-bounties')}
                                    className=" w-36 text-end"
                                    size="none"
                                    variant="transparent"
                                >
                                    View Bounties &gt;{' '}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <Loader />
                    )}
                </WhiteCard>
            )}
            {(isAdmin || isBountyManager) && (
                <WhiteCard className="py-5 px-6">
                    {!loading ? (
                        <>
                            <div className="flex justify-between items-center">
                                <Heading className={'text-primaryInput font-medium'} type={'large'}>
                                    Projects
                                </Heading>

                                <Modal>
                                    <Modal.Button
                                        className="flex gap-2 cursor-pointer items-center"
                                        opens={'createproject'}
                                    >
                                        <Button size="xsmall">+ Add Project</Button>
                                    </Modal.Button>
                                    <Modal.Window name={'createproject'}>
                                        <CreateProjectModal />
                                    </Modal.Window>
                                </Modal>
                            </div>
                            <div className="flex justify-between items-end mt-7">
                                <div className="flex  gap-2 flex-col">
                                    <p className="text-primaryInput text-5xl font-medium ">{liveProjectCount}</p>
                                    <p className="text-secondaryInput">Live Projects</p>
                                </div>
                                <Button
                                    onClick={() => navigate('view-projects')}
                                    className=" w-36 text-end"
                                    size="none"
                                    variant="transparent"
                                >
                                    View Projects &gt;{' '}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <Loader />
                    )}
                </WhiteCard>
            )}
            {(isAdmin || isTreasurer) && (
                <WhiteCard className="py-5 px-6">
                    {!loading ? (
                        <>
                            <div className="flex justify-between items-center">
                                <Heading className={'text-primaryInput font-medium'} type={'large'}>
                                    Proposals & Requests
                                </Heading>
                            </div>
                            <div className="flex justify-between items-end mt-7">
                                <div className="flex items-center gap-7">
                                    <div className="flex  gap-2 flex-col">
                                        <p className="text-primaryInput text-5xl font-medium ">{proposalCount}</p>
                                        <p className="text-secondaryInput">Proposals</p>
                                    </div>
                                    <div className="flex  gap-2 flex-col">
                                        <p className="text-primaryInput text-5xl font-medium ">{requestCount}</p>
                                        <p className="text-secondaryInput">Requests</p>
                                    </div>
                                </div>

                                <Button
                                    onClick={() => navigate('view-proposalsandrequests')}
                                    className=" w-36 text-end"
                                    size="none"
                                    variant="transparent"
                                >
                                    View All &gt;{' '}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <Loader />
                    )}
                </WhiteCard>
            )}
            {(isAdmin || isBountyValidator) && (
                <WhiteCard className="py-5 px-6">
                    {!loading ? (
                        <>
                            <div className="flex justify-between items-center">
                                <Heading className={'text-primaryInput font-medium'} type={'large'}>
                                    Validation
                                </Heading>
                            </div>
                            <div className="flex justify-between items-end mt-7">
                                <div className="flex  gap-2 flex-col">
                                    <p className="text-primaryInput text-5xl font-medium ">{unvalidatedBountyCount}</p>
                                    <p className="text-secondaryInput">Unvalidated bounties</p>
                                </div>
                                <Button
                                    onClick={() => navigate('validation')}
                                    className=" w-36 text-end"
                                    size="none"
                                    variant="transparent"
                                >
                                    View Validations &gt;{' '}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <Loader />
                    )}
                </WhiteCard>
            )}
        </div>
    );
}

export default LeftSection;
