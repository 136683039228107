function Legend({ shield, text }) {
    return (
        <p className="flex items-center gap-1 py-1 px-2 rounded-full border border-borderColor ">
            <span className="">{shield}</span>
            <span className="text-sm font-medium">{text}</span>
        </p>
    );
}

export default Legend;
