import { useEffect, useRef } from 'react';
import Overlay from '../../ui/Overlay';
import Button from '../../ui/Button';
import Feedbackclose from '../../assets/icons/Feedbackclose';
import { deleteInvitation } from '../../apis/user/user';
import { toast } from 'sonner';
import { useLoadingState } from '../../hooks/useLoader';
import Loader from '../../ui/Loader';

function DeleteInvitationModal({ close, member, onDelete }) {
    const myRef = useRef({ close });
    const { loading, startLoading, stopLoading } = useLoadingState();
    useEffect(
        function () {
            function handleClick(e) {
                if (myRef.current && !myRef.current.contains(e.target)) {
                    close();
                }
            }

            document.addEventListener('click', handleClick, true);

            return () => document.removeEventListener('click', handleClick, true);
        },
        [close]
    );
    return (
        <Overlay>
            <div
                ref={myRef}
                className="absolute rounded-lg left-[50%] w-[528px]  py-10 px-14   bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
            >
                <div>
                    <Button
                        onClick={close}
                        className=" border-0 absolute top-3  right-4 "
                        variant="tertiary"
                        size="circle"
                    >
                        <Feedbackclose />
                    </Button>
                </div>
                <p className="text-xl">
                    Are you sure you want to Delete the invitation sent to{' '}
                    <span className="font-bold"> {member.userName}</span> ?
                </p>
                <div className="flex items-center mt-8 gap-3">
                    <Button
                        onClick={async () => {
                            startLoading();
                            const { success } = await deleteInvitation(member._id);
                            if (success) {
                                toast.success('Invitation successfully deleted.');
                                close();
                                onDelete(member._id);
                                stopLoading();
                            }
                        }}
                        size="round"
                        variant="primary"
                    >
                        {loading ? <Loader /> : 'Delete'}
                    </Button>
                    <Button onClick={close} size="round" variant="tertiary">
                        Cancel
                    </Button>
                </div>
            </div>
        </Overlay>
    );
}

export default DeleteInvitationModal;
