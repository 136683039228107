import CloseCircle from '../../../assets/icons/CloseCircle';
import Button from '../../../ui/Button';
import dropCards from '../../../assets/dropCards.png';
import Modal from '../../../ui/Modal';
import MintModal from './MintModal';
import { useContext } from 'react';
import { ProfileContext } from '../../../contexts/ProfileContext';
import Loader from '../../../ui/Loader';
import { AuthContext } from '../../../contexts/AuthContext';
// import WhyMintModal from './WhyMintModal';

function UnclaimedBack({ certificateId }) {
    const { mintYourIc, claimYourIc, minting, claiming } = useContext(ProfileContext);
    const { state } = useContext(AuthContext);
    const { user } = state;
    const userProfile = user[0];

    return (
        <div className="bg-[#F5FAFF] border flex flex-col items-center justify-center relative border-[#EAEAEA] rounded-md  px-[29.2px] h-full w-full ">
            <Button className="bg-none border-0 absolute top-3  right-3 " variant="tertiary" size="circle">
                <CloseCircle />
            </Button>
            <img className="mx-auto w-11 my-6" src={dropCards} alt="" />
            <div>
                <Button
                    onClick={() => {
                        claimYourIc(certificateId);
                    }}
                    className="text-xs font-medium"
                    size="round"
                >
                    {claiming ? <Loader w="w-4" h="h-4" /> : 'Claim'}
                </Button>
                <p className="text-[10px] font-semibold text-center my-3 ">OR</p>

                <Button
                    onClick={() => {
                        mintYourIc(certificateId, userProfile?.walletAddress);
                    }}
                    className="text-xs font-medium"
                    size="round"
                >
                    {minting ? <Loader w="w-4" h="h-4" /> : ' Mint as NFT'}
                </Button>
            </div>
            <div>
                <Modal>
                    <Modal.Button
                        className="text-xs cursor-pointer text-center my-3 underline font-medium "
                        opens="whymint"
                    >
                        Why Mint ?
                    </Modal.Button>
                    <Modal.Window name="whymint">
                        <MintModal />
                    </Modal.Window>
                </Modal>
            </div>
        </div>
    );
}

export default UnclaimedBack;
