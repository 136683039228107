function CloseCircle() {
    return (
        <svg width="28" height="28" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 1.5C4.8525 1.5 1.5 4.8525 1.5 9C1.5 13.1475 4.8525 16.5 9 16.5C13.1475 16.5 16.5 13.1475 16.5 9C16.5 4.8525 13.1475 1.5 9 1.5ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15ZM11.6925 5.25L9 7.9425L6.3075 5.25L5.25 6.3075L7.9425 9L5.25 11.6925L6.3075 12.75L9 10.0575L11.6925 12.75L12.75 11.6925L10.0575 9L12.75 6.3075L11.6925 5.25Z"
                fill="#A9A9A9"
            />
        </svg>
    );
}

export default CloseCircle;
