import Input from '../../ui/Input';
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';
import BackButton from '../../assets/icons/BackButton';
import { useNavigate } from 'react-router-dom';
function AttesterInputStepThree({ setStep, register, errors }) {
    const navigate = useNavigate();
    return (
        <div>
            <div className="w-[70%] mb-9">
                <div className="flex items-center">
                    <BackButton onClick={() => navigate(-1)} />
                    <Heading type={'pageHeading'} className={'text-lightBlack ml-3'}>
                        Proof Of Identity
                    </Heading>
                </div>
                <p className="mt-2">
                    In the table below you will find Impact Claims waiting to be evaluated for their authenticity, if
                    found to be a credible impact claim in your impartial evaluation, then attest the impact.
                </p>
            </div>
            <div className="mb-7">
                <Heading type="medium" className="capitalize mb-2">
                    Social Links
                </Heading>
                <div className="flex flex-col gap-2">
                    <Input
                        {...register('link1', {
                            required: 'Please enter your social link.',
                            pattern: {
                                value: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g,
                                message: 'Please enter valid url', // JS only: <p>error message</p> TS only support string
                            },
                        })}
                        type="text"
                        error={errors?.link1?.message}
                        placeholder={'Link 1'}
                        className={'w-[100%]'}
                    />

                    <Input
                        {...register('link2', {
                            pattern: {
                                value: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g,
                                message: 'Please enter valid url', // JS only: <p>error message</p> TS only support string
                            },
                        })}
                        type="text"
                        error={errors?.link2?.message}
                        placeholder={'Link 2(optional)'}
                        className={'w-[100%]'}
                    />
                    <Input
                        {...register('link3', {
                            pattern: {
                                value: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g,
                                message: 'Please enter valid url', // JS only: <p>error message</p> TS only support string
                            },
                        })}
                        type="text"
                        error={errors?.link32?.message}
                        placeholder={'Link 3(optional)'}
                        className={'w-[100%]'}
                    />
                </div>
            </div>

            <div className="flex justify-between items-center mt-16">
                <Button onClick={() => setStep(2)} className="bg-white" variant="tertiary" size="small">
                    Back
                </Button>
                <Button onClick={() => setStep(4)} size="small">
                    Next
                </Button>
            </div>
        </div>
    );
}

export default AttesterInputStepThree;
