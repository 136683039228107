import { useEffect, useRef, useState } from 'react';
import Overlay from '../../../ui/Overlay';
import Button from '../../../ui/Button';
import Feedbackclose from '../../../assets/icons/Feedbackclose';
import Heading from '../../../ui/Heading';
import WalletConnectButton from '../../walletconnectUI/WalletConnectButton';
import Dropdown from '../../../ui/Dropdown';
import { getTokensForBudget } from '../../../apis/general/general';
import { useController, useForm } from 'react-hook-form';
import Input from '../../../ui/Input';
import { clusterApiUrl, Connection, sendAndConfirmTransaction } from '@solana/web3.js';
import { executorToAddFunds } from '../../../blockchainInteraction/addFunds';
import { getProvider } from '../../../helpers/getProvider';
import { useLoadingState } from '../../../hooks/useLoader';
import Loader from '../../../ui/Loader';
import { useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import { toast } from 'sonner';
import { RPC_ENDPOINT } from '../../../constants/apiPaths';

function AddFundsInOrganisation({ close, multisig, fetchFunds, setOrgFunds, orgFunds }) {
    const [tokens, setTokens] = useState([]);

    const myRef = useRef({ close });
    const { loading: transferring, startLoading: startTransferring, stopLoading: stopTransferring } = useLoadingState();
    const { publicKey } = useWallet();

    const {
        register,
        handleSubmit,
        control,
        getValues,
        trigger,
        formState: { errors },
        setError,
        reset,
        setValue,

        clearErrors,
    } = useForm({
        mode: 'onChange',
    });

    const { field: tokenField } = useController({
        name: 'token',
        control,
        rules: { required: 'Token is required' },
    });

    const { field: totalRewardAmountField } = useController({
        name: 'totalRewardAmount',
        control,
        rules: {
            required: 'Budget is required',
            // validate: (value) => {
            //     console.log(distributionModeField.value);
            //     if (distributionModeField.value === 'Rank based distribution') {
            //         console.log(getValues('ranks'), value);
            //         const ranksAmount = getValues('ranks')
            //             .filter((rank) => rank.amount)
            //             .map((c) => Number(c.amount))
            //             .reduce((acc, curr) => acc + curr, 0);
            //         console.log(ranksAmount, 'ranks here');
            //         if (ranksAmount > Number(value)) return 'Please add more fund';
            //         return true;
            //     }
            //     return true;
            // },
        },
    });
    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    const data = await getTokensForBudget();
                    setTokens(data);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();

            function handleClick(e) {
                if (myRef.current && !myRef.current.contains(e.target)) {
                    close();
                }
            }

            document.addEventListener('click', handleClick, true);

            return () => document.removeEventListener('click', handleClick, true);
        },
        [close]
    );

    const onSubmit = async (data) => {
        const connection = new Connection(RPC_ENDPOINT, 'confirmed');

        try {
            if (!publicKey?.toBase58()) {
                throw { code: 402, message: 'Please connect your wallet' };
            }
            startTransferring();
            const transaction = await executorToAddFunds(
                new PublicKey(tokenField.value.address),
                Number(totalRewardAmountField.value),
                new PublicKey(multisig),
                publicKey,
                connection
            );

            const signedTransaction = await getProvider().signTransaction(transaction);

            const signature = await sendAndConfirmTransaction(connection, signedTransaction);

            const fundsAfterTransferring = orgFunds.map((token) => {
                if (token?.name?.toLowerCase() === tokenField?.value?.name?.toLowerCase()) {
                    return { ...token, amount: Number(token.amount) + Number(totalRewardAmountField.value) };
                }
                return token;
            });
            setOrgFunds(fundsAfterTransferring);
            close();
            toast.success('Funds have been successfully transferred. ');
        } catch (error) {
            console.log(error);
            if (error?.code === 402) {
                setError('wallet', {
                    type: 'manual',
                    message: 'Please connect your wallet',
                });

                return;
            }
            console.log(error, 'see the errors');
            close();
            toast.error('sorry unable to transer funds');
        } finally {
            stopTransferring();
        }
    };

    return (
        <Overlay>
            <div
                ref={myRef}
                className="absolute rounded-lg left-[50%] w-[625px]  p-2   bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
            >
                <div className="relative modal max-h-[80vh]   p-8  ">
                    <Button
                        onClick={close}
                        className=" border-0 absolute top-3  right-4 "
                        variant="tertiary"
                        size="circle"
                    >
                        <Feedbackclose />
                    </Button>
                    <form onSubmit={handleSubmit(onSubmit)} action="">
                        <div className="border-b border-borderColor pb-6">
                            <div className="flex gap-3">
                                <Heading type="large" className="capitalize font-bold  text-lightBlack">
                                    Add Funds
                                </Heading>
                            </div>
                            <p className="text-sm">Add Funds to your organisation</p>
                        </div>
                        <div className="mt-6">
                            <div className="flex gap-3">
                                <Heading type="medium" className="capitalize font-bold  text-lightBlack">
                                    Connect your wallet
                                </Heading>
                            </div>
                            <p className="text-sm">
                                To sign and authorize organizational transactions, vote on proposals etc.{' '}
                            </p>
                        </div>
                        <div className="mt-3">
                            <WalletConnectButton widthPer="230px" />
                            {errors?.wallet?.message && <p className="text-foundryRed">{errors?.wallet?.message}</p>}
                        </div>
                        <div className="mt-11">
                            <div className="flex gap-3">
                                <p className="text-lg font-semibold">Total Amount</p>
                            </div>
                            <div className="flex items-center mt-2  ">
                                <div className="relative ">
                                    <Dropdown
                                        modifyLabel={(listItem) => {
                                            return (
                                                <span className="flex items-center gap-5">
                                                    <img
                                                        className="w-5 h-5 rounded-full "
                                                        src={listItem?.image}
                                                        alt=""
                                                    />
                                                    <span>{listItem?.symbol} </span>
                                                </span>
                                            );
                                        }}
                                        data={tokens.filter((token) => token.chain === 'SOLANA')}
                                        isSymbol={true}
                                        selected={tokenField.value}
                                        onSelected={tokenField.onChange}
                                        noSelectedText="Choose Token"
                                        btnClass="rounded-e-none"
                                        w="w-40"
                                    />
                                </div>
                                <Input
                                    {...totalRewardAmountField}
                                    type="number"
                                    placeholder={'Amount...'}
                                    className={'w-[100%] rounded-s-none '}
                                />
                            </div>
                            {(errors?.token?.message || errors?.totalRewardAmount?.message) && (
                                <p className="text-foundryRed">Please choose token and enter amount</p>
                            )}
                        </div>
                        <div className="flex mt-6 items-center justify-end">
                            <Button variant="primary" size="small">
                                {transferring ? <Loader /> : 'Transfer'}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </Overlay>
    );
}

export default AddFundsInOrganisation;
