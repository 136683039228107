/* global BigInt */
import * as multisig from '@sqds/multisig';
import { PublicKey, TransactionMessage, SystemProgram } from '@solana/web3.js';
import { Program, BN } from '@coral-xyz/anchor';
import * as token from '@solana/spl-token';
import { BountyManager, IDL } from '../types/bounty_manager';
import { BOUNTY_MANAGER_PROGRAM_ID } from '../constants/apiPaths';
import { getProvider } from '../helpers/getProvider';
import { VersionedTransaction } from '@solana/web3.js';

const bountyMangerProgramId = new PublicKey(BOUNTY_MANAGER_PROGRAM_ID);

function decodeAccountData(buffer) {
    // Example of decoding a simple struct
    const data = buffer.slice(0, 8); // Adjust based on your data layout
    const value = buffer.readUInt32LE(0);
    return { value }; // Adjust to match your data structure
}

export const executorForCreatingProposalForBountyValidation = async (
    connection,
    orgMultisig,
    walletAddress,
    bountyPubkey,
    winners,
    rewardTokenAddress
) => {
    const provider = getProvider();
    const program = new Program(/*<BountyManager>*/ IDL, bountyMangerProgramId.toBase58(), provider);

    const bountyInfo = await connection.getAccountInfo(bountyPubkey);

    const creator = new PublicKey(bountyInfo.data.slice(8 + 32, 8 + 32 + 32));

    const [vaultPda] = multisig.getVaultPda({
        multisigPda: orgMultisig,
        index: 0,
    });
    const instructions = [];
    const finalInstructions = [];
    let tokenAccount = null;
    let rewardTokenMint = null;

    if (rewardTokenAddress) {
        tokenAccount = token.getAssociatedTokenAddressSync(rewardTokenAddress, bountyPubkey, true);
        rewardTokenMint = await token.getMint(connection, rewardTokenAddress);
        const tokenAccountInfo = await token.getAccount(connection, tokenAccount);

        const remainingAmount =
            Number(tokenAccountInfo.amount) / 10 ** rewardTokenMint.decimals -
            winners.reduce((acc, curr) => acc + curr.amount, 0);

        // return;
        if (remainingAmount > 0) {
            winners.push({ pubkey: creator, amount: remainingAmount });
        }
        const remainingAccounts = [];

        for (let i = 0; i < winners.length; ++i) {
            remainingAccounts.push({
                pubkey: winners[i].pubkey,
                isSigner: false,
                isWritable: false,
            });
            remainingAccounts.push({
                pubkey: token.getAssociatedTokenAddressSync(rewardTokenAddress, winners[i].pubkey, true),
                isSigner: false,
                isWritable: true,
            });
        }
        const sendRewardsIx = await program.methods
            .transferRewards(winners.map((ele) => new BN(ele.amount * 10 ** rewardTokenMint.decimals)))
            .accounts({
                creator: vaultPda,
                bounty: bountyPubkey,
                rewardMint: rewardTokenAddress,
                tokenAccount,
                tokenProgram: token.TOKEN_PROGRAM_ID,
                associatedTokenProgram: token.ASSOCIATED_TOKEN_PROGRAM_ID,
                systemProgram: SystemProgram.programId,
            })
            .remainingAccounts(remainingAccounts)
            .instruction();
        instructions.push(sendRewardsIx);
    }

    const closeBountyIx = await program.methods
        .closeBounty()
        .accounts({
            bounty: bountyPubkey,
            creator: vaultPda,
            rewardMint: rewardTokenAddress,
            tokenAccount,
            tokenProgram: token.TOKEN_PROGRAM_ID,
        })
        .instruction();
    instructions.push(closeBountyIx);

    // create transaction message
    const transactionMessage = new TransactionMessage({
        payerKey: vaultPda,
        recentBlockhash: (await connection.getLatestBlockhash()).blockhash,
        instructions,
    });

    // Get the current multisig transaction index
    const multisigInfo = await multisig.accounts.Multisig.fromAccountAddress(connection, orgMultisig);
    const currentTransactionIndex = Number(multisigInfo.transactionIndex);
    const newTransactionIndex = BigInt(currentTransactionIndex + 1);

    const vaultTxCreateInstruction = multisig.instructions.vaultTransactionCreate({
        multisigPda: orgMultisig,
        transactionIndex: newTransactionIndex,
        creator: walletAddress,
        vaultIndex: 0,
        ephemeralSigners: 0,
        transactionMessage,
    });

    // transaction.add(vaultTxCreateInstruction);
    finalInstructions.push(vaultTxCreateInstruction);
    const proposalCreateInstruction = multisig.instructions.proposalCreate({
        multisigPda: orgMultisig,
        creator: walletAddress,
        transactionIndex: newTransactionIndex,
    });

    // transaction.add(proposalCreateInstruction);
    finalInstructions.push(proposalCreateInstruction);

    let blockhash = await connection.getLatestBlockhash().then((res) => res.blockhash);

    const message = new TransactionMessage({
        payerKey: walletAddress,
        recentBlockhash: blockhash,
        instructions: finalInstructions,
    }).compileToV0Message();

    const transaction = new VersionedTransaction(message);

    const proposalAddress = multisig.getProposalPda({
        multisigPda: orgMultisig,
        transactionIndex: newTransactionIndex,
    })[0];

    return { transaction, proposalAddress: proposalAddress.toBase58() };
};
