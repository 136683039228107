import { useNavigate } from 'react-router-dom';
import Button from '../../ui/Button';
import Heading from '../../ui/Heading';
import WhiteCard from '../../ui/WhiteCard';
import TextWithTooltip from '../../ui/TextWithTooltip';

function ImpactCard({
    type = '',
    typeColor = '',
    heading = '',
    img = '',
    stats = '',
    brief = '',
    btnText = '',
    mT = '',
    mR = '',
    tooltipText = '',
    navigateTo = '',
    rank = null,
}) {
    const navigate = useNavigate();
    return (
        <div className="mb-6">
            <TextWithTooltip className="leading-10" expandedTextWidth="w-[332px]" hoverOverText={heading}>
                <p className="text-[#404040] ">{tooltipText}</p>
            </TextWithTooltip>

            <WhiteCard verticalMargin="" className=" p-6">
                <div className="flex justify-between items-center">
                    <div className="flex  items-center  ">
                        <img className={`${mR} w-12 object-cover `} src={img} alt="trophyimage" />
                        <div>
                            <Heading className={`${typeColor}`} type={'large'}>
                                IMPACT
                            </Heading>
                            <Heading className={`font-semibold ${typeColor}`} type={'large'}>
                                {type}
                            </Heading>
                        </div>
                    </div>
                    <div className="flex flex-col items-center">
                        <p className={`${typeColor} text-[40px] leading-none font-semibold`}>{stats}</p>
                        {rank && <p className={`${typeColor} text-base font-medium`}>Rank #{rank} </p>}
                    </div>
                </div>
                <div className={`flex justify-between ${mT} items-center`}>
                    <p className="text-secondaryText whitespace-pre-wrap">{brief}</p>
                    <Button onClick={() => navigate(navigateTo)} className="flex-1 text-end" variant="transparent">
                        {btnText} &gt;{' '}
                    </Button>
                </div>
            </WhiteCard>
        </div>
    );
}

export default ImpactCard;
