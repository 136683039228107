import MembersAndRolesList from './MembersAndRolesList';

function MembersList() {
    return (
        <>
            <MembersAndRolesList />
        </>
    );
}

export default MembersList;
