import AvatarImage from '../../ui/AvatarImage';
import Table from '../../ui/Table';
import Tag from '../../ui/Tag';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import InviteMemberModal from './InviteMemberModal';
import StatsCard from '../../ui/StatsCard';
import WhiteCard from '../../ui/WhiteCard';
import { useEffect, useState } from 'react';
import { getOrganizationMembers } from '../../apis/oragnisations/organisation';
import { Connection } from '@solana/web3.js';
import { clusterApiUrl } from '@solana/web3.js';
import { executorToFetchThreshold } from '../../blockchainInteraction/fetchThreshold';
import { useLoadingState } from '../../hooks/useLoader';
import Loader from '../../ui/Loader';
import NoDataAvailable from '../../ui/NoDataAvailable';
import { RPC_ENDPOINT } from '../../constants/apiPaths';

function MembersAndRolesList() {
    const [particularOrg, _, roles] = useOutletContext();
    const [orgMembers, setOrgMembers] = useState([]);
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [treasurerThreshold, setTreasurerThreshold] = useState(0);
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const { treasurerMultisig } = particularOrg;

    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    startLoading();
                    const [response1] = await Promise.all([getOrganizationMembers(id)]);

                    setOrgMembers(response1);
                    stopLoading();
                } catch (error) {
                    console.error(error, 'erroe');
                }
            };

            const onExecution = async () => {
                const connection = new Connection(RPC_ENDPOINT, 'confirmed');

                try {
                    const fetchedThreshold = await executorToFetchThreshold(treasurerMultisig, connection);

                    setTreasurerThreshold(fetchedThreshold);
                } catch (error) {
                    console.log(error);
                }
            };

            fetchData();
            if (treasurerMultisig) onExecution();
        },
        [id]
    );
    let relativeAddress = location.pathname.split('/');
    relativeAddress.pop();
    relativeAddress = relativeAddress.join('/');
    const isAdmin = roles.includes('isAdmin');

    const initialCount = { totalMembers: orgMembers.length, bountyManagers: 0, bountyValidators: 0, treasurer: 0 };
    const membersCount = orgMembers.reduce((acc, item, index) => {
        return {
            ...acc,
            bountyManagers: item.isBountyManager ? acc.bountyManagers + 1 : acc.bountyManagers,
            bountyValidators: item.isBountyValidator ? acc.bountyValidators + 1 : acc.bountyValidators,
            treasurer: item.isTreasurer ? acc.treasurer + 1 : acc.treasurer,
        };
    }, initialCount);

    const adminFirstOrgMembers = [
        ...orgMembers.filter((member) => member.isAdmin),
        ...orgMembers.filter((member) => !member.isAdmin),
    ];

    return (
        <div>
            <WhiteCard className="w-fit p-6 flex gap-6">
                <StatsCard countHeading={'Total Members'} count={membersCount.totalMembers} />
                <StatsCard countHeading={'Bounty Managers'} count={membersCount.bountyManagers} />
                <StatsCard countHeading={'Bounty Validators'} count={membersCount.bountyValidators} />
                <StatsCard countHeading={'Treasurer'} count={membersCount.treasurer} />
            </WhiteCard>
            <WhiteCard className="flex p-6 font-semibold text-lightBlack gap-1 w-fit ">
                <p>Treasury Threshold:</p>
                <p>{treasurerThreshold}</p>
            </WhiteCard>

            <Table>
                {isAdmin && (
                    <Table.Description
                        description="Members & Roles"
                        secondaryBtn="Edit Members"
                        tertiaryBtn="Invitations/Requests"
                        primaryBtnModal="+ Add Member"
                        clickOnPrimary={() => {
                            navigate(`${relativeAddress}/add-member`);
                        }}
                        clickOnTertiary={() => navigate(`invitations`)}
                        clickOnSecondary={() => navigate(`${relativeAddress}/edit-member`)}
                        modalNeedToOpen={<InviteMemberModal orgMembers={orgMembers} />}
                    />
                )}
                <Table.Header>
                    <p className="text-sm font-semibold text-secondaryText ">Citizen ID</p>
                    <p className="text-sm font-semibold text-secondaryText ">Member Username</p>
                    <p className="text-sm  font-semibold text-secondaryText ">Roles</p>
                </Table.Header>
                {!loading ? (
                    adminFirstOrgMembers.length > 0 ? (
                        adminFirstOrgMembers.map((member, index) => {
                            return (
                                <Table.Row key={member.uid + index + Math.random()}>
                                    <p className="truncate w-28 ">{member.uid}</p>
                                    <div className="flex items-center gap-1 ">
                                        <AvatarImage w="w-8" h="h-8" url={member.profileImage} />
                                        <p className="text-lightBlack font-medium ">{member.userName}</p>
                                    </div>

                                    <div className="flex items-center  gap-[10px] ">
                                        <>
                                            {member.isAdmin && (
                                                <Tag
                                                    rounded="rounded-lg "
                                                    className="px-2 py-1 leading-3   "
                                                    textClassName="text-xs font-semibold"
                                                    bg="bg-foundryPurpleL"
                                                    textColor="text-foundryPurple"
                                                    text={'Admin'}
                                                />
                                            )}
                                            {member.isBountyValidator && (
                                                <Tag
                                                    rounded="rounded-lg "
                                                    className="px-2 py-1 leading-3   "
                                                    textClassName="text-xs font-semibold"
                                                    bg="bg-foundryGreenL"
                                                    textColor="text-foundryGreen"
                                                    text={'Validator'}
                                                />
                                            )}
                                            {member.isBountyManager && (
                                                <Tag
                                                    rounded="rounded-lg"
                                                    className="px-2 py-1 leading-3   "
                                                    textClassName="text-xs font-semibold"
                                                    bg="bg-foundryRedL"
                                                    textColor="text-foundryRed"
                                                    text={'Bounty Manager'}
                                                />
                                            )}
                                            {member.isTreasurer && (
                                                <Tag
                                                    rounded="rounded-lg"
                                                    className="px-2 py-1 leading-3   "
                                                    textClassName="text-xs font-semibold"
                                                    bg="bg-treasurerBg"
                                                    textColor="text-treasurerText"
                                                    text={'Treasurer'}
                                                />
                                            )}
                                        </>
                                    </div>
                                </Table.Row>
                            );
                        })
                    ) : (
                        <NoDataAvailable />
                    )
                ) : (
                    <div className="min-h-[20vh] flex justify-center items-center">
                        <Loader />
                    </div>
                )}
            </Table>
        </div>
    );
}

export default MembersAndRolesList;
