function BountiesIcon({ fillColor }) {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.5255 0.966797C3.81471 0.966797 0 4.79004 0 9.50083C0 14.2116 3.81471 18.0349 8.5255 18.0349C13.2448 18.0349 17.0681 14.2116 17.0681 9.50083C17.0681 4.79004 13.2448 0.966797 8.5255 0.966797ZM8.53426 16.3283C4.76221 16.3283 1.70703 13.2731 1.70703 9.50105C1.70703 5.72901 4.76221 2.67383 8.53426 2.67383C12.3063 2.67383 15.3615 5.72901 15.3615 9.50105C15.3615 13.2731 12.3063 16.3283 8.53426 16.3283ZM11.5203 8.64615C12.2287 8.64615 12.8004 8.07437 12.8004 7.36604C12.8004 6.65772 12.2287 6.08594 11.5203 6.08594C10.812 6.08594 10.2402 6.65772 10.2402 7.36604C10.2402 8.07437 10.812 8.64615 11.5203 8.64615ZM5.54768 8.64615C6.25601 8.64615 6.82779 8.07437 6.82779 7.36604C6.82779 6.65772 6.25601 6.08594 5.54768 6.08594C4.83936 6.08594 4.26758 6.65772 4.26758 7.36604C4.26758 8.07437 4.83936 8.64615 5.54768 8.64615ZM8.53303 14.1939C10.2654 14.1939 11.776 13.2467 12.5867 11.8471C12.7488 11.5655 12.544 11.207 12.2112 11.207H4.85486C4.53057 11.207 4.31722 11.5655 4.47936 11.8471C5.2901 13.2467 6.80062 14.1939 8.53303 14.1939Z"
                fill={fillColor}
            />
        </svg>
    );
}

export default BountiesIcon;
