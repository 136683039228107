import { useContext } from 'react';
import { BountiesContext } from '../../../contexts/BountiesContext';
import { useBountyFields } from '../../../hooks/useBountyFields';
import mobileFrame from '../../../assets/mobileFrame.png';
import Heading from '../../../ui/Heading';
import SubHeading from '../../../ui/SubHeading';
import { CalendarIcon } from '../../../assets/icons/CalendarIcon';
import { ClockIcon } from '../../../assets/icons/ClockIcon';
import Button from '../../../ui/Button';
import LeftPreviewArrow from '../../../assets/icons/LeftPreviewArrow';
function PreviewGetUserLocation({ data }) {
    const { state } = useContext(BountiesContext);
    const { titleField } = useBountyFields();

    return (
        <div
            className="relative w-[300px] h-[610px]     "
            style={{
                backgroundImage: `url(${mobileFrame})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <main className="absolute  top-28 left-6 right-6 ">
                <div>
                    <div className="my-4">
                        <Heading className={'text-minerTitle'} type={'xsmall'}>
                            {data.getLocation_Title || 'Title'}
                        </Heading>
                        <SubHeading className={'!text-xs text-minerDescription font-semibold mt-2'} type="xsmall">
                            {data.getLocation_Subheading || 'Task Description'}
                        </SubHeading>
                    </div>
                    <Button variant="miner" size="largeMiner" radius="rounded-full" className="text-xs py-2 ">
                        Get my location coordinates
                    </Button>
                    <div className="my-4">
                        <p className="my-2 text-xs text-minerSecondary">
                            <span>Latitude : </span>
                            {/* <span>0</span> */}
                        </p>
                        <p>
                            <span className="text-xs text-minerSecondary">Longitude : </span>
                            {/* <span>0</span> */}
                        </p>
                    </div>
                </div>
            </main>

            <footer className="absolute bottom-6 left-6 right-6">
                <div className="flex mb-2 items-center justify-between">
                    <Button
                        className=" flex !text-[#1A56DB] text-xs items-center justify-center gap-1 text-end"
                        size="none"
                        variant="transparent"
                    >
                        <span>{'<-'}</span> <span> Back</span>
                    </Button>
                    {data.skippable && (
                        <Button className=" !text-[#1A56DB] text-xs  text-end" size="none" variant="transparent">
                            Skip {'->'}
                        </Button>
                    )}
                </div>
                <Button variant="miner" size="largeMiner" className="py-2 text-xs " radius="rounded-full">
                    Next
                </Button>
            </footer>
        </div>
    );
}

export default PreviewGetUserLocation;
