import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Table from '../../ui/Table';
import Tag from '../../ui/Tag';
import { ValidationOverviewData } from '../../constants/validationOverviewData';
import Heading from '../../ui/Heading';
import SortIcon from '../../assets/icons/SortIcon';
import { getBountySubmissions } from '../../apis/bounties/bounties';
import { useEffect, useState } from 'react';
import WhiteCard from '../../ui/WhiteCard';
import StatsCard from '../../ui/StatsCard';
import IssuedIcon from '../../assets/icons/IssuedIcon';
import NotIssuedIcon from '../../assets/icons/NotIssuedIcon';

import Legend from '../../ui/Legend';
import Button from '../../ui/Button';
import { maxDate } from '../../helpers/maxDate';
import NoDataAvailable from '../../ui/NoDataAvailable';
import Loader from '../../ui/Loader';
import { FirstRank, SecondRank, ThirdRank } from '../../assets/icons/RankIcons';
import useLocalStorage from '../../hooks/useLocalStoarge';
import { formatIntDateAndThenTime } from '../../helpers/formatInDateAndThenTime';

const rank = {
    1: <FirstRank />,
    2: <SecondRank />,
    3: <ThirdRank />,
};

function ValidationBountySubmission() {
    const { bountyId } = useParams();
    const [bountyDetails, handleBountyDetails] = useOutletContext();
    const [storedValue, setStoredValue] = useLocalStorage(bountyDetails);
    const [isVisible, setIsVisible] = useState(false);

    const handleMouseEnter = () => {
        setIsVisible(true);
    };
    const handleMouseLeave = () => {
        setIsVisible(false);
    };
    const [isLoading, setIsLoading] = useState(true);
    const [submissions, setSubmissions] = useState([]);
    const navigate = useNavigate();

    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    const data = await getBountySubmissions(bountyId);
                    setIsLoading(false);
                    setSubmissions(data);
                    const maxUpdateTime = maxDate(data.map((submission) => submission?.updatedAt));
                    handleBountyDetails((initial) => ({ ...initial, maxUpdateTime }));
                    setStoredValue((initial) => ({ ...initial, maxUpdateTime }));
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [bountyId]
    );

    const currentTotal = submissions.length;
    const scoresCount = submissions.filter((submission) => submission.accuracy).length;
    const { status } = bountyDetails;

    const shouldDisable =
        submissions.length !== scoresCount || submissions.length === 0 || status?.toLowerCase() !== 'unvalidated';
    return (
        <div>
            <div className="my-6">
                <p>Bounty Title :</p>

                <Heading type={'large'} className={'text-lightBlack font-medium '}>
                    {bountyDetails?.title}
                </Heading>
            </div>
            <div className="flex justify-between items-end  ">
                <WhiteCard verticalMargin="" className="w-fit p-6 ">
                    <p className="font-semibold mb-2">Submissions</p>
                    <div className="flex items-center gap-4 ">
                        <StatsCard countHeading={'Current Total'} count={currentTotal} />
                        <StatsCard countHeading={'Not Scored'} count={currentTotal - scoresCount} />
                        <StatsCard countHeading={'Scored'} count={scoresCount} />
                    </div>
                </WhiteCard>

                {status?.toLowerCase() !== 'completed' && (
                    <div>
                        <p className="mb-2">Propose Validation</p>
                        <div className="relative">
                            <Button
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                data-tooltip-target="tooltip-left"
                                data-tooltip-placement="left"
                                type="button"
                                onClick={() => {
                                    if (shouldDisable) return;
                                    navigate(`request`);
                                }}
                                className={` pl-5 pr-5 ${shouldDisable ? 'cursor-not-allowed' : ' '} `}
                                // disabled={shouldDisable}
                                size="fit"
                            >
                                Request Validation
                            </Button>

                            <div
                                id="tooltip-left"
                                role="tooltip"
                                style={{
                                    top: '50%',
                                    right: '100%',
                                    transform: 'translateY(-50%)',
                                }}
                                className={`absolute z-10 mr-3 w-[494px]  px-4 py-2 transition-opacity duration-300 ${
                                    isVisible && shouldDisable ? 'opacity-100 visible' : 'opacity-0 invisible'
                                } text-sm font-medium text-primaryInput border border-borderColor bg-white rounded-[4px] shadow-sm opacity-0 tooltip  `}
                            >
                                <p className="text-sm">
                                    Validation can be requested only after the bounty expires on &nbsp;
                                    {formatIntDateAndThenTime(bountyDetails.endDate)} and when all submissions have been
                                    validated.
                                </p>
                                <div class="tooltip-arrow" data-popper-arrow></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <Table className=" items-center gap-4 " cols="grid-cols-12">
                <Table.Header>
                    <p className="text-sm col-span-2  font-semibold text-secondaryText "> Submission ID </p>
                    <p className="text-sm justify-self-center  font-semibold text-secondaryText ">Score</p>
                    <p className="text-sm col-span-3  font-semibold text-secondaryText ">Impact Certificate</p>
                </Table.Header>
                {isLoading ? (
                    <div className="min-h-[20vh] flex justify-center items-center">
                        <Loader />
                    </div>
                ) : submissions.length ? (
                    submissions.map((submission, index) => (
                        <Table.Row key={submission.submissionId}>
                            <p
                                onClick={() => navigate(submission.submissionId)}
                                className=" col-span-2 text-primaryColor flex items-center cursor-pointer  uppercase justify-self-start font-medium  "
                            >
                                <span className="truncate w-32">{submission.submissionId} </span>{' '}
                                {submission?.accuracy ? (
                                    submission?.accuracy * 100 >= bountyDetails?.passThreshold * 100 ? (
                                        <span>{index + 1 <= 3 && rank[index + 1]}</span>
                                    ) : null
                                ) : null}
                            </p>
                            <p className="justify-self-center text-primaryInput  ">
                                {submission?.accuracy >= 0 ? (Number(submission.accuracy) * 100).toFixed(2) : '-'}
                            </p>
                            <p className="col-span-3 ">
                                {submission?.issueImpactCertificate ? <IssuedIcon /> : <NotIssuedIcon />}
                            </p>
                        </Table.Row>
                    ))
                ) : (
                    <NoDataAvailable />
                )}
                <div className="flex items-center justify-end mt-6">
                    <div className="flex items-center gap-3 ">
                        <p className="text-sm font-bold">Impact Certificate</p>
                        <Legend shield={<NotIssuedIcon />} text={'Not Issued'} />
                        <Legend shield={<IssuedIcon />} text={'Issued'} />
                    </div>
                </div>
            </Table>
        </div>
    );
}

export default ValidationBountySubmission;

// submissions.length === scoresCount && status?.toLowerCase() === 'unvalidated' &&
// {submission?.accuracy ? (
//     submission?.accuracy * 100 >= 40 ? (
//         <span>{index + 1 <= 3 && rank[index + 1]}</span>
//     ) : null
// ) : null}
