function StatsCard({ countHeading, count }) {
    return (
        <div className="flex flex-col ">
            <p className="text-lightBlack text-4xl ">{count}</p>
            <p className="text-sm text-secondaryText font-medium ">{countHeading}</p>
        </div>
    );
}

export default StatsCard;
