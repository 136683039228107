import axios from 'axios';
import { auth, getAccessToken } from '../../firebase';
import { BASE_URL } from '../../constants/apiPaths';
import { toast } from 'sonner';
import { onAuthStateChanged } from 'firebase/auth';
import { accessToken } from 'mapbox-gl';
const getHeader = () => {
    return {
        'Content-Type': 'application/json',
    };
};

const getConfig = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

export const getOrganisations = async () => {
    const url = `${BASE_URL}/organisation`;
    try {
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

export const getOrganisationApplications = async () => {
    const url = `${BASE_URL}/orgApplication`;
    try {
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};
// organisation/favorite
export const getFavoriteOrganizations = async () => {
    const url = `${BASE_URL}/organisation/favorite`;
    try {
        const accessToken = await getAccessToken();

        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

export const makeFavoriteOrganization = async (payload) => {
    const url = `${BASE_URL}/organisation/favorite`;
    try {
        const accessToken = await getAccessToken();
        const response = await axios.put(
            url,
            {
                organisations: payload,
            },
            getConfig(accessToken)
        );

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

export const deleteFromFavouriteOrganizations = async (payload) => {
    const url = `${BASE_URL}/organisation/favorite`;
    try {
        const accessToken = await getAccessToken();
        const response = await axios.put(
            url,
            {
                organisations: payload,
            },
            getConfig(accessToken)
        );

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

export const getOrgMembers = async (orgID) => {
    try {
        const url = `${BASE_URL}/organisation/${orgID}`;

        let accessToken;
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    accessToken = await user.getIdToken(true);
                } catch (error) {
                    console.error('Error getting access token:', error);
                }
            } else {
                console.log('No user is signed in.');
            }
        });

        // const accessToken = await auth.currentUser.getIdToken(true);

        const config = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };
        const headers = {
            'Content-Type': 'application/json', // Assuming you're sending JSON data
            // Add any other headers you need here
        };

        const response = await axios.get(url, config);

        if (response.status === 200 || response.status == 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        console.log('error', error);
        return error;
    }
};

// paginated all organisations  /organisation/allOrganisations?page=<page_count>&entriesPerPage=<entry_count>
export const getExploreOrg = async (entries) => {
    try {
        const url = `${BASE_URL}/organisation/allOrganisations?page=${1}&entriesPerPage=${entries} `;
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

// fethes user list  /citizen?userName=<substring>

export const searchUser = async (name) => {
    try {
        const url = `${BASE_URL}/citizen?userName=${name}`;

        const accessToken = await auth.currentUser.getIdToken(true);

        const config = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };
        const headers = {
            'Content-Type': 'application/json', // Assuming you're sending JSON data
            // Add any other headers you need here
        };

        const response = await axios.get(url, config);

        if (response.status === 200 || response.status == 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        console.log('error', error);
        return error;
    }
};

// after adding member post to backend

export const addMemberCall = async (orgID, uid) => {
    try {
        const accessToken = await auth.currentUser.getIdToken(true);
        const config = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };
        // const uid  = auth.currentUser.uid
        const payload = {
            organisationId: orgID,
            uid: uid,
            isBountyManager: true,
            isBountyValidator: true,
        };
        const url = `${BASE_URL}/organisation/member `;

        // Make a POST request instead of a GET request
        const response = await axios.post(url, payload, config);

        // Check the response status
        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        if (error.response) {
            // The error is related to the HTTP response
            console.log('HTTP status code: for profile', error.response.data);
            return error.response.data;
        } else {
            // The error is not related to the HTTP response
            console.log('An error occurred:', error.message);
            return error.response.data;
        }
    }
};

export const getNotifications = async () => {
    try {
        const url = `${BASE_URL}/notification`;
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        console.log('error', error);
        return error;
    }
};

export const createOrganizationAtDb = async (organization) => {
    try {
        const url = `${BASE_URL}/orgApplication`;

        const accessGoogle = await auth.currentUser.getIdToken(true);

        const response = await axios.post(url, organization, {
            headers: {
                Authorization: `Bearer ${accessGoogle}`,
            },
        });

        if (response.data.status === 'SUBMITTED')
            toast.success(
                'Your request to create an organization has been sent. Please wait for the Atlantis team to review and approve it.'
            );
        return response.data;
    } catch (error) {
        console.log(error);
        toast.error('Sorry unable to sent application.');
    }
};
//

export const editOrganisation = async (payload, orgId) => {
    try {
        const url = `${BASE_URL}/organisation/${orgId}`;

        const accessToken = await getAccessToken();
        const response = await axios.patch(url, payload, getConfig(accessToken));

        if (response.status === 201 || response.status === 200)
            toast.success('Your Organization details have been updated. ');
        return response;
    } catch (error) {
        console.log(error);
        toast.error(error);
        // toast.error('Sorry unable to create your organization.');
    }
};

export const getOrganization = async (id) => {
    const url = `${BASE_URL}/organisation/${id}`;
    try {
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

export const getOrganizationMembers = async (id) => {
    const url = `${BASE_URL}/organisation/member?organisationId=${id}`;

    try {
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};
export const getOrganizationStats = async (id) => {
    const url = `${BASE_URL}/organisation/statistics?organisationId=${id}`;

    try {
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

export const getOrganizationFundAllocation = async (id) => {
    const url = `${BASE_URL}/organisation/fundAllocation?organisationId=${id}`;

    try {
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

export const sendRequestToJoinOrg = async (id, payload) => {
    try {
        const url = `${BASE_URL}/organisation/joinRequest?organisationId=${id}`;

        const accessGoogle = await auth.currentUser.getIdToken(true);

        const response = await axios.post(url, payload, {
            headers: {
                Authorization: `Bearer ${accessGoogle}`,
            },
        });

        if (response.data.status === 'SUBMITTED') toast.success('Organization is successfully created !');
        return response.data;
    } catch (error) {
        console.log(error);
        toast.error('Sorry unable to create your organization.');
    }
};

export const getRequest = async (id) => {
    const url = `${BASE_URL}/organisation/joinRequest/userRequest?organisationId=${id}`;

    try {
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

export const getRequestsToJoinOrg = async (id) => {
    const url = `${BASE_URL}/organisation/joinRequest?organisationId=${id}`;

    try {
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};
// PATCH /organisation/joinRequest/<requestId>?accept=<boolean></boolean>
export const acceptOrDeclineRequestToJoinOrg = async (reqId, action) => {
    try {
        const url = `${BASE_URL}/organisation/joinRequest/${reqId}?accept=${action}`;

        const accessGoogle = await auth.currentUser.getIdToken(true);

        const response = await axios.patch(
            url,
            {},
            {
                headers: {
                    Authorization: `Bearer ${accessGoogle}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        console.log(error);
        toast.error('Sorry unable perform action');
    }
};
export const actionOnRequestToJoinOrg = async (organization) => {
    try {
        const url = `${BASE_URL}/orgApplication`;

        const accessGoogle = await auth.currentUser.getIdToken(true);

        const response = await axios.post(url, organization, {
            headers: {
                Authorization: `Bearer ${accessGoogle}`,
            },
        });

        if (response.data.status === 'SUBMITTED') toast.success('Organization is successfully created !');
        return response.data;
    } catch (error) {
        console.log(error);
        toast.error('Sorry unable to create your organization.');
    }
};

// PUT /organisation/member

export const updateMembers = async (payload) => {
    const url = `${BASE_URL}/organisation/member`;
    try {
        const accessToken = await getAccessToken();
        const response = await axios.put(url, payload, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

// /orgApplication/execute/:applicationId?treasurerMultisig=<pubkey></pubkey>

export const executeOrgApplication = async (applicationId, multisig) => {
    const url = `${BASE_URL}/orgApplication/execute/${applicationId}?treasurerMultisig=${multisig}`;
    try {
        const accessToken = await getAccessToken();
        const response = await axios.patch(url, {}, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            toast.success(
                'Your organisation has been successfully created. You can now invite members and add bounties. '
            );
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

export const getOrganisationFunds = async (id) => {
    try {
        const url = `${BASE_URL}/organisation/funds?organisationId=${id}`;
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

export const getParticularApplication = async (id) => {
    const url = `${BASE_URL}/orgApplication/${id}`;
    try {
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};
