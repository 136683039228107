// export const dateToIsoString = (date) => {
//     // Ensure date is a Date object
//     if (!(date instanceof Date)) {
//         throw new Error('Input must be a Date object');
//     }

//     // Get the UTC offset in minutes and format it as ±HH:mm
//     const offset = -date.getTimezoneOffset();
//     const offsetHours = Math.floor(Math.abs(offset) / 60);
//     const offsetMinutes = Math.abs(offset) % 60;
//     const sign = offset >= 0 ? '+' : '-';
//     const formattedOffset = `${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;

//     // Format the date into YYYY-MM-DDTHH:mm:ss
//     const year = date.getUTCFullYear();
//     const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
//     const day = String(date.getUTCDate()).padStart(2, '0');
//     const hours = String(date.getUTCHours()).padStart(2, '0');
//     const minutes = String(date.getUTCMinutes()).padStart(2, '0');
//     const seconds = String(date.getUTCSeconds()).padStart(2, '0');

//     return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${formattedOffset}`;
// };
export const dateToIsoString = (date) => {
    // Ensure date is a Date object
    if (!(date instanceof Date)) {
        throw new Error('Input must be a Date object');
    }

    // Get the local timezone offset in minutes and format it as ±HH:mm
    const offset = -date.getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offset) / 60);
    const offsetMinutes = Math.abs(offset) % 60;
    const sign = offset >= 0 ? '+' : '-';
    const formattedOffset = `${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;

    // Format the date into YYYY-MM-DDTHH:mm:ss
    const year = date.getFullYear(); // Use local time
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${formattedOffset}`;
};
