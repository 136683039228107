function DragAndDropIcon({ className = '', ...props }) {
    return (
        <svg
            className={`${className}`}
            {...props}
            width="11"
            height="22"
            viewBox="0 0 11 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
            <circle cx="2" cy="8" r="2" fill="#D9D9D9" />
            <circle cx="2" cy="14" r="2" fill="#D9D9D9" />
            <circle cx="9" cy="14" r="2" fill="#D9D9D9" />
            <circle cx="9" cy="20" r="2" fill="#D9D9D9" />
            <circle cx="2" cy="20" r="2" fill="#D9D9D9" />
            <circle cx="9" cy="8" r="2" fill="#D9D9D9" />
            <circle cx="9" cy="2" r="2" fill="#D9D9D9" />
        </svg>
    );
}

export default DragAndDropIcon;
