import { useEffect, useState } from 'react';
import EditProfileData from './EditProfileData';
import SuperPowers from './SuperPowers';
import { superpowers } from '../../../constants/powersData';
import { useLoadingState } from '../../../hooks/useLoader';
import { getSuperpowers } from '../../../apis/general/general';

function EditProfileDetails() {
    const { loading, startLoading, stopLoading } = useLoadingState();

    const [powers, setPowers] = useState([]);
    const categories = Object.keys(powers);

    useEffect(function () {
        const fetchData = async () => {
            try {
                // const data = await getExploreOrg(5);
                startLoading();
                const data = await getSuperpowers();
                setPowers(data);

                stopLoading();
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="grid grid-cols-editProfile gap-6 mt-6 h-[75vh] overflow-hidden   ">
            <EditProfileData />
            <SuperPowers powers={powers} loading={loading} categories={categories} />
        </div>
    );
}

export default EditProfileDetails;
