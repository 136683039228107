export const addTime = function (date, days = 0, hours = 0, minutes = 0) {
    const newDate = new Date(date); // Copy the original date

    // Add days
    newDate.setDate(newDate.getDate() + days);

    // Add hours
    newDate.setHours(newDate.getHours() + hours);

    // Add minutes
    newDate.setMinutes(newDate.getMinutes() + minutes);

    // Return the timestamp
    return newDate.getTime();
};

export function convertToMilliseconds(days, hours, minutes) {
    // Define the number of milliseconds in one unit
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const millisecondsPerHour = 60 * 60 * 1000;
    const millisecondsPerMinute = 60 * 1000;

    // Calculate total milliseconds
    const totalMilliseconds = days * millisecondsPerDay + hours * millisecondsPerHour + minutes * millisecondsPerMinute;

    return totalMilliseconds;
}
