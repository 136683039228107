import { title } from 'process';
import { createContext, useEffect, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getProjectsList } from '../apis/bounties/bountyDraft';
import { useParams } from 'react-router-dom';

const initialState = {
    title: '',
    description: '',
    organisationId: '',
    projectId: '',
    cores: [],
    type: '',
    mode: 'digital',
    city: '',
    location: { coordinates: [] },
    bountyRadius: 50,
    maxAcceptancesPerUser: '',
    criteria: [''],
    microtasks: [],
    totalRewardAmount: 0,
    noOfWinners: 1,
    passThreshold: '',
    expirationTime: null,
    estimatedCompletionTime: '',
    validationTillDate: null,
    day: '',
    hours: '',
    minutes: '',
    distributionMode: 'Distribute evenly between all winners',
    rank1: 0,
    rank2: 0,
    rank3: 0,
    core: '',
    SDGs: [],
    backer: {
        name: '',
        message: '',
    },
    doughnutMetrics: [],
    // rewardPerUser
};
export const BountiesContext = createContext();

const reducer = (state, action) => {
    switch (action.type) {
        case 'setTitle':
            return { ...state, title: action.payload };

        default:
            return state;
    }
};

export const BountiesProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [projects, setProjects] = useState([]);
    const [active, setActive] = useState(1);
    const { id } = useParams();

    const {
        handleSubmit,
        control,
        getValues,
        register,
        trigger,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
        defaultValues: initialState,
    });

    const handleStepChange = async (nextStep) => {
        // if (nextStep === 2) {
        //     handleManualValueSetting(nextStep, 'superPowers', selectedPowers);
        //     return;
        // }
        // if (nextStep < step) {
        //     setStep(nextStep);
        //     return;
        // }
        console.log('clicked');
        const isValid = await trigger(); // Manually trigger validation for all fields
        if (isValid) {
            console.log('go next');
            setActive(nextStep);
        } else {
            console.log('Validation failed');
        }
    };

    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    const data = await getProjectsList(id);
                    setProjects(data);
                    console.log(data, 'in bounties context');
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [id]
    );

    return (
        <BountiesContext.Provider
            value={{
                state,
                handleSubmit,
                projects,
                control,
                errors,
                dispatch,
                register,
                active,
                setActive,
                handleStepChange,
            }}
        >
            {children}
        </BountiesContext.Provider>
    );
};

// {

//    "backer": {
//              "name": "Backer",
//              "message": "message here"
//          },
//          "rewardToken": {
//              "tokenAddress": "sfdsfsfsf",
//              "totalRewardAmount": 500,
//              "distribution": [200,100,100,50,50]
//          },
//   "hasImpactCertificate": false,
//          // impactBrief is optional and must be specified ony if
//   "impactBrief": "Helped in testing the application 'Impact Miner'",
//   "cores": ["Earth"],
//   "SDGs":["Climate action", "Partnerships for the goals"],
//   "doughnutMetrics": ["income & work"],
//   "maxAcceptancesPerUser": 1
// }
