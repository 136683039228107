import { microtaskTypeData } from './microtasktypes/microtaskTypeData';
import NoPreviewAvailable from './previews/NoPreviewAvailable';
import PreviewBacker from './previews/PreviewBacker';
import PreviewBrief from './previews/PreviewBrief';
import PreviewConfig from './previews/PreviewConfig';
import PreviewCriteria from './previews/PreviewCriteria';
import PreviewMicrotask from './previews/PreviewMicrotask';
import PreviewRewards from './previews/PreviewRewards';
function BountiesPreview({
    step,
    allMicrotasks,
    activeMicrotask,
    unregister,
    control,
    criterias,
    descriptionLinkPayload,
    instructionLinkPayload,
    icImage,
    impactPointsData,
    icFetching,
    radiusPreview,
}) {
    const x = microtaskTypeData?.find((obj) => obj?.type === allMicrotasks[activeMicrotask]?.microtaskType);

    return (
        <div className="overflow-auto pr-12 max-h-[90vh]  mx-auto  main">
            {step === 1 && (
                <PreviewBrief
                    unregister={unregister}
                    descriptionLinkPayload={descriptionLinkPayload}
                    control={control}
                    radiusPreview={radiusPreview}
                />
            )}
            {step === 2 && (
                <PreviewCriteria
                    descriptionLinkPayload={descriptionLinkPayload}
                    criterias={criterias}
                    control={control}
                    radiusPreview={radiusPreview}
                />
            )}
            {step === 3 && (
                <>
                    {!x ? (
                        <NoPreviewAvailable />
                    ) : (
                        x.getPreview(allMicrotasks[activeMicrotask], instructionLinkPayload, control, activeMicrotask)
                    )}
                </>
            )}
            {step === 4 && (
                <PreviewConfig
                    descriptionLinkPayload={descriptionLinkPayload}
                    criterias={criterias}
                    control={control}
                    radiusPreview={radiusPreview}
                />
            )}
            {step === 5 && (
                <PreviewBacker
                    descriptionLinkPayload={descriptionLinkPayload}
                    criterias={criterias}
                    control={control}
                    radiusPreview={radiusPreview}
                />
            )}
            {step === 6 && (
                <PreviewRewards
                    descriptionLinkPayload={descriptionLinkPayload}
                    criterias={criterias}
                    control={control}
                    icImage={icImage}
                    impactPointsData={impactPointsData}
                    icFetching={icFetching}
                    radiusPreview={radiusPreview}
                />
            )}
        </div>
    );
}

export default BountiesPreview;

{
    /* <header className="bg-previewDark absolute text-white  top-[3px] left-[8px] right-[8px] rounded-t-[44px] p-3   h-24  ">
                <div className="flex items-center justify-between px-3 ">
                    <p className="text-sm">9:41</p>
                    <div className="flex gap-1 ">
                        <Networks />
                        <Wifi />
                        <Battery />
                    </div>
                </div>
                <div className="flex gap-2 m-3  items-center justify-between ">
                    <PreviewBack />
                    <p className="flex-1">Bounty</p>
                    <ThreeVerticalDots />
                </div>
            </header> */
}
