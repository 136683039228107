function ProgressBar({
    color,
    width = '45%',
    className = '',
    colorb,
    bPer = "35%'",
    cPer = "20%'",
    dPer = "15%'",
    colorc,
    colord,
    multiple = false,
    bgDefault = 'bg-gray-100',
}) {
    return (
        <div className={`w-full ${bgDefault}  rounded-full h-2.5 flex-1 flex ${className} `}>
            <div
                style={{ width: width }}
                className={` ${color} h-2.5 rounded-full ${multiple ? 'rounded-r-none' : ''}  `}
            ></div>
            {multiple && (
                <>
                    <div
                        style={{ width: bPer }}
                        className={` ${colorb} h-2.5 rounded-full rounded-s-none rounded-e-none  `}
                    ></div>
                    <div
                        style={{ width: cPer }}
                        className={` ${colorc} h-2.5 rounded-full rounded-s-none rounded-e-none   `}
                    ></div>
                    <div style={{ width: dPer }} className={` ${colord} h-2.5 rounded-full rounded-s-none  `}></div>
                </>
            )}
        </div>
    );
}

export default ProgressBar;
