import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import AvatarImage from '../../ui/AvatarImage';
import Heading from '../../ui/Heading';

import { WaterIcon } from '../../assets/icons/ProgressBarIcons';
import Status from '../../ui/Status';
import Button from '../../ui/Button';

import { useWallet } from '@solana/wallet-adapter-react';
import { Connection } from '@solana/web3.js';
import { clusterApiUrl } from '@solana/web3.js';
import { executor } from '../../blockchainInteraction/createOrganization';
import { getProvider } from '../../helpers/getProvider';
import { executeOrgApplication, getParticularApplication } from '../../apis/oragnisations/organisation';
import { useEffect, useState } from 'react';
import WalletConnectCustomButton from '../walletconnectUI/WalletCustomText';
import { useLoadingState } from '../../hooks/useLoader';
import Loader from '../../ui/Loader';
import LinkIcon from '../../assets/icons/LinkIcon';
import BackButton from '../../assets/icons/BackButton';
import { RPC_ENDPOINT } from '../../constants/apiPaths';

const statusText = {
    SUBMITTED: 'pending',
    APPROVED: 'accepted',
    REJECTED: 'rejected',
    EXECUTED: 'executed',
};
function MyParticularApplication() {
    const location = useLocation();
    const outletContext = useOutletContext();
    const [application, setApplication] = useState({});
    const { loading, startLoading, stopLoading } = useLoadingState();
    // const particularApplicationContext = useParticularApplication();
    const { id } = useParams();
    // Parse the search parameters from the URL and decode them
    const searchParams = new URLSearchParams(location.search);

    // Decode the URL-encoded parameters
    const comingFrom = decodeURIComponent(searchParams.get('from') || '');
    // const { particularApplication, setParticularApplication, setApplications } =
    //     comingFrom === 'home' ? particularApplicationContext : outletContext;
    const { setApplications } = outletContext;
    const navigate = useNavigate();

    const { publicKey } = useWallet();
    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    startLoading();
                    const data = await getParticularApplication(id);
                    // setBounties(data);
                    setApplication(data);

                    stopLoading();
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [id]
    );
    const address = publicKey;

    const { orgName, orgTagline, orgLogo, status, orgDescription, createdAt, handles } = application;

    const onExecution = async () => {
        const connection = new Connection(RPC_ENDPOINT, 'confirmed');

        try {
            const { transaction, multisigPda } = await executor(address, connection);
            const { signature } = await getProvider().signAndSendTransaction(transaction);
            await connection.getSignatureStatus(signature);
            const sendMultiSigPDA = multisigPda.toBase58();
            const res = await executeOrgApplication(application._id, sendMultiSigPDA);

            setApplication((initial) => ({ ...initial, status: 'EXECUTED' }));
            if (comingFrom !== 'home')
                setApplications((initial) => [
                    ...initial.map((app) => {
                        if (app._id === application._id) return { ...app, status: 'EXECUTED' };
                        return app;
                    }),
                ]);
        } catch (error) {
            console.log(error);
        }
    };

    return loading ? (
        <div className="min-h-[20vh] flex justify-center items-center">
            <Loader />
        </div>
    ) : (
        <div>
            <div className="flex gap-2 items-center">
                <BackButton onClick={() => navigate(-1)} />
                <div className="flex items-center">
                    {/* <div className="flex gap-2 items-center"> */}

                    <AvatarImage w="w-[78px]" h="h-[78px]" url={orgLogo} />

                    <div className="ml-6">
                        <div className="flex gap-2 items-center">
                            <Heading type={'large'}>{orgName} </Heading>
                        </div>

                        <div className="flex items-center gap-2">
                            <p className="text-lightBlack">{orgTagline}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex  items-center mt-6">
                <div className="flex items-center gap-3 ">
                    <Status bg="bg-white" text={statusText[status]} />

                    <Status bg="bg-white" text={'Submitted:' + new Date(createdAt).toLocaleDateString()} />

                    {address
                        ? status === 'APPROVED' && (
                              <Button
                                  type="button"
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      onExecution();
                                  }}
                                  size="small"
                              >
                                  {' '}
                                  Execute
                              </Button>
                          )
                        : status === 'APPROVED' && (
                              <div className="w-full flex items-center">
                                  <WalletConnectCustomButton
                                      customText={'Execute'}
                                      width="128px"
                                      customStyles={{
                                          backgroundColor: 'var(--primary-color)',
                                          color: '#fff',
                                          border: '1px solid #dcdcdc',
                                          lineHeight: 'normal',
                                          fontWeight: '500',
                                          paddingLeft: '12px',
                                          paddingRight: '12px',
                                          height: '40px',
                                          borderRadius: '8px',
                                          flexGrow: 1,
                                      }}
                                      connectedText={'Execute'}
                                      disconnectedText="Execute"
                                      removeIconOnDisconnect={true}
                                      removeIconOnConnect={true}
                                      removeIconOnWalletSelect={true}
                                      removeIconOnConnecting={true}
                                  />
                              </div>
                          )}
                </div>
            </div>

            <div className="my-6">
                <Heading className={'text-lightBlack'} type={'medium'}>
                    Brief Description
                </Heading>
                <p className="text-lightBlack">{orgDescription}</p>
            </div>
            <div className="my-6">
                <Heading className={'text-lightBlack '} type={'medium'}>
                    Organization Social Links
                </Heading>
                <ul className=" pl-2 text-foundryBlue cursor-pointer">
                    {handles?.map((handle, index) => {
                        const formattedLink =
                            handle.startsWith('http://') || handle.startsWith('https://')
                                ? handle
                                : `https://${handle}`;
                        return (
                            <li className="flex items-center gap-2 " key={handle + index}>
                                <span>
                                    <LinkIcon />
                                </span>
                                <a href={formattedLink} target="_blank">
                                    {handle}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}

export default MyParticularApplication;
