const NotSelectedIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.34016 5.65967C7.34016 4.55967 6.44016 3.65967 5.34016 3.65967C4.24016 3.65967 3.34016 4.55967 3.34016 5.65967C3.34016 6.75967 4.24016 7.65967 5.34016 7.65967C6.44016 7.65967 7.34016 6.75967 7.34016 5.65967ZM5.34016 6.32634C4.9735 6.32634 4.6735 6.02634 4.6735 5.65967C4.6735 5.29301 4.9735 4.99301 5.34016 4.99301C5.70683 4.99301 6.00683 5.29301 6.00683 5.65967C6.00683 6.02634 5.70683 6.32634 5.34016 6.32634ZM9.6735 7.65967C10.4135 7.65967 11.0068 7.06634 11.0068 6.32634C11.0068 5.58634 10.4135 4.99301 9.6735 4.99301C8.9335 4.99301 8.3335 5.58634 8.34016 6.32634C8.34016 7.06634 8.9335 7.65967 9.6735 7.65967ZM7.00016 0.333008C3.32016 0.333008 0.333496 3.31967 0.333496 6.99967C0.333496 10.6797 3.32016 13.6663 7.00016 13.6663C10.6802 13.6663 13.6668 10.6797 13.6668 6.99967C13.6668 3.31967 10.6802 0.333008 7.00016 0.333008ZM2.90016 10.4063C3.3535 10.0463 4.4135 9.66634 5.34016 9.66634C5.38683 9.66634 5.44016 9.67301 5.4935 9.67301C5.6535 9.24634 5.94016 8.81301 6.36016 8.43301C5.98683 8.36634 5.6335 8.32634 5.34016 8.32634C4.4735 8.32634 3.08016 8.62634 2.18683 9.27967C1.8535 8.58634 1.66683 7.81301 1.66683 6.99301C1.66683 4.05301 4.06016 1.65967 7.00016 1.65967C9.94016 1.65967 12.3335 4.05301 12.3335 6.99301C12.3335 7.79301 12.1535 8.55301 11.8335 9.23967C11.1668 8.84634 10.2602 8.65967 9.6735 8.65967C8.66016 8.65967 6.6735 9.19968 6.6735 10.4597V12.313C5.16016 12.2263 3.8135 11.5063 2.90016 10.4063Z"
                fill="#737373"
            />
        </svg>
    );
};

export default NotSelectedIcon;
