const StepCompleted = ({ fillColor = '#0085FF' }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icon">
                <path
                    id="Icon_2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.49987 13.4746L4.6082 10.5829C4.2832 10.2579 3.7582 10.2579 3.4332 10.5829C3.1082 10.9079 3.1082 11.4329 3.4332 11.7579L6.91654 15.2413C7.24154 15.5663 7.76654 15.5663 8.09154 15.2413L16.9082 6.42461C17.2332 6.09961 17.2332 5.57461 16.9082 5.24961C16.5832 4.92461 16.0582 4.92461 15.7332 5.24961L7.49987 13.4746Z"
                    fill={fillColor}
                />
            </g>
        </svg>
    );
};

export default StepCompleted;
