import Table from '../../ui/Table';
import ReactMapboxGl, { Feature, Layer, Marker } from 'react-mapbox-gl';
import { microtaskTypeData } from '../bounties/microtasktypes/microtaskTypeData';
import { useState } from 'react';
import { WaterIcon } from '../../assets/icons/ProgressBarIcons';
import defaultMarker from '../../assets/defaultMarker.png';
const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoiZmFpc2FsZHJvcGNoYWluIiwiYSI6ImNsdXJnOWF2czA3NmYyam4xZWkzbmltaHAifQ.IK0wrkOv-H-C57rLCcMBMA',
});
const GetUserLocationSD = ({ dataTask }) => {
    const titleObject = dataTask.task.find((obj) => Object.keys(obj).includes('Title'));
    const subheadingObject = dataTask.task.find((obj) => Object.keys(obj).includes('Subheading'));
    const [mapCenter, setMapCenter] = useState([
        dataTask?.submission?.long === 'undefined' ? 0 : dataTask?.submission?.long,
        dataTask?.submission?.lat === 'undefined' ? 0 : dataTask?.submission?.lat,
    ]);
    const [zoom, setZoom] = useState(14);

    return (
        <Table.Row>
            <p className=" text-lightBlack flex flex-col   ">
                <span className="font-medium mb-1  "> {titleObject?.Title || '-'} </span>
                <span className="text-xs font-medium mb-1">{subheadingObject?.Subheading || '-'}</span>
                {/* {imageObject?.image && <img className="w-[100%] h-32 rounded-md" src={imageObject.image} alt="" />} */}
            </p>
            <p className="text-sm justify-self-center">
                {microtaskTypeData.find((ele) => ele.type === dataTask.actionType)?.label}
            </p>

            {dataTask.submission?.long && dataTask.submission?.lat && (
                <div>
                    {mapCenter[0] && mapCenter[1] ? (
                        <div className="my-6 overflow-hidden bg-white rounded-2xl">
                            <Map
                                style="mapbox://styles/mapbox/streets-v9"
                                containerStyle={{
                                    height: '20vh',
                                    width: '100%',
                                    zIndex: '0',
                                }}
                                center={mapCenter}
                                zoom={[zoom]}
                                onZoomEnd={(map) => {
                                    setZoom(map.getZoom());
                                }}
                            >
                                {dataTask.submission && (
                                    <>
                                        {/* Custom Marker with Image */}
                                        <Marker
                                            anchor="bottom"
                                            coordinates={[dataTask.submission?.long, dataTask.submission?.lat]}
                                        >
                                            <img src={defaultMarker} alt="marker" />
                                        </Marker>
                                    </>
                                )}
                            </Map>
                        </div>
                    ) : null}
                </div>
            )}
        </Table.Row>
    );
};

export default GetUserLocationSD;
