import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { getBountySubmissions, getValidationPreview } from '../../apis/bounties/bounties';
import Table from '../../ui/Table';
import Legend from '../../ui/Legend';
import NotIssuedIcon from '../../assets/icons/NotIssuedIcon';
import IssuedIcon from '../../assets/icons/IssuedIcon';
import RankOneIcon from '../../assets/icons/RankOneIcon';
import RankTwoIcon from '../../assets/icons/RankTwoIcon';
import RankThreeIcon from '../../assets/icons/RankThreeIcon';
import Button from '../../ui/Button';
import { toast } from 'sonner';
import Loader from '../../ui/Loader';
import ValidationCreationModal from './ValidationCreationModal';
import Modal from '../../ui/Modal';
import { useLoadingState } from '../../hooks/useLoader';
import { FirstRank, SecondRank, ThirdRank } from '../../assets/icons/RankIcons';
import NoDataAvailable from '../../ui/NoDataAvailable';
import { toNormalRepresentation } from '../../helpers/convertIntoDecimals';

const rank = {
    1: <FirstRank />,
    2: <SecondRank />,
    3: <ThirdRank />,
};
function RequestValidation() {
    const { bountyId } = useParams();
    const [bountyDetails] = useOutletContext();
    const { loading, startLoading, stopLoading } = useLoadingState();
    const { id } = useParams();
    const [preview, setPreview] = useState({});
    const navigate = useNavigate();

    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    startLoading();

                    const data = await getValidationPreview(bountyId);

                    setPreview(data);
                    stopLoading();
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [bountyId]
    );
    console.log(preview?.rewardDistribution, 'rewards');
    return (
        <div>
            {loading ? (
                <div className="h-40 col-span-2 bg-white flex mt-6 items-center justify-center">
                    <Loader />
                </div>
            ) : (
                <>
                    <Table className=" items-center gap-4 " cols="grid-cols-12">
                        <Table.Header>
                            <p className="text-sm col-span-2  font-semibold text-secondaryText "> Submission ID </p>
                            <p className="text-sm justify-self-center  font-semibold text-secondaryText ">Score</p>
                            <p className="text-sm justify-self-center col-span-2 font-semibold text-secondaryText ">
                                Reward Token
                            </p>
                            <p className="text-sm justify-self-center col-span-2  font-semibold text-secondaryText ">
                                Impact Points
                            </p>
                            <p className="text-sm col-span-3  font-semibold text-secondaryText ">Impact Certificate</p>
                        </Table.Header>
                        {preview?.rewardDistribution?.length > 0 ? (
                            preview?.rewardDistribution?.map((submission, index) => (
                                <Table.Row>
                                    <p
                                        onClick={() =>
                                            navigate(
                                                `/organizations/${id}/validation/bounty-submissions/${bountyId}/${submission.submissionId}`
                                            )
                                        }
                                        className=" col-span-2 text-primaryColor flex items-center cursor-pointer  uppercase justify-self-start font-medium  "
                                    >
                                        <span className="truncate w-32">{submission.submissionId} </span>{' '}
                                        <span>{index + 1 <= 3 && rank[index + 1]}</span>
                                    </p>
                                    <p className="justify-self-center  ">
                                        {submission.accuracy
                                            ? toNormalRepresentation(Number(submission.accuracy) * 100)
                                            : '-'}
                                    </p>
                                    <p className="justify-self-center col-span-2  ">
                                        {' '}
                                        <span>{toNormalRepresentation(submission.rewardAmount)} </span>{' '}
                                        <span>{preview.rewardTokenSymbol}</span>{' '}
                                    </p>
                                    <p className="justify-self-center col-span-2  ">
                                        {submission.impactPoints ? Number(submission.impactPoints).toFixed(2) : '-'}
                                    </p>
                                    <p className="col-span-3 ">
                                        {submission?.issueImpactCertificate ? <IssuedIcon /> : <NotIssuedIcon />}
                                    </p>
                                </Table.Row>
                            ))
                        ) : (
                            <NoDataAvailable text="No person will be awarded upon validation" />
                        )}
                        <div className="flex items-center justify-end mt-6">
                            <div className="flex items-center gap-3 ">
                                <p className="text-sm font-bold">Impact Certificate</p>
                                <Legend shield={<NotIssuedIcon />} text={'Not Issued'} />
                                <Legend shield={<IssuedIcon />} text={'Issued'} />
                            </div>
                        </div>
                    </Table>
                    <div className="flex justify-end items-center">
                        <Modal>
                            <Modal.Button
                                className="flex gap-2 cursor-pointer items-center"
                                opens={'validationrequest'}
                            >
                                <Button size="xsmall">Submit Request</Button>
                            </Modal.Button>
                            <Modal.Window name={'validationrequest'}>
                                <ValidationCreationModal
                                    infoRequired={{ lastUpdateTime: bountyDetails?.maxUpdateTime }}
                                />
                            </Modal.Window>
                        </Modal>
                    </div>
                </>
            )}
        </div>
    );
}

export default RequestValidation;
// {
// 	"organisationId": "6606d2c0ed51ae4d31a51ae3",
// 	"requestType": "BOUNTY_VALIDATION", // OR "BOUNTY_CREATION
// 	"bountyId": "6606d2c0ed51ae4d31a51gdsg",
// 	// need to send the max value of `lastUpdateTime` from the response of GET /organisation/validation/submission/<submissionId>
// 	"lastUpdateTime": "2024-03-29T14:40:00.179Z"
// }
