import { useNavigate, useOutletContext } from 'react-router-dom';
import BackButton from '../assets/icons/BackButton';
import Heading from '../ui/Heading';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import AvatarImage from '../ui/AvatarImage';
import SubHeading from '../ui/SubHeading';
import TopInLead from '../assets/icons/TopInLead';
import ProgressBar from '../ui/ProgressBar';
import trophy from '../assets/trophy.png';
import drop from '../assets/impactDrop.png';
import { EarthIcon, FistIcon, SunIcon, WaterIcon } from '../assets/icons/ProgressBarIcons';
import { getLeaderBoard } from '../apis/user/user';
import RankOneIcon from '../assets/icons/RankOneIcon';
import RankTwoIcon from '../assets/icons/RankTwoIcon';
import RankThreeIcon from '../assets/icons/RankThreeIcon';
import MultiColorProgressBar from '../ui/MultiProgressBar';
function Leaderboard() {
    const navigate = useNavigate();
    const { state } = useContext(AuthContext);
    const { user } = state;
    const userProfile = user[0];

    const { impactPoints, impactCertificatesIssued } = useOutletContext();
    const [leaderBoardMembers, setLeaderBoardMembers] = useState([]);
    useEffect(function () {
        const fetchData = async () => {
            try {
                const data = await getLeaderBoard();
                setLeaderBoardMembers(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);
    return (
        <>
            <div className="flex items-center">
                <BackButton onClick={() => navigate(-1)} />
                <Heading type={'pageHeading'} className={'text-lightBlack ml-3'}>
                    Impact Leaderboard
                </Heading>
            </div>
            <div className="flex items-center mt-12">
                <AvatarImage w="w-[76px]" h="h-[76px]" url={userProfile.profileImage} />

                <div className="ml-6">
                    <Heading type={'large'}>{userProfile.userName}</Heading>
                    <div className="flex gap-9">
                        <div className="flex gap-1 items-center">
                            <img className="w-6 " src={trophy} alt="" />
                            <p className={`text-impactGreen text-3xl font-semibold`}>{impactPoints?.toFixed(2)}</p>
                        </div>
                        <div className="flex gap-1 items-center">
                            <img className="w-6" src={drop} alt="" />
                            <p className={`text-impactRed text-3xl  font-semibold`}>{impactCertificatesIssued}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-16">
                <div className="grid gap-10 grid-cols-tableLeaderboard">
                    <div className="flex items-center">
                        <SubHeading className={'text-primaryInput'} type={'large'}>
                            Rank
                        </SubHeading>
                    </div>
                    <div className="flex items-center">
                        <SubHeading className={'text-primaryInput'} type={'large'}>
                            Username
                        </SubHeading>
                    </div>
                    <div className="flex items-center">
                        <SubHeading className={'text-primaryInput  '} type={'large'}>
                            Impact Score
                        </SubHeading>
                    </div>
                    <div className="flex items-center pr-[242px]">
                        <SubHeading className={'text-primaryInput'} type={'large'}>
                            Impact Cores
                        </SubHeading>
                        <div className="flex items-center">
                            <EarthIcon />
                            <SunIcon />
                            <FistIcon />
                            <WaterIcon />
                        </div>
                    </div>
                </div>
                <div>
                    {leaderBoardMembers.map((leaderBoardMember, index) => {
                        const totalCorePoints = leaderBoardMember?.coreWiseImpactPoints?.reduce(
                            (acc, curr, i) => acc + curr?.impactPoints,
                            0
                        );
                        const waterPoints = leaderBoardMember?.coreWiseImpactPoints?.find(
                            (item) => item?.core.toLowerCase() === 'water'
                        );
                        const earthPoints = leaderBoardMember?.coreWiseImpactPoints?.find(
                            (item) => item?.core.toLowerCase() === 'earth'
                        );
                        const energyPoints = leaderBoardMember?.coreWiseImpactPoints?.find(
                            (item) => item?.core.toLowerCase() === 'energy'
                        );
                        const socialPoints = leaderBoardMember?.coreWiseImpactPoints?.find(
                            (item) => item?.core.toLowerCase() === 'social'
                        );

                        return (
                            <div
                                key={leaderBoardMember._id}
                                className="grid gap-10 grid-cols-tableLeaderboard py-4 border-b border-borderColor "
                            >
                                {index + 1 === 1 && <TopInLead className="place-self-center" />}
                                {index + 1 === 2 && <TopInLead color="#B3BAC7" className="place-self-center" />}
                                {index + 1 === 3 && <TopInLead color="#D03801" className="place-self-center" />}

                                {index + 1 > 3 && <p className="place-self-center">{index + 1}</p>}

                                <div className="flex items-center">
                                    <AvatarImage
                                        w="w-9"
                                        h="h-9"
                                        className="mr-3"
                                        url={leaderBoardMember.profileImage}
                                    />
                                    <p className="text-lg text-primaryInput  capitalize">
                                        {leaderBoardMember.userName}
                                    </p>
                                </div>

                                <p className="text-lg text-primaryInput   font-medium ">
                                    {(Number(leaderBoardMember?.impactPoints) / 1000).toFixed(2)}K
                                </p>
                                <div className="pr-[242px]">
                                    <MultiColorProgressBar
                                        total={leaderBoardMember?.coreWiseImpactPoints}
                                        totalCorePoints={totalCorePoints}
                                        className="place-self-center w-full  "
                                        sections={[
                                            {
                                                percentage: earthPoints?.impactPoints ? earthPoints?.impactPoints : 0,

                                                color: 'bg-[#00BA34]',
                                            }, // Red section, 25%
                                            {
                                                percentage: energyPoints?.impactPoints ? energyPoints?.impactPoints : 0,
                                                color: 'bg-[#FBCF4E]',
                                            }, // Green section, 20%
                                            {
                                                percentage: socialPoints?.impactPoints ? socialPoints?.impactPoints : 0,
                                                color: 'bg-[#BA2C2C]',
                                            }, // Blue section, 15%
                                            {
                                                percentage: waterPoints?.impactPoints ? waterPoints?.impactPoints : 0,
                                                color: 'bg-[#76C8E8]',
                                            }, // Yellow section, 10%
                                        ]}
                                    />
                                </div>
                                {/* <ProgressBar
                                    className="place-self-center pr-[242px]"
                                    multiple={true}
                                    width={
                                        (Number(energyPoints?.impactPoints ? energyPoints?.impactPoints : 0) /
                                            totalCorePoints) *
                                        100
                                    }
                                    bPer={
                                        (Number(energyPoints?.impactPoints ? energyPoints?.impactPoints : 0) /
                                            totalCorePoints) *
                                        100
                                    }
                                    cPer={
                                        (Number(socialPoints?.impactPoints ? socialPoints?.impactPoints : 0) /
                                            totalCorePoints) *
                                        100
                                    }
                                    dPer={
                                        (Number(earthPoints?.impactPoints ? earthPoints?.impactPoints : 0) /
                                            totalCorePoints) *
                                        100
                                    }
                                    color="bg-foundryGreen"
                                    colorb={'bg-foundryYellow'}
                                    colorc={'bg-foundryRed'}
                                    colord="bg-foundryBlue"
                                /> */}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default Leaderboard;
