import { useContext, useEffect, useState } from 'react';
import Button from '../../../ui/Button';
import Heading from '../../../ui/Heading';
import { executeProposal, getSummaryOfProposal } from '../../../apis/treasurer/treasurer';
import Loader from '../../../ui/Loader';
import NoDataAvailable from '../../../ui/NoDataAvailable';
import { useWallet } from '@solana/wallet-adapter-react';
import { AuthContext } from '../../../contexts/AuthContext';
import { clusterApiUrl, PublicKey, sendAndConfirmTransaction } from '@solana/web3.js';
import {
    executorToCheckSufficientSOLBalance,
    executorToExecuteProposal,
    executorToFindIfUserHasVoted,
    executorToReadVotingCount,
    executorToVoteOnProposal,
} from '../../../blockchainInteraction/votingOnProposals';
import { executorToFetchThreshold } from '../../../blockchainInteraction/fetchThreshold';
import { Connection } from '@solana/web3.js';
import OwnButton from '../../walletconnectUI/OwnButton';
import { getProvider } from '../../../helpers/getProvider';
import WalletConnectCustomButton from '../../walletconnectUI/WalletCustomText';

import VotedTick from '../../../assets/icons/VotedTick';
import VotedTickRed from '../../../assets/icons/VotedTickRed';
import ProgressBar from '../../../ui/ProgressBar';
import { useLoadingState } from '../../../hooks/useLoader';
import { toast } from 'sonner';
import { RPC_ENDPOINT } from '../../../constants/apiPaths';

function VotingCardForBountyValidation({
    activeValidationProposal,
    reqId,
    afterExecutionOperation,
    multiSig,
    changeVotingStatusForValidation,
}) {
    const [votingStatus, setVotingStatus] = useState('');
    const { loading: executing, startLoading: startExecuting, stopLoading: stopExecuting } = useLoadingState();
    const { publicKey } = useWallet();
    const [summary, setSummary] = useState({});
    const [isUserVoted, setIsUserVoted] = useState(false);
    const { state } = useContext(AuthContext);
    const { user } = state;
    const userProfile = user[0];
    const [blockchainData, setBlockchainData] = useState({
        thresholdVoteCount: 0,
        yesVoteCount: 0,
        noVoteCount: 0,
    });
    const [isLoading, setIsLoading] = useState(false);
    useEffect(
        function () {
            if (
                blockchainData.yesVoteCount >= blockchainData.thresholdVoteCount &&
                blockchainData.thresholdVoteCount !== 0 &&
                summary.proposalAddress
            ) {
                changeVotingStatusForValidation('ready to execute', summary.proposalAddress);
            }
        },
        [blockchainData, summary]
    );
    useEffect(
        function () {
            const fetchData = async () => {
                const connection = new Connection(RPC_ENDPOINT, 'confirmed');
                try {
                    setIsLoading(true);

                    const data = await getSummaryOfProposal(reqId);
                    setSummary(data);
                    setIsLoading(false);
                    const fetchedThreshold = await executorToFetchThreshold(multiSig, connection);

                    const votingCount = await executorToReadVotingCount(
                        connection,
                        new PublicKey(data.proposalAddress)
                    );
                    const hasUserVoted = await executorToFindIfUserHasVoted(
                        connection,
                        new PublicKey(data.proposalAddress),
                        new PublicKey(userProfile.walletAddress),
                        multiSig
                    );

                    setIsUserVoted(hasUserVoted);
                    setVotingStatus(hasUserVoted);
                    setBlockchainData((initial) => ({
                        ...initial,
                        thresholdVoteCount: fetchedThreshold,
                        yesVoteCount: votingCount.yesVoteCount,
                        noVoteCount: votingCount.noVoteCount,
                    }));
                } catch (error) {
                    console.log(error);
                }
            };

            if (reqId) fetchData();
            if (!reqId) {
                setIsLoading(false);
                setSummary({});
            }
            return () =>
                setBlockchainData((initial) => ({
                    ...initial,
                    thresholdVoteCount: 0,
                    yesVoteCount: 0,
                    noVoteCount: 0,
                }));
        },
        [reqId]
    );
    const onVoting = async (vote) => {
        const connection = new Connection(RPC_ENDPOINT, 'confirmed');

        try {
            const transaction = await executorToVoteOnProposal(
                connection,
                new PublicKey(multiSig),
                new PublicKey(summary.proposalAddress),
                new PublicKey(userProfile.walletAddress),
                vote
            );
            const { signature } = await getProvider().signAndSendTransaction(transaction);

            await connection.getSignatureStatus(signature);

            const votingCount = await executorToReadVotingCount(connection, new PublicKey(summary.proposalAddress));

            setBlockchainData((initial) => ({
                ...initial,
                ...(vote
                    ? {
                          yesVoteCount: initial.yesVoteCount + 1,
                      }
                    : { noVoteCount: initial.noVoteCount + 1 }),
            }));

            const isExecutable = vote ? blockchainData.yesVoteCount + 1 >= blockchainData.thresholdVoteCount : false;

            const status = isExecutable ? 'ready to execute' : vote ? 'voted yes' : 'voted no';
            // const status = vote ? 'voted yes' : 'voted no';

            changeVotingStatusForValidation(status, summary.proposalAddress);
            setVotingStatus(status);
        } catch (error) {
            console.log(error);
        }
    };
    const onExecution = async () => {
        const connection = new Connection(RPC_ENDPOINT, 'confirmed');

        try {
            const isNotEnoughSOLBalance = await executorToCheckSufficientSOLBalance(
                connection,
                userProfile?.walletAddress,
                new PublicKey(multiSig),
                new PublicKey(summary.proposalAddress)
            );
            if (isNotEnoughSOLBalance)
                throw new Error('You do not have enough SOL in your wallet to execute this transaction. ');
            startExecuting();
            const transaction = await executorToExecuteProposal(
                connection,
                userProfile?.walletAddress,
                new PublicKey(multiSig),
                new PublicKey(summary.proposalAddress)
            );

            const signedTransaction = await getProvider().signTransaction(transaction);

            const signature = await sendAndConfirmTransaction(connection, signedTransaction);

            const response = await executeProposal(reqId);
            afterExecutionOperation(reqId);
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
        stopExecuting();
    };

    const address = publicKey;
    console.log(summary, 'see summary');
    return (
        <div className="bg-white shadow-md h-full max-h-[85vh]  rounded-md  p-6 border border-borderColor min-w-full ">
            {isLoading ? (
                <div className="min-h-[20vh] flex justify-center items-center">
                    <Loader />
                </div>
            ) : Object.keys(summary).length ? (
                <div className="flex  flex-col h-full justify-between ">
                    <div className="">
                        <div className="flex w-full justify-between items-center">
                            <div className="w-[50%]">
                                <Heading type={'large'} className={'text-lightBlack truncate'}>
                                    {summary.bountyTitle}
                                </Heading>
                                <p className="text-xs truncate text-secondaryText">{summary.bountyId}</p>
                            </div>
                            {blockchainData.yesVoteCount >= blockchainData.thresholdVoteCount &&
                                blockchainData.thresholdVoteCount !== 0 && (
                                    <div className="30%">
                                        {address ? (
                                            <Button onClick={onExecution} size="small">
                                                {executing ? <Loader /> : 'Execute'}
                                            </Button>
                                        ) : (
                                            // <ExecuteButton />
                                            <div className="w-full flex items-center">
                                                <WalletConnectCustomButton
                                                    customText={'Execute'}
                                                    width="128px"
                                                    customStyles={{
                                                        backgroundColor: 'var(--primary-color)',
                                                        color: '#fff',
                                                        border: '1px solid #dcdcdc',
                                                        lineHeight: 'normal',
                                                        fontWeight: '500',
                                                        paddingLeft: '12px',
                                                        paddingRight: '12px',
                                                        height: '40px',
                                                        borderRadius: '8px',
                                                        flexGrow: 1,
                                                    }}
                                                    connectedText={'Execute'}
                                                    disconnectedText="Execute"
                                                    removeIconOnDisconnect={true}
                                                    removeIconOnConnect={true}
                                                    removeIconOnWalletSelect={true}
                                                    removeIconOnConnecting={true}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                        </div>

                        <div className=" flex flex-col  ">
                            <p className="font-medium mt-6 mb-4">Required Votes </p>
                            <div className="flex items-center gap-5 justify-between">
                                <ProgressBar
                                    color={'bg-[#0085FF]'}
                                    width={`${
                                        (blockchainData.yesVoteCount / blockchainData.thresholdVoteCount) * 100
                                    }%`}
                                    className=""
                                    colorb={'bg-red-400'}
                                    colorc={'bg-green-400 '}
                                    colord={'bg-blue-200'}
                                    // multiple={true}
                                    bgDefault={'bg-[#E5F3FF]'}
                                />
                                <p className="flex items-center gap-1">
                                    <span className="font-bold">
                                        {blockchainData.yesVoteCount}/{blockchainData.thresholdVoteCount}
                                    </span>{' '}
                                    <span>Votes</span>
                                </p>
                            </div>
                        </div>
                        <div className="flex gap-5 pb-3 mt-5 border-b border-borderColor">
                            <p className="bg-foundryGreenL flex items-center gap-1 border border-green-200 rounded-lg   py-1  text-foundryGreen capitalize text-xs sm:px-2 px-4 font-medium ">
                                <span>
                                    <VotedTick />
                                </span>
                                <span> Voted - Yes : {blockchainData.yesVoteCount}</span>
                            </p>
                            <p className="bg-foundryRedL flex items-center gap-1  border border-red-200 rounded-lg sm:px-2 px-4  py-1 text-foundryRed capitalize text-xs font-medium ">
                                <span>
                                    <VotedTickRed />
                                </span>
                                <span> Voted - No : {blockchainData.noVoteCount}</span>
                            </p>
                        </div>
                        <ul className="py-3">
                            <li className="flex justify-between mb-3">
                                <span className="text-secondaryText">Number of Submissions</span>
                                <span>{summary.submissionCount}</span>
                            </li>
                            <li className="flex justify-between mb-3">
                                <span className="text-secondaryText">Number of Bounty Winners</span>
                                <span>{summary.winnerCount}</span>
                            </li>
                            <li className="flex justify-between mb-3">
                                <span className="text-secondaryText">Amount Issued in total</span>
                                <span>
                                    ${summary.rewardTokenSymbol}
                                    <span className="ml-1"> {summary.issueRewardAmount}</span>
                                </span>
                            </li>
                            <li className="flex justify-between mb-3">
                                <span className="text-secondaryText">Validator</span>
                                <span>@{summary.requesterUsername}</span>
                            </li>
                        </ul>

                        {/* <Button
                            // onClick={() => navigate('view-bounties')}
                            className=" w-fit "
                            size="none"
                            variant="transparent"
                        >
                            View More
                        </Button> */}
                    </div>
                    {blockchainData.yesVoteCount < blockchainData.thresholdVoteCount && (
                        <div className="flex mt-5 justify-between">
                            <div>
                                {address ? (
                                    <Button
                                        disabled={votingStatus === 'voted yes' || votingStatus === 'voted no'}
                                        onClick={() => onVoting(true)}
                                        className={`font-bold ${
                                            votingStatus === 'voted yes' || votingStatus === 'voted no'
                                                ? 'cursor-not-allowed'
                                                : ''
                                        } transition-colors duration-2000 ease-in-out `}
                                        variant="approveVoting"
                                        size="small"
                                        fadeOut={votingStatus === 'voted no'}
                                    >
                                        Vote-Yes
                                    </Button>
                                ) : (
                                    <div className="w-full flex items-center">
                                        <WalletConnectCustomButton
                                            customText={'Vote-Yes'}
                                            width="128px"
                                            customStyles={{
                                                backgroundColor: 'var(--alert-green)',
                                                color: '#fff',
                                                border: '1px solid #dcdcdc',
                                                lineHeight: 'normal',
                                                fontWeight: '700',
                                                paddingLeft: '12px',
                                                paddingRight: '12px',
                                                height: '40px',
                                                borderRadius: '8px',
                                                flexGrow: 1,
                                            }}
                                            connectedText={'Vote-Yes'}
                                            disconnectedText="Vote-Yes"
                                            removeIconOnDisconnect={true}
                                            removeIconOnConnect={true}
                                            removeIconOnWalletSelect={true}
                                            removeIconOnConnecting={true}
                                        />
                                    </div>
                                )}
                            </div>

                            <div>
                                {address ? (
                                    <Button
                                        disabled={votingStatus === 'voted yes' || votingStatus === 'voted no'}
                                        onClick={() => onVoting(false)}
                                        className={`font-bold ${
                                            votingStatus === 'voted yes' || votingStatus === 'voted no'
                                                ? 'cursor-not-allowed'
                                                : ''
                                        }  transition-colors duration-2000 ease-in-out  `}
                                        variant="denyVoting"
                                        size="small"
                                        fadeOut={votingStatus === 'voted yes'}
                                    >
                                        Vote-No
                                    </Button>
                                ) : (
                                    <div className="w-full flex items-center">
                                        <WalletConnectCustomButton
                                            customText={'Vote-No'}
                                            width="128px"
                                            customStyles={{
                                                backgroundColor: 'var(--alert-red)',
                                                color: '#fff',
                                                border: '1px solid #dcdcdc',
                                                lineHeight: 'normal',
                                                fontWeight: '700',
                                                paddingLeft: '12px',
                                                paddingRight: '12px',
                                                height: '40px',
                                                borderRadius: '8px',
                                                flexGrow: 1,
                                            }}
                                            connectedText={'Vote-No'}
                                            disconnectedText="Vote-No"
                                            removeIconOnDisconnect={true}
                                            removeIconOnConnect={true}
                                            removeIconOnWalletSelect={true}
                                            removeIconOnConnecting={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <NoDataAvailable minHeight="" />
            )}
        </div>
    );
}

export default VotingCardForBountyValidation;
