import * as multisig from '@sqds/multisig';
import {
    Connection,
    Keypair,
    clusterApiUrl,
    PublicKey,
    sendAndConfirmTransaction,
    Transaction,
    SystemProgram,
    LAMPORTS_PER_SOL,
} from '@solana/web3.js';
import * as token from '@solana/spl-token';
import { TransactionMessage } from '@solana/web3.js';
import { VersionedTransaction } from '@solana/web3.js';
import { toast } from 'sonner';
// import wallet from '/Users/altar/.config/solana/id.json';

// const connection = new Connection(RPC_ENDPOINT, 'confirmed');
// const treasurerMultisig = new PublicKey('E29xeuy78c1HTt63exiNnVZQPWn3f7hUQ2jB8d4qZfpT');

export const executorToAddFunds = async (tokenAddress, sendAmount, treasurerMultisig, sender, connection) => {
    // const tokenAddress = new PublicKey('MziuzBBX5MKoNrfCh551VzVGFNLyEpJMWTajJ7mLERL');
    // const sendAmount = 2;

    const mintDetails = await token.getMint(connection, tokenAddress);
    const rawSendAmount = sendAmount * 10 ** mintDetails.decimals;
    // const sender = Keypair.fromSecretKey(new Uint8Array(wallet));
    const [receiverAddress] = multisig.getVaultPda({ multisigPda: treasurerMultisig, index: 0 });
    const instructions = [];

    const sourceAccount = token.getAssociatedTokenAddressSync(tokenAddress, sender);
    const destinationAccount = token.getAssociatedTokenAddressSync(tokenAddress, receiverAddress, true);
    const destinationAccountInfo = await connection.getAccountInfo(destinationAccount);
    if (!destinationAccountInfo) {
        const createTokenAccountIx = token.createAssociatedTokenAccountInstruction(
            sender,
            destinationAccount,
            receiverAddress,
            tokenAddress
        );
        instructions.push(createTokenAccountIx);
    }
    const transferIx = token.createTransferInstruction(sourceAccount, destinationAccount, sender, rawSendAmount);

    instructions.push(transferIx);

    let blockhash = await connection.getLatestBlockhash().then((res) => res.blockhash);

    const message = new TransactionMessage({
        payerKey: sender,
        recentBlockhash: blockhash,
        instructions,
    }).compileToV0Message();

    const transaction = new VersionedTransaction(message);

    return transaction;
};

export const executorToAddSOL = async (connection, solAmount, sender, orgMultisig) => {
    const lamportAmount = solAmount * LAMPORTS_PER_SOL;
    const balance = await connection.getBalance(sender, 'confirmed');
    if (lamportAmount >= balance) {
        throw new Error('Insufficient balance');
    }
    const [receiver] = multisig.getVaultPda({
        multisigPda: orgMultisig,
        index: 0,
    });
    const instruction = SystemProgram.transfer({ fromPubkey: sender, toPubkey: receiver, lamports: lamportAmount });
    // create transaction message
    const message = new TransactionMessage({
        payerKey: sender,
        recentBlockhash: (await connection.getLatestBlockhash()).blockhash,
        instructions: [instruction],
    }).compileToV0Message();

    const transaction = new VersionedTransaction(message);
    return transaction;
};

export const executorToReadSOL = async (connection, orgMultisig) => {
    const [treasury] = multisig.getVaultPda({
        multisigPda: orgMultisig,
        index: 0,
    });
    const balance = await connection.getBalance(treasury, 'confirmed');
    return balance / LAMPORTS_PER_SOL;
};
