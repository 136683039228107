import Input from '../../ui/Input';
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';
import Toggle from '../../ui/Toggle';
import { useStepFiveInputs } from './bountiesHooks/useStepFiveInputs';
import UploadLogo from './UploadLogo';
import CharacterLimit from '../../ui/CharacterLimit';

function StepFive({ setStep, control, errors, getValues, clearErrors }) {
    const {
        hasBackerField,
        backerNameField,
        backerLogoField,
        backerMessageField,
        initiatorNameField,
        initiatorLogoField,
        hasInitiatorField,
    } = useStepFiveInputs(control);

    return (
        <div className="p-12 pb-0 shadow-md  rounded-md min-h-full flex flex-col justify-between ">
            <div>
                <div className=" mb-6">
                    <div className="flex gap-2 items-center">
                        <Heading type="xsmall" className="  text-primaryInput">
                            Does the bounty have a sponsor or backer ?
                        </Heading>
                        <Toggle
                            valuee={hasBackerField.value}
                            onChangeFn={() => {
                                if (getValues()?.hasBacker) {
                                    clearErrors('backerName');
                                    clearErrors('backerLogo');
                                    hasBackerField.onChange(false);
                                    return;
                                }
                                if (!getValues()?.hasBacker) hasBackerField.onChange(true);
                            }}
                        />
                    </div>
                    <p className="text-sm text-secondaryInput">
                        Select this option if you wish to display the bounty <br /> sponsor/backer's logo and name on
                        the bounty?
                    </p>
                </div>
                {hasBackerField.value && (
                    <div className="mb-6 pb-6 border-b-2 border-borderColor ">
                        <div className="my-6">
                            <Heading type="xsmall" className="capitalize mb-2 text-primaryInput">
                                Enter sponsor or backer's name <sup className="text-lg relative top-[1px]">*</sup>
                            </Heading>
                            <Input
                                {...backerNameField}
                                type="text"
                                placeholder={'Mention the name that will appear on bounties & impact certs'}
                                className={'w-[100%]'}
                                error={errors?.backerName?.message}
                            />
                            <div className="mt-1">
                                <CharacterLimit
                                    min={backerNameField.value ? backerNameField.value.length : 0}
                                    max={35}
                                />
                            </div>
                        </div>

                        <div className="my-6">
                            <Heading type="xsmall" className="capitalize mb-2 text-primaryInput">
                                Upload sponsor or backer’s logo <sup className="text-lg relative top-[1px]">*</sup>
                            </Heading>

                            {backerLogoField.value ? (
                                <img
                                    className="w-20 h-20 rounded-md object-center object-cover "
                                    src={backerLogoField.value}
                                    alt="profile"
                                />
                            ) : (
                                <UploadLogo
                                    outerPadding=""
                                    fieldName={backerLogoField}
                                    errorField={'backerLogo'}
                                    errors={errors}
                                />
                            )}
                        </div>
                        <div className="my-6">
                            <Heading type="xsmall" className="capitalize mb-2 text-primaryInput">
                                Enter Sponsor Message
                            </Heading>
                            <Input
                                {...backerMessageField}
                                type="text"
                                placeholder={'Add sponsor message if any, optional'}
                                className={'w-[100%]'}
                                error={errors?.backerMessage?.message}
                            />
                            <div className="mt-1">
                                <CharacterLimit
                                    min={backerMessageField.value ? backerMessageField.value.length : 0}
                                    max={80}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className="mb-6">
                    <div className="flex gap-2 items-center">
                        <Heading type="xsmall" className="  text-primaryInput">
                            Does the bounty have an Initiator ?
                        </Heading>
                        <Toggle
                            valuee={hasInitiatorField.value}
                            onChangeFn={() => {
                                if (getValues()?.hasInitiator) {
                                    clearErrors('initiatorName');
                                    hasInitiatorField.onChange(false);
                                    return;
                                }
                                if (!getValues()?.hasInitiator) hasInitiatorField.onChange(true);
                            }}
                        />
                    </div>
                    {hasInitiatorField.value && (
                        <>
                            <div className="my-6">
                                <Heading type="xsmall" className=" mb-2 text-primaryInput">
                                    Enter Initiator's name
                                </Heading>
                                <Input
                                    {...initiatorNameField}
                                    type="text"
                                    error={errors?.initiatorName?.message}
                                    placeholder={'Mention the name that will appear on bounties & impact certs'}
                                    className={'w-[100%]'}
                                />
                                <div className="mt-1">
                                    <CharacterLimit
                                        min={initiatorNameField.value ? initiatorNameField.value.length : 0}
                                        max={35}
                                    />
                                </div>
                            </div>

                            <div className="my-6">
                                <Heading type="xsmall" className=" mb-2 text-primaryInput">
                                    Upload Initiator’s image
                                </Heading>
                                {initiatorLogoField.value ? (
                                    <img
                                        className="w-20 h-20 rounded-md object-center object-cover "
                                        src={initiatorLogoField.value}
                                        alt="profile"
                                    />
                                ) : (
                                    <UploadLogo
                                        outerPadding=""
                                        fieldName={initiatorLogoField}
                                        errorField={'initiatorLogo'}
                                        errors={errors}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className="fixed bottom-10  z-[9999] w-[618px] mx-auto  ">
                <div className="flex justify-between items-center  ">
                    <Button
                        type="button"
                        onClick={() => setStep(4)}
                        className="bg-white"
                        variant="tertiary"
                        size="small"
                    >
                        Back
                    </Button>
                    <Button type="button" onClick={() => setStep(6)} size="small">
                        Next
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default StepFive;
