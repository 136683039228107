// useMetaMask.js
import { useState, useEffect } from 'react';
import detectEthereumProvider from '@metamask/detect-provider';

const useMetaMask = () => {
    const [provider, setProvider] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [isConnected, setIsConnected] = useState(false);
    const [chainId, setChainId] = useState(null);

    useEffect(() => {
        const initMetaMask = async () => {
            // Attempt to detect MetaMask provider
            const detectedProvider = await detectEthereumProvider();

            if (detectedProvider && detectedProvider.isMetaMask) {
                setProvider(detectedProvider);

                try {
                    // Fetch accounts and chain ID
                    const accounts = await detectedProvider.request({ method: 'eth_accounts' });
                    const chainId = await detectedProvider.request({ method: 'eth_chainId' });

                    if (accounts.length > 0) {
                        setAccounts(accounts);
                        setIsConnected(true);
                    }
                    setChainId(chainId);

                    // Set up event listeners
                    detectedProvider.on('accountsChanged', handleAccountsChanged);
                    detectedProvider.on('chainChanged', handleChainChanged);
                } catch (error) {
                    console.error('Error initializing MetaMask:', error);
                }
            } else {
                // MetaMask is not installed or detected
                setProvider(null);
                setIsConnected(false);
            }
        };

        const handleAccountsChanged = (accounts) => {
            if (accounts.length > 0) {
                setAccounts(accounts);
                setIsConnected(true);
            } else {
                setAccounts([]);
                setIsConnected(false);
            }
        };

        const handleChainChanged = (chainId) => {
            setChainId(chainId);
            window.location.reload(); // Refresh the page to update the network
        };

        initMetaMask();

        // Cleanup event listeners on component unmount
        return () => {
            if (provider) {
                provider.removeListener('accountsChanged', handleAccountsChanged);
                provider.removeListener('chainChanged', handleChainChanged);
            }
        };
    }, [provider]);

    const connect = async () => {
        const detectedProvider = await detectEthereumProvider();

        if (detectedProvider && detectedProvider.isMetaMask) {
            try {
                const accounts = await detectedProvider.request({ method: 'eth_requestAccounts' });
                const chainId = await detectedProvider.request({ method: 'eth_chainId' });
                setAccounts(accounts);
                setChainId(chainId);
                setIsConnected(true);
                return accounts;
            } catch (error) {
                console.error('Error connecting to MetaMask:', error);
                return false;
            }
        } else {
            // Redirect to MetaMask installation page
            window.open('https://metamask.io/download.html', '_blank');
        }
    };

    const disconnect = () => {
        setAccounts([]);
        setIsConnected(false);
    };

    const switchNetwork = async (chainId) => {
        if (provider) {
            try {
                await provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: `0x${chainId.toString(16)}` }],
                });
                setChainId(`0x${chainId.toString(16)}`);
            } catch (error) {
                console.error('Error switching network:', error);
            }
        }
    };

    return { provider, accounts, isConnected, chainId, connect, disconnect, switchNetwork };
};

export default useMetaMask;
