function Status({ text, className, bg = 'bg-primaryBg' }) {
    return (
        <p
            className={` capitalize font-semibold text-xs px-3 py-2 ${bg} text-thirdGray rounded-3xl border-2  border-borderColor  ${className}`}
        >
            {text}
        </p>
    );
}

export default Status;
