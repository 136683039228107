import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import AvatarImage from '../../ui/AvatarImage';
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';
import { CopyIcon, EditIcon } from '../../assets/icons/UserProfileIcons';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { copyToClipboard } from '../../helpers/copyText';
import { toast } from 'sonner';

function ProfileHeader() {
    const { userProfile } = useOutletContext();
    const navigate = useNavigate();
    return (
        <div className="flex justify-between items-center">
            <div className="flex gap-5  items-center  ">
                <AvatarImage w="w-[76px]" h="h-[76px]" url={userProfile.profileImage} />
                <div>
                    <Heading type={'xxlarge'}>{userProfile.userName}</Heading>

                    <div className="flex items-center gap-2">
                        <Heading className={'text-secondaryText mt-3'} type="medium">
                            User ID : <span className="font-normal"> {userProfile.uid.slice(0, 8)}...</span>
                        </Heading>
                        <div
                            onClick={() => {
                                copyToClipboard(userProfile.uid);
                                toast.success('Text copied to clipboard.');
                            }}
                            className="cursor-pointer"
                        >
                            <CopyIcon />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex gap-3 items-center">
                <Button
                    className="flex items-center justify-center gap-[10px]"
                    onClick={() => navigate('editprofile')}
                    variant="white"
                    size="xsmall"
                >
                    <span> Edit Profile </span> <EditIcon />
                </Button>
            </div>
        </div>
    );
}

export default ProfileHeader;
