import { useEffect, useRef, useState } from 'react';
import Overlay from '../../../ui/Overlay';
import Button from '../../../ui/Button';
import Feedbackclose from '../../../assets/icons/Feedbackclose';
import Heading from '../../../ui/Heading';
import WalletConnectButton from '../../walletconnectUI/WalletConnectButton';
import Dropdown from '../../../ui/Dropdown';
import { getTokensForBudget } from '../../../apis/general/general';
import { useController, useForm } from 'react-hook-form';
import Input from '../../../ui/Input';
import { clusterApiUrl, Connection, sendAndConfirmTransaction } from '@solana/web3.js';
import { executorToAddFunds, executorToAddSOL } from '../../../blockchainInteraction/addFunds';
import { getProvider } from '../../../helpers/getProvider';
import { useLoadingState } from '../../../hooks/useLoader';
import Loader from '../../../ui/Loader';
import { useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import { toast } from 'sonner';
import { RPC_ENDPOINT } from '../../../constants/apiPaths';

function AddSOLInOrganisation({ close, multisig, solBalance, setSolBalance, orgFunds }) {
    const [tokens, setTokens] = useState([]);

    const myRef = useRef({ close });
    const { loading: transferring, startLoading: startTransferring, stopLoading: stopTransferring } = useLoadingState();
    const { publicKey } = useWallet();

    const {
        register,
        handleSubmit,
        control,
        getValues,
        trigger,
        formState: { errors },
        setError,
        reset,
        setValue,

        clearErrors,
    } = useForm({
        mode: 'onChange',
    });

    const { field: totalRewardAmountField } = useController({
        name: 'totalRewardAmount',
        control,
        rules: {
            required: 'Budget is required',
            // validate: (value) => {
            //     console.log(distributionModeField.value);
            //     if (distributionModeField.value === 'Rank based distribution') {
            //         console.log(getValues('ranks'), value);
            //         const ranksAmount = getValues('ranks')
            //             .filter((rank) => rank.amount)
            //             .map((c) => Number(c.amount))
            //             .reduce((acc, curr) => acc + curr, 0);
            //         console.log(ranksAmount, 'ranks here');
            //         if (ranksAmount > Number(value)) return 'Please add more fund';
            //         return true;
            //     }
            //     return true;
            // },
        },
    });
    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    const data = await getTokensForBudget();
                    setTokens(data);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();

            function handleClick(e) {
                if (myRef.current && !myRef.current.contains(e.target)) {
                    close();
                }
            }

            document.addEventListener('click', handleClick, true);

            return () => document.removeEventListener('click', handleClick, true);
        },
        [close]
    );

    const onSubmit = async (data) => {
        const connection = new Connection(RPC_ENDPOINT, 'confirmed');

        try {
            startTransferring();
            const transaction = await executorToAddSOL(
                connection,
                Number(totalRewardAmountField.value),
                publicKey,
                new PublicKey(multisig)
            );

            const signedTransaction = await getProvider().signTransaction(transaction);

            const signature = await sendAndConfirmTransaction(connection, signedTransaction);

            setSolBalance((initial) => initial + Number(totalRewardAmountField.value));
            close();
            toast.success('Funds have been successfully transferred. ');
        } catch (error) {
            console.log(error);

            toast.error(error);
            close();
        } finally {
            stopTransferring();
        }
    };

    return (
        <Overlay>
            <div
                ref={myRef}
                className="absolute rounded-lg left-[50%] w-[625px]  p-2   bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
            >
                <div className="relative modal max-h-[80vh]   p-8  ">
                    <Button
                        onClick={close}
                        className=" border-0 absolute top-3  right-4 "
                        variant="tertiary"
                        size="circle"
                    >
                        <Feedbackclose />
                    </Button>
                    <form onSubmit={handleSubmit(onSubmit)} action="">
                        <div className="border-b border-borderColor pb-6">
                            <div className="flex gap-3">
                                <Heading type="large" className="capitalize font-bold  text-lightBlack">
                                    Add SOL
                                </Heading>
                            </div>
                            <p className="text-sm">Add SOL to your organisation</p>
                        </div>
                        <div className="mt-6">
                            <div className="flex gap-3">
                                <Heading type="medium" className="capitalize font-bold  text-lightBlack">
                                    Connect your wallet
                                </Heading>
                            </div>
                            <p className="text-sm">
                                To sign and authorize organizational transactions, vote on proposals etc.{' '}
                            </p>
                        </div>
                        <div className="mt-3">
                            <WalletConnectButton widthPer="230px" />
                        </div>
                        <div className="mt-11">
                            <div className="flex flex-col gap-3">
                                <p className="text-lg font-semibold">Total Amount</p>
                                <Input
                                    {...totalRewardAmountField}
                                    type="number"
                                    placeholder={'Amount...'}
                                    className={'w-[100%] rounded-s-none '}
                                />
                            </div>

                            {errors?.totalRewardAmount?.message && (
                                <p className="text-foundryRed">Please enter amount</p>
                            )}
                        </div>
                        <div className="flex mt-6 items-center justify-end">
                            <Button variant="primary" size="small">
                                {transferring ? <Loader /> : 'Transfer'}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </Overlay>
    );
}

export default AddSOLInOrganisation;
