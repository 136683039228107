export const desiredInstructionalLinks = (linkArray) => {
    if (linkArray.length === 0) return '';

    return linkArray
        .map(({ startIndex, endIndex, link }) => {
            // Ensure the link has "https://"
            const formattedLink = link.startsWith('https://') ? link : `https://${link}`;
            return `${startIndex}~${endIndex}~${formattedLink}`; // Format as required
        })
        .join(','); // Join all formatted strings with a comma
};
