import Table from '../../ui/Table';
import ReactMapboxGl, { Feature, Layer, Marker } from 'react-mapbox-gl';
import { microtaskTypeData } from '../bounties/microtasktypes/microtaskTypeData';
import { useState } from 'react';
import defaultMarker from '../../assets/defaultMarker.png';
const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoiZmFpc2FsZHJvcGNoYWluIiwiYSI6ImNsdXJnOWF2czA3NmYyam4xZWkzbmltaHAifQ.IK0wrkOv-H-C57rLCcMBMA',
});

const VisitLocationSD = ({ dataTask }) => {
    const titleObject = dataTask?.task.find((obj) => Object.keys(obj).includes('Title'));
    const descriptionObject = dataTask?.task.find((obj) => Object.keys(obj).includes('Description'));
    const coordinateObject = dataTask?.task.find((obj) => Object.keys(obj).includes('coordinate'));
    const [lat, long] = coordinateObject?.coordinate?.split(',');
    const [mapCenter, setMapCenter] = useState([
        dataTask?.submission?.long === 'undefined' ? 0 : dataTask?.submission?.long,
        dataTask?.submission?.lat === 'undefined' ? 0 : dataTask?.submission?.lat,
    ]);
    const [mtCenter, setMtCenter] = useState([long, lat]);
    const [zoomMt, setZoomMt] = useState(14);
    const [zoom, setZoom] = useState(14);

    return (
        <Table.Row>
            <p className=" text-lightBlack flex flex-col   ">
                <span className="font-medium mb-1  "> {titleObject?.Title || '-'} </span>
                <span className="text-xs font-medium mb-1">{descriptionObject?.Description || '-'}</span>
                {lat && long && (
                    <div className=" overflow-hidden bg-white rounded-2xl">
                        <Map
                            style="mapbox://styles/mapbox/streets-v9"
                            containerStyle={{
                                height: '20vh',
                                width: '100%',
                                zIndex: '0',
                            }}
                            center={mtCenter}
                            zoom={[zoomMt]}
                            onZoomEnd={(map) => {
                                setZoomMt(map.getZoom());
                            }}
                        >
                            {lat && long && (
                                <>
                                    {/* Custom Marker with Image */}
                                    <Marker anchor="bottom" coordinates={[long, lat]}>
                                        <img src={defaultMarker} alt="marker" />
                                    </Marker>
                                </>
                            )}
                        </Map>
                    </div>
                )}
            </p>
            <p className="text-sm justify-self-center">
                {microtaskTypeData.find((ele) => ele.type === dataTask.actionType).label}
            </p>

            {dataTask.submission?.long && dataTask.submission?.lat && (
                <div>
                    {mapCenter[0] && mapCenter[1] ? (
                        <div className=" overflow-hidden bg-white rounded-2xl">
                            <Map
                                style="mapbox://styles/mapbox/streets-v9"
                                containerStyle={{
                                    height: '20vh',
                                    width: '100%',
                                    zIndex: '0',
                                }}
                                center={mapCenter}
                                zoom={[zoom]}
                                onZoomEnd={(map) => {
                                    setZoom(map.getZoom());
                                }}
                            >
                                {dataTask?.submission && (
                                    <>
                                        {/* Custom Marker with Image */}
                                        <Marker
                                            anchor="bottom"
                                            coordinates={[dataTask.submission?.long, dataTask.submission?.lat]}
                                        >
                                            <img src={defaultMarker} alt="marker" />
                                        </Marker>
                                    </>
                                )}
                            </Map>
                        </div>
                    ) : null}
                </div>
            )}
        </Table.Row>
    );
};

export default VisitLocationSD;
