import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Overlay from './Overlay';
import Button from './Button';
import Feedbackclose from '../assets/icons/Feedbackclose';

const ExpandedImage = ({ close, children }) => {
    const myRef = useRef({ close });
    const navigate = useNavigate();

    useEffect(
        function () {
            function handleClick(e) {
                if (myRef.current && !myRef.current.contains(e.target)) {
                    close();
                }
            }

            document.addEventListener('click', handleClick, true);

            return () => document.removeEventListener('click', handleClick, true);
        },

        [close]
    );

    return (
        <Overlay>
            <div ref={myRef} className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                {children}
            </div>
        </Overlay>
    );
};

export default ExpandedImage;
