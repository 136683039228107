import ProfileHeader from '../features/profile/ProfileHeader';
import ProfileDetails from '../features/profile/ProfileDetails';

function UserProfile() {
    return (
        <>
            <ProfileHeader />
            <ProfileDetails />
        </>
    );
}

export default UserProfile;
