import { useEffect, useRef } from 'react';
import Overlay from '../../../ui/Overlay';
import CloseCircle from '../../../assets/icons/CloseCircle';
import Button from '../../../ui/Button';
import Heading from '../../../ui/Heading';
import SubHeading from '../../../ui/SubHeading';

function MintModal({ close }) {
    const myRef = useRef();
    useEffect(
        function () {
            function handleClick(e) {
                if (myRef.current && !myRef.current.contains(e.target)) {
                    close();
                }
            }

            document.addEventListener('click', handleClick, true);

            return () => document.removeEventListener('click', handleClick, true);
        },
        [close]
    );
    return (
        <Overlay>
            <div
                ref={myRef}
                className="absolute rounded-lg left-[50%] max-w-[625px] p-2   bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
            >
                <div className="relative modal max-h-[80vh]  overflow-y-auto p-8">
                    <Button
                        onClick={close}
                        className="bg-none border-0 absolute top-1  right-3 "
                        variant="tertiary"
                        size="circle"
                    >
                        <CloseCircle />
                    </Button>
                    <div>
                        <Heading className={'font-bold text-modalHeading'} type={'small'}>
                            Why convert my Impact Certificate into an NFT?
                        </Heading>
                        <div className="mt-6">
                            <SubHeading className={`text-modalHeading`} type={'xsmall'}>
                                Unlock Exclusive Opportunities
                            </SubHeading>
                            <p className="text-xs mt-1 leading-5">
                                By minting your Impact Certificate as an NFT, you open the door to a world of exclusive
                                access. This includes invites to special events, offers, and experiences both within our
                                network and across our partner ecosystems. It's not just a digital asset; it's your VIP
                                pass to opportunities that reward your positive contributions to the climate.
                            </p>
                        </div>
                        <div className="mt-6">
                            <SubHeading className={`text-modalHeading`} type={'xsmall'}>
                                Enhance Your Fundraising Potential
                            </SubHeading>
                            <p className="text-xs mt-1 leading-5">
                                In the realm of web3, credibility is key. Minting your Impact Certificate as an NFT can
                                significantly bolster your reputation, especially on platforms like Gitcoin and others
                                dedicated to forward-thinking projects. This digital badge of honor showcases your
                                commitment and achievements, making it easier to rally support and raise funds for your
                                causes.
                            </p>
                        </div>
                        <div className="mt-6">
                            <SubHeading className={`text-modalHeading`} type={'xsmall'}>
                                Secure and Shareable
                            </SubHeading>
                            <p className="text-xs mt-1 leading-5">
                                Choosing to mint your Impact Certificate as an NFT means securing your achievements on
                                the blockchain, an immutable digital ledger. This not only protects your records but
                                also makes sharing your accomplishments with the world straightforward and tamper-proof
                            </p>
                        </div>

                        <div className="mt-6">
                            <SubHeading className={`text-modalHeading`} type={'xsmall'}>
                                A Gateway to the Web3 Community
                            </SubHeading>
                            <p className="text-xs mt-1 leading-5">
                                Even if you're new to web3, minting an NFT is a simple and seamless step into a vibrant,
                                supportive community. With your Impact NFT, you're not just holding a token of
                                appreciation; you're carrying a story of change that resonates with like-minded
                                individuals and groups across the globe.
                            </p>
                        </div>
                        <div className="mt-6">
                            <SubHeading className={`text-modalHeading`} type={'xsmall'}>
                                It's Your Choice
                            </SubHeading>
                            <p className="text-xs mt-1 leading-5">
                                We believe in empowering you with choices. Minting your Impact Certificate as an NFT is
                                entirely optional, but doing so can significantly amplify the impact of your positive
                                actions. Whether you're here to make a difference, gain recognition, or explore the
                                expanding universe of web3, minting your certificate as an NFT can open up a new
                                dimension of possibilities.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Overlay>
    );
}

export default MintModal;
