import { useContext } from 'react';
import { useController, useFieldArray, useForm } from 'react-hook-form';
import { BountiesContext } from '../contexts/BountiesContext';

export const useBountyFields = () => {
    const { control } = useContext(BountiesContext);
    const { field: titleField } = useController({
        name: 'title',
        control,
        rules: { required: 'Title is required' },
    });
    const { field: descriptionField } = useController({
        name: 'description',
        control,
        rules: {
            required: 'Description is required',
            maxLength: {
                value: 1000,
                message: 'The maximum length is 1,000 characters',
            },
        },
    });

    const { field: projectIdField } = useController({
        name: 'projectId',
        control,
        // rules: { required: 'Project is required' },
    });
    const { field: coreField } = useController({
        name: 'core',
        control,
        rules: { required: 'Core is required' },
    });

    const { field: typeField } = useController({
        name: 'type',
        control,
        rules: { required: 'Bounty Type is required' },
    });

    const { field: modeField } = useController({
        name: 'mode',
        control,
    });
    const { field: distributionModeField } = useController({
        name: 'distributionMode',
        control,
    });
    const { field: cityField } = useController({
        name: 'city',
        control,
    });

    const { field: locationField } = useController({
        name: 'location',
        control,
    });

    const { field: bountyRadiusField } = useController({
        name: 'bountyRadius',
        control,
    });

    const { field: maxAcceptancesPerUserField } = useController({
        name: 'maxAcceptancesPerUser',
        control,
        // rules: { required: 'This field is required' },
    });
    const { field: expirationTimeField } = useController({
        name: 'expirationTime',
        control,
    });
    const { field: estimatedCompletionTimeField } = useController({
        name: 'estimatedCompletionTime',
        control,
    });
    const { field: validationTillDateField } = useController({
        name: 'validationTillDate',
        control,
    });

    const { field: backerNameField } = useController({
        name: 'backer.name',
        control,
        defaultValue: '',
    });

    const { field: backerMessageField } = useController({
        name: 'backer.message',
        control,
        defaultValue: '',
    });
    const { field: noOfWinnersField } = useController({
        name: 'noOfWinners',
        control,
    });
    const { field: SDGsField } = useController({
        name: 'SDGs',
        control,
    });
    const { field: doughnutMetricsField } = useController({
        name: 'doughnutMetrics',
        control,
    });
    const { field: coresField } = useController({
        name: 'cores',
        control,
    });

    const {
        fields: criteriaFields,
        move,
        remove,
    } = useFieldArray({
        control,
        name: 'criteria',
    });
    const {
        fields: microTaskFields,
        append,
        prepend: addTaskInList,
    } = useFieldArray({
        control,
        name: 'microtasks',
    });
    // const { field: totalRewardAmountField } = useController({
    //     name: 'totalRewardAmount',
    // });
    // const { field: distributionField } = useController({
    //     name: 'distribution',
    // });
    return {
        titleField,
        descriptionField,

        typeField,
        modeField,
        cityField,
        coreField,
        locationField,
        bountyRadiusField,
        maxAcceptancesPerUserField,
        criteriaFields,
        microTaskFields,
        distributionModeField,
        noOfWinnersField,
        expirationTimeField,
        estimatedCompletionTimeField,
        validationTillDateField,
        SDGsField,
        doughnutMetricsField,
        coresField,
        projectIdField,
        backerNameField,
        backerMessageField,
        // totalRewardAmountField,
        // distributionField,
        move,
        addTaskInList,
    };
};
