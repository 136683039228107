export const getMicroTaskFieldsAndValues = (obj) => {
    let arr = [];

    for (const [key, value] of Object.entries(obj)) {
        const keyToPush = key.split('_')[1];

        const objToPush = {
            name: keyToPush,
            value: value,
        };
        arr.push(objToPush);
    }

    return arr;
};
