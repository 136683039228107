const BountyTakenTimeIcon = () => {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.4582 5.87872L11.1365 7.38456L11.3207 9.37789L9.36525 9.82206L8.3415 11.5446L6.49984 10.7537L4.65817 11.55L3.63442 9.82747L1.679 9.38331L1.86317 7.38456L0.541504 5.87872L1.86317 4.36747L1.679 2.37414L3.63442 1.93539L4.65817 0.212891L6.49984 1.00372L8.3415 0.212891L9.36525 1.93539L11.3207 2.37956L11.1365 4.37289L12.4582 5.87872ZM11.0119 5.87872L10.0207 4.73581L10.1507 3.22456L8.6665 2.89956L7.89734 1.58331L6.49984 2.18456L5.10234 1.58331L4.33317 2.89956L2.849 3.21914L2.979 4.73039L1.98775 5.87872L2.979 7.01622L2.849 8.53289L4.33317 8.85789L5.10234 10.1741L6.49984 9.56747L7.89734 10.1687L8.6665 8.85789L10.1507 8.52747L10.0207 7.01622L11.0119 5.87872ZM5.95817 7.50372H7.0415V8.58706H5.95817V7.50372ZM5.95817 3.17039H7.0415V6.42039H5.95817V3.17039Z"
                fill="#36B67B"
            />
        </svg>
    );
};

export default BountyTakenTimeIcon;
