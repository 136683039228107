import CloseIcon from '../../assets/icons/CloseIcon';
import SubHeading from '../../ui/SubHeading';
import Tag from '../../ui/Tag';

function SuperPowersInAttesterInput({ powers, setPowers, categories, selectedPowers, setSelectedPowers }) {
    return (
        <div>
            <div className="mb-8">
                {/* <TextWithTooltip infoColor="#5B6677" hoverOverText={'Superpowers'} hoverOverTextColor="text-[#262626]">
                    <p className="text-[#404040]">
                        Superpowers are the skills you are good at. Setup your superpowers accurately to get improved
                        matching with earning opportunities.
                    </p>
                </TextWithTooltip> */}

                <div className="flex flex-wrap mt-3 gap-2">
                    {selectedPowers.map((selectedPower, index) => (
                        <Tag
                            key={selectedPower.item}
                            textColor="#303B47"
                            textClassName="mr-3"
                            className=" border flex items-center cursor-pointer text-sm capitalize  px-[10px] py-[6px]  border-[#B3BAC7]"
                            text={selectedPower.item}
                            icon={
                                <CloseIcon
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const filteredPowers = selectedPowers.filter(
                                            (power) => power.item !== selectedPower.item
                                        );
                                        setPowers((prev) => ({
                                            ...prev,
                                            [selectedPower.category]: [
                                                ...powers[selectedPower.category],
                                                selectedPower.item,
                                            ],
                                        }));
                                        setSelectedPowers(filteredPowers);
                                    }}
                                    color="#B2B2B2"
                                />
                            }
                        />
                    ))}
                </div>
            </div>

            <div className="mt-8">
                <SubHeading className={'text-[#262626]'} type={'large'}>
                    Engineering & Technology
                </SubHeading>
                <div className="flex flex-wrap mt-3 gap-2">
                    {powers[categories[0]].map((item) => (
                        <Tag
                            key={item}
                            onClick={() => {
                                const filteredPows = powers[categories[0]].filter((power) => power !== item);

                                setPowers((prev) => ({ ...prev, [categories[0]]: filteredPows }));
                                setSelectedPowers((prev) => [...prev, { category: categories[0], item }]);
                            }}
                            textColor="#303B47"
                            className=" border cursor-pointer text-sm capitalize  px-[10px] py-[6px]  border-[#B3BAC7]"
                            text={item}
                        />
                    ))}
                </div>
            </div>
            <div className="mt-8">
                <SubHeading className={'text-[#262626]'} type={'large'}>
                    Media , Art & Content
                </SubHeading>
                <div className="flex flex-wrap mt-3 gap-2">
                    {powers[categories[1]].map((item) => (
                        <Tag
                            key={item}
                            onClick={() => {
                                const filteredPows = powers[categories[1]].filter((power) => power !== item);

                                setPowers((prev) => ({ ...prev, [categories[1]]: filteredPows }));
                                setSelectedPowers((prev) => [...prev, { category: categories[1], item }]);
                            }}
                            textColor="#303B47"
                            className=" border cursor-pointer text-sm capitalize  px-[10px] py-[6px]  border-[#B3BAC7]"
                            text={item}
                        />
                    ))}
                </div>
            </div>
            <div className="mt-8">
                <SubHeading className={'text-[#262626]'} type={'large'}>
                    Business , Finance & Law
                </SubHeading>
                <div className="flex flex-wrap mt-3 gap-2">
                    {powers[categories[2]].map((item) => (
                        <Tag
                            key={item}
                            onClick={() => {
                                const filteredPows = powers[categories[2]].filter((power) => power !== item);

                                setPowers((prev) => ({ ...prev, [categories[2]]: filteredPows }));

                                setSelectedPowers((prev) => [...prev, { category: categories[2], item }]);
                            }}
                            textColor="#303B47"
                            className=" border cursor-pointer text-sm capitalize  px-[10px] py-[6px]  border-[#B3BAC7]"
                            text={item}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default SuperPowersInAttesterInput;
