import { useContext, useEffect, useRef, useState } from 'react';
import AddProfileImage from '../../assets/icons/AddProfileImage';
import Info from '../../assets/icons/Info';
import Button from '../../ui/Button';
import Heading from '../../ui/Heading';
import Input from '../../ui/Input';
import WalletConnection from './WalletConnection';
import { createProfileAtDb } from '../../apis/user/user';
import { useWallet } from '@solana/wallet-adapter-react';
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { useLoadingState } from '../../hooks/useLoader';
import Loader from '../../ui/Loader';
import { useController, useForm } from 'react-hook-form';
import UploadLogo from '../bounties/UploadLogo';
import CharacterLimit from '../../ui/CharacterLimit';
import { toast } from 'sonner';
import WalletConnectRedirect from '../walletconnectUI/ConnectWalletRedirect';

function ProfileInput() {
    const [profileCreated, setProfileCreated] = useState(false);
    const {
        register,
        handleSubmit,
        getValues,
        control,
        formState: { errors },
    } = useForm();
    const { field: bioField } = useController({
        name: 'bio',
        control,
        rules: {
            required: 'Please enter your bio',
            maxLength: {
                value: 130,
                message: 'The maximum length is 130 characters',
            },
        },
    });
    const { field: profileImageField } = useController({
        name: `profileImage`,
        control,
        rules: {
            required: 'Please select your profile picture',
        },
    });

    const { state, dispatch } = useContext(AuthContext);
    const { loading, startLoading, stopLoading } = useLoadingState();
    const { publicKey, disconnect } = useWallet();
    const navigate = useNavigate();
    const [walletError, setWalletError] = useState('');
    const profileCreatedRef = useRef(false); // Tracks profile creation state across renders
    const [walletInstalled, setWalletInstalled] = useState(false);
    // const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     // Check if Phantom Wallet extension is installed
    //     if (window?.solana?.isPhantom) {
    //         setWalletInstalled(true); // Phantom Wallet is installed
    //     } else {
    //         setWalletInstalled(false); // Phantom Wallet is not installed
    //     }
    // }, []);
    const checkWalletInstallation = () => {
        if (window?.solana?.isPhantom) {
            setWalletInstalled(true);
        } else {
            setWalletInstalled(false);
        }
    };
    useEffect(() => {
        // Check wallet installation on initial load
        checkWalletInstallation();

        // Add visibilitychange event listener
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                checkWalletInstallation(); // Re-check wallet installation when tab becomes visible
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        // Update the ref whenever `profileCreated` state changes
        profileCreatedRef.current = profileCreated;
    }, [profileCreated]);

    useEffect(() => {
        return () => {
            // If profile has not been created, log out user on component unmount
            if (!profileCreatedRef.current) {
                const handleLogout = async () => {
                    try {
                        await auth.signOut();
                        await disconnect();

                        dispatch({ type: 'logout' });
                    } catch (error) {
                        console.error('Error signing out:', error);
                    }
                };
                handleLogout();
            }
        };
    }, []);

    const onSubmit = async (data) => {
        // e.preventDefault();

        if (!publicKey) {
            setWalletError('Please connect your wallet');
            return; // Prevent form submission if wallet is not connected
        } else {
            setWalletError(''); // Clear wallet error if connected
        }

        const profileToSend = new FormData();
        profileToSend.append('userName', data.username);
        profileToSend.append('email', auth.currentUser.email);
        profileToSend.append('bio', data.bio);
        profileToSend.append('profileImage', data.profileImage);
        profileToSend.append('walletAddress', publicKey?.toBase58());

        startLoading();
        const response = await createProfileAtDb(profileToSend);

        if (!response) {
            toast.error(
                'Unable to create profile. Please try after sometime. If the issue still persists, reach out to Atlantis Customer Support. '
            );
            stopLoading();
            try {
                await auth.signOut();
                await disconnect();

                dispatch({ type: 'logout' });
            } catch (error) {
                console.error('Error signing out:', error);
            }

            return;
        }
        dispatch({ type: 'updateAfterProfileCreation', payload: response });
        stopLoading();
        setProfileCreated(true);
        if (Object.keys(response).length) navigate('/', { replace: true });
    };
    const handleWalletInstall = () => {
        // Redirect to Phantom Wallet installation page
        window.open('https://phantom.app/download', '_blank');
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className=" px-[198px] pb-[42px] shadow-md ">
            <div className="mb-7">
                <Input
                    {...register('username', {
                        required: 'Please enter a username',
                    })}
                    type="transparent"
                    error={errors?.username?.message}
                    placeholder={'Create Username *'}
                    className={'w-[100%]'}
                />
            </div>
            <div className="mb-6">
                <Heading type="medium" className="capitalize text-lightBlack mb-2">
                    Bio <sup className="text-lg relative top-[1px]">*</sup>
                </Heading>

                <Input
                    // value={profile.bio}
                    // onChange={(e) => setProfile({ ...profile, bio: e.target.value })}
                    {...bioField}
                    error={errors?.bio?.message}
                    placeholder={'Tell us something about yourself'}
                    className={'w-[100%]'}
                />
                <CharacterLimit min={bioField.value?.length} max={130} />
            </div>
            <div className="mb-6">
                <Heading type="medium" className="capitalize text-lightBlack mb-2">
                    Upload Profile Picture <sup className="text-lg relative top-[1px]">*</sup>
                </Heading>
                {profileImageField.value ? (
                    <div className="p-6 bg-white flex flex-col text-uploadText items-center shadow-sm rounded-md">
                        <div className="border relative flex flex-col gap-2 border-borderColor border-dashed w-full p-6 rounded-md">
                            <img
                                className="w-20 h-20 rounded-md object-center object-cover "
                                src={URL.createObjectURL(profileImageField.value)}
                                alt="profile"
                            />
                        </div>
                    </div>
                ) : (
                    <UploadLogo
                        isUploadEnable={false}
                        fieldName={profileImageField}
                        errors={errors}
                        errorField={'profileImage'}
                    />
                )}
            </div>

            <div className="mb-6">
                {/* {walletInstalled ? ( */}
                {/* <WalletConnection /> */}
                <Heading type="medium" className="capitalize mb-1 text-lightBlack">
                    Connect Your Wallet <sup className="text-lg relative top-[1px]">*</sup>
                </Heading>
                <p className="mb-2">To sign and authorize organizational transactions, vote on proposals etc. </p>
                <WalletConnectRedirect widthPer={'267px'} />

                {walletError && <p className="text-foundryRed">{walletError}</p>}
            </div>
            <div>
                <Button onClick={handleSubmit} variant="secondary" size="large" width="w-[267px]">
                    {loading ? <Loader /> : 'Create Profile'}
                </Button>
            </div>
        </form>
    );
}

export default ProfileInput;
