import { useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

function ProtectedRoute({ children }) {
    const navigate = useNavigate();

    //   loading user

    const { state } = useContext(AuthContext);
    const { isAuthenticated } = state;

    const { user } = state;
    const userProfile = user[0];

    //   if not authenticated redirect to login page
    useEffect(
        function () {
            if (!isAuthenticated) navigate('/login');
        },
        [isAuthenticated, navigate]
    );

    if (isAuthenticated) return children;
}

export default ProtectedRoute;
