import ReactMapboxGl from 'react-mapbox-gl';
import trophy from '../../assets/trophy.png';
import Button from '../../ui/Button';
import Heading from '../../ui/Heading';
import ImpactFields from '../../ui/ImpactFields';

import ExploreImpactOrganizations from './ExploreImpactOrganizations';
import { useContext, useEffect, useState } from 'react';
import { getCoreWiseDistribution, getYourImpacts } from '../../apis/user/user';
import { useLoadingState } from '../../hooks/useLoader';
import { AuthContext } from '../../contexts/AuthContext';

import WhiteCard from '../../ui/WhiteCard';
import NewDropIcon from '../../assets/icons/NewDropIcon';
import TrophyIcon from '../../assets/icons/TrophyIcon';
import { toNormalRepresentation } from '../../helpers/convertIntoDecimals';
function DisplayMap() {
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [yourImpacts, setYourImpacts] = useState([]);

    const [coreWiseDistribution, setCoreWiseDistribution] = useState([]);
    const { state } = useContext(AuthContext);
    const { user } = state;
    const userProfile = user[0];

    useEffect(function () {
        const fetchData = async () => {
            try {
                startLoading();
                const [response1, response2] = await Promise.all([
                    getYourImpacts(userProfile?.uid),
                    getCoreWiseDistribution(),
                ]);
                setYourImpacts(response1);
                setCoreWiseDistribution(response2);
                stopLoading();
            } catch (error) {
                console.error(error, 'erroe');
            }
        };
        fetchData();
    }, []);

    return (
        <div className=" ">
            <div className="mb-6">
                <div>
                    <WhiteCard verticalMargin="" className="py-3  px-6">
                        <>
                            <div className="flex items-center border-b  border-dashed border-borderColor pb-2 justify-between">
                                <Heading className={'text-lightBlack leading-[58px]  '} type="pageHeading">
                                    Your Impact
                                </Heading>
                                {/* <Button
                                    // onClick={() => navigate('public-view')}
                                    className=" w-44 text-end leading-[58px"
                                    size="none"
                                    variant="transparent"
                                >
                                    Impact Profile {'->'}
                                </Button> */}
                            </div>

                            <div className="flex py-6  border-b border-dashed border-borderColor gap-12">
                                <div className="flex flex-col gap-1 ">
                                    <p className={`text-sm text-secondaryText`}>Impact Points</p>
                                    <div className="flex  gap-1 items-center  ">
                                        <TrophyIcon />
                                        <p className={`text-2xl text-lightBlack font-medium`}>
                                            {yourImpacts[0]?.impactPoints?.toFixed(2)}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col  gap-1 ">
                                    <p className={`text-sm text-secondaryText`}>Certificates</p>
                                    <div className="flex  gap-1 items-center  ">
                                        <NewDropIcon />
                                        <p className={`text-2xl text-lightBlack font-medium`}>
                                            {yourImpacts[0]?.impactCertificatesIssued}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-1 ">
                                    <p className={`text-sm text-secondaryText`}>Rank</p>
                                    <div className="flex  gap-1 items-center  ">
                                        <p className="text-2xl text-lightBlack font-medium">#</p>
                                        <p className={`text-2xl text-lightBlack font-medium`}>
                                            {yourImpacts[0]?.impactRank}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <ImpactFields className="pt-6" coreWiseDistribution={coreWiseDistribution} />
                        </>
                    </WhiteCard>
                </div>
            </div>
            <ExploreImpactOrganizations />
        </div>
    );
}

export default DisplayMap;
