import { useContext } from 'react';
import Button from '../../ui/Button';
import Heading from '../../ui/Heading';
import ExploreImpactOrganizations from './ExploreImpactOrganizations';
import MyOrganizations from './MyOrganizations';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import Notifications from './Notifications';
import MyApplications from './MyApplictions';

function InfoForUser() {
    const navigate = useNavigate();
    return (
        <div>
            <MyOrganizations />
            <Button onClick={() => navigate('/createorganization')} variant="primary" className={'mt-3 mb-6'}>
                + Create a new organisation
            </Button>
            <MyApplications />
        </div>
    );
}

export default InfoForUser;
