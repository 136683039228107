import { useActive } from '../../../hooks/useActive';
import TabSwitching from '../../../ui/TabSwitching';
import Table from '../../../ui/Table';
import BountyCreationRequests from './BountyCreationRequests';
import BountyValidationRequests from './BountyValidationRequests';

function RequestSentForTreasurerApproval({ screen, organisation }) {
    const [activeTab, handleActiveTab] = useActive(screen);

    return (
        <div>
            <Table className=" items-center gap-4 " cols="grid-cols-treasurerTable">
                <div className="flex mb-3   ">
                    <TabSwitching
                        activeTab={activeTab}
                        onSelectingActive={handleActiveTab}
                        firstTab={'Bounty Creation'}
                        secondTab={'Bounty Validation'}
                        activeStyling={'text-lightBlack border-primaryColor  '}
                        firstTabStyle={'rounded-e-none  '}
                        secondTabStyle={'rounded-s-none '}
                        className={'text-sm   font-medium border-2 border-borderColor p-1 leading-6  px-4 rounded-md '}
                    />
                </div>
                {activeTab.toLowerCase() === 'bounty creation' && (
                    <BountyCreationRequests multiSig={organisation?.treasurerMultisig} />
                )}
                {activeTab.toLowerCase() === 'bounty validation' && (
                    <BountyValidationRequests multiSig={organisation?.treasurerMultisig} />
                )}
            </Table>
        </div>
    );
}

export default RequestSentForTreasurerApproval;
