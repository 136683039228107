import { useNavigate, useOutletContext } from 'react-router-dom';
import Button from '../../../ui/Button';
import Heading from '../../../ui/Heading';
import WhiteCard from '../../../ui/WhiteCard';
import AvatarImage from '../../../ui/AvatarImage';
import CreateProjectModal from '../../projects/CreateProjectModal';
import Modal from '../../../ui/Modal';
import ExploreImpactOrganizations from '../../homepage/ExploreImpactOrganizations';
import LinkIcon from '../../../assets/icons/LinkIcon';

function LeftSectionForExternalUser() {
    const navigate = useNavigate();

    const [particularOrg, orgMembers, roles] = useOutletContext();

    return (
        <div className="pt-6">
            <div className="mb-6">
                <Heading className={'text-black '} type={'medium'}>
                    About
                </Heading>
                <p className="mt-2">{particularOrg?.description}</p>
            </div>
            <div className="mb-6">
                <Heading className={'text-black '} type={'medium'}>
                    Social Links
                </Heading>
                <ul className=" pl-2 text-primaryColor font-medium mt-2 ">
                    {particularOrg?.handles?.map((link) => {
                        const formattedLink =
                            link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
                        return (
                            <li className="flex items-center gap-2" key={link}>
                                <span>
                                    <LinkIcon />
                                </span>{' '}
                                <a href={formattedLink} target="_blank" rel="noopener noreferrer">
                                    {link}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <WhiteCard className="p-6 ">
                <div className="flex justify-between items-center">
                    <Heading className={'text-black font-medium'} type={'large'}>
                        Members
                    </Heading>
                </div>
                <div className="flex justify-between items-end mt-6">
                    <div className="flex  gap-2 flex-col">
                        <p className="text-black text-5xl font-medium ">{orgMembers.length}</p>
                        <p>Total Members</p>
                    </div>
                </div>
            </WhiteCard>
            <ExploreImpactOrganizations heading="Explore more impact organisations" />
        </div>
    );
}

export default LeftSectionForExternalUser;
