import React from 'react';
import { notificationTypeData } from './notificationType';
import { deleteNotification } from '../../apis/notifications/notifications';

// Function to get the component for a notification type
const getComponentForNotification = (type, notificationData, handleDeleteNotification, onToggle) => {
    const notification = notificationTypeData.find((n) => n.type === type);

    return notification ? (
        notification.getComponent(notificationData, handleDeleteNotification, onToggle)
    ) : (
        <div>Unknown Notification Type</div>
    );
};

// NotificationList component that filters notifications by category
const NotificationList = ({ notifications, onDelete, onToggle }) => {
    const filteredNotifications = notifications.filter((notification) => {
        const matchedNotification = notificationTypeData.find((n) => n.type === notification.notificationType);
        return matchedNotification;
    });

    const handleDeleteNotification = async (id) => {
        onDelete(id);
        try {
            const res = await deleteNotification(id);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="bg-white overflow-hidden rounded-lg">
            {filteredNotifications.map((notification, index) => {
                return (
                    <div key={index}>
                        {getComponentForNotification(
                            notification.notificationType,
                            notification,
                            handleDeleteNotification,
                            onToggle
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default NotificationList;
