import { useContext } from 'react';
import { FeedbackIcon, HelpIcon, NotifIcon } from '../../assets/icons/HeaderIcons';
import OpenIcon from '../../assets/icons/OpenIcon';

import { AuthContext } from '../../contexts/AuthContext';
import AvatarImage from '../../ui/AvatarImage';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../ui/Modal';
import FeedbackModal from './FeedbackModal';
import WalletConnectButton from '../../features/walletconnectUI/WalletConnectButton';
import { fontWeight, height, lineHeight } from '@mui/system';
import Notifications from '../../ui/Notifications';
import { useNotifications } from '../../contexts/NotificationsContext';

export default function ToptabBar({ showSidebar, toggle }) {
    const { state } = useContext(AuthContext);
    const { user } = state;
    const userProfile = user[0];
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return (
        <div
            className={`flex  ${
                pathname === '/' ? 'bg-[#F3F3EB]' : 'bg-gray-100'
            }  z-50 p-2 sticky top-0 shadow-sm justify-between lg:justify-end`}
        >
            <button className={`lg:hidden border border-borderColor p-2 rounded-md `} onClick={toggle}>
                {showSidebar ? '' : <OpenIcon />}
            </button>

            <WalletConnectButton
                backgroundColor="#fff"
                widthPer="173px"
                customStyles={{
                    color: '#6B7280',
                    fontWeight: 500,
                    height: '32px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                }}
            />

            <div className="flex items-center gap-7 px-4 py-2">
                <Modal>
                    <Modal.Button className="flex gap-2 cursor-pointer items-center" opens={'feedback'}>
                        <FeedbackIcon />
                    </Modal.Button>
                    <Modal.Window name={'feedback'}>
                        <FeedbackModal />
                    </Modal.Window>
                </Modal>

                <HelpIcon />
                <Notifications />
                <AvatarImage
                    className="cursor-pointer"
                    onClick={() => {
                        navigate('/profile');
                    }}
                    w="w-8"
                    h="h-8"
                    url={userProfile?.profileImage}
                />
            </div>
        </div>
    );
}
