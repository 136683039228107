import { extractVideoIdFromLink } from '../../helpers/extractVideoIdFromLink';
import Table from '../../ui/Table';
import { microtaskTypeData } from '../bounties/microtasktypes/microtaskTypeData';

const WatchYoutubeVideoSD = ({ dataTask }) => {
    const titleObject = dataTask.task.find((obj) => Object.keys(obj).includes('Title'));
    const descriptionObject = dataTask.task.find((obj) => Object.keys(obj).includes('Description'));
    const linkObject = dataTask.task.find((obj) => Object.keys(obj).includes('Link'));

    return (
        <Table.Row>
            <p className=" text-lightBlack flex flex-col   ">
                <span className="font-medium mb-1  "> {titleObject?.Title || '-'} </span>
                <span className="text-xs font-medium mb-1">{descriptionObject?.Description || '-'}</span>
                <div className="w-full  rounded-xl overflow-hidden">
                    <iframe
                        className="w-full h-32"
                        width="480"
                        height="315"
                        src={`https://www.youtube.com/embed/${extractVideoIdFromLink(linkObject?.Link)}`}
                        title="YouTube Video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                {/* {imageObject?.image && <img className="w-[100%] h-32 rounded-md" src={imageObject.image} alt="" />} */}
            </p>
            <p className="text-sm justify-self-center">
                {microtaskTypeData.find((ele) => ele.type === dataTask.actionType).label}
            </p>

            <div>{/* <p className=" text-lightBlack mb-1 ">{dataTask.submission.answer.join(' ')}</p> */}</div>
        </Table.Row>
    );
};

export default WatchYoutubeVideoSD;
