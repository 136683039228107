import React from 'react';

const MultiSelectMCQSubDetails = ({ data }) => {
    const { action } = data;

    const titleObject = action.find((obj) => obj.name === 'title');
    const questionObject = action.find((obj) => obj.name === 'question');
    const answersObject = action.find((obj) => obj.name === 'answers')?.value?.split(',');

    return (
        <>
            <p className="flex gap-1 text-xs text-secondaryInput mb-2  ">
                <span>Response type : </span>{' '}
                <span className="font-bold capitalize "> {data?.label?.split('_')?.join(' ')?.toLowerCase()}</span>
            </p>
            <p className="font-semibold capitalize text-primaryInput mb-2">
                <span>{titleObject?.value}</span>
            </p>
            <p className="text-sm capitalize text-primaryInput mb-2">
                <span>{questionObject?.value}</span>
            </p>
            <ol>
                {answersObject.map((answer, index) => {
                    return (
                        <p key={answer} className="text-sm flex items-center gap-1 capitalize text-primaryInput mb-2">
                            <span>{index + 1}.</span> <span>{answer}</span>
                        </p>
                    );
                })}
            </ol>
        </>
    );
};

export default MultiSelectMCQSubDetails;
