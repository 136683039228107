export const sanitizeBountyData = (bounty) => {
    // Remove top-level fields if they exist
    const fieldsToRemove = [
        'status',
        'publish',
        'skills',
        'accountAddress',
        'rewardTokenPriceUSD',
        'impactPoints',
        'isTest',
        'publishedAt',
        'project',
        'projectTitle',
        'totalRewardedAmount',
    ];

    fieldsToRemove.forEach((field) => {
        if (bounty.hasOwnProperty(field)) {
            delete bounty[field];
        }
    });

    // Remove _id from descriptionHyperlinks array
    if (Array.isArray(bounty.descriptionHyperlinks)) {
        bounty.descriptionHyperlinks = bounty.descriptionHyperlinks.map(({ _id, ...rest }) => rest);
    }

    // Remove _id from criteriaHyperlinks array
    if (Array.isArray(bounty.criteriaHyperlinks)) {
        bounty.criteriaHyperlinks = bounty.criteriaHyperlinks.map(({ _id, ...rest }) => rest);
    }

    // Process microtasks
    if (Array.isArray(bounty.microtasks)) {
        bounty.microtasks = bounty.microtasks.map((microtask) => {
            // Remove specified fields from each microtask
            const { _id, bountyId, createdAt, updatedAt, __v, actions, ...microtaskRest } = microtask;

            // Process actions in each microtask
            const sanitizedActions = actions.map(({ _id, action, ...actionRest }) => ({
                ...actionRest,
                action: action.map(({ _id, ...actionDetailRest }) => actionDetailRest),
            }));

            return { ...microtaskRest, actions: sanitizedActions };
        });
    }

    return bounty;
};
