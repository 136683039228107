import { useEffect, useRef } from 'react';
import Overlay from '../../ui/Overlay';
import Button from '../../ui/Button';
import { deleteProject } from '../../apis/projects/projects';
import { toast } from 'sonner';
import Feedbackclose from '../../assets/icons/Feedbackclose';
import { deleteBounty } from '../../apis/bounties/bounties';
import ModalBox from '../../ui/ModalBox';
import { useLoadingState } from '../../hooks/useLoader';

function DeleteBountyModal({ close, bountyName, bountyId, onDelete }) {
    const myRef = useRef({ close });
    const { loading, startLoading, stopLoading } = useLoadingState();
    useEffect(
        function () {
            function handleClick(e) {
                if (myRef.current && !myRef.current.contains(e.target)) {
                    close();
                }
            }

            document.addEventListener('click', handleClick, true);

            return () => document.removeEventListener('click', handleClick, true);
        },
        [close]
    );
    return (
        <Overlay>
            <div
                ref={myRef}
                className="absolute rounded-lg left-[50%]     bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
            >
                <ModalBox
                    close={close}
                    onSubmit={async () => {
                        try {
                            startLoading();
                            const { success } = await deleteBounty(bountyId);
                            if (success) {
                                onDelete('delete', bountyId);
                                toast.success('Bounty successfully deleted. ');
                            }
                        } catch (error) {
                            console.log(error);
                        } finally {
                            close(); // Close modal after duplication
                            stopLoading();
                        }
                    }}
                    primaryBtnText={'Submit'}
                    loading={loading}
                    secondaryBtnText={'Cancel'}
                >
                    <>
                        Are you sure you want to <span className="font-bold">delete the bounty {bountyName} </span>?
                    </>
                </ModalBox>
            </div>
        </Overlay>
    );
}

export default DeleteBountyModal;
