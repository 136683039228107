import Button from '../../ui/Button';
import WhiteCard from '../../ui/WhiteCard';
import jumpingBoy from '../../assets/jumpingboy.png';
import { useNavigate, useOutletContext } from 'react-router-dom';
function AttesterProfileCreation() {
    const navigate = useNavigate();
    const [attester] = useOutletContext();

    return (
        <div>
            {attester.status === 'APPROVAL_PENDING' ? (
                <Button variant="primary" size="xsmall">
                    Registration under Review
                </Button>
            ) : (
                <Button onClick={() => navigate('details')} variant="primary" size="xsmall">
                    Create Attester Profile
                </Button>
            )}
            <WhiteCard className={'flex pt-24 pb-24 pl-64 pr-64 items-center  justify-center'}>
                <div>
                    <img src={jumpingBoy} alt="jumping boy" />
                    <p className="text-lightBlack font-medium mt-3">Do you have what it takes to be an attester ?</p>
                </div>
            </WhiteCard>
        </div>
    );
}

export default AttesterProfileCreation;
