import { useContext } from 'react';
import { BountiesContext } from '../../../contexts/BountiesContext';
import { useBountyFields } from '../../../hooks/useBountyFields';
import mobileFrame from '../../../assets/mobileFrame.png';
import Heading from '../../../ui/Heading';
import SubHeading from '../../../ui/SubHeading';
import { CalendarIcon } from '../../../assets/icons/CalendarIcon';
import { ClockIcon } from '../../../assets/icons/ClockIcon';
import Button from '../../../ui/Button';
import UploadIcon from '../../../assets/icons/UploadIcon';
import LeftPreviewArrow from '../../../assets/icons/LeftPreviewArrow';
function PreviewUploadFile({ data }) {
    const { state } = useContext(BountiesContext);
    const { titleField } = useBountyFields();

    return (
        <div
            className="relative w-[300px] h-[610px]     "
            style={{
                backgroundImage: `url(${mobileFrame})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <main className="absolute  top-28 left-6 right-6 ">
                <div className="my-4">
                    <Heading className={'break-words text-minerTitle'} type={'xsmall'}>
                        {data.uploadFile_Title || 'Title'}
                    </Heading>
                    <SubHeading className={'!text-xs text-minerDescription font-semibold mt-2'} type="xsmall">
                        {data.uploadFile_Description || 'Task Description'}
                    </SubHeading>
                </div>
                <div className="bg-foundryBlueL h-48 rounded-xl  p-4 flex items-center justify-center">
                    <div>
                        <p className="mb-1 text-xs text-minerDescription">Click here to upload files</p>
                        <Button variant="miner" className="text-xs px-9 py-2 " radius="rounded-full" size="largeMiner">
                            SELECT FILES
                        </Button>
                    </div>
                </div>
                <p className="font-medium text-xs text-minerSecondary mt-1 leading-5">Max Upload size : 8MB</p>
            </main>

            <footer className="absolute bottom-6 left-6 right-6">
                <div className="flex mb-2 items-center justify-between">
                    <Button
                        className=" flex text-[#1A56DB] !text-xs items-center justify-center gap-1 text-end"
                        size="none"
                        variant="transparent"
                    >
                        <span>{'<-'}</span> <span> Back</span>
                    </Button>
                    {data.skippable && (
                        <Button className=" text-[#1A56DB] !text-xs  text-end" size="none" variant="transparent">
                            Skip {'->'}
                        </Button>
                    )}
                </div>
                <Button variant="miner" className="text-xs py-2 " size="largeMiner" radius="rounded-full">
                    Next
                </Button>
            </footer>
        </div>
    );
}

export default PreviewUploadFile;
