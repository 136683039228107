export type BountyManager = {
    version: '0.1.0';
    name: 'bounty_manager';
    instructions: [
        {
            name: 'createBounty';
            accounts: [
                {
                    name: 'createKey';
                    isMut: false;
                    isSigner: true;
                },
                {
                    name: 'creator';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'bounty';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'rewardMint';
                    isMut: false;
                    isSigner: false;
                    isOptional: true;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                }
            ];
            args: [
                {
                    name: 'title';
                    type: 'string';
                },
                {
                    name: 'description';
                    type: 'string';
                },
                {
                    name: 'rewardAmount';
                    type: {
                        option: 'u64';
                    };
                }
            ];
        },
        {
            name: 'fundBounty';
            accounts: [
                {
                    name: 'bounty';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'funder';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'rewardMint';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'sendTokenAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'receiveTokenAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'tokenProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'associatedTokenProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                }
            ];
            args: [
                {
                    name: 'amount';
                    type: 'u64';
                }
            ];
        },
        {
            name: 'startBounty';
            accounts: [
                {
                    name: 'bounty';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'creator';
                    isMut: false;
                    isSigner: true;
                },
                {
                    name: 'rewardMint';
                    isMut: false;
                    isSigner: false;
                    isOptional: true;
                },
                {
                    name: 'tokenAccount';
                    isMut: false;
                    isSigner: false;
                    isOptional: true;
                }
            ];
            args: [
                {
                    name: 'unlockTime';
                    type: 'i64';
                }
            ];
        },
        {
            name: 'transferRewards';
            accounts: [
                {
                    name: 'bounty';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'creator';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'rewardMint';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'tokenAccount';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'tokenProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'associatedTokenProgram';
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                }
            ];
            args: [
                {
                    name: 'amounts';
                    type: {
                        vec: 'u64';
                    };
                }
            ];
        },
        {
            name: 'closeBounty';
            accounts: [
                {
                    name: 'bounty';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'creator';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'rewardMint';
                    isMut: false;
                    isSigner: false;
                    isOptional: true;
                },
                {
                    name: 'tokenAccount';
                    isMut: true;
                    isSigner: false;
                    isOptional: true;
                },
                {
                    name: 'tokenProgram';
                    isMut: false;
                    isSigner: false;
                    isOptional: true;
                }
            ];
            args: [];
        },
        {
            name: 'updateBounty';
            accounts: [
                {
                    name: 'bounty';
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: 'creator';
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: 'systemProgram';
                    isMut: false;
                    isSigner: false;
                }
            ];
            args: [
                {
                    name: 'title';
                    type: 'string';
                },
                {
                    name: 'description';
                    type: 'string';
                }
            ];
        }
    ];
    accounts: [
        {
            name: 'bounty';
            type: {
                kind: 'struct';
                fields: [
                    {
                        name: 'createKey';
                        type: 'publicKey';
                    },
                    {
                        name: 'creator';
                        type: 'publicKey';
                    },
                    {
                        name: 'title';
                        type: 'string';
                    },
                    {
                        name: 'description';
                        type: 'string';
                    },
                    {
                        name: 'addedTime';
                        type: 'i64';
                    },
                    {
                        name: 'startTime';
                        type: 'i64';
                    },
                    {
                        name: 'isStarted';
                        type: 'bool';
                    },
                    {
                        name: 'rewardTokenMint';
                        type: {
                            option: 'publicKey';
                        };
                    },
                    {
                        name: 'rewardTokenAmount';
                        type: {
                            option: 'u64';
                        };
                    },
                    {
                        name: 'unlockTime';
                        type: 'i64';
                    },
                    {
                        name: 'bump';
                        type: 'u8';
                    }
                ];
            };
        }
    ];
    errors: [
        {
            code: 6000;
            name: 'EmptyDetails';
            msg: 'Bounty title and description must be specified';
        },
        {
            code: 6001;
            name: 'ZeroRewardAmount';
            msg: 'Reward amount must be non-zero';
        },
        {
            code: 6002;
            name: 'InsufficientBalance';
            msg: 'Amount specified more than balance';
        },
        {
            code: 6003;
            name: 'ZeroTransferAmount';
            msg: 'Transfer amount must be non-zero';
        },
        {
            code: 6004;
            name: 'InsufficientFundsToStart';
            msg: 'Insufficient funds to start the bounty';
        },
        {
            code: 6005;
            name: 'AlreadyStarted';
            msg: 'Bounty has already been started';
        },
        {
            code: 6006;
            name: 'InvalidUnlockTime';
            msg: 'Unlock time must be in future';
        },
        {
            code: 6007;
            name: 'EmptyReceiverList';
            msg: 'Receiver list specified is empty';
        },
        {
            code: 6008;
            name: 'BountyOngoing';
            msg: "Bounty's unlock time has not been reached";
        },
        {
            code: 6009;
            name: 'IncorrectDestinationTokenAccount';
            msg: 'Destination token account is not valid ATA of reciever account passed';
        }
    ];
};

export const IDL: BountyManager = {
    version: '0.1.0',
    name: 'bounty_manager',
    instructions: [
        {
            name: 'createBounty',
            accounts: [
                {
                    name: 'createKey',
                    isMut: false,
                    isSigner: true,
                },
                {
                    name: 'creator',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'bounty',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'rewardMint',
                    isMut: false,
                    isSigner: false,
                    isOptional: true,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: 'title',
                    type: 'string',
                },
                {
                    name: 'description',
                    type: 'string',
                },
                {
                    name: 'rewardAmount',
                    type: {
                        option: 'u64',
                    },
                },
            ],
        },
        {
            name: 'fundBounty',
            accounts: [
                {
                    name: 'bounty',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'funder',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'rewardMint',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'sendTokenAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'receiveTokenAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'associatedTokenProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: 'amount',
                    type: 'u64',
                },
            ],
        },
        {
            name: 'startBounty',
            accounts: [
                {
                    name: 'bounty',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'creator',
                    isMut: false,
                    isSigner: true,
                },
                {
                    name: 'rewardMint',
                    isMut: false,
                    isSigner: false,
                    isOptional: true,
                },
                {
                    name: 'tokenAccount',
                    isMut: false,
                    isSigner: false,
                    isOptional: true,
                },
            ],
            args: [
                {
                    name: 'unlockTime',
                    type: 'i64',
                },
            ],
        },
        {
            name: 'transferRewards',
            accounts: [
                {
                    name: 'bounty',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'creator',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'rewardMint',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'tokenAccount',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'associatedTokenProgram',
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: 'amounts',
                    type: {
                        vec: 'u64',
                    },
                },
            ],
        },
        {
            name: 'closeBounty',
            accounts: [
                {
                    name: 'bounty',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'creator',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'rewardMint',
                    isMut: false,
                    isSigner: false,
                    isOptional: true,
                },
                {
                    name: 'tokenAccount',
                    isMut: true,
                    isSigner: false,
                    isOptional: true,
                },
                {
                    name: 'tokenProgram',
                    isMut: false,
                    isSigner: false,
                    isOptional: true,
                },
            ],
            args: [],
        },
        {
            name: 'updateBounty',
            accounts: [
                {
                    name: 'bounty',
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: 'creator',
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: 'systemProgram',
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: 'title',
                    type: 'string',
                },
                {
                    name: 'description',
                    type: 'string',
                },
            ],
        },
    ],
    accounts: [
        {
            name: 'bounty',
            type: {
                kind: 'struct',
                fields: [
                    {
                        name: 'createKey',
                        type: 'publicKey',
                    },
                    {
                        name: 'creator',
                        type: 'publicKey',
                    },
                    {
                        name: 'title',
                        type: 'string',
                    },
                    {
                        name: 'description',
                        type: 'string',
                    },
                    {
                        name: 'addedTime',
                        type: 'i64',
                    },
                    {
                        name: 'startTime',
                        type: 'i64',
                    },
                    {
                        name: 'isStarted',
                        type: 'bool',
                    },
                    {
                        name: 'rewardTokenMint',
                        type: {
                            option: 'publicKey',
                        },
                    },
                    {
                        name: 'rewardTokenAmount',
                        type: {
                            option: 'u64',
                        },
                    },
                    {
                        name: 'unlockTime',
                        type: 'i64',
                    },
                    {
                        name: 'bump',
                        type: 'u8',
                    },
                ],
            },
        },
    ],
    errors: [
        {
            code: 6000,
            name: 'EmptyDetails',
            msg: 'Bounty title and description must be specified',
        },
        {
            code: 6001,
            name: 'ZeroRewardAmount',
            msg: 'Reward amount must be non-zero',
        },
        {
            code: 6002,
            name: 'InsufficientBalance',
            msg: 'Amount specified more than balance',
        },
        {
            code: 6003,
            name: 'ZeroTransferAmount',
            msg: 'Transfer amount must be non-zero',
        },
        {
            code: 6004,
            name: 'InsufficientFundsToStart',
            msg: 'Insufficient funds to start the bounty',
        },
        {
            code: 6005,
            name: 'AlreadyStarted',
            msg: 'Bounty has already been started',
        },
        {
            code: 6006,
            name: 'InvalidUnlockTime',
            msg: 'Unlock time must be in future',
        },
        {
            code: 6007,
            name: 'EmptyReceiverList',
            msg: 'Receiver list specified is empty',
        },
        {
            code: 6008,
            name: 'BountyOngoing',
            msg: "Bounty's unlock time has not been reached",
        },
        {
            code: 6009,
            name: 'IncorrectDestinationTokenAccount',
            msg: 'Destination token account is not valid ATA of reciever account passed',
        },
    ],
};
