import OrganizationInput from '../features/Organization/OrganizationInput';
import LayoutWithoutSidebar from '../ui/LayoutWithoutSidebar';

function CreateOrganization() {
    return (
        <LayoutWithoutSidebar marginV="my-8" className="grid  grid-cols-1 ">
            <OrganizationInput />
        </LayoutWithoutSidebar>
    );
}

export default CreateOrganization;
