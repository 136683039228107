// Extract the day, month, and year

// Format the date as MM/DD/YYYY
export const dmyFormat = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() returns 0-based month
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
};
