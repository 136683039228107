function ProposalsIcon({ fillColor }) {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.53251 0.820312H1.70681C0.768063 0.820312 0 1.58838 0 2.52712V14.4748C0 15.4135 0.768063 16.1816 1.70681 16.1816H13.6545C14.5932 16.1816 15.3613 15.4135 15.3613 14.4748V6.64906C15.3613 6.19675 15.182 5.76152 14.8577 5.44576L10.7358 1.32382C10.4201 0.999527 9.98482 0.820312 9.53251 0.820312ZM4.26702 11.0612H11.0942C11.5636 11.0612 11.9476 11.4452 11.9476 11.9146C11.9476 12.3839 11.5636 12.768 11.0942 12.768H4.26702C3.79764 12.768 3.41361 12.3839 3.41361 11.9146C3.41361 11.4452 3.79764 11.0612 4.26702 11.0612ZM4.26702 7.64754H11.0942C11.5636 7.64754 11.9476 8.03157 11.9476 8.50094C11.9476 8.97031 11.5636 9.35434 11.0942 9.35434H4.26702C3.79764 9.35434 3.41361 8.97031 3.41361 8.50094C3.41361 8.03157 3.79764 7.64754 4.26702 7.64754ZM4.26702 4.23393H8.53403C9.0034 4.23393 9.38743 4.61796 9.38743 5.08733C9.38743 5.5567 9.0034 5.94073 8.53403 5.94073H4.26702C3.79764 5.94073 3.41361 5.5567 3.41361 5.08733C3.41361 4.61796 3.79764 4.23393 4.26702 4.23393Z"
                fill={fillColor}
            />
        </svg>
    );
}

export default ProposalsIcon;
