export const BTCode = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 3.5V6.33L5.33 9L8 11.67V14.5L2.5 9M10 3.43L15.57 9L10 14.57V11.74L12.74 9L10 6.26M2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0H2Z"
                fill="#1A56DB"
            />
        </svg>
    );
};

export const BTHarvest = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.1 0.809998L10.27 3.64C9.50002 4.42 9.50002 5.69 10.27 6.47L11.68 7.88L7.13001 12.43L4.31001 9.6L2.89001 11C-2.05999 16 1.50001 19.5 1.50001 19.5C1.50001 19.5 5.00001 23 10 18.09L11.41 16.68L8.61001 13.88L13.15 9.34L14.54 10.73C15.32 11.5 16.59 11.5 17.37 10.73L20.2 7.9L13.1 0.809998ZM15.93 9.28L14.55 7.9L13.11 6.46L11.71 5.06L13.12 3.65L17.35 7.88L15.93 9.28Z"
                fill="#1A56DB"
            />
        </svg>
    );
};

export const BTScouting = () => {
    return (
        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.5 10C16 10 18 12 18 14.5C18 15.38 17.75 16.2 17.31 16.9L20.39 20L19 21.39L15.88 18.32C15.19 18.75 14.37 19 13.5 19C11 19 9 17 9 14.5C9 12 11 10 13.5 10ZM13.5 12C12.837 12 12.2011 12.2634 11.7322 12.7322C11.2634 13.2011 11 13.837 11 14.5C11 15.163 11.2634 15.7989 11.7322 16.2678C12.2011 16.7366 12.837 17 13.5 17C14.163 17 14.7989 16.7366 15.2678 16.2678C15.7366 15.7989 16 15.163 16 14.5C16 13.837 15.7366 13.2011 15.2678 12.7322C14.7989 12.2634 14.163 12 13.5 12ZM17.5 0C17.6326 0 17.7598 0.0526785 17.8536 0.146447C17.9473 0.240215 18 0.367392 18 0.5V9.81C17.42 9.26 16.75 8.81 16 8.5V2.7L13 3.86V8C12.3 8.07 11.62 8.24 11 8.5V3.87L7 2.47V14.5C7 15.14 7.09 15.76 7.26 16.34L6 15.9L0.66 17.97L0.5 18C0.367392 18 0.240215 17.9473 0.146447 17.8536C0.0526785 17.7598 0 17.6326 0 17.5V2.38C0 2.15 0.15 1.97 0.36 1.9L6 0L12 2.1L17.34 0.03L17.5 0ZM2 3.46V15.31L5 14.15V2.45L2 3.46Z"
                fill="#1A56DB"
            />
        </svg>
    );
};
export const BTSurvey = () => {
    return (
        <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 3C9.06087 3 10.0783 3.42143 10.8284 4.17157C11.5786 4.92172 12 5.93913 12 7C12 8.06087 11.5786 9.07828 10.8284 9.82843C10.0783 10.5786 9.06087 11 8 11C6.93913 11 5.92172 10.5786 5.17157 9.82843C4.42143 9.07828 4 8.06087 4 7C4 5.93913 4.42143 4.92172 5.17157 4.17157C5.92172 3.42143 6.93913 3 8 3ZM8 13C10.67 13 16 14.34 16 17V19H0V17C0 14.34 5.33 13 8 13ZM15.76 3.36C17.78 5.56 17.78 8.61 15.76 10.63L14.08 8.94C14.92 7.76 14.92 6.23 14.08 5.05L15.76 3.36ZM19.07 0C23 4.05 22.97 10.11 19.07 14L17.44 12.37C20.21 9.19 20.21 4.65 17.44 1.63L19.07 0Z"
                fill="#1A56DB"
            />
        </svg>
    );
};

export const BTValidate = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.5 15L14.5 20L11 16.5L12.5 15L14.5 17L18 13.5L19.5 15ZM2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V18C0 19.11 0.89 20 2 20H9.81C9.45 19.38 9.2 18.7 9.08 18H2V2H9V7H14V11.08C14.33 11.03 14.67 11 15 11C15.34 11 15.67 11.03 16 11.08V6L10 0M4 10V12H12V10M4 14V16H9V14H4Z"
                fill="#1A56DB"
            />
        </svg>
    );
};

export const BTCommunity = () => {
    return (
        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 0C11.0717 0 10.1815 0.368749 9.52513 1.02513C8.86875 1.6815 8.5 2.57174 8.5 3.5C8.5 4.42826 8.86875 5.3185 9.52513 5.97487C10.1815 6.63125 11.0717 7 12 7C12.9283 7 13.8185 6.63125 14.4749 5.97487C15.1313 5.3185 15.5 4.42826 15.5 3.5C15.5 2.57174 15.1313 1.6815 14.4749 1.02513C13.8185 0.368749 12.9283 0 12 0ZM12 2C12.3978 2 12.7794 2.15804 13.0607 2.43934C13.342 2.72064 13.5 3.10218 13.5 3.5C13.5 3.89782 13.342 4.27936 13.0607 4.56066C12.7794 4.84196 12.3978 5 12 5C11.6022 5 11.2206 4.84196 10.9393 4.56066C10.658 4.27936 10.5 3.89782 10.5 3.5C10.5 3.10218 10.658 2.72064 10.9393 2.43934C11.2206 2.15804 11.6022 2 12 2ZM5.5 3C4.83696 3 4.20107 3.26339 3.73223 3.73223C3.26339 4.20107 3 4.83696 3 5.5C3 6.44 3.53 7.25 4.29 7.68C4.65 7.88 5.06 8 5.5 8C5.94 8 6.35 7.88 6.71 7.68C7.08 7.47 7.39 7.17 7.62 6.81C6.89148 5.86057 6.49767 4.69672 6.5 3.5V3.22C6.2 3.08 5.86 3 5.5 3ZM18.5 3C18.14 3 17.8 3.08 17.5 3.22V3.5C17.5 4.7 17.11 5.86 16.38 6.81C16.5 7 16.63 7.15 16.78 7.3C17.241 7.74728 17.8576 7.99822 18.5 8C18.94 8 19.35 7.88 19.71 7.68C20.47 7.25 21 6.44 21 5.5C21 4.83696 20.7366 4.20107 20.2678 3.73223C19.7989 3.26339 19.163 3 18.5 3ZM12 9C9.66 9 5 10.17 5 12.5V14H19V12.5C19 10.17 14.34 9 12 9ZM4.71 9.55C2.78 9.78 0 10.76 0 12.5V14H3V12.07C3 11.06 3.69 10.22 4.71 9.55ZM19.29 9.55C20.31 10.22 21 11.06 21 12.07V14H24V12.5C24 10.76 21.22 9.78 19.29 9.55ZM12 11C13.53 11 15.24 11.5 16.23 12H7.77C8.76 11.5 10.47 11 12 11Z"
                fill="#1A56DB"
            />
        </svg>
    );
};

export const BTContent = () => {
    return (
        <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 0H14C14.5304 0 15.0391 0.210714 15.4142 0.585786C15.7893 0.960859 16 1.46957 16 2V22C16 22.5304 15.7893 23.0391 15.4142 23.4142C15.0391 23.7893 14.5304 24 14 24H2C1.46957 24 0.960859 23.7893 0.585786 23.4142C0.210714 23.0391 0 22.5304 0 22V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0ZM8 6C8.79565 6 9.55871 6.31607 10.1213 6.87868C10.6839 7.44129 11 8.20435 11 9C11 9.79565 10.6839 10.5587 10.1213 11.1213C9.55871 11.6839 8.79565 12 8 12C7.20435 12 6.44129 11.6839 5.87868 11.1213C5.31607 10.5587 5 9.79565 5 9C5 8.20435 5.31607 7.44129 5.87868 6.87868C6.44129 6.31607 7.20435 6 8 6ZM7 1V3H9V1H7ZM2 4V16.5C2 15.12 4.69 14 8 14C11.31 14 14 15.12 14 16.5V4H2ZM9 18H5V20H9V22L12 19L9 16V18Z"
                fill="#1A56DB"
            />
        </svg>
    );
};

export const BTDataScience = () => {
    return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.68 9.32C14.2622 8.90219 13.7661 8.57095 13.22 8.34526C12.674 8.11957 12.0888 8.00388 11.4979 8.00481C10.9071 8.00574 10.3222 8.12327 9.77689 8.35068C9.23155 8.57808 8.73646 8.91088 8.32 9.33C7.90154 9.74712 7.56952 10.2427 7.34297 10.7884C7.11643 11.3341 6.99981 11.9192 6.99981 12.51C6.99981 13.1008 7.11643 13.6859 7.34297 14.2316C7.56952 14.7773 7.90154 15.2729 8.32 15.69C9.04117 16.408 9.984 16.8611 10.9952 16.9755C12.0065 17.0898 13.0267 16.8588 13.89 16.32L17 19.39L18.39 18L15.3 14.89C16.43 13.12 16.17 10.8 14.68 9.32ZM13.27 14.27C12.29 15.25 10.71 15.24 9.73 14.27C8.76 13.29 8.76 11.71 9.74 10.73C10.71 9.76 12.29 9.76 13.27 10.73C14.24 11.71 14.24 13.29 13.27 14.27ZM6.9 17.1C6.24674 16.4412 5.7421 15.6501 5.42 14.78C2.27 14.25 0 12.76 0 11V14C0 16.21 3.58 18 8 18C7.6 17.74 7.23 17.44 6.9 17.1ZM0 6V9C0 10.68 2.07 12.12 5 12.7V12.5C5 11.57 5.2 10.65 5.58 9.81C2.34 9.3 0 7.79 0 6ZM8 0C3.58 0 0 1.79 0 4C0 6 3 7.68 6.85 8H6.9C8.1 6.74 9.76 6 11.5 6C12.41 6 13.31 6.19 14.14 6.56C14.6442 6.3295 15.0803 5.97273 15.4062 5.52424C15.732 5.07575 15.9366 4.55073 16 4C16 1.79 12.42 0 8 0Z"
                fill="#1A56DB"
            />
        </svg>
    );
};

export const BTDesign = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C15.5 0 20 4 20 9C20 10.5913 19.3679 12.1174 18.2426 13.2426C17.1174 14.3679 15.5913 15 14 15H12.2C11.9 15 11.7 15.2 11.7 15.5C11.7 15.6 11.8 15.7 11.8 15.8C12.2 16.3 12.4 16.9 12.4 17.5C12.5 18.9 11.4 20 10 20ZM10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C10.3 18 10.5 17.8 10.5 17.5C10.5 17.3 10.4 17.2 10.4 17.1C10 16.6 9.8 16.1 9.8 15.5C9.8 14.1 10.9 13 12.3 13H14C15.0609 13 16.0783 12.5786 16.8284 11.8284C17.5786 11.0783 18 10.0609 18 9C18 5.1 14.4 2 10 2ZM4.5 8C5.3 8 6 8.7 6 9.5C6 10.3 5.3 11 4.5 11C3.7 11 3 10.3 3 9.5C3 8.7 3.7 8 4.5 8ZM7.5 4C8.3 4 9 4.7 9 5.5C9 6.3 8.3 7 7.5 7C6.7 7 6 6.3 6 5.5C6 4.7 6.7 4 7.5 4ZM12.5 4C13.3 4 14 4.7 14 5.5C14 6.3 13.3 7 12.5 7C11.7 7 11 6.3 11 5.5C11 4.7 11.7 4 12.5 4ZM15.5 8C16.3 8 17 8.7 17 9.5C17 10.3 16.3 11 15.5 11C14.7 11 14 10.3 14 9.5C14 8.7 14.7 8 15.5 8Z"
                fill="#1A56DB"
            />
        </svg>
    );
};

export const BTFunding = () => {
    return (
        <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 5C11 7.21 9.21 9 7 9C4.79 9 3 7.21 3 5C3 2.79 4.79 1 7 1C9.21 1 11 2.79 11 5ZM11 11.72V17H0V15C0 12.79 3.13 11 7 11C8.5 11 9.87 11.27 11 11.72ZM24 17H13V0H24V17ZM16 8.5C16 7.83696 16.2634 7.20107 16.7322 6.73223C17.2011 6.26339 17.837 6 18.5 6C19.163 6 19.7989 6.26339 20.2678 6.73223C20.7366 7.20107 21 7.83696 21 8.5C21 9.16304 20.7366 9.79893 20.2678 10.2678C19.7989 10.7366 19.163 11 18.5 11C17.837 11 17.2011 10.7366 16.7322 10.2678C16.2634 9.79893 16 9.16304 16 8.5ZM22 4C21.4696 4 20.9609 3.78929 20.5858 3.41421C20.2107 3.03914 20 2.53043 20 2H17C17 3.11 16.11 4 15 4V13C15.5304 13 16.0391 13.2107 16.4142 13.5858C16.7893 13.9609 17 14.4696 17 15H20C20 13.9 20.9 13 22 13V4Z"
                fill="#1A56DB"
            />
        </svg>
    );
};

export const BTGardenening = () => {
    return (
        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.5022 3.47C15.7622 4.2 15.5722 5.25 15.9222 6.15L13.0022 9.07V7C13.0022 6.45 12.5522 6 12.0022 6H10.9722C11.0022 5.83 11.0022 5.67 11.0022 5.5C11.0022 2.46 8.54216 2.41873e-06 5.50216 2.41873e-06C4.36935 -0.00105997 3.26387 0.347883 2.33695 0.999099C1.41004 1.65032 0.706945 2.572 0.32386 3.63807C-0.0592247 4.70414 -0.103594 5.86253 0.196822 6.95478C0.497238 8.04703 1.12777 9.01979 2.00216 9.74V16C2.00216 16.55 2.45216 17 3.00216 17H12.0022C12.5522 17 13.0022 16.55 13.0022 16V11.89L17.3322 7.56C18.2322 7.91 19.2822 7.73 20.0022 7L16.5022 3.47ZM2.05216 6C2.03216 5.83 2.00216 5.67 2.00216 5.5C2.00216 3.57 3.57216 2 5.50216 2C7.43216 2 9.00216 3.57 9.00216 5.5C9.00216 5.67 8.97216 5.83 8.95216 6M11.0022 15H4.00216V8H11.0022V15Z"
                fill="#1A56DB"
            />
        </svg>
    );
};
export const BTGeneral = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 10H11.4L16.1 14.7L14.7 16.1L10 11.4V18H8V11.3L3.3 16L1.9 14.6L6.4 10H0V8H6.6L1.9 3.3L3.3 1.9L8 6.6V0H10V6.4L14.6 1.8L16 3.3L11.3 8H18V10Z"
                fill="#1A56DB"
            />
        </svg>
    );
};

export const BTGHGRemoval = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17 16.31V18C17 18.5304 16.7893 19.0391 16.4142 19.4142C16.0391 19.7893 15.5304 20 15 20H5C4.46957 20 3.96086 19.7893 3.58579 19.4142C3.21071 19.0391 3 18.5304 3 18V14.3C2.54 14.12 1.95 14 1 14C0.734784 14 0.48043 13.8946 0.292893 13.7071C0.105357 13.5196 0 13.2652 0 13C0 12.7348 0.105357 12.4804 0.292893 12.2929C0.48043 12.1054 0.734784 12 1 12C1.82 12 2.47 12.08 3 12.21V10.3C2.54 10.12 1.95 10 1 10C0.734784 10 0.48043 9.89464 0.292893 9.70711C0.105357 9.51957 0 9.26522 0 9C0 8.73478 0.105357 8.48043 0.292893 8.29289C0.48043 8.10536 0.734784 8 1 8C1.82 8 2.47 8.08 3 8.21V6.3C2.54 6.12 1.95 6 1 6C0.734784 6 0.48043 5.89464 0.292893 5.70711C0.105357 5.51957 0 5.26522 0 5C0 4.73478 0.105357 4.48043 0.292893 4.29289C0.48043 4.10536 0.734784 4 1 4C1.82 4 2.47 4.08 3 4.21V2C3 1.46957 3.21071 0.960859 3.58579 0.585786C3.96086 0.210714 4.46957 0 5 0H15C15.5304 0 16.0391 0.210714 16.4142 0.585786C16.7893 0.960859 17 1.46957 17 2V4.16C18.78 4.47 19.54 5.13 19.71 5.29C20.1 5.68 20.1 6.32 19.71 6.71C19.32 7.1 18.8 7.09 18.29 6.71C18.29 6.71 17.25 6 15 6C13.74 6 12.91 6.41 11.95 6.9C10.91 7.41 9.74 8 8 8C7.64 8 7.31 8 7 7.96V5.95C7.3 6 7.63 6 8 6C9.26 6 10.09 5.59 11.05 5.11C12.09 4.59 13.27 4 15 4V2H5V18H15V16C16.5 16 16.97 16.29 17 16.31ZM15 8C13.27 8 12.09 8.59 11.05 9.11C10.09 9.59 9.26 10 8 10C7.63 10 7.3 10 7 9.95V11.96C7.31 12 7.64 12 8 12C9.74 12 10.91 11.41 11.95 10.9C12.91 10.42 13.74 10 15 10C17.25 10 18.29 10.71 18.29 10.71C18.8 11.1 19.32 11.1 19.71 10.71C20.1 10.32 20.1 9.69 19.71 9.29C19.5 9.08 18.25 8 15 8ZM15 12C13.27 12 12.09 12.59 11.05 13.11C10.09 13.59 9.26 14 8 14C7.63 14 7.3 14 7 13.95V15.96C7.31 16 7.64 16 8 16C9.74 16 10.91 15.41 11.95 14.9C12.91 14.42 13.74 14 15 14C17.25 14 18.29 14.71 18.29 14.71C18.8 15.1 19.32 15.1 19.71 14.71C20.1 14.32 20.1 13.69 19.71 13.29C19.5 13.08 18.25 12 15 12Z"
                fill="#1A56DB"
            />
        </svg>
    );
};

export const BTLearn = () => {
    return (
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 0L13 4.5V15.5L18 11V0ZM5.5 3C3.55 3 1.45 3.4 0 4.5V19.16C0 19.41 0.25 19.66 0.5 19.66C0.6 19.66 0.65 19.59 0.75 19.59C2.1 18.94 4.05 18.5 5.5 18.5C7.45 18.5 9.55 18.9 11 20C12.35 19.15 14.8 18.5 16.5 18.5C18.15 18.5 19.85 18.81 21.25 19.56C21.35 19.61 21.4 19.59 21.5 19.59C21.75 19.59 22 19.34 22 19.09V4.5C21.4 4.05 20.75 3.75 20 3.5V17C18.9 16.65 17.7 16.5 16.5 16.5C14.8 16.5 12.35 17.15 11 18V4.5C9.55 3.4 7.45 3 5.5 3Z"
                fill="#1A56DB"
            />
        </svg>
    );
};

export const BTPromotion = () => {
    return (
        <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 0V1.5C0 5.65 2.21 9.28 5.5 11.3V16H20.5V14C20.5 11.34 15.17 10 12.5 10H12.25C7.5 10 3.5 6 3.5 1.5V0M12.5 0C11.4391 0 10.4217 0.421427 9.67157 1.17157C8.92143 1.92172 8.5 2.93913 8.5 4C8.5 5.06087 8.92143 6.07828 9.67157 6.82843C10.4217 7.57857 11.4391 8 12.5 8C13.5609 8 14.5783 7.57857 15.3284 6.82843C16.0786 6.07828 16.5 5.06087 16.5 4C16.5 2.93913 16.0786 1.92172 15.3284 1.17157C14.5783 0.421427 13.5609 0 12.5 0Z"
                fill="#1A56DB"
            />
        </svg>
    );
};
export const BTRecycle = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.16995 6.91001L7.89995 7.91001L11.0799 2.42001L13.3299 6.31001L10.7299 7.81001L16.1899 9.27001L17.6599 3.81001L15.0599 5.31001L12.8099 1.41001C12.6794 1.18202 12.505 0.982125 12.2969 0.821868C12.0887 0.661611 11.8508 0.544162 11.597 0.476296C11.3432 0.40843 11.0785 0.391491 10.8181 0.426455C10.5577 0.461419 10.3069 0.547596 10.0799 0.680012C9.80995 0.830012 9.57995 1.05001 9.40995 1.31001M8.99995 19V17H2.65995L4.89995 13.1L7.49995 14.6L6.03995 9.14001L0.569946 10.6L3.16995 12.1L0.919946 16C0.749294 16.2961 0.657215 16.6308 0.652467 16.9725C0.64772 17.3142 0.730462 17.6514 0.892824 17.9521C1.05519 18.2527 1.29177 18.5069 1.58007 18.6903C1.86837 18.8737 2.19881 18.9803 2.53995 19M18.0599 10.5L16.3199 11.5L19.4999 17H14.9999V14L10.9999 18L14.9999 22V19H19.4999C20.6099 19 21.4999 18.11 21.4999 17C21.4999 16.69 21.4199 16.38 21.2799 16.11L18.0599 10.5Z"
                fill="#1A56DB"
            />
        </svg>
    );
};
export const BTRegenerate = () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.1 2.1V0.3L19.3 0.0999999C19.2 0.0999999 18.6 0 17.6 0C13.5 0 10.5 1.2 8.8 3.3C7.5 2.5 5.7 2 3.6 2C2.6 2 1.9 2.1 1.9 2.1L0 2.4L0.1 4.1C0.2 7.1 1.7 12.8 6.9 12.8H7.1V16.2C3.3 16.7 0.1 18 0.1 18V20H20.1V18C20.1 18 16.9 16.7 13.1 16.2V13C19.4 12.9 21.1 5.8 21.1 2.1ZM10.1 16H9.1V10.4C9.1 10.4 8.9 7 6.1 7C6.1 7 7.6 7.8 8 10.7C7.6 10.8 7.2 10.8 6.9 10.8C2.3 10.8 2.1 4.1 2.1 4.1C2.1 4.1 2.7 4 3.6 4C5.5 4 8.6 4.4 9.5 7.1C10 2.6 15.1 2 17.6 2C18.5 2 19.1 2.1 19.1 2.1C19.1 2.1 19.1 11.1 12.8 11.1H12.1C12.1 9.1 14.1 6.1 14.1 6.1C11.1 7.1 11.1 11 11.1 11V16H10.1Z"
                fill="#1A56DB"
            />
        </svg>
    );
};
export const BTRegistration = () => {
    return (
        <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.7 10.9L13 16.6H10.7V14.3L16.4 8.6L18.7 10.9ZM22.1 10.1C22.1 10.4 21.8 10.7 21.5 11L19 13.5L18.1 12.6L20.7 10L20.1 9.4L19.4 10.1L17.1 7.8L19.3 5.7C19.5 5.5 19.9 5.5 20.2 5.7L21.6 7.1C21.8 7.3 21.8 7.7 21.6 8C21.4 8.2 21.2 8.4 21.2 8.6C21.2 8.8 21.4 9 21.6 9.2C21.9 9.5 22.2 9.8 22.1 10.1ZM2 18V2H9V7H14V8.5L16 6.5V6L10 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18H2ZM10 15.1C9.8 15.1 9.6 15.2 9.5 15.2L9 13H7.5L5.4 14.7L6 12H4.5L3.5 17H5L7.9 14.4L8.5 16.7H9.5L10 16.6V15.1Z"
                fill="#1A56DB"
            />
        </svg>
    );
};
export const BTResearch = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 0V15H9V0H6ZM9 2L13 15L16 14L12 1L9 2ZM2 2V15H5V2H2ZM0 16V18H18V16H0Z" fill="#1A56DB" />
        </svg>
    );
};

export const BTRestoration = () => {
    return (
        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.92 13C15.7824 13.9549 15.4483 14.8709 14.9388 15.6902C14.4293 16.5095 13.7556 17.2142 12.96 17.76C12.76 16.93 11.53 16.25 9.92 16.06C10.09 14.87 10.44 13.76 10.68 13.12C11.06 13.36 11.5 13.5 12 13.5C15.55 13.5 15 7.5 15 7.5C15 7.5 14.5 8.5 13 8.5H12C11.5552 8.49988 11.1184 8.61844 10.7347 8.84345C10.3509 9.06847 10.0342 9.39179 9.81715 9.78006C9.60009 10.1683 9.49055 10.6075 9.49984 11.0522C9.50913 11.4969 9.63691 11.9311 9.87 12.31C10.53 11.62 11.24 11 12 10.5C10.68 11.83 9.67 13.5 9 15.1C8.37 13.87 7.58 12.93 7 12.5C7.47 12.7 7.91 13 8.33 13.3C8.44 13.06 8.5 12.79 8.5 12.5C8.45 9.77 4 10 4 10C4.31 10.42 4.5 10.95 4.5 11.5V12.5C4.5 13.6 5.4 14.5 6.5 14.5C7 14.5 7.45 14.32 7.8 14C8.03 14.63 8.2 15.35 8.29 16.04C6.57 16.19 5.25 16.89 5.04 17.77C3.21 16.5 2 14.39 2 12C2 8.14 5.14 5 9 5V8L14 4L9 0V3C6.61305 3 4.32387 3.94821 2.63604 5.63604C0.948212 7.32387 0 9.61305 0 12C0 14.3869 0.948212 16.6761 2.63604 18.364C4.32387 20.0518 6.61305 21 9 21C13.63 21 17.44 17.5 17.94 13H15.92Z"
                fill="#1A56DB"
            />
        </svg>
    );
};

export const BTTraining = () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 15C20.1 15 21 14.1 21 13V2C21 0.9 20.1 0 19 0H8.5C8.8 0.6 9 1.3 9 2H19V13H10V15M14 5V7H8V20H6V14H4V20H2V12H0.5V7C0.5 5.9 1.4 5 2.5 5H14ZM7 2C7 3.1 6.1 4 5 4C3.9 4 3 3.1 3 2C3 0.9 3.9 0 5 0C6.1 0 7 0.9 7 2ZM16 4H18V12H16V4ZM13 8H15V12H13V8ZM10 8H12V12H10V8Z"
                fill="#1A56DB"
            />
        </svg>
    );
};
export const BTVlogging = () => {
    return (
        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 2V10H2V2H12ZM13 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H13C13.2652 12 13.5196 11.8946 13.7071 11.7071C13.8946 11.5196 14 11.2652 14 11V7.5L18 11.5V0.5L14 4.5V1C14 0.734784 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0Z"
                fill="#1A56DB"
            />
        </svg>
    );
};

export const BTVolunteer = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15 1.23C15.71 0.41 16.61 0 17.7 0C18.61 0 19.37 0.33 20 1C20.63 1.67 20.96 2.43 21 3.3C21 4 20.67 4.81 20 5.76C19.33 6.71 18.68 7.5 18.03 8.15C17.38 8.79 16.37 9.74 15 11C13.61 9.74 12.59 8.79 11.94 8.15C10.965 7.19 10.63 6.71 9.97 5.76C9.31 4.81 9 4 9 3.3C9 2.39 9.32 1.63 9.97 1C10.62 0.37 11.4 0.04 12.31 0C13.38 0 14.27 0.41 15 1.23ZM21 17V18L13 20.5L6 18.56V20H0V9H7.97L14.13 11.3C14.6789 11.5071 15.1518 11.8763 15.4858 12.3585C15.8198 12.8408 15.9992 13.4133 16 14H18C19.66 14 21 15.34 21 17ZM4 18V11H2V18H4ZM18.9 16.57C18.74 16.24 18.39 16 18 16H12.65C12.11 16 11.58 15.92 11.07 15.75L8.69 14.96L9.32 13.06L11.7 13.85C12 13.95 14 14 14 14C14 13.63 13.77 13.3 13.43 13.17L7.61 11H6V16.5L12.97 18.41L18.9 16.57Z"
                fill="#1A56DB"
            />
        </svg>
    );
};
export const BTWriting = () => {
    return (
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.13 9L16.39 7.74C16.83 7.3 17.39 7.06 18 7V6L12 0H2C0.89 0 0 0.89 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H8V16.13L8.13 16H2V2H9V9H15.13ZM11 1.5L16.5 7H11V1.5ZM16.13 10.83L18.17 12.87L12.04 19H10V16.96L16.13 10.83ZM19.85 11.19L18.87 12.17L16.83 10.13L17.81 9.15C18 8.95 18.33 8.95 18.53 9.15L19.85 10.47C20.05 10.67 20.05 11 19.85 11.19Z"
                fill="#1A56DB"
            />
        </svg>
    );
};
