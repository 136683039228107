import React, { useState, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import Button from '../../ui/Button';
import SelectWalletModal from './SelectWalletModal';

const OwnButton = ({ className, variant, size, text, setIsAnotherModalOpen }) => {
    const { connect, disconnect, connected, select } = useWallet();
    const [showWalletList, setShowWalletList] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [walletAvailable, setWalletAvailable] = useState(false);
    const [isWalletSelected, setIsWalletSelected] = useState(false);
    // URL to install Phantom Wallet (or other wallet URLs)
    const PHANTOM_INSTALL_URL = 'https://phantom.app/download';
    // Add installation URLs for other wallets here
    const WALLET_INSTALL_URLS = {
        Phantom: PHANTOM_INSTALL_URL,
        Sollet: 'https://sollet.io/',
        Solflare: 'https://solflare.com/',
    };

    // Toggle wallet list visibility
    const handleToggleWalletList = (e) => {
        e.stopPropagation();
        setShowWalletList((prev) => !prev);
    };

    // Check wallet availability
    const checkWalletAvailability = () => {
        const isPhantomAvailable = window.solana && window.solana.isPhantom;
        setWalletAvailable(isPhantomAvailable);
    };

    useEffect(() => {
        checkWalletAvailability();
    }, []);

    // Handle wallet connection
    const handleConnect = async (selectedWallet) => {
        setError(null); // Reset error state
        setLoading(true); // Set loading state

        try {
            if (!walletAvailable && selectedWallet === 'Phantom') {
                const installUrl = WALLET_INSTALL_URLS[selectedWallet];
                if (installUrl) {
                    window.open(installUrl, '_blank');
                }
                return; // Stop further execution
            }

            await select(selectedWallet); // Select the wallet
            setIsWalletSelected(true);
            // await connect(); // Attempt to connect
        } catch (error) {
            console.error('Failed to connect wallet:', error);
            setError('Failed to connect wallet. Please ensure the wallet is installed.');
            setIsWalletSelected(false);
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    return (
        <div>
            <Button
                variant={variant}
                size={size}
                onClick={(e) => {
                    if (setIsAnotherModalOpen) setIsAnotherModalOpen(true);
                    connected ? disconnect() : handleToggleWalletList(e);
                }}
                className={`${className}`}
                disabled={loading}
            >
                {connected ? 'Disconnect Wallet' : text}
            </Button>
            {showWalletList && !connected && (
                <SelectWalletModal close={() => setShowWalletList(false)}>
                    <h2>Select a Wallet</h2>
                    {isWalletSelected ? (
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                connect();
                            }}
                        >
                            Connect Now
                        </Button>
                    ) : (
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleConnect('Phantom');
                            }}
                        >
                            Phantom
                        </Button>
                    )}

                    {/* Uncomment and add more wallet options if needed */}
                    {/* <Button onClick={() => handleConnect('Sollet')}>Sollet</Button>
                        <Button onClick={() => handleConnect('Solflare')}>Solflare</Button> */}
                    {/* <Button onClick={() => setShowWalletList(false)}>Close</Button> */}
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </SelectWalletModal>
            )}
        </div>
    );
};

export default OwnButton;
