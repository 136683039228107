import Table from '../../ui/Table';
import { microtaskTypeData } from '../bounties/microtasktypes/microtaskTypeData';

const UploadVideoSD = ({ dataTask }) => {
    const titleObject = dataTask?.task.find((obj) => Object.keys(obj).includes('Title'));
    const descriptionObject = dataTask?.task.find((obj) => Object.keys(obj).includes('Description'));

    return (
        <Table.Row>
            <p className=" text-lightBlack flex flex-col   ">
                <span className="font-medium mb-1  "> {titleObject?.Title || '-'} </span>
                <span className="text-xs font-medium mb-1">{descriptionObject?.Description || '-'}</span>
            </p>
            <p className="text-sm justify-self-center">
                {microtaskTypeData.find((ele) => ele.type === dataTask.actionType).label}
            </p>

            {dataTask?.files && (
                <div className="flex justify-center rounded-xl overflow-hidden">
                    <video controls className="w-full h-32 object-cover rounded-lg shadow-lg">
                        <source src={dataTask?.files[0]} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}
        </Table.Row>
    );
};

export default UploadVideoSD;
