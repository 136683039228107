import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css'; // Import Quill Bubble theme styles

import { useEffect, useRef, useState } from 'react';
import { removeAllDuplicates, textToHtml } from '../../../helpers/plainText';
import { createPortal } from 'react-dom';

let Abc;

const RichTextEditorBubbleLinkOnly = ({ descriptionField, handleDescriptionLinkPayload, instructionLinkPayload }) => {
    const modules = {
        toolbar: [['link']],
    };
    const quillRef = useRef(null);
    // Local state to manage HTML content with tags for the editor
    const [htmlContent, setHtmlContent] = useState(textToHtml(descriptionField.value, instructionLinkPayload) || '');

    useEffect(function () {
        handleDescriptionLinkPayload(instructionLinkPayload);
    }, []);

    useEffect(() => {
        // Access the Quill editor instance
        const quillInstance = quillRef.current.getEditor();

        // Function to move the tooltip to the portal
        const moveTooltipToPortal = () => {
            const tooltip = document.querySelector('.ql-bubble .ql-tooltip');

            if (tooltip) {
                Abc = createPortal(tooltip, document.getElementById('tooltip-root'));
            }
        };

        // Initial call to move tooltip to the portal if it exists
        moveTooltipToPortal();

        // Listen to any changes that might make the tooltip visible
        quillInstance.on('selection-change', () => {
            moveTooltipToPortal();
        });

        // Cleanup: If the component unmounts, move the tooltip back to prevent issues

        return () => {
            const tooltip = document.querySelector('.ql-bubble .ql-tooltip');
            if (tooltip && document.getElementById('tooltip-root').contains(tooltip)) {
                document.body.appendChild(tooltip); // Move back to the body if necessary
            }
        };
    }, []);

    const extractLinkPayload = () => {
        const quillEditor = document.querySelector('.ql-editor');

        // Get the full plain text from the editor, trimmed and stripped of punctuation
        const fullText = quillEditor.innerText.trim();

        // Split the text into words and remove punctuation using a regex
        const words = fullText.split(/\s+/).map((word) => word.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, ''));

        // Find all <a> elements (links) in the editor content
        const links = Array.from(quillEditor.querySelectorAll('a'));

        // Generate the payload for each link found
        const payload = removeAllDuplicates([
            ...instructionLinkPayload,
            ...links.map((linkElement) => {
                const linkedText = linkElement.innerText; // Get the linked word(s) inside the <a> tag
                const link = linkElement.getAttribute('href'); // Get the link URL

                // Remove punctuation from the linked words
                const linkedWordsArray = linkedText
                    .split(/\s+/)
                    .map((word) => word.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, ''));

                // Use a manual search for the startIndex, which accounts for edge cases and punctuation
                let startIndex = -1;
                let wordIndex = 0;

                for (let i = 0; i < words.length; i++) {
                    if (words[i] === linkedWordsArray[wordIndex]) {
                        if (wordIndex === 0) {
                            startIndex = i; // Found the start of the link
                        }
                        wordIndex++;
                        if (wordIndex === linkedWordsArray.length) {
                            break; // Found the full linked text
                        }
                    } else {
                        // Only reset if we have already matched part of the word sequence
                        if (startIndex !== -1) {
                            startIndex = -1;
                            wordIndex = 0;
                        }
                    }
                }

                const endIndex = startIndex + linkedWordsArray.length - 1;

                return {
                    startIndex: startIndex,
                    endIndex: endIndex,
                    link: link,
                };
            }),
        ]);

        handleDescriptionLinkPayload(payload);
    };

    const handleContentChange = (newHtmlValue) => {
        setHtmlContent(newHtmlValue); // Update local state with HTML content

        // Remove HTML tags to get plain text
        // const plainTextValue = newHtmlValue.replace(/<\/?[^>]+(>|$)/g, '').trim();

        const plainTextValue = newHtmlValue
            .replace(/<p><br\s*\/?><\/p>/gi, '\n') // Replace empty paragraphs with a single newline
            .replace(/<br\s*\/?>/gi, '\n') // Replace <br> or <br/> with newline
            .replace(/<\/p>/gi, '\n') // Replace closing </p> with a single newline for paragraph separation
            .replace(/<p[^>]*>/gi, '') // Remove opening <p> tags
            .replace(/<\/?[^>]+(>|$)/g, '') // Remove any remaining HTML tags
            .trim();
        // Update the form field with plain text content only

        descriptionField.onChange(plainTextValue);

        // Extract link payload whenever content changes
        extractLinkPayload();
    };

    return (
        <div className="instructional-text">
            <ReactQuill
                ref={quillRef}
                value={htmlContent} // HTML content with tags for the editor
                onChange={handleContentChange}
                className=" placeholder:font-semibold     rounded-md "
                theme="bubble"
                onBlur={descriptionField.onBlur}
                modules={modules} // Pass custom toolbar with link only
                placeholder="Add instruction text here"
            />
        </div>
    );
};

const FormWithInstructionField = ({ errors, field, handleDescriptionLinkPayload, instructionLinkPayload }) => {
    return (
        <div className="my-4">
            <div className="mb-1">
                <p className="text-primaryInput text-sm mb-1">
                    Instructional Text : <span className="text-secondaryInput">{'(Highlight text to add links)'}</span>
                </p>
            </div>

            <RichTextEditorBubbleLinkOnly
                handleDescriptionLinkPayload={handleDescriptionLinkPayload}
                descriptionField={field}
                instructionLinkPayload={instructionLinkPayload}
            />
        </div>
    );
};

export default FormWithInstructionField;
