import { useState } from 'react';

export const useActive = (screen) => {
    const [activeTab, setActiveTab] = useState(screen);

    const handleActiveTab = (active) => {
        setActiveTab(active);
    };

    return [activeTab, handleActiveTab];
};
