import { useContext, useEffect, useState } from 'react';
import Button from '../../ui/Button';
import Dropdown from '../../ui/Dropdown';
import Heading from '../../ui/Heading';
import Input from '../../ui/Input';
import { BountiesContext } from '../../contexts/BountiesContext';
import RadioButtonComponent from '../../ui/RadioButtonComponent';
import { useBountyFields } from '../../hooks/useBountyFields';
import RankBasedDistributions from './RankBasedDistributions';
import Checkbox from '../../ui/Checkbox';
import { coresData, coresWithIconData, SUSTAINABLE_DEVELOPMENT_GOALS } from '../../constants/datas';
import { useStepSixInputs } from './bountiesHooks/useStepSixInputs';
import Toggle from '../../ui/Toggle';
import CharacterLimit from '../../ui/CharacterLimit';
import Loader from '../../ui/Loader';
import { getICImageOnBountyCreationScreen, getImpactPoints } from '../../apis/general/general';
import TextWithTooltip from '../../ui/TextWithTooltip';
import { useWatch } from 'react-hook-form';

const MAX_SELECTION = 5;

function StepSix({
    setStep,
    register,
    control,
    errors,
    getValues,
    setError,
    clearErrors,
    onSubmit,
    loading,
    reset,
    icImage,
    setIcImage,
    impactPointsData,
    setImpactPointsData,
    startGettingIcImage,
    stopGettingIcImage,
}) {
    const { setActive } = useContext(BountiesContext);
    const [maxSDGError, setMaxSDGError] = useState('');

    const {
        tokenField,
        tokens,
        totalRewardAmountField,
        noOfWinnersField,
        passThresholdField,
        hasImpactCertificateField,
        impactBriefField,
        coresField,
        SDGsField,
        isAttestableField,
        dominantSDGField,
        distributionModeField,
        ranksField,
        appendRank,
        removeRank,
    } = useStepSixInputs(control, getValues()?.hasImpactCertificate, getValues, setError, clearErrors);
    const watchedRanks = useWatch({ name: 'ranks', control });

    useEffect(
        function () {
            let payload = {};
            if (distributionModeField.value === 'Distribute evenly between all winners') {
                if (noOfWinnersField.value)
                    payload.amount = Number(totalRewardAmountField.value) / noOfWinnersField.value;
            }
            if (distributionModeField.value === 'Rank based distribution') {
                const ranks = watchedRanks.filter((i) => i.amount).map((obj) => Number(obj.amount));
                payload.amount = Math.max(...ranks);
            }
            payload.symbol = tokenField?.value?.symbol;
            payload.bountyType = getValues('type');

            const fetchData = async () => {
                try {
                    const data = await getImpactPoints(payload);
                    const sum = data?.impactPoints + data?.participatoryImpactPoints;

                    setImpactPointsData(sum);
                } catch (error) {
                    console.log(error);
                }
            };
            if (payload.amount && payload.bountyType && payload.symbol) {
                fetchData();
            }
        },
        [tokenField.value, totalRewardAmountField.value, distributionModeField, noOfWinnersField, watchedRanks]
    );
    useEffect(
        function () {
            coresField.onChange([...coresField.value, getValues()?.dominantCore]);
        },
        [getValues()?.dominantCore]
    );
    useEffect(
        function () {
            if (getValues()?.dominantSDG) {
                const filteredValues = SDGsField.value.filter((sdg) => sdg !== getValues()?.dominantSDG);
                SDGsField.onChange([...filteredValues, getValues()?.dominantSDG]);
            } else SDGsField.onChange([...SDGsField.value]);
        },
        [getValues()?.dominantSDG]
    );

    useEffect(
        function () {
            const payload = {
                coresArray: [...new Set(coresField.value)],
                briefMessage: impactBriefField.value,
                sdgsArray: [...new Set(SDGsField.value)],
                tokenSymbol: tokenField.value?.symbol,
            };

            const fetchData = async () => {
                try {
                    startGettingIcImage();
                    const data = await getICImageOnBountyCreationScreen(payload);
                    setIcImage(data);
                } catch (error) {
                    console.log(error);
                }
                stopGettingIcImage();
            };

            if (
                payload.coresArray.length > 0 &&
                payload.briefMessage &&
                payload.tokenSymbol &&
                payload.sdgsArray.length > 0
            ) {
                fetchData();
            } else {
                setIcImage({});
            }
        },
        [coresField.value, SDGsField.value, impactBriefField.value, tokenField.value]
    );

    const handleUpdateOptions = (isSelected, item) => {
        const currentSelection = SDGsField.value;
        const updatedSelection = isSelected
            ? currentSelection.filter((val) => val !== item) // Remove item if deselected
            : [...currentSelection, item]; // Add item if selected

        if (updatedSelection.length > MAX_SELECTION) {
            setMaxSDGError(`You can only choose upto 4 secondary SDG’s`);
            return; // Prevent further updates
        }

        setMaxSDGError(''); // Clear error if within limit
        updateOptions(isSelected, item); // Call the original update logic
    };

    function updateOptions(value, item) {
        if (value) {
            const removeRoleArr = SDGsField.value.filter((role) => role !== item);
            SDGsField.onChange(removeRoleArr);
        } else {
            SDGsField.onChange([...SDGsField.value, item]);
        }
    }
    function updateCores(value, item) {
        if (value) {
            const removeRoleArr = coresField.value.filter((role) => role !== item);
            coresField.onChange(removeRoleArr);
        } else {
            coresField.onChange([...coresField.value, item]);
        }
    }

    const tokenSymbols = tokens.map((token) => token.symbol);

    return (
        <div className="p-12 pb-0 shadow-md min-h-full flex flex-col justify-between  rounded-md ">
            <div>
                <div className="mb-6">
                    <div className="flex gap-3">
                        <Heading type="xsmall" className="capitalize  text-primaryInput">
                            Bounty Reward
                        </Heading>
                    </div>
                    <p className="text-sm text-secondaryInput">Choose bounty reward token and set the amount</p>
                </div>
                <div className="flex flex-col   ">
                    <div className="w-full">
                        <p className="text-primaryInput">
                            Reward Token & Budget for this bounty? <sup className="text-lg relative top-[1px]">*</sup>{' '}
                        </p>
                        <div className="flex items-center mt-2 w-[100%]  ">
                            <div className="relative ">
                                <Dropdown
                                    modifyLabel={(listItem) => {
                                        return (
                                            <span className="flex items-center gap-5">
                                                <img className="w-5 h-5 rounded-full " src={listItem?.image} alt="" />
                                                <span>{listItem?.symbol} </span>
                                            </span>
                                        );
                                    }}
                                    data={tokens}
                                    isSymbol={true}
                                    selected={tokenField.value}
                                    onSelected={tokenField.onChange}
                                    // className="py-1 bg-red-400"
                                    noSelectedText="Choose Token"
                                    btnClass="rounded-e-none leading-6 "
                                    w="w-40"
                                />
                            </div>
                            <Input
                                {...totalRewardAmountField}
                                type="number"
                                placeholder={'Enter amount'}
                                className={'w-[100%] rounded-s-none '}
                            />
                        </div>
                    </div>
                    <div className="flex items-center gap-2  mb-6   ">
                        {
                            <p className="min-w-40 text-foundryRed ">
                                {errors?.token?.message && errors?.token?.message}
                            </p>
                        }
                        {
                            <p className="text-foundryRed">
                                {errors?.totalRewardAmount?.message && errors?.totalRewardAmount?.message}
                            </p>
                        }
                    </div>
                </div>
                <div className="mb-6">
                    <p className="text-primaryInput">
                        How many winners ? <sup className="text-lg relative top-[1px]">*</sup>
                    </p>
                    <Input
                        {...noOfWinnersField}
                        type="number"
                        error={errors?.noOfWinners?.message}
                        onChange={(e) => {
                            // if (distributionModeField.value === 'Rank based distribution') {
                            const count = parseInt(e.target.value, 10);
                            if (!isNaN(count) && count >= 0) {
                                // Clear existing fields if needed
                                const currentFields = ranksField.length;

                                // If the new count is greater than the current count, append fields
                                for (let i = currentFields; i < count; i++) {
                                    appendRank({ value: '' });
                                }

                                // If the new count is less than the current count, remove fields
                                for (let i = currentFields; i > count; i--) {
                                    removeRank(i - 1);
                                }
                                // }
                            }
                            noOfWinnersField.onChange(e.target.value);
                        }}
                        placeholder={'Enter number of winners'}
                        className={'w-[100%] mt-2'}
                    />
                </div>
                <div className="mb-6">
                    <p className="text-primaryInput">How would you like to distribute the rewards ?</p>
                    <RadioButtonComponent
                        className={'flex-col mt-2'}
                        selectedOption={distributionModeField.value}
                        onSelectOption={distributionModeField.onChange}
                        options={['Distribute evenly between all winners', 'Rank based distribution']}
                    />
                </div>

                {distributionModeField.value === 'Rank based distribution' && (
                    <RankBasedDistributions
                        errors={errors}
                        register={register}
                        ranksField={ranksField}
                        control={control}
                    />
                )}
                <div className="mb-6">
                    <p className="mt-2 text-primaryInput">
                        Maximum Impact Points allocated:{' '}
                        <span className="font-bold">{impactPointsData ? impactPointsData : null}</span>{' '}
                    </p>
                </div>
                <div className="mb-6">
                    <TextWithTooltip
                        heading={
                            <p className="text-primaryInput">
                                Set Pass Threshold <sup className="text-lg relative top-[1px]">*</sup>
                            </p>
                        }
                    >
                        <p className="text-sm">Determines the cut off accuracy for the user to be eligible to win.</p>
                        <p className="text-sm mt-3 ">
                            ex, if 50% is chosen as the Pass Threshold. A users with 40% accuracy upon validation will
                            not be eligible to be a winner.
                        </p>
                    </TextWithTooltip>
                    <Input
                        {...passThresholdField}
                        type="number"
                        step="0.002"
                        error={errors?.passThreshold?.message}
                        placeholder={'Enter the pass %'}
                        className={'w-[100%] mt-2'}
                    />
                </div>
                <div className=" mb-6">
                    <div className="flex gap-2 items-center">
                        <Toggle
                            valuee={hasImpactCertificateField.value}
                            onChangeFn={() => {
                                if (getValues()?.hasImpactCertificate) {
                                    clearErrors('impactBrief');
                                    hasImpactCertificateField.onChange(false);
                                    return;
                                }
                                if (!getValues()?.hasImpactCertificate) hasImpactCertificateField.onChange(true);
                            }}
                        />
                        <Heading type="xsmall" className="capitalize  text-primaryInput">
                            Impact Certificate
                        </Heading>
                    </div>
                    <p className="text-sm text-secondaryInput">
                        Do you wish to issue impact certificates for this bounty?
                    </p>
                </div>
                {hasImpactCertificateField.value && (
                    <div className="mb-6">
                        <TextWithTooltip
                            heading={
                                <p className="text-primaryInput">
                                    Impact Certificate Brief <sup className="text-lg relative top-[1px]">*</sup>
                                </p>
                            }
                        >
                            <p className="text-sm">
                                Briefly describe the impact created by the user by doing the bounty
                            </p>
                        </TextWithTooltip>

                        <Input
                            {...impactBriefField}
                            type="textArea"
                            name="impactField"
                            placeholder={'Enter a brief description'}
                            className={'w-[100%]'}
                            error={errors?.impactBrief?.message}
                        />
                        <CharacterLimit min={impactBriefField.value?.length} max={100} />
                    </div>
                )}
                <div className="mb-6">
                    <TextWithTooltip
                        heading={
                            <Heading type="xsmall" className="capitalize  text-primaryInput">
                                Additional Impact Cores
                            </Heading>
                        }
                    >
                        <p className="text-sm">
                            You have previously chosen a dominant impact core. Here you can add more impact cores which
                            would be displayed in the impact certificate.
                        </p>
                    </TextWithTooltip>
                    <div className="relative">
                        <Dropdown
                            w="w-full"
                            data={[
                                getValues()?.dominantCore,
                                ...coresData.filter((core) => core !== getValues()?.dominantCore),
                            ]}
                            modifySelected={(array) => {
                                return array.map((text) => (
                                    <span className="flex items-center gap-1">
                                        <img
                                            className={` ${
                                                text.toLowerCase() === 'water' || text.toLowerCase() === 'social'
                                                    ? 'w-4 mr-1'
                                                    : 'w-5 h-5'
                                            }  `}
                                            src={
                                                coresWithIconData?.find(
                                                    (obj) => obj.label.toLowerCase() === text.toLowerCase()
                                                )?.icon
                                            }
                                            alt="image"
                                        />
                                        <span>{text}</span>
                                    </span>
                                ));
                            }}
                            selected={[...new Set(coresField.value)]}
                            render={(listItem) => {
                                return (
                                    <Checkbox
                                        pl="pl-5"
                                        name={listItem}
                                        value={coresField.value.includes(listItem)}
                                        disabling={listItem === getValues()?.dominantCore}
                                        label={listItem}
                                        icon={
                                            <img
                                                className={` ${
                                                    listItem.toLowerCase() === 'water' ||
                                                    listItem.toLowerCase() === 'social'
                                                        ? 'w-4 mr-1'
                                                        : 'w-5 h-5'
                                                }  `}
                                                src={
                                                    coresWithIconData?.find(
                                                        (obj) => obj.label.toLowerCase() === listItem.toLowerCase()
                                                    )?.icon
                                                }
                                                alt="image"
                                            />
                                        }
                                        update={() => updateCores(coresField.value.includes(listItem), listItem)}
                                    />
                                );
                            }}
                            withCheckBox={true}
                        />
                    </div>
                </div>

                <div className="mb-6">
                    <div className="mb-6">
                        <TextWithTooltip
                            heading={
                                <Heading type="xsmall" className="capitalize mb-2 text-primaryInput">
                                    SDGs <sup className="text-lg relative top-[1px]">*</sup>
                                </Heading>
                            }
                        >
                            <p className="text-sm">Choose the SDGs based on the impact areas of bounty.</p>
                            <p className="mt-3 text-sm">
                                Sustainable development goals is a framework created by the UN that we're using to
                                measure impact. You can learn more about it here:{' '}
                                <a className="text-foundryBlue" href="https://sdgs.un.org/goals" target="_blank">
                                    https://sdgs.un.org/goals
                                </a>
                            </p>
                        </TextWithTooltip>

                        <div className="relative mb-2">
                            <Dropdown
                                data={SUSTAINABLE_DEVELOPMENT_GOALS}
                                selected={dominantSDGField.value}
                                onSelected={dominantSDGField.onChange}
                                noSelectedText="Choose Dominant SDG"
                                error={errors?.dominantSDG?.message}
                            />
                        </div>
                        <div className="relative">
                            <Dropdown
                                w="w-full"
                                data={[
                                    getValues()?.dominantSDG,
                                    ...SUSTAINABLE_DEVELOPMENT_GOALS.filter((SDG) => SDG !== getValues()?.dominantSDG),
                                ]}
                                selected={SDGsField.value}
                                noSelectedText="Choose other SDGs"
                                render={(listItem) => {
                                    return (
                                        <Checkbox
                                            pl="pl-5"
                                            name={listItem}
                                            value={SDGsField.value.includes(listItem)}
                                            disabling={listItem === getValues()?.dominantSDG}
                                            label={listItem}
                                            update={() =>
                                                handleUpdateOptions(SDGsField.value.includes(listItem), listItem)
                                            }
                                        />
                                    );
                                }}
                                withCheckBox={true}
                            />
                            {maxSDGError && <p className="text-foundryRed mt-2">{maxSDGError}</p>}
                        </div>
                    </div>
                </div>
                <div className=" mb-2">
                    <div className="flex gap-2 items-center">
                        <Toggle
                            valuee={isAttestableField.value}
                            onChangeFn={() => {
                                if (getValues()?.isAttestable) {
                                    isAttestableField.onChange(false);
                                    return;
                                }
                                if (!getValues()?.isAttestable) isAttestableField.onChange(true);
                            }}
                        />
                        <TextWithTooltip
                            heading={
                                <Heading type="xsmall" className="capitalize  text-primaryInput">
                                    Attestation
                                </Heading>
                            }
                        >
                            <p className="text-sm">
                                Attesters are those who verify the authenticity of the bounty validation, this is done
                                to ensure rewards are given to the rightful winners. It's done using Etherium
                                Attestation Services (EAS)
                            </p>
                        </TextWithTooltip>
                    </div>
                    <p className="text-sm text-secondaryInput">Do you want this bounty to be attestable?</p>
                </div>
            </div>

            <div className="fixed bottom-10  z-[9999] w-[618px] mx-auto  ">
                <div className="flex justify-between items-center  ">
                    <Button
                        type="button"
                        onClick={() => setStep(5)}
                        className="bg-white"
                        variant="tertiary"
                        size="small"
                    >
                        Back
                    </Button>
                    <Button size="small" type="submit">
                        {loading ? <Loader /> : '  Create Bounty'}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default StepSix;
