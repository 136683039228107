import { useContext, useEffect } from 'react';
import { BountiesContext } from '../../../contexts/BountiesContext';
import { useBountyFields } from '../../../hooks/useBountyFields';
import mobileFrame from '../../../assets/mobileFrame.png';
import Heading from '../../../ui/Heading';

import SubHeading from '../../../ui/SubHeading';
import { CalendarIcon } from '../../../assets/icons/CalendarIcon';
import { ClockIcon } from '../../../assets/icons/ClockIcon';
import Button from '../../../ui/Button';
import LeftPreviewArrow from '../../../assets/icons/LeftPreviewArrow';
import ReactMapboxGl, { Feature, Layer, Marker } from 'react-mapbox-gl';

import { useState } from 'react';
import defaultMarker from '../../../assets/defaultMarker.png';
const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoiZmFpc2FsZHJvcGNoYWluIiwiYSI6ImNsdXJnOWF2czA3NmYyam4xZWkzbmltaHAifQ.IK0wrkOv-H-C57rLCcMBMA',
});

function PreviewVisitLocation({ data }) {
    const [lat, long] = data?.goToLocation_coordinate ? data?.goToLocation_coordinate?.split(',') : ['', ''];
    const [mtCenter, setMtCenter] = useState([long, lat]);
    const [zoomMt, setZoomMt] = useState(14);

    useEffect(
        function () {
            setMtCenter([long, lat]);
        },
        [lat, long]
    );

    return (
        <div
            className="relative w-[300px] h-[610px]     "
            style={{
                backgroundImage: `url(${mobileFrame})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <main className="absolute  top-28 left-6 right-6 ">
                <div className="mt-4">
                    <Heading type={'xsmall'}>{data.goToLocation_Title || 'Title'}</Heading>
                    <SubHeading className={'!text-xs font-semibold mt-2'} type="xsmall">
                        {data.goToLocation_Description || 'Task Description'}
                    </SubHeading>
                    {/* 
                    <div className="mt-2 flex items-center gap-1">
                        <p className="text-xs">Latitude : </p>
                        <p className="text-xs">{data?.goToLocation_coordinate?.split(',')[0] || ''}</p>
                    </div>
                    <div className="mt-2 flex items-center gap-1">
                        <p className="text-xs">Longitude : </p>
                        <p className="text-xs">{data?.goToLocation_coordinate?.split(',')[1] || ''}</p>
                    </div> */}
                    <div className="mt-4">
                        {lat && long && (
                            <div className=" overflow-hidden bg-white rounded-2xl">
                                <Map
                                    style="mapbox://styles/mapbox/streets-v9"
                                    containerStyle={{
                                        height: '20vh',
                                        width: '100%',
                                        zIndex: '0',
                                    }}
                                    center={mtCenter}
                                    zoom={[zoomMt]}
                                    onZoomEnd={(map) => {
                                        setZoomMt(map.getZoom());
                                    }}
                                >
                                    {lat && long && (
                                        <>
                                            {/* Custom Marker with Image */}
                                            <Marker anchor="bottom" coordinates={[long, lat]}>
                                                <img className="w-5" src={defaultMarker} alt="marker" />
                                            </Marker>
                                        </>
                                    )}
                                </Map>
                            </div>
                        )}
                    </div>
                </div>
            </main>

            <footer className="absolute bottom-6 left-6 right-6">
                <div className="flex mb-2 items-center justify-between">
                    <Button
                        className=" flex !text-[#1A56DB] text-xs items-center justify-center gap-1 text-end"
                        size="none"
                        variant="transparent"
                    >
                        <span>{'<-'}</span> <span> Back</span>
                    </Button>
                    {data.skippable && (
                        <Button className=" !text-[#1A56DB] text-xs  text-end" size="none" variant="transparent">
                            Skip {'->'}
                        </Button>
                    )}
                </div>
                <Button variant="miner" size="largeMiner" className="py-2 text-xs " radius="rounded-full">
                    Next
                </Button>
            </footer>
        </div>
    );
}

export default PreviewVisitLocation;
