import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Heading from '../../../ui/Heading';
import Input from '../../../ui/Input';
import Button from '../../../ui/Button';
import { createOrganizationAtDb, sendRequestToJoinOrg } from '../../../apis/oragnisations/organisation';
import Checkbox from '../../../ui/Checkbox';
import { useState } from 'react';
import { Description } from '@mui/icons-material';
import { useLoadingState } from '../../../hooks/useLoader';
import Loader from '../../../ui/Loader';

function FormForSendingRequestToJoin({ close, setIsRequested }) {
    const {
        register,
        handleSubmit,
        getValues,

        formState: { errors },
    } = useForm();
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [rolesWith, setRolesWith] = useState(['BOUNTY_MANAGER']);
    const { id } = useParams();
    const navigate = useNavigate();

    const onSubmit = async (description) => {
        const obj = {
            roles: rolesWith,
            message: description.motiveDescription,
        };

        startLoading();
        const res = await sendRequestToJoinOrg(id, obj);
        setIsRequested(true);
        stopLoading();
        close();
    };

    const updateRoles = (role) => {
        if (rolesWith.includes(role)) setRolesWith(rolesWith.filter((r) => r !== role));
        else setRolesWith((initial) => [...initial, role]);
    };

    return (
        <div className=" flex gap-3 items-start    ">
            <form onSubmit={handleSubmit(onSubmit)} className=" w-[100%] ">
                <div className="mb-9">
                    <Heading type="large" className="capitalize font-semibold text-black mb-2">
                        What do you want to request to join as ?
                    </Heading>
                    <div className="py-2">
                        <Checkbox
                            name={'Bounty Manager'}
                            value={rolesWith.includes('BOUNTY_MANAGER')}
                            label={'Bounty Manager'}
                            update={() => updateRoles('BOUNTY_MANAGER')}
                        />
                        <Checkbox
                            name={'Validator'}
                            value={rolesWith.includes('BOUNTY_VALIDATOR')}
                            label={'Validator'}
                            update={() => updateRoles('BOUNTY_VALIDATOR')}
                        />
                        <Checkbox
                            name={'Treasurer'}
                            value={rolesWith.includes('TREASURER')}
                            label={'Treasurer'}
                            update={() => updateRoles('TREASURER')}
                        />
                    </div>
                    <Input
                        {...register('motiveDescription', {
                            required: 'Please write a small description about your motive to join this Org.',
                        })}
                        error={errors?.motiveDescription?.message}
                        type="textArea"
                        name="motiveDescription"
                        placeholder={'Why do you want to join this organization ?'}
                        className={'w-[100%] h-32 mt-4'}
                    />
                </div>
                <div className="flex items-center justify-end">
                    <div className="flex items-center gap-4 w-[50%] justify-end  ">
                        <Button radius="rounded-full" type="submit" variant="primary" size="roundPreview">
                            {loading ? <Loader /> : 'Submit'}
                        </Button>
                        <Button radius="rounded-full" type="submit" variant="tertiary" size="roundPreview">
                            Cancel
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default FormForSendingRequestToJoin;
