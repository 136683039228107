import { useController, useFieldArray } from 'react-hook-form';

export const useStepFiveInputs = (control, isBackerRequired) => {
    const { field: hasBackerField } = useController({
        name: 'hasBacker',
        control,
    });

    const { field: backerNameField } = useController({
        name: 'backerName',
        control,
        rules: {
            required: hasBackerField.value ? 'Please enter a name' : false,
            maxLength: {
                value: 35,
                message: 'The maximum length is 35 characters',
            },
        },
    });
    const { field: backerLogoField } = useController({
        name: 'backerLogo',
        control,
        rules: {
            required: hasBackerField.value ? 'Please upload a logo' : false,
        },
    });
    const { field: backerMessageField } = useController({
        name: 'backerMessage',
        control,
        rules: {
            maxLength: {
                value: 80,
                message: 'The maximum length is 80 characters',
            },
        },
    });

    const { field: hasInitiatorField } = useController({
        name: 'hasInitiator',
        control,
    });

    const { field: initiatorNameField } = useController({
        name: 'initiatorName',
        control,
        rules: {
            maxLength: {
                value: 35,
                message: 'The maximum length is 35 characters',
            },
        },
    });
    const { field: initiatorLogoField } = useController({
        name: 'initiatorLogo',
        control,
    });

    return {
        hasBackerField,
        backerNameField,
        backerMessageField,
        backerLogoField,
        initiatorNameField,
        hasInitiatorField,
        initiatorLogoField,
    };
};
