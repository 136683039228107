import { useNavigate } from 'react-router-dom';
import BackButton from '../../assets/icons/BackButton';
import Button from '../../ui/Button';
import Heading from '../../ui/Heading';
import Input from '../../ui/Input';

function AttesterInputStepTwo({ setStep, register, errors }) {
    const navigate = useNavigate();
    return (
        <div>
            <div className="w-[70%] mb-9">
                <div className="flex items-center">
                    <BackButton onClick={() => navigate(-1)} />
                    <Heading type={'pageHeading'} className={'text-lightBlack ml-3'}>
                        Proof Of Expertise
                    </Heading>
                </div>
                <p className="mt-2">
                    In the table below you will find Impact Claims waiting to be evaluated for their authenticity, if
                    found to be a credible impact claim in your impartial evaluation, then attest the impact.
                </p>
            </div>
            <div className="mb-7">
                <Heading type="medium" className="capitalize mb-2">
                    Link to Proof Of Work
                </Heading>
                <div className="flex flex-col gap-2">
                    <Input
                        {...register('proofofwork1', {
                            required: 'Please attach your proof of work',
                            pattern: {
                                value: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g,
                                message: 'Please enter valid url', // JS only: <p>error message</p> TS only support string
                            },
                        })}
                        type="text"
                        name="proofofwork1"
                        error={errors?.proofofwork1?.message}
                        placeholder={'Proof of work Link 1'}
                        className={'w-[100%]'}
                    />

                    <Input
                        {...register('proofofwork2', {
                            pattern: {
                                value: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g,
                                message: 'Please enter valid url', // JS only: <p>error message</p> TS only support string
                            },
                        })}
                        type="text"
                        name="proofofwork2"
                        error={errors?.proofofwork2?.message}
                        placeholder={'proofofwork2(optional)'}
                        className={'w-[100%]'}
                    />
                    <Input
                        {...register('proofofwork3', {
                            pattern: {
                                value: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g,
                                message: 'Please enter valid url', // JS only: <p>error message</p> TS only support string
                            },
                        })}
                        type="text"
                        name="proofofwork3"
                        error={errors?.proofofwork3?.message}
                        placeholder={'proofofwork3(optional)'}
                        className={'w-[100%]'}
                    />
                </div>
            </div>
            <div className="mb-7">
                <Heading type="medium" className="capitalize mb-2">
                    Why do you want to be an attester ?
                </Heading>
                <p className="font-medium mb-2">
                    Link to your organization’s Website, Twitter, LinkedIn or Instagram. <br /> Ensure to add atleast
                    one link for verification.
                </p>
                <Input
                    {...register('message', {
                        required: 'Please write a small description about your organization.',
                    })}
                    error={errors?.message?.message}
                    type="textArea"
                    name="message"
                    placeholder={'What does this organization do ?'}
                    className={'w-[100%]'}
                />
            </div>
            <div className="flex justify-between items-center mt-16">
                <Button onClick={() => setStep(1)} className="bg-white" variant="tertiary" size="small">
                    Back
                </Button>
                <Button onClick={() => setStep(3)} size="small">
                    Next
                </Button>
            </div>
        </div>
    );
}

export default AttesterInputStepTwo;
