export const invitationMembers = [
    {
        name: 'Himanshu',
        citizenId: 'ndbhwh2wh',
    },
    {
        name: 'Nakul',
        citizenId: 'wgvdhhx76',
    },
    {
        name: 'Shubham',
        citizenId: 'nhbgswq43',
    },
    {
        name: 'Irthu',
        citizenId: 'xzadhydler',
    },
    {
        name: 'Devanshi',
        citizenId: 'yuhilatler',
    },
    {
        name: 'Faisal',
        citizenId: 'xcobledler',
    },
    {
        name: 'Aditi',
        citizenId: 'xfauujyer',
    },
    {
        name: 'Madhav',
        citizenId: 'xfa43dler',
    },
    {
        name: 'Mohan',
        citizenId: '23daudler',
    },
    {
        name: 'Pratham',
        citizenId: 'xfaudler',
    },
];
