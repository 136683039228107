// function LinkRenderer({ text, links, dummyText = 'your bounty description appears here' }) {
//     // Split the input text into an array of words
//     const words = text.split(' ');

//     // Function to check if a word is within the range of a link
//     const getLinkedWords = (wordIndex) => {
//         const linkObj = links.find((link) => wordIndex >= link.startIndex && wordIndex <= link.endIndex);
//         return linkObj ? linkObj.link : null;
//     };

//     return text.length > 0 ? (
//         <p className="text-xs  mt-2 break-words ">
//             {words.map((word, index) => {
//                 // Check if this word has a link
//                 const link = getLinkedWords(index);

//                 if (link) {
//                     // If the word has a link, render it inside an <a> tag
//                     return (
//                         <a
//                             key={index}
//                             href={link}
//                             target="_blank"
//                             rel="noopener noreferrer"
//                             className="text-blue-600 underline"
//                         >
//                             {word}{' '}
//                         </a>
//                     );
//                 }

//                 // Otherwise, render the word as normal text
//                 return <span key={index}>{word} </span>;
//             })}
//         </p>
//     ) : (
//         <p className="text-xs  mt-2 break-words ">{dummyText}</p>
//     );
// }

// export default LinkRenderer;
function LinkRenderer({ text, links, dummyText = 'your bounty description appears here' }) {
    // Split the input text into paragraphs by double newlines first
    const paragraphs = text?.split('\n\n');

    // Function to check if a word is within the range of a link
    const getLinkedWords = (wordIndex) => {
        const linkObj = links?.find((link) => wordIndex >= link.startIndex && wordIndex <= link.endIndex);
        return linkObj ? linkObj.link : null;
    };

    let globalWordIndex = 0; // Global word index to track position across paragraphs and lines

    return text?.length > 0 ? (
        <div className="text-xs mt-2 break-words">
            {paragraphs?.map((paragraph, paragraphIndex) => {
                // Split each paragraph into lines, using single newline as line breaks within paragraphs
                const lines = paragraph?.split('\n');

                return (
                    <div key={paragraphIndex} className="mb-2">
                        {lines.map((line, lineIndex) => (
                            <p key={`${paragraphIndex}-${lineIndex}`}>
                                {line.split(' ').map((word, index) => {
                                    const link = getLinkedWords(globalWordIndex);
                                    globalWordIndex++; // Increment global index after each word

                                    if (link) {
                                        // If the word has a link, render it inside an <a> tag
                                        return (
                                            <a
                                                key={index}
                                                href={link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-blue-600 underline"
                                            >
                                                {word}{' '}
                                            </a>
                                        );
                                    }

                                    // Otherwise, render the word as normal text
                                    return <span key={index}>{word} </span>;
                                })}
                            </p>
                        ))}
                    </div>
                );
            })}
        </div>
    ) : (
        <p className="text-xs mt-2 break-words">{dummyText}</p>
    );
}

export default LinkRenderer;
