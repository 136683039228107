import { useContext } from 'react';
import { BountiesContext } from '../../../contexts/BountiesContext';
import { useBountyFields } from '../../../hooks/useBountyFields';
import mobileFrame from '../../../assets/mobileFrame.png';
import Heading from '../../../ui/Heading';
import SubHeading from '../../../ui/SubHeading';
import { CalendarIcon } from '../../../assets/icons/CalendarIcon';
import { ClockIcon } from '../../../assets/icons/ClockIcon';
import Button from '../../../ui/Button';
import Checkbox from '../../../ui/Checkbox';
import LeftPreviewArrow from '../../../assets/icons/LeftPreviewArrow';
function PreviewMultiSelectMCQ({ data }) {
    const { state } = useContext(BountiesContext);
    const { titleField } = useBountyFields();

    return (
        <div
            className="relative w-[300px] h-[610px]     "
            style={{
                backgroundImage: `url(${mobileFrame})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <main className="absolute  top-28 left-6 right-6 ">
                <div className="mt-4">
                    <Heading type={'xsmall'}>{data.multiSelect_title || 'Title'}</Heading>
                    <SubHeading className={'!text-xs font-semibold mt-2'} type="xsmall">
                        {data.multiSelect_question || 'Question'}
                    </SubHeading>
                </div>
                <ul>
                    {data?.multiSelect_answers
                        ?.filter((i) => i.answerName)
                        .map((answer) => {
                            return (
                                <li className="flex items-center text-[10px] gap-2 border-b border-borderColor py-3">
                                    <input className={`  w-3 h-3   accent-blue-500  `} type="checkbox" />
                                    <span> {answer.answerName}</span>
                                </li>
                            );
                        })}
                </ul>
            </main>

            <footer className="absolute bottom-6 left-6 right-6">
                <div className="flex mb-2 items-center justify-between">
                    <Button
                        className=" flex !text-[#1A56DB] text-xs items-center justify-center gap-1 text-end"
                        size="none"
                        variant="transparent"
                    >
                        <span>{'<-'}</span> <span> Back</span>
                    </Button>
                    {data.skippable && (
                        <Button className=" !text-[#1A56DB] text-xs  text-end" size="none" variant="transparent">
                            Skip {'->'}
                        </Button>
                    )}
                </div>
                <Button variant="miner" size="largeMiner" className="py-2 text-xs " radius="rounded-full">
                    Next
                </Button>
            </footer>
        </div>
    );
}

export default PreviewMultiSelectMCQ;
