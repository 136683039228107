import React from 'react';
import { extractVideoId } from '../../../helpers/extractVideoId';

const WatchYoutubeVideoSubDetails = ({ data }) => {
    const { action } = data;

    const titleObject = action.find((obj) => obj.name === 'Title');
    const descriptionObject = action.find((obj) => obj.name === 'Description');
    const linkObject = action.find((obj) => obj.name === 'Link');

    const videoId = extractVideoId(linkObject?.value);

    return (
        <>
            <p className="flex gap-1 text-xs text-secondaryInput mb-2  ">
                <span>Response type : </span>{' '}
                <span className="font-bold capitalize "> {data?.label?.split('_')?.join(' ')?.toLowerCase()}</span>
            </p>
            <p className="font-semibold capitalize text-primaryInput mb-2">
                <span>{titleObject?.value}</span>
            </p>
            <p className="text-sm capitalize text-primaryInput mb-2">
                <span>{descriptionObject?.value}</span>
            </p>
            <div className=" mb-2" key={videoId}>
                <div className="w-full rounded-xl overflow-hidden">
                    <iframe
                        className="w-full h-52"
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        title="YouTube Video"
                        frame-border="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </>
    );
};

export default WatchYoutubeVideoSubDetails;
