import React from 'react';

const LinkifiedMessage = ({ message, links }) => {
    if (!message) return null; // If no message, return nothing

    // Split the message into words
    const words = message.split(' ');

    // Parse links
    const parsedLinks = links
        ? links.split(',').map((link) => {
              const [startIdx, endIdx, rawUrl] = link.split('~');
              const url =
                  rawUrl?.startsWith('http://') || rawUrl?.startsWith('https://') ? rawUrl : `https://${rawUrl}`;
              return {
                  start: parseInt(startIdx, 10),
                  end: parseInt(endIdx, 10),
                  url,
              };
          })
        : [];

    // Render the message with links
    return (
        <p>
            {words.map((word, idx) => {
                const link = parsedLinks.find((l) => idx >= l.start && idx <= l.end);

                // Render linked words
                if (link && idx === link.start) {
                    const linkedText = words.slice(link.start, link.end + 1).join(' ');
                    return (
                        <a
                            key={idx}
                            href={link.url}
                            className="text-foundryBlue cursor-pointer underline"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {linkedText}{' '}
                        </a>
                    );
                }

                // Skip words already covered in a link
                if (link && idx > link.start && idx <= link.end) {
                    return null;
                }

                // Render non-linked words
                return <span key={idx}>{word} </span>;
            })}
        </p>
    );
};

export default LinkifiedMessage;
