import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { toast } from 'sonner';

const StyledWalletButton = ({ widthPer, customStyles, backgroundColor = '#5029E9' }) => {
    const { publicKey, disconnect } = useWallet();
    const { user } = useContext(AuthContext)?.state;
    const userProfile = user[0];

    const linkedWallet = userProfile?.walletAddress;

    useEffect(() => {
        if (publicKey && linkedWallet) {
            const connectingWallet = publicKey.toBase58();

            // Compare addresses
            if (connectingWallet !== linkedWallet) {
                // Show toast message if wallet does not match
                toast.error('Please try connecting the wallet with which you created this profile.');

                // Disconnect the wallet
                disconnect();
            }
        }
    }, [publicKey, linkedWallet, disconnect]);

    const handlePhantomCheck = () => {
        // Check if Phantom Wallet is installed
        if (!window.solana || !window.solana.isPhantom) {
            // Open Phantom installation page in a new tab
            window.open('https://phantom.app/', '_blank');
        } else {
            // Trigger WalletMultiButton functionality
            const button = document.querySelector('.wallet-adapter-button');
            if (button) button.click();
        }
    };

    return (
        <div
            onClick={handlePhantomCheck}
            style={{
                width: widthPer,
                backgroundColor: backgroundColor,
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                justifySelf: 'start',
                cursor: 'pointer',
                ...customStyles,
            }}
        >
            <WalletMultiButton style={{ width: '100%', background: 'transparent', boxShadow: 'none' }} />
        </div>
    );
};

export default function WalletConnectRedirect({ widthPer, backgroundColor, customStyles }) {
    return <StyledWalletButton widthPer={widthPer} backgroundColor={backgroundColor} customStyles={customStyles} />;
}
