import axios from 'axios';
import { BASE_URL } from '../../constants/apiPaths';

export const sendOtpEmail = async (email) => {
    console.log({ email });
    try {
        const url = `${BASE_URL}/auth/send-otp-email`;
        console.log('url', url);
        const headers = {
            'Content-Type': 'application/json', // Assuming you're sending JSON data
            // Add any other headers you need here
        };

        const payload = {
            emailID: email,
        };
        // console.log('token',accessToken)
        const response = await axios.post(url, payload, { headers: headers });
        // console.log('came here',response)
        // Check the response status

        if (response.status === 200 || response.status == 201) {
            return response.data;
        } else {
            console.log('HTTP status code:', response.status);

            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        console.log({ error: error?.message });
        console.log({ error1: error?.data });
        console.log({ error2: error?.message?.response });
        return error;
    }
};
export const verifyOtpEmail = async (email, otp, otpToken) => {
    try {
        const url = `${BASE_URL}/auth/verify-email-otp`;
        console.log('url', url);
        // console.log('token',accessToken)
        const response = await axios.post(url, {
            email: email,
            otp: otp,
            otpToken: otpToken,
        });
        // console.log('came here',response)
        // Check the response status
        if (response.status === 200 || response.status == 201) {
            return response.data;
        } else {
            console.log('HTTP status code:', response.status);
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error.data;
    }
};
