import TabSwitching from '../ui/TabSwitching';
import { useActive } from '../hooks/useActive';
import ProposalsCreatedByTreasurer from '../features/treasurer/proposals/ProposalsCreatedByTreasurer';
import RequestSentForTreasurerApproval from '../features/treasurer/requests/RequestsSentForTreasurerApproval';
import { useOutletContext, useSearchParams } from 'react-router-dom';

function TreasurerRequestsAndProposals({ screen }) {
    const [searchParams] = useSearchParams();
    const forScreen = searchParams.get('for');
    const [activeTab, handleActiveTab] = useActive(() => forScreen || screen);

    const [particularOrg] = useOutletContext();

    return (
        <div>
            <div className="flex gap-10 mt-6 ">
                <TabSwitching
                    activeTab={activeTab}
                    onSelectingActive={handleActiveTab}
                    firstTab={'Requests'}
                    secondTab={'Proposals'}
                    activeStyling={'text-primaryColor border-b-2 border-primaryColor '}
                    className={'text-xl font-medium pb-2'}
                />
            </div>
            {activeTab.toLowerCase() === 'requests' && (
                <RequestSentForTreasurerApproval organisation={particularOrg} screen="bounty creation" />
            )}
            {activeTab.toLowerCase() === 'proposals' && <ProposalsCreatedByTreasurer organisation={particularOrg} />}
        </div>
    );
}

export default TreasurerRequestsAndProposals;
