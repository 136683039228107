import { useOutletContext } from 'react-router-dom';

import TextWithTooltip from '../../../ui/TextWithTooltip';
import CategoryWiseSuperpowers from './CategoryWiseSuperpowers';
import Loader from '../../../ui/Loader';

function SuperPowers({ powers, setPowers, categories }) {
    const { selectedPowers, setSelectedPowers, loading } = useOutletContext();
    return (
        <div className="overflow-y-scroll h-[92%]   main">
            <div className="mb-8">
                <TextWithTooltip
                    infoColor="#5B6677"
                    hoverOverText={'Superpowers'}
                    expandedTextWidth="w-[332px]"
                    className="!text-base"
                    hoverOverTextColor="text-[#262626]"
                >
                    <p className="text-[#404040]">
                        Superpowers are the skills you are good at. Setup your superpowers accurately to get improved
                        matching with earning opportunities.
                    </p>
                </TextWithTooltip>
            </div>

            <div className="pb-8 ">
                {loading ? (
                    <div className="w-full  h-[76vh] flex items-center justify-center">
                        <Loader />
                    </div>
                ) : (
                    powers.map((power) => {
                        return (
                            <CategoryWiseSuperpowers
                                selectedPowers={selectedPowers}
                                selectionFn={(item) => {
                                    if (selectedPowers.includes(item)) {
                                        const filteredPowers = selectedPowers.filter((power) => power !== item);

                                        setSelectedPowers(filteredPowers);
                                        return;
                                    }

                                    setSelectedPowers((prev) => [...prev, item]);
                                }}
                                powersObject={power}
                            />
                        );
                    })
                )}
            </div>
        </div>
    );
}
export default SuperPowers;
