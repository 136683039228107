function MembersAndRolesIcon({ fillColor = '#585757' }) {
    return (
        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.399 7.9477C7.21126 7.93916 7.02351 7.93063 6.82722 7.93063C4.76199 7.93063 2.8333 8.50241 1.18623 9.48382C0.435236 9.92759 0 10.7639 0 11.6429V13.051C0 13.5204 0.384031 13.9045 0.853403 13.9045H7.90251C7.22832 12.9401 6.82722 11.7624 6.82722 10.4908C6.82722 9.5777 7.04058 8.72429 7.399 7.9477Z"
                fill={fillColor}
            />
            <path
                d="M6.82722 7.07723C8.71251 7.07723 10.2408 5.5489 10.2408 3.66361C10.2408 1.77833 8.71251 0.25 6.82722 0.25C4.94194 0.25 3.41361 1.77833 3.41361 3.66361C3.41361 5.5489 4.94194 7.07723 6.82722 7.07723Z"
                fill={fillColor}
            />
            <path
                d="M16.0013 10.4908C16.0013 10.3031 15.9757 10.1324 15.9501 9.95319L16.667 9.33021C16.8206 9.19367 16.8547 8.97178 16.7523 8.79257L16.2488 7.92209C16.1464 7.74288 15.933 7.66607 15.7453 7.73435L14.8407 8.04157C14.5676 7.81115 14.2604 7.63194 13.919 7.50393L13.7313 6.57372C13.6886 6.37743 13.5179 6.23236 13.3131 6.23236H12.3061C12.1013 6.23236 11.9306 6.37743 11.8879 6.57372L11.7002 7.50393C11.3588 7.63194 11.0516 7.81115 10.7785 8.04157L9.87387 7.73435C9.67759 7.66607 9.47277 7.75141 9.37037 7.92209L8.86686 8.79257C8.76445 8.97178 8.79859 9.19367 8.9522 9.33021L9.66906 9.95319C9.64345 10.1324 9.61785 10.3031 9.61785 10.4908C9.61785 10.6786 9.64345 10.8493 9.66906 11.0285L8.9522 11.6515C8.79859 11.788 8.76445 12.0099 8.86686 12.1891L9.37037 13.0596C9.47277 13.2388 9.68612 13.3156 9.87387 13.2473L10.7785 12.9401C11.0516 13.1705 11.3588 13.3497 11.7002 13.4777L11.8879 14.408C11.9306 14.6042 12.1013 14.7493 12.3061 14.7493H13.3131C13.5179 14.7493 13.6886 14.6042 13.7313 14.408L13.919 13.4777C14.2604 13.3497 14.5676 13.1705 14.8407 12.9401L15.7453 13.2473C15.9416 13.3156 16.1464 13.2303 16.2488 13.0596L16.7523 12.1891C16.8547 12.0099 16.8206 11.788 16.667 11.6515L15.9501 11.0285C15.9757 10.8493 16.0013 10.6786 16.0013 10.4908ZM12.801 12.1976C11.8623 12.1976 11.0942 11.4296 11.0942 10.4908C11.0942 9.55209 11.8623 8.78403 12.801 8.78403C13.7398 8.78403 14.5079 9.55209 14.5079 10.4908C14.5079 11.4296 13.7398 12.1976 12.801 12.1976Z"
                fill={fillColor}
            />
        </svg>
    );
}

export default MembersAndRolesIcon;
