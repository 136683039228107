function RequestTreasuryMenuIcon() {
    return (
        <svg width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 12V14H6V12C6 12 6 8 12 8C18 8 18 12 18 12ZM15 3C15 2.40666 14.8241 1.82664 14.4944 1.33329C14.1648 0.839943 13.6962 0.455426 13.1481 0.228363C12.5999 0.00129986 11.9967 -0.0581102 11.4147 0.0576455C10.8328 0.173401 10.2982 0.459123 9.87868 0.878681C9.45912 1.29824 9.1734 1.83279 9.05764 2.41473C8.94189 2.99667 9.0013 3.59987 9.22836 4.14805C9.45542 4.69623 9.83994 5.16477 10.3333 5.49441C10.8266 5.82405 11.4067 6 12 6C12.7956 6 13.5587 5.68393 14.1213 5.12132C14.6839 4.55871 15 3.79565 15 3ZM18.2 8.06C18.7466 8.5643 19.1873 9.17244 19.4964 9.84891C19.8054 10.5254 19.9766 11.2566 20 12V14H23V12C23 12 23 8.55 18.2 8.06ZM17 1.1415e-06C16.6979 1.87014e-05 16.3976 0.0472552 16.11 0.140001C16.6951 0.97897 17.0087 1.97718 17.0087 3C17.0087 4.02282 16.6951 5.02103 16.11 5.86C16.3976 5.95275 16.6979 5.99998 17 6C17.7956 6 18.5587 5.68393 19.1213 5.12132C19.6839 4.55871 20 3.79565 20 3C20 2.20435 19.6839 1.44129 19.1213 0.878681C18.5587 0.316072 17.7956 1.1415e-06 17 1.1415e-06ZM6.34 3.92L7.5 5.33L2.75 10.08L0 7.08L1.16 5.92L2.75 7.5L6.34 3.92Z"
                fill="#5E5E5E"
            />
        </svg>
    );
}

export default RequestTreasuryMenuIcon;
