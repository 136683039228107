import { useOutletContext } from 'react-router-dom';
import TreasuryBullet from '../../../assets/icons/TreasuryBullet';
import Button from '../../../ui/Button';
import Heading from '../../../ui/Heading';
import ImpactFields from '../../../ui/ImpactFields';
import PointsWithGrayText from '../../../ui/PointsWithGrayText';
import WhiteCard from '../../../ui/WhiteCard';
import Loader from '../../../ui/Loader';
import { SDG_IMAGES } from '../../../constants/datas';
import TrophyIcon from '../../../assets/icons/TrophyIcon';

function RightSectionForExternalUser({ fundsAllocated }) {
    if (!Object.keys(fundsAllocated).length) return <Loader />;
    const { totalFundAllocationUSD, impactRank, impactPoints, allocation } = fundsAllocated;

    const coreWiseDistribution = allocation?.map((obj) => ({ ...obj, impactPoints: obj?.allocationUSD }));
    return (
        <div className="pt-7">
            <WhiteCard className="py-3 px-6 ">
                <div className="flex items-center border-b  border-dashed border-borderColor pb-2 justify-between">
                    <Heading className={'text-lightBlack leading-[58px]  '} type="pageHeading">
                        Org Impact
                    </Heading>
                    {/* <div className="flex py-6  border-b border-borderColor justify-between">
                            <PointsWithGrayText
                                text="Impact so far in $"
                                sign="$"
                                points={totalFundAllocationUSD}
                                signClass="text-base"
                                pointsClass="text-2xl"
                                textClass="text-sm text-secondaryText"
                            />
                            <PointsWithGrayText
                                text="Impact Points"
                                sign="IP"
                                points={impactPoints}
                                signClass="text-base"
                                pointsClass="text-2xl"
                                textClass="text-sm text-secondaryText"
                            />
                            <PointsWithGrayText
                                text="Impact Ranking"
                                sign="Rank"
                                points={impactRank}
                                signClass="text-base"
                                pointsClass="text-2xl"
                                textClass="text-sm text-secondaryText"
                            />
                        </div> */}
                    <Heading className={'text-lightBlack leading-[58px]  '} type="pageHeading">
                        ${fundsAllocated?.totalFundAllocationUSD}
                    </Heading>
                </div>
                <div className="flex py-6 justify-between  border-b border-borderColor gap-12">
                    <div className="flex flex-col  gap-1 ">
                        <p className={`text-sm text-secondaryText`}>SDGs:</p>

                        <div className="mb-5">
                            <div className="grid grid-cols-3 gap-1">
                                {fundsAllocated.SDGs.map((sdg) => (
                                    <img className="w-14 h-14" src={SDG_IMAGES[sdg]} alt="" />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-1 items-end  ">
                        <p className={`text-sm text-secondaryText`}>Impact Points</p>
                        <div className="flex  gap-1 items-center  ">
                            <TrophyIcon />
                            <p className={`text-2xl text-lightBlack font-medium`}>{impactPoints}</p>
                        </div>
                    </div>
                </div>
                <ImpactFields className="  py-6" coreWiseDistribution={coreWiseDistribution} isDollar={true} />
            </WhiteCard>
        </div>
    );
}

export default RightSectionForExternalUser;
