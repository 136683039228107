import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import AvatarImage from '../ui/AvatarImage';
import Heading from '../ui/Heading';
import { FavouriteIcon } from '../assets/icons/SidebarIcons';
import { DataContext } from '../contexts/DataContext';

import FavouriteAdded from '../assets/icons/FavouriteAdded';
import {
    getOrganization,
    getOrganizationFundAllocation,
    getOrganizationMembers,
    getOrganizationStats,
    getRequest,
} from '../apis/oragnisations/organisation';
import Loader from '../ui/Loader';
import { AuthContext } from '../contexts/AuthContext';
import Modal from '../ui/Modal';
import Button from '../ui/Button';
import SendRequestToJoin from '../features/Organization/organizationhomepage/SendRequestToJoin';
import { getRoles } from '../helpers/getRoles';
import { useElementHider } from '../contexts/ElementHider';
import { useLoadingState } from '../hooks/useLoader';
import BackButton from '../assets/icons/BackButton';

function OrganizationHomePage() {
    const navigate = useNavigate();
    const {
        state: data,
        dispatch,
        addToFavouriteOrganizations,
        removingFromFavouriteOrganizations,
    } = useContext(DataContext);
    const [particularOrg, setParticularOrg] = useState({});
    const [isRequested, setIsRequested] = useState();
    const [stats, setStats] = useState({});
    const { loading, startLoading, stopLoading } = useLoadingState();
    const { hiderClass } = useElementHider();
    const [orgMembers, setOrgMembers] = useState([]);
    const [fundsAllocated, setFundsAllocated] = useState({});
    const { state } = useContext(AuthContext);
    const { user } = state;
    const userProfile = user[0];
    const userUID = userProfile.uid;
    const { favourite } = data;
    const { id } = useParams();
    const isFavourite = favourite.map((fav) => fav.id).includes(id);
    const location = useLocation();

    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    startLoading();
                    const [response1, response2, response3, response4, response5] = await Promise.all([
                        getOrganization(id),
                        getOrganizationMembers(id),
                        getRequest(id),
                        getOrganizationStats(id),
                        getOrganizationFundAllocation(id),
                    ]);

                    setParticularOrg(response1);
                    setOrgMembers((initial) => [...initial, ...response2]);
                    setStats(response4);
                    setFundsAllocated(response5);
                    if (response3.requestCreated === true) setIsRequested(true);
                    if (response3.requestCreated === false) setIsRequested(false);
                    stopLoading();
                } catch (error) {
                    console.error(error, 'erroe');
                }
            };
            fetchData();
        },
        [id]
    );

    const isMemberOfOrganisation = orgMembers.length && orgMembers?.find((member) => member?.uid === userUID);

    const roles = getRoles(isMemberOfOrganisation);

    const isPublicView = location.pathname?.includes('public-view');

    return (
        <div className="">
            {loading ? (
                <div className="min-h-[20vh] flex justify-center items-center">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className={` ${hiderClass ? 'hideOnSomePage' : 'flex items-center justify-between'}  `}>
                        <div className="flex-1 ">
                            <div className="flex gap-2 items-center">
                                <BackButton onClick={() => navigate(-1)} />
                                <div className={` flex  items-center flex-1`}>
                                    <AvatarImage w="w-[78px]" h="h-[78px]" url={particularOrg.logo} />

                                    <div className="ml-6 flex-1 flex items-center justify-between  w-full ">
                                        <div>
                                            <div className="flex gap-2   items-center">
                                                <Heading type={'large'}>{particularOrg.name} </Heading>
                                                <button
                                                    onClick={() => {
                                                        if (isFavourite) {
                                                            // dispatch({ type: 'organization/deleteFavourite', payload: dummyData[id] });
                                                            removingFromFavouriteOrganizations(id, particularOrg.name);
                                                        }
                                                        if (!isFavourite)
                                                            // dispatch({
                                                            //     type: 'organization/addFavourite',
                                                            //     payload: dummyData[id],
                                                            // });
                                                            addToFavouriteOrganizations(id, particularOrg.name);
                                                    }}
                                                >
                                                    {isFavourite ? (
                                                        <FavouriteAdded />
                                                    ) : (
                                                        <FavouriteIcon fillColor={'#585757'} />
                                                    )}
                                                </button>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <p>{particularOrg.tagline}</p>
                                            </div>
                                        </div>

                                        {!roles.length && (
                                            <div>
                                                {!isRequested ? (
                                                    <Modal>
                                                        <Modal.Button
                                                            className="flex gap-2 cursor-pointer items-center"
                                                            opens={'joinrequest'}
                                                        >
                                                            <Button size="xsmall"> Request To Join</Button>
                                                        </Modal.Button>
                                                        <Modal.Window name={'joinrequest'}>
                                                            <SendRequestToJoin setIsRequested={setIsRequested} />
                                                        </Modal.Window>
                                                    </Modal>
                                                ) : (
                                                    <Button size="xsmall"> Requested</Button>
                                                )}
                                            </div>
                                        )}
                                        {roles.includes('isAdmin') && (
                                            <>
                                                {isPublicView ? (
                                                    <Button
                                                        onClick={() => navigate('edit-org')}
                                                        className=" w-44 text-end"
                                                        size="none"
                                                        variant="transparent"
                                                    >
                                                        Edit Details {'->'}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        onClick={() => navigate('public-view')}
                                                        className=" w-44 text-end"
                                                        size="none"
                                                        variant="transparent"
                                                    >
                                                        View Public Profile {'->'}
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Outlet context={[particularOrg, orgMembers, roles, stats, fundsAllocated, setParticularOrg]} />
                </>
            )}
        </div>
    );
}

export default OrganizationHomePage;
