import Input from '../../ui/Input';
import InputForDragAndDrop from '../../ui/InputForDragAndDrop';

function RankBasedDistributions({ register, ranksField, control, errors }) {
    return (
        <div className="">
            {ranksField.map((rankField, index) => {
                return (
                    <div
                        key={rankField.id}
                        // draggable
                        // onDragStart={() => handleDragStart(index)}
                        // onDragEnter={() => handleDragEnter(index)}
                        // onDragOver={(e) => e.preventDefault()}
                        // onDrop={() => handleDrop(index)}
                        // onDragEnd={handleDragEnd}
                        className="flex  gap-3   mb-3 w-full"
                    >
                        <p className="relative top-3">Rank #{index + 1} </p>
                        <div className="flex flex-col">
                            <InputForDragAndDrop
                                name={`ranks.${index}.amount`}
                                control={control}
                                index={index}
                                type="number"
                                width="w-44"
                                placeholder={`Amount for Rank`}
                                error={errors?.ranks ? errors?.ranks[index]?.amount?.message : ''}
                                validateObject={{
                                    required: 'Please enter an amount',
                                }}
                            />
                        </div>
                        {/* <DeleteIcon onClick={() => remove(index)} /> */}
                    </div>
                );
            })}
        </div>
    );
}

export default RankBasedDistributions;
