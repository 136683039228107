import { cloneElement, createContext, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const ModalContext = createContext();

function Modal({ children }) {
    const [open, setOpen] = useState('');

    const close = () => setOpen('');
    return <ModalContext.Provider value={{ open, setOpen, close }}>{children}</ModalContext.Provider>;
}

function ModalButton({ children, opens, className = '' }) {
    const { setOpen } = useContext(ModalContext);

    return (
        <p
            onClick={() => {
                setOpen(opens);
            }}
            className={`${className}`}
        >
            {children}
        </p>
    );
}

function ModalWindow({ name, children }) {
    const { open, close } = useContext(ModalContext);

    if (open !== name) return null;

    return createPortal(cloneElement(children, { close }), document.body);
}

Modal.Button = ModalButton;
Modal.Window = ModalWindow;

export default Modal;
