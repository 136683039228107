import { useEffect, useState } from 'react';
import { getOrganisationApplications, getOrganisations } from '../../apis/oragnisations/organisation';
import SubHeading from '../../ui/SubHeading';
import SummaryCard from '../../ui/SummaryCard';
import Loader from '../../ui/Loader';
import { useNavigate } from 'react-router-dom';
import NoDataAvailable from '../../ui/NoDataAvailable';
import { useLoadingState } from '../../hooks/useLoader';
import WhiteCard from '../../ui/WhiteCard';
import { useParticularApplication } from './useParticularApplication';

function MyApplications() {
    const { applications, setApplications, particularApplication, setParticularApplication } =
        useParticularApplication();
    const navigate = useNavigate();
    const { loading, startLoading, stopLoading } = useLoadingState();

    const getParticularApplication = (index, from) => {
        if (from === 'summary') {
            setParticularApplication({ ...applications[index], status: 'EXECUTED' });
            return;
        }

        setParticularApplication(applications[index]);
    };

    useEffect(function () {
        const fetchData = async () => {
            try {
                // const data = await getExploreOrg(5);
                startLoading();
                const applicationData = await getOrganisationApplications();

                // setImpactOrganizations(data);
                setApplications(applicationData);
                stopLoading();
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    return (
        <div>
            <div className="flex justify-between items-center">
                <SubHeading type="large" className={'text-lightBlack'}>
                    My Applications
                </SubHeading>

                {applications.length > 0 && (
                    <p className="cursor-pointer underline" onClick={() => navigate('/myapplications')}>
                        View All
                    </p>
                )}
            </div>
            <div className={`grid grid-cols-1 `}>
                {loading ? (
                    <div className="h-40 col-span-2 bg-white flex items-center justify-center">
                        <Loader />
                    </div>
                ) : (
                    <WhiteCard verticalMargin="" className="mt-1 px-5 py-[18px] ">
                        {applications.length ? (
                            applications.slice(0, 3).map((organization, index) => (
                                <SummaryCard
                                    redirectTo={() => {
                                        getParticularApplication(index);
                                        // navigate(`/myapplications/${organization._id}?from=home`);
                                        setTimeout(
                                            () => navigate(`/myapplications/${organization._id}?from=home`),
                                            1000
                                        );
                                    }}
                                    className=""
                                    key={organization._id + index + Math.random()}
                                    organization={organization}
                                    onSettingApp={setApplications}
                                    application={organization}
                                    setParticularApplication={() => getParticularApplication(index, 'summary')}
                                />
                            ))
                        ) : (
                            <div className="h-auto min-h-40 flex items-center justify-center">
                                <NoDataAvailable marginTop="mt-8" imgClassName="w-36" className="mb-8" />
                            </div>
                        )}
                    </WhiteCard>
                )}
            </div>
        </div>
    );
}

export default MyApplications;

{
    /* <WhiteCard className="mt-1">
{isLoading ? (
    <div className="h-40 flex items-center justify-center">
        <Loader />
    </div>
) : (
    <>
        {organizations.length ? (
            organizations
                .slice(0, 3)
                .map((organization) => (
                    <SummaryCard
                        redirectTo={() => navigate(`/organizations/${organization._id}`)}
                        key={organization.name + organization.tagline}
                        organization={organization}
                    />
                ))
        ) : (
            <div className="h-auto min-h-40 flex items-center justify-center">
                <NoDataAvailable marginTop="mt-8" imgClassName="w-36" className="mb-8" />
            </div>
        )}
    </>
)}
</WhiteCard> */
}
