function GreenRight() {
    return (
        <>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="36" height="36" rx="18" fill="#E5F8EB" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.8333 10.5007H20.35C20 9.53398 19.0833 8.83398 18 8.83398C16.9167 8.83398 16 9.53398 15.65 10.5007H12.1667C11.25 10.5007 10.5 11.2507 10.5 12.1673V23.834C10.5 24.7507 11.25 25.5007 12.1667 25.5007H23.8333C24.75 25.5007 25.5 24.7507 25.5 23.834V12.1673C25.5 11.2507 24.75 10.5007 23.8333 10.5007ZM18 10.5007C18.4583 10.5007 18.8333 10.8757 18.8333 11.334C18.8333 11.7923 18.4583 12.1673 18 12.1673C17.5417 12.1673 17.1667 11.7923 17.1667 11.334C17.1667 10.8757 17.5417 10.5007 18 10.5007ZM15.7417 21.5757L13.5833 19.4173C13.2583 19.0923 13.2583 18.5673 13.5833 18.2423C13.9083 17.9173 14.4333 17.9173 14.7583 18.2423L16.3333 19.809L21.2333 14.909C21.5583 14.584 22.0833 14.584 22.4083 14.909C22.7333 15.234 22.7333 15.759 22.4083 16.084L16.9167 21.5757C16.6 21.9007 16.0667 21.9007 15.7417 21.5757Z"
                    fill="#00BA34"
                />
            </svg>
        </>
    );
}

export default GreenRight;
