import InviteMembers from '../features/members/InviteMembers';

function AddMember() {
    return (
        <div className="pt-7">
            <InviteMembers />
        </div>
    );
}

export default AddMember;
