import { useState } from 'react';
import { useLoadingState } from '../../hooks/useLoader';
import { useNavigate, useParams } from 'react-router-dom';
import Menus from '../../ui/Menus';
import DeleteAllNotificationsModal from './DeleteAllNotificationModal';
import ReadAllNotificationsModal from './ReadAllNotificationModal';
import DuplicateMenuIcon from '../../assets/icons/DuplicateMenuIcon';
import { ToDelete } from '../../assets/icons/IconsForNotifications';

function DeleteAllNotifications() {
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [isModalOpen, setModalOpen] = useState(false); // Modal visibility state
    const [modalAction, setModalAction] = useState(''); // Track action type: 'duplicate' or 'delete'

    const { id } = useParams();
    const navigate = useNavigate();

    const handleOpenModal = (action) => {
        setModalAction(action); // Set the action type ('duplicate' or 'delete')

        setModalOpen(true); // Open the modal
    };

    const handleCloseModal = () => {
        setModalOpen(false); // Close the modal
        setModalAction(''); // Clear action type
    };

    const handleConfirmAction = () => {
        if (modalAction === 'deleteAll') {
            console.log('deleting all');
            // dispatch delete all
        }
        if (modalAction === 'readAll') {
            console.log('reading all');
            // dispatch read all
        }

        handleCloseModal();
    };

    return (
        <>
            <Menus>
                <Menus.Toggle id={'deleteAllNotifications'} vertical={true} verticalOffset={25} horizontalOffest={10} />
                <Menus.List id={'deleteAllNotifications'}>
                    {/* <Menus.Button
                        shouldCloseMenu={true}
                        onClick={(fn) => {
                            handleOpenModal('readAll');
                            fn();
                        }}
                        icon={<DuplicateMenuIcon />}
                    >
                        Read All
                    </Menus.Button> */}
                    <Menus.Button
                        menuIconClassName="!w-fit"
                        shouldCloseMenu={true}
                        onClick={(fn) => {
                            handleOpenModal('deleteAll');
                            fn();
                        }}
                        icon={<ToDelete />}
                    >
                        Delete all notifications
                    </Menus.Button>
                </Menus.List>
            </Menus>
            {isModalOpen && (
                <div>
                    {/* {modalAction === 'readAll' && (
                        <ReadAllNotificationsModal handleConfirmAction={handleConfirmAction} close={handleCloseModal} />
                    )} */}
                    {modalAction === 'deleteAll' && (
                        <DeleteAllNotificationsModal onDelete={handleConfirmAction} close={handleCloseModal} />
                    )}
                </div>
            )}
        </>
    );
}

export default DeleteAllNotifications;
