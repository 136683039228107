function TabSwitching({
    firstTab,
    secondTab,
    thirdTab,
    fourthTab,
    onSelectingActive,
    activeTab,
    activeStyling,
    firstTabStyle,
    secondTabStyle,
    thirdTabStyle,
    fourthTabStyle,
    className,
}) {
    return (
        <>
            {firstTab && (
                <p
                    className={`${
                        activeTab?.toLowerCase() === firstTab?.toLowerCase() ? activeStyling : ''
                    } ${firstTabStyle} ${className} cursor-pointer `}
                    onClick={() => onSelectingActive(firstTab)}
                >
                    {firstTab}
                </p>
            )}
            {secondTab && (
                <p
                    className={`${
                        activeTab?.toLowerCase() === secondTab?.toLowerCase() ? activeStyling : ''
                    } ${secondTabStyle} ${className} cursor-pointer `}
                    onClick={() => onSelectingActive(secondTab)}
                >
                    {secondTab}
                </p>
            )}
            {thirdTab && (
                <p
                    className={`${
                        activeTab?.toLowerCase() === thirdTab?.toLowerCase() ? activeStyling : ''
                    } ${thirdTabStyle} ${className} cursor-pointer `}
                    onClick={() => onSelectingActive(thirdTab)}
                >
                    {thirdTab}
                </p>
            )}
            {fourthTab && (
                <p
                    className={`${
                        activeTab?.toLowerCase() === fourthTab?.toLowerCase() ? activeStyling : ''
                    } ${fourthTabStyle} ${className} cursor-pointer `}
                    onClick={() => onSelectingActive(fourthTab)}
                >
                    {fourthTab}
                </p>
            )}
        </>
    );
}

export default TabSwitching;
