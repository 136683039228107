import { useState } from 'react';
import Button from '../../ui/Button';
import Heading from '../../ui/Heading';
import Input from '../../ui/Input';
import DragAndDropIcon from '../../assets/icons/DragAndDropIcon';
import DeleteIcon from '../../assets/icons/Delete';
import InputForDragAndDrop from '../../ui/InputForDragAndDrop';
import { useStepTwoInputs } from './bountiesHooks/useStepTwoInputs';
import TextWithTooltip from '../../ui/TextWithTooltip';
import CharacterLimit from '../../ui/CharacterLimit';
import { useWatch } from 'react-hook-form';

function StepTwo({ setStep, register, control, errors }) {
    const { maxAcceptancesPerUserField, criteriaFields, append, remove, swap } = useStepTwoInputs(control);

    const [draggedIndex, setDraggedIndex] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const watchedCriteria = useWatch({ name: 'criteria', control });
    const handleDragStart = (index) => {
        setDraggedIndex(index);
        setIsDragging(true);
    };
    const handleDragEnter = (index) => {
        if (draggedIndex !== null && draggedIndex !== index) {
            // Update your state to reflect the new order visually, if necessary
        }
    };

    const handleDrop = (index) => {
        if (draggedIndex !== null) {
            swap(draggedIndex, index);
            setDraggedIndex(null);
            setIsDragging(false);
        }
    };

    const handleDragEnd = () => {
        setIsDragging(false);
        setDraggedIndex(null);
    };

    return (
        <div className="p-12 pb-0 shadow-md flex flex-col justify-between min-h-full   rounded-md  ">
            <div>
                <div className="">
                    <div className="flex gap-3">
                        <Heading type="xsmall" className="capitalize  text-primaryInput">
                            Terms and Conditions
                        </Heading>
                    </div>
                </div>
                <div className="my-6">
                    <div className="flex gap-3">
                        <TextWithTooltip
                            heading={
                                <Heading type="xsmall" className="capitalize  text-primaryInput">
                                    Maximum Attempts Per Bounty Hunter:
                                    <sup className="text-lg relative top-[1px]">*</sup>
                                </Heading>
                            }
                        >
                            <p className="text-sm">
                                This determines how many times the same user can attempt the bounty on Impact Miner.
                            </p>
                            <p className="text-sm mt-3">
                                It depends on what type of bounty it is. For ex, if a bounty requires the user to submit
                                an essay it could have less number of attempts but a bounty like taking surveys where
                                the user has to collect information multiple times could have more attempts
                            </p>
                        </TextWithTooltip>
                    </div>

                    <Input
                        {...maxAcceptancesPerUserField}
                        type="number"
                        step="0"
                        error={errors?.maxAcceptancesPerUser?.message}
                        className="my-2 w-[300px]"
                        placeholder="Enter number of attempts"
                    />
                </div>
                <div className="mt-6">
                    <div className="flex gap-3">
                        <Heading type="xsmall" className="capitalize  text-primaryInput">
                            List Of Criteria: <sup className="text-lg relative top-[1px]">*</sup>
                        </Heading>
                    </div>
                    <p className="text-sm text-secondaryInput">
                        Mention clearly the terms & conditions, instructions and criteria on who can participate, any
                        non-negotiable evaluation criteria for rewarding winners, disclaimers and any information
                        relevant. This information is displayed to the users on the Impact Miner
                    </p>
                </div>
                <div className="p-5">
                    <div className="  items-center">
                        {criteriaFields.map((criteriaField, index) => {
                            const criteriaNameLength = watchedCriteria?.[index]?.criteriaName?.length || 0; // Track length of criteriaName

                            return (
                                <>
                                    <div
                                        key={criteriaField.id}
                                        draggable
                                        onDragStart={() => handleDragStart(index)}
                                        onDragEnter={() => handleDragEnter(index)}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={() => handleDrop(index)}
                                        onDragEnd={handleDragEnd}
                                        className="flex  gap-3 mb-3 w-full"
                                    >
                                        <DragAndDropIcon className="relative top-3 cursor-pointer" />
                                        <div className="flex-1">
                                            <InputForDragAndDrop
                                                name={`criteria.${index}.criteriaName`}
                                                containerClass="flex-1"
                                                control={control}
                                                error={
                                                    errors?.criteria
                                                        ? errors?.criteria[index]?.criteriaName?.message
                                                        : ''
                                                }
                                                index={index}
                                                className="w-full "
                                                validateObject={{
                                                    required: 'Please enter a criteria',
                                                    maxLength: {
                                                        value: 200,
                                                        message: 'The maximum length is 200 characters',
                                                    },
                                                }}
                                            />
                                            <CharacterLimit min={criteriaNameLength} max={200} />
                                        </div>
                                        <DeleteIcon
                                            className="relative top-3 cursor-pointer"
                                            onClick={() => remove(index)}
                                        />
                                    </div>
                                </>
                            );
                        })}

                        <Button
                            type="button"
                            onClick={() => {
                                append({
                                    criteriaName: '',
                                });
                            }}
                            size="none"
                            className=" ml-5 font-semibold text-sm"
                            variant="transparent"
                        >
                            + Add Criteria
                        </Button>
                    </div>
                    <p className="text-foundryRed">{errors.criteria?.root?.message}</p>
                </div>
            </div>

            <div className="fixed bottom-10  z-[9999] w-[618px] mx-auto  ">
                <div className="flex justify-between items-center  ">
                    <Button
                        type="button"
                        onClick={() => setStep(1)}
                        className="bg-white"
                        variant="tertiary"
                        size="small"
                    >
                        Back
                    </Button>
                    <Button type="button" onClick={() => setStep(3)} size="small">
                        Next
                    </Button>
                </div>
            </div>
        </div>
    );
}

{
    /* <div className="flex items-center gap-2">
                        <BoldIcon />
                        <ItalicIcon />
                        <MakeHyperLink />
                    </div>
                    <div className="flex items-center gap-2">
                        <UndoIcon />
                        <RedoIcon />
                    </div> */
}

export default StepTwo;
