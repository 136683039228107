import { useWallet } from '@solana/wallet-adapter-react';
import WalletConnectButton from '../walletconnectUI/WalletConnectButton';
import Button from '../../ui/Button';
import { Connection, clusterApiUrl } from '@solana/web3.js';
import { verifyWallet } from '../../apis/auth/loginwithwallet';
import { signInWithCustomToken } from 'firebase/auth';
import { getProfile } from '../../apis/user/user';
import { useNavigate } from 'react-router-dom';
import { useLoadingState } from '../../hooks/useLoader';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import Loader from '../../ui/Loader';
import { auth } from '../../firebase';
import { toast } from 'sonner';

// import * as bs58 from 'bs58';

// Establish connection to the network
new Connection(clusterApiUrl('mainnet-beta'));

async function signMessageWithPhantom(message) {
    try {
        // Request Phantom wallet to sign the message
        // const selectedWallet = 'Phantom'; // Change this to the selected wallet
        // await connectToWallet(selectedWallet);

        const messageBytes = new TextEncoder().encode(message);

        const signedMessage = await window.solana.signMessage(messageBytes);

        return signedMessage.signature;
    } catch (error) {
        console.error('Error signing message:', error);
        return null;
    }
}
function LoginThroughWallet() {
    const { publicKey, disconnect } = useWallet();
    const { loading: requesting, startLoading: startRequesting, stopLoading: stopRequesting } = useLoadingState();
    const [noProfileError, setNoProfileError] = useState('');
    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();
    const address = publicKey?.toBase58();
    const timeStamp = new Date().toISOString();
    const handleSignInWithWalletAddress = async () => {
        const message = `impact foundry ${timeStamp}`;
        try {
            if (!address) {
                toast.error('Please connect your Phantom Wallet first. ');

                return;
            }
            const sign = await signMessageWithPhantom(message);
            const signatureArray = [...sign];
            startRequesting();
            const verified = await verifyWallet({
                walletAddress: address,
                timestamp: timeStamp,
                signature: signatureArray,
            });

            if (verified.code === 404) throw new Error(verified.message);
            if (verified.customToken && verified.customToken.length > 0) {
                await signInWithCustomToken(auth, verified.customToken)
                    .then(async (user) => {
                        stopRequesting();
                        const profile = await getProfile();

                        // if (profile.length) {
                        //     if (!profile[0].walletAddress) {
                        //         console.log('hit api');
                        //         const response = await updateProfile(address);
                        //         console.log('ho gayi update', response);
                        //         dispatch({ type: 'login', payload: profile });
                        //     } else {
                        //         dispatch({ type: 'login', payload: profile });
                        //     }
                        //     navigate('/', { replace: true });
                        // }
                        if (profile.length) {
                            dispatch({ type: 'login', payload: profile });
                            navigate('/', { replace: true });
                        }
                    })
                    .catch((error) => {
                        console.log('error tbhis?', error);
                    });
            }
        } catch (error) {
            stopRequesting();
            console.log('error connecting with wallet ', error);
            await disconnect();
            setNoProfileError(
                'No profile associated with this wallet address. Retry with another wallet or use email to sign up'
            );

            // toast.error(error);
        }
    };
    useEffect(
        function () {
            let timeout;

            if (noProfileError) {
                timeout = setTimeout(() => {
                    setNoProfileError('');
                }, 3000);
            }
            return () => {
                if (timeout) clearTimeout(timeout);
            };
        },
        [noProfileError]
    );
    useEffect(
        function () {
            if (address) {
                handleSignInWithWalletAddress();
            }
        },
        [address]
    );
    return (
        <div className="flex flex-col gap-2 ">
            <WalletConnectButton widthPer={'100%'} />
            <div className="w-full">
                <p className="text-foundryRed text-center text-sm font-medium">{noProfileError}</p>
            </div>
        </div>
    );
}

export default LoginThroughWallet;
