import nomember from '../../assets/nomemberselected.png';

function NoMemberAdded() {
    return (
        <div className="w-full flex flex-col rounded-lg shadow-sm items-center my-4 py-8 bg-white ">
            <img className="w-56 " src={nomember} alt="No Member added" />
            <p>Please add atleast one member to send invites</p>
        </div>
    );
}

export default NoMemberAdded;
