import { useState } from 'react';
import Heading from '../../../ui/Heading';
import Input from '../../../ui/Input';
import WhiteCard from '../../../ui/WhiteCard';
import { useGetCoordinates } from '../bountiesHooks/useGetCoordinates';
import MapWithSearch from '../SearchMap';

function GetCoordinates({ control, errors, core = 'Water', microIndex, setValue }) {
    // const { cityField, bountyRadiusField, locationField } = usePhysicalBountyInputs(control);
    const [hasStartedTyping, setHasStartedTyping] = useState(false);
    const { placeField, placeCoordsField, radiusField } = useGetCoordinates(control);
    const { coordinates } = placeCoordsField.value;
    // microtasks.${microIndex}.goToLocation_coordinate
    const handleCityFieldChange = (e) => {
        placeField.onChange(e.target.value);
        if (e.target.value.length > 0) {
            setHasStartedTyping(true); // Mark as typing when there is input
        } else {
            setHasStartedTyping(false); // Reset typing if input is cleared
        }
    };

    return core ? (
        <div className="my-4 ">
            <div>
                <p className="text-primaryInput text-sm mb-1">City/Place to visit ?</p>
                <Input
                    type="text"
                    value={placeField.value}
                    onChange={handleCityFieldChange}
                    error={errors?.place?.message}
                    placeholder={'Enter City...'}
                    className={'w-[100%]'}
                />
            </div>
            <div className=" my-2 overflow-hidden bg-white rounded-2xl  ">
                <MapWithSearch
                    core={core}
                    circleRadius={radiusField.value}
                    searchQuery={placeField.value}
                    setSearchText={placeField.onChange}
                    hasStartedTyping={hasStartedTyping}
                    setCoordinates={(value) => {
                        setValue(`microtasks.${microIndex}.goToLocation_coordinate`, value.coordinates.join(','));
                        placeCoordsField.onChange(value);
                    }}
                />
            </div>
            {/* <div className="flex items-center gap-2">
                <Input
                    type="text"
                    value={coordinates[0]}
                    // onChange={placeField.onChange}
                    // error={errors?.place?.message}
                    placeholder={'Add Latitude...'}
                    className={'w-[100%]'}
                />
                <Input
                    type="text"
                    value={coordinates[1]}
                    // onChange={placeField.onChange}
                    // error={errors?.place?.message}
                    placeholder={'Add Longitude...'}
                    className={'w-[100%]'}
                />
            </div> */}
            {/* <div className="mt-6">
                <Heading type="xsmall" className="capitalize mb-2 text-lightBlack">
                    Area radius (in Kms)
                </Heading>
                <Input
                    type="number"
                    value={radiusField.value}
                    onChange={radiusField.onChange}
                    error={errors?.radius?.message}
                    placeholder={'50'}
                    className={'w-[100%]'}
                />
            </div> */}
        </div>
    ) : null;
}

export default GetCoordinates;
