import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { toast } from 'sonner';

const StyledWalletButton = ({ widthPer, customStyles, backgroundColor = '#5029E9' }) => {
    const { publicKey, disconnect } = useWallet();
    const { user } = useContext(AuthContext)?.state;
    const userProfile = user[0];

    const linkedWallet = userProfile?.walletAddress;
    useEffect(() => {
        if (publicKey && linkedWallet) {
            const connectingWallet = publicKey.toBase58();

            // Compare addresses
            if (connectingWallet !== linkedWallet) {
                // Show toast message if wallet does not match
                toast.error('Please try connecting the wallet with which you created this profile. ');

                // Disconnect the wallet
                disconnect();
            }
        }
    }, [publicKey, linkedWallet, disconnect]);

    // const w = widthPer ? '80%' : '100%';
    return (
        <WalletMultiButton
            style={{
                width: widthPer,
                backgroundColor: backgroundColor,
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                justifySelf: 'center',
                ...customStyles,
            }}
        />
    );
};

export default function WalletConnectButton({ widthPer, backgroundColor, customStyles }) {
    return <StyledWalletButton widthPer={widthPer} backgroundColor={backgroundColor} customStyles={customStyles} />;
}
