// import React, { createContext, useEffect, useReducer } from 'react';
// import {
//     deleteFromFavouriteOrganizations,
//     getFavoriteOrganizations,
//     makeFavoriteOrganization,
// } from '../apis/oragnisations/organisation';
// import { getAccessToken } from '../firebase';

// const initialState = {
//     favourite: [],
// };
// function reducer(state, action) {
//     switch (action.type) {
//         case 'organization/setFavourite':
//             return {
//                 ...state,
//                 favourite: action.payload,
//             };

//         case 'organization/addFavourite':
//             return { ...state, favourite: [...state.favourite, action.payload] };

//         case 'organization/deleteFavourite':
//             const dataAfterDelete = state.favourite.filter((fav) => fav.id !== action.payload.id);
//             return { ...state, favourite: dataAfterDelete };

//         default:
//             return state;
//     }
// }

// export const DataContext = createContext();

// export const DataProvider = ({ children }) => {
//     const [state, dispatch] = useReducer(reducer, initialState);
//     useEffect(function () {
//         const fetchData = async () => {
//             try {
//                 const token = await getAccessToken();
//                 console.log(token, 'see which token 2');
//                 const data = await getFavoriteOrganizations();
//                 console.log(data, 'is it run on initial login');
//                 const setData = data.map((fav) => ({ id: fav.id, name: fav.name }));
//                 dispatch({ type: 'organization/setFavourite', payload: setData });
//             } catch (error) {
//                 console.log(error);
//             }
//         };
//         fetchData();
//     }, []);

//     const addToFavouriteOrganizations = async (id, name) => {
//         try {
//             const payload = state.favourite.length ? state?.favourite.map((fav) => fav.id) : [];

//             dispatch({ type: 'organization/addFavourite', payload: { id, name } });

//             const data = await makeFavoriteOrganization([...payload, id]);

//             // dispatch({ type: 'organization/setFavourite', payload: data });
//         } catch (error) {
//             console.log(error);
//         }
//     };

//     const removingFromFavouriteOrganizations = async (id, name) => {
//         try {
//             const payload = state.favourite.length ? state?.favourite.filter((fav) => fav.id !== id) : [];
//             dispatch({ type: 'organization/deleteFavourite', payload: { id, name } });
//             const payloadIds = payload.map((fav) => fav.id);

//             const data = await deleteFromFavouriteOrganizations(payloadIds);
//         } catch (error) {
//             console.log(error);
//         }
//     };
//     return (
//         <DataContext.Provider
//             value={{ state, dispatch, addToFavouriteOrganizations, removingFromFavouriteOrganizations }}
//         >
//             {children}
//         </DataContext.Provider>
//     );
// };

import { createContext, useEffect, useReducer, useContext } from 'react';
import {
    deleteFromFavouriteOrganizations,
    getFavoriteOrganizations,
    makeFavoriteOrganization,
} from '../apis/oragnisations/organisation';
import { getAccessToken } from '../firebase';
import { AuthContext } from './AuthContext'; // Import AuthContext to access userProfileUID

const initialState = {
    favourite: [],
};

function reducer(state, action) {
    switch (action.type) {
        case 'organization/setFavourite':
            return { ...state, favourite: action.payload };

        case 'organization/addFavourite':
            return { ...state, favourite: [...state.favourite, action.payload] };

        case 'organization/deleteFavourite':
            const dataAfterDelete = state.favourite.filter((fav) => fav.id !== action.payload.id);
            return { ...state, favourite: dataAfterDelete };

        default:
            return state;
    }
}

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const { state: authState } = useContext(AuthContext); // Access userProfileUID from AuthContext
    const [state, dispatch] = useReducer(reducer, initialState);
    const { user } = authState;
    const userProfileUID = user[0]?.uid;

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (userProfileUID) {
                    // Only fetch if there's a current user
                    const token = await getAccessToken();
                    if (token) {
                        const data = await getFavoriteOrganizations();
                        const setData = data.map((fav) => ({ id: fav.id, name: fav.name }));
                        dispatch({ type: 'organization/setFavourite', payload: setData });
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData(); // Call fetchData whenever userProfileUID changes
    }, [userProfileUID]); // Re-run when userProfileUID changes to fetch new user's favorite organizations

    const addToFavouriteOrganizations = async (id, name) => {
        try {
            const payload = state.favourite.map((fav) => fav.id);
            dispatch({ type: 'organization/addFavourite', payload: { id, name } });
            await makeFavoriteOrganization([...payload, id]);
        } catch (error) {
            console.log(error);
        }
    };

    const removingFromFavouriteOrganizations = async (id) => {
        try {
            dispatch({ type: 'organization/deleteFavourite', payload: { id } });
            const payloadIds = state.favourite.filter((fav) => fav.id !== id).map((fav) => fav.id);
            await deleteFromFavouriteOrganizations(payloadIds);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <DataContext.Provider
            value={{ state, dispatch, addToFavouriteOrganizations, removingFromFavouriteOrganizations }}
        >
            {children}
        </DataContext.Provider>
    );
};
