import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { getNotifications } from '../apis/notifications/notifications';

const initialState = {
    notifications: [],
};
function reducer(state, action) {
    switch (action.type) {
        case 'setNotifications':
            return { ...state, notifications: action.payload };

        case 'read':
            const afterreadunread = state.notifications.map((notif) => {
                if (notif.id === action.payload.notifId) return { ...notif, isRead: !action.payload.readStatus };
                return notif;
            });
            return { ...state, notifications: afterreadunread };
        case 'unread':
            const afterUnread = state.notifications.map((notif) => {
                if (notif.id === action.payload.notifId) return { ...notif, isRead: false };
                return notif;
            });
            return { ...state, notifications: afterUnread };
        default:
            return state;
    }
}
const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(function () {
        const fetchData = async () => {
            try {
                const data = await getNotifications();
                dispatch({ type: 'setNotifications', payload: data });
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);
    const onDelete = (id) => {
        const afterDelete = state.notifications.filter((notif) => notif.id !== id);

        dispatch({ type: 'setNotifications', payload: afterDelete });
    };
    const onDeleteAllNotifications = () => {
        dispatch({ type: 'setNotifications', payload: [] });
    };
    return (
        <NotificationsContext.Provider value={{ state, dispatch, onDelete, onDeleteAllNotifications }}>
            {children}
        </NotificationsContext.Provider>
    );
};

export const useNotifications = () => {
    return useContext(NotificationsContext);
};
