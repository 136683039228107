import { useContext } from 'react';
import { BountiesContext } from '../../../contexts/BountiesContext';
import { useBountyFields } from '../../../hooks/useBountyFields';
import mobileFrame from '../../../assets/mobileFrame.png';
import Heading from '../../../ui/Heading';
import SubHeading from '../../../ui/SubHeading';
import { CalendarIcon } from '../../../assets/icons/CalendarIcon';
import { ClockIcon } from '../../../assets/icons/ClockIcon';
import Button from '../../../ui/Button';
import LeftPreviewArrow from '../../../assets/icons/LeftPreviewArrow';
function PreviewMcq({ data }) {
    const { state } = useContext(BountiesContext);
    const { titleField } = useBountyFields();

    return (
        <div
            className="relative w-[300px] h-[610px]     "
            style={{
                backgroundImage: `url(${mobileFrame})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            {/* <header className="bg-previewDark absolute text-white  top-[3px] left-[8px] right-[8px] rounded-t-[44px] p-3   h-24  ">
                <div className="flex items-center justify-between px-3 ">
                    <p className="text-sm">9:41</p>
                    <div className="flex gap-1 ">
                        <Networks />
                        <Wifi />
                        <Battery />
                    </div>
                </div>
                <div className="flex gap-2 m-3  items-center justify-between ">
                    <PreviewBack />
                    <p className="flex-1">Bounty</p>
                    <ThreeVerticalDots />
                </div>
            </header> */}
            <main className="absolute  top-28 left-6 right-6 ">
                <div className="mt-4">
                    <Heading className={'text-minerTitle'} type={'xsmall'}>
                        {data.multiChoiceQuestions_Title || 'Title'}
                    </Heading>
                    <SubHeading className={'!text-xs text-minerDescription font-semibold mt-2'} type="xsmall">
                        {data.multiChoiceQuestions_question || 'Question'}
                    </SubHeading>
                </div>
                <ul>
                    {data?.multiChoiceQuestions_answers
                        ?.filter((i) => i.answer)
                        .map((answer) => {
                            return (
                                <li className="flex items-center text-minerSecondary text-xs gap-3 border-b border-borderColor py-3">
                                    <input className={` w-3 h-3  accent-blue-500  `} type="radio" />
                                    <span> {answer.answer}</span>
                                </li>
                            );
                        })}
                </ul>
                {/* <div className="mt-8">
                    <SubHeading className={'!text-xs italic mt-2'} type="xsmall">
                        {data.multiChoiceQuestions_instructionHeading || 'Instruction Heading'}
                    </SubHeading>
                </div> */}

                {/* <ul className="list-disc">
                    {data?.multiChoiceQuestions_instructions?.map((answer) => {
                        return (
                            <li className="flex  items-center gap-3 border-b border-borderColor py-3">
                                <span> {answer.instruction}</span>
                            </li>
                        );
                    })}
                </ul> */}
            </main>

            <footer className="absolute bottom-6 left-6 right-6">
                <div className="flex mb-2 items-center justify-between">
                    <Button
                        className=" flex !text-[#1A56DB] text-xs items-center justify-center gap-1 text-end"
                        size="none"
                        variant="transparent"
                    >
                        <span>{'<-'}</span> <span> Back</span>
                    </Button>
                    {data.skippable && (
                        <Button className=" !text-[#1A56DB] text-xs  text-end" size="none" variant="transparent">
                            Skip {'->'}
                        </Button>
                    )}
                </div>
                <Button variant="miner" size="largeMiner" className="py-2 text-xs " radius="rounded-full">
                    Next
                </Button>
            </footer>
        </div>
    );
}

export default PreviewMcq;
