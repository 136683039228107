function ProjectsIcon({ fillColor }) {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.94618 3.56936C4.20523 5.52365 3.01047 8.33135 2.89953 8.5959L0.970835 7.7681C0.416123 7.52915 0.279578 6.80376 0.70628 6.37705L3.27502 3.80831C3.67612 3.40721 4.25644 3.228 4.81968 3.33894L5.94618 3.56936ZM7.21775 12.2399C7.47377 12.496 7.84926 12.5642 8.17356 12.4106C9.16351 11.9498 11.2885 10.866 12.6625 9.49198C16.5796 5.57485 16.6137 2.38313 16.3833 1.01768C16.3236 0.676321 16.0505 0.403232 15.7091 0.343494C14.3437 0.113075 11.1519 0.147211 7.23481 4.06433C5.86083 5.43831 4.78555 7.56328 4.31618 8.55323C4.16256 8.87753 4.23937 9.26156 4.48686 9.50904L7.21775 12.2399ZM13.166 10.7891C11.2117 12.5301 8.40398 13.7249 8.13942 13.8358L8.96722 15.7645C9.20617 16.3192 9.93157 16.4557 10.3583 16.029L12.927 13.4603C13.3281 13.0592 13.5073 12.4789 13.3964 11.9156L13.166 10.7891ZM5.73282 13.008C5.9035 13.9126 5.60481 14.7489 5.03303 15.3207C4.37591 15.9778 2.33628 16.4643 1.01351 16.7203C0.424657 16.8312 -0.0959189 16.3107 0.0150235 15.7218C0.271044 14.399 0.74895 12.3594 1.4146 11.7023C1.98638 11.1305 2.82272 10.8318 3.72733 11.0025C4.72581 11.1902 5.54508 12.0095 5.73282 13.008ZM9.19764 5.83088C9.19764 4.89213 9.9657 4.12407 10.9044 4.12407C11.8432 4.12407 12.6113 4.89213 12.6113 5.83088C12.6113 6.76962 11.8432 7.53768 10.9044 7.53768C9.9657 7.53768 9.19764 6.76962 9.19764 5.83088Z"
                fill={fillColor}
            />
        </svg>
    );
}

export default ProjectsIcon;
