function PointsWithGrayText({ text = '', sign = '', points = '', signClass = '', pointsClass = '', textClass = '' }) {
    return (
        <div className="flex flex-col gap-1 items-end">
            <p className={`${textClass}`}>{text}</p>
            <div className="flex text-lightBlack gap-1  font-medium ">
                <p className={`${signClass}`}>{sign}</p>
                <p className={`${pointsClass}`}>{points}</p>
            </div>
        </div>
    );
}

export default PointsWithGrayText;
