import { useEffect, useState } from 'react';
import Input from '../../ui/Input';
import Heading from '../../ui/Heading';
import Dropdown from '../../ui/Dropdown';
import Button from '../../ui/Button';
import RadioButtonComponent from '../../ui/RadioButtonComponent';
import OnSelectingPhysicalBounty from './OnSelectingPhysicalBounty';
import { bountyTypesWithIconData, coresWithIconData } from '../../constants/datas';
import Modal from '../../ui/Modal';
import CreateProjectModal from '../projects/CreateProjectModal';
import { getProjectsList } from '../../apis/bounties/bountyDraft';
import { useLocation, useParams } from 'react-router-dom';
import { useStepOneInputs } from './bountiesHooks/useStepOneInputs';
import FormWithDescriptionField from '../../ui/RichTextInputForm';
import TextWithTooltip from '../../ui/TextWithTooltip';
import NotSelectedIcon from '../../assets/icons/NotSelectedIcon';
import CancelBountyModal from './CancelBountyModal';
const NewProject = ({ setProjectsAfterCreatingNewProject, selectAfterCreation }) => {
    return (
        <Modal>
            <Modal.Button className="flex gap-2 cursor-pointer items-center" opens={'createproject'}>
                <Button type="button" variant="transparent" size="none">
                    + Create New Project
                </Button>
            </Modal.Button>
            <Modal.Window name={'createproject'}>
                <CreateProjectModal
                    from="bounty"
                    selectAfterCreation={selectAfterCreation}
                    setProjectsAfterCreatingNewProject={setProjectsAfterCreatingNewProject}
                />
            </Modal.Window>
        </Modal>
    );
};
function StepOne({
    setStep,
    control,
    errors,
    getValues,
    clearErrors,
    descriptionLinkPayload,
    handleDescriptionLinkPayload,
    setRadiusPreview,
}) {
    const [projects, setProjects] = useState([]);
    const location = useLocation();
    const { titleField, descriptionField, projectIdField, typeField, dominantCoreField, modeField } =
        useStepOneInputs(control);
    const { id } = useParams();
    const [selectedProject, setSelectedProject] = useState(() => {
        const searchParams = new URLSearchParams(location.search);

        // Decode the URL-encoded parameters
        const projectIdFromUrl = decodeURIComponent(searchParams.get('projectId') || '');
        const projectTitleFromUrl = decodeURIComponent(searchParams.get('projectTitle') || '');
        // const foundProject = projects.find((project) => project.title === value);

        projectIdField.onChange(projectIdFromUrl);
        // setSelectedProject(value);
        return projectTitleFromUrl || '';
    });
    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    const data = await getProjectsList(id);
                    setProjects(data);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [id]
    );
    useEffect(() => {
        const handleKeyDown = (event) => {
            // Check if Enter key is pressed and if focused element is an input, textarea, or select
            if (
                event.key === 'Enter' &&
                (document.activeElement.tagName === 'INPUT' ||
                    document.activeElement.tagName === 'TEXTAREA' ||
                    document.activeElement.tagName === 'SELECT')
            ) {
                event.preventDefault();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    const handleSelectProject = (value) => {
        if (value === null) {
            projectIdField.onChange('');
            setSelectedProject('');
            return;
        }
        if (typeof value === 'object') {
            return;
        }
        if (value.toLowerCase() === 'no project') {
            projectIdField.onChange('');
            setSelectedProject('');
            return;
        }

        if (value.toLowerCase() === selectedProject.toLowerCase()) {
            projectIdField.onChange('');
            setSelectedProject('');
            return;
        }

        const foundProject = projects.find((project) => project.title === value);

        projectIdField.onChange(foundProject._id);
        setSelectedProject(value);
    };

    const handleSelectProjectAfterCreation = (value, id) => {
        projectIdField.onChange(id);
        setSelectedProject(value);
    };

    return (
        <div className=" p-12 py-0   relative flex flex-col justify-between min-h-full    ">
            <div>
                <div>
                    <Input
                        {...titleField}
                        error={errors?.title?.message}
                        type="transparent"
                        placeholder={`Bounty Title *`}
                        className={'w-[100%]'}
                    />
                    <p className=" text-secondaryInput text-xs">
                        <span className="font-semibold ">({'character limit'}</span> :{' '}
                        <span>{titleField.value ? titleField.value.length : 0}/40)</span>
                    </p>
                </div>

                <FormWithDescriptionField
                    handleDescriptionLinkPayload={handleDescriptionLinkPayload}
                    descriptionLinkPayload={descriptionLinkPayload}
                    errors={errors}
                    field={descriptionField}
                />
                <div>
                    <TextWithTooltip
                        heading={
                            <Heading type="xsmall" className="capitalize  text-primaryInput">
                                Project
                            </Heading>
                        }
                    >
                        <p className="text-sm">
                            Projects are a group of bounties under an organisation with a specific objective. It is not
                            required for a bounty to be under a project.
                        </p>
                        <p className="mt-3 text-sm">
                            Projects help users find bounties with the same goals. For ex, you could have a project that
                            just focuses on water scarcity and another project that focuses on water pollution
                        </p>
                    </TextWithTooltip>
                    <div className="relative">
                        <Dropdown
                            w="w-full"
                            noProject={projects?.length > 0 ? false : true}
                            data={[
                                <NewProject
                                    selectAfterCreation={handleSelectProjectAfterCreation}
                                    setProjectsAfterCreatingNewProject={setProjects}
                                />,
                                ...projects?.map((project) => project.title),
                            ]}
                            selected={selectedProject}
                            onSelected={handleSelectProject}
                            noSelectedText={
                                <span className="flex items-center gap-[7px]">
                                    <span>Choose a project to link the bounty</span>
                                </span>
                            }
                            error={errors?.projectId?.message}
                        />
                    </div>
                </div>
                <div className="mt-6 flex gap-9 items-center ">
                    <div>
                        <div>
                            <TextWithTooltip
                                heading={
                                    <Heading type="xsmall" className="capitalize  text-primaryInput">
                                        Bounty Type <sup className="text-lg relative top-[1px]">*</sup>
                                    </Heading>
                                }
                            >
                                <p className="text-sm">
                                    The bounty type hints users on the Impact Miner as to what the bounty is about.
                                    Users choose bounties based on their strengths, or as we say 'superpowers'.
                                </p>
                            </TextWithTooltip>

                            <div className="relative">
                                <Dropdown
                                    data={bountyTypesWithIconData.map((obj) => obj.label)}
                                    modifyLabel={(item) => {
                                        return (
                                            <span className="flex items-center gap-2">
                                                {' '}
                                                <span className="">
                                                    {
                                                        bountyTypesWithIconData?.find(
                                                            (obj) => obj.label.toLowerCase() === item.toLowerCase()
                                                        )?.icon
                                                    }
                                                </span>{' '}
                                                <span>{item}</span>{' '}
                                            </span>
                                        );
                                    }}
                                    modifySelected={(item) => {
                                        return (
                                            <span className="flex items-center gap-2">
                                                {' '}
                                                <span>
                                                    {
                                                        bountyTypesWithIconData?.find(
                                                            (obj) => obj.label.toLowerCase() === item.toLowerCase()
                                                        )?.icon
                                                    }
                                                </span>{' '}
                                                <span>{item}</span>{' '}
                                            </span>
                                        );
                                    }}
                                    selected={typeField.value}
                                    onSelected={typeField.onChange}
                                    noSelectedText={
                                        <span className="flex items-center gap-[7px]">
                                            <NotSelectedIcon /> <span>Choose Category</span>
                                        </span>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <TextWithTooltip
                                heading={
                                    <Heading type="xsmall" className="capitalize  text-primaryInput">
                                        Dominant Impact Core <sup className="text-lg relative top-[1px]">*</sup>
                                    </Heading>
                                }
                            >
                                <p className="text-sm">
                                    Impact Cores is a framework that Atlantis has developed, that forms the basis of
                                    measuring impact.
                                </p>
                                <p className="text-sm mt-3">
                                    There are 4 types of Impact Cores, choose one that the bounty is majorly trying to
                                    solve for. Eg: the dominant impact core to map groundwater wells in a specific
                                    region would be water
                                </p>
                            </TextWithTooltip>

                            <div className="relative">
                                <Dropdown
                                    data={coresWithIconData.map((obj) => obj.label)}
                                    modifyLabel={(item) => {
                                        return (
                                            <span className="flex items-center gap-2">
                                                {' '}
                                                <span>
                                                    {
                                                        <img
                                                            className={` ${
                                                                item.toLowerCase() === 'water' ||
                                                                item.toLowerCase() === 'social'
                                                                    ? 'w-4 mr-1'
                                                                    : 'w-5 h-5'
                                                            }  `}
                                                            src={
                                                                coresWithIconData?.find(
                                                                    (obj) =>
                                                                        obj.label.toLowerCase() === item.toLowerCase()
                                                                )?.icon
                                                            }
                                                            alt="image"
                                                        />
                                                    }
                                                </span>{' '}
                                                <span>{item}</span>{' '}
                                            </span>
                                        );
                                    }}
                                    modifySelected={(item) => {
                                        return (
                                            <span className="flex items-center gap-2">
                                                <img
                                                    className={` ${
                                                        item.toLowerCase() === 'water' ||
                                                        item.toLowerCase() === 'social'
                                                            ? 'w-4 mr-1'
                                                            : 'w-5 h-5'
                                                    }  `}
                                                    src={
                                                        coresWithIconData?.find(
                                                            (obj) => obj.label.toLowerCase() === item.toLowerCase()
                                                        )?.icon
                                                    }
                                                    alt="image"
                                                />
                                                <span>{item}</span>{' '}
                                            </span>
                                        );
                                    }}
                                    selected={dominantCoreField.value}
                                    onSelected={dominantCoreField.onChange}
                                    noSelectedText={
                                        <span className="flex items-center gap-[7px]">
                                            <NotSelectedIcon /> <span>Choose Dominant Core</span>
                                        </span>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-9 mb-6 ">
                    <p className="text-foundryRed w-64 ">{errors?.type?.message && errors?.type?.message}</p>
                    <p className="text-foundryRed w-64 ">
                        {errors?.dominantCore?.message && errors?.dominantCore?.message}
                    </p>
                </div>
                <div className="my-6 ">
                    <TextWithTooltip
                        heading={
                            <Heading type="xsmall" className="capitalize  text-primaryInput">
                                Bounty Mode
                            </Heading>
                        }
                    >
                        <p className="text-sm">
                            Digital Bounties are those bounties which a user can take up from anywhere in the world. Eg:
                            Providing ideas for a community outreach programme.
                        </p>
                        <p className="text-sm mt-3">
                            Physical bounties are those which require the user to be at a specific location for them to
                            accept and complete the bounty.
                        </p>
                    </TextWithTooltip>
                    <div className="flex gap-9 items-center ">
                        <RadioButtonComponent
                            selectedOption={modeField.value}
                            onSelectOption={(value) => {
                                if (modeField.value === 'physical') {
                                    clearErrors('city');
                                    clearErrors('bountyRadius');
                                }

                                modeField.onChange(value);
                            }}
                            options={['digital', 'physical']}
                        />
                    </div>
                </div>
                {modeField.value === 'physical' && (
                    <OnSelectingPhysicalBounty
                        placeholder="Type in the location where the bounty should be available"
                        control={control}
                        core={dominantCoreField.value}
                        errors={errors}
                        getValues={getValues}
                        setRadiusPreview={setRadiusPreview}
                    />
                )}
            </div>

            <div className="fixed bottom-10  w-[618px]  z-0  mx-auto  ">
                <div className="flex justify-between items-center ">
                    <Modal>
                        <Modal.Button className="flex gap-2   cursor-pointer items-center" opens={'cancelbounty'}>
                            <Button type="button" className="bg-white" variant="tertiary" size="small">
                                Cancel
                            </Button>
                        </Modal.Button>
                        <Modal.Window name={'cancelbounty'}>
                            <CancelBountyModal />
                        </Modal.Window>
                    </Modal>
                    <Button type="button" onClick={() => setStep(2)} size="small">
                        Next
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default StepOne;

// show newly project created left
