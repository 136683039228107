const variations = {
    large: {
        fontSize: 'text-base',
        fontWeight: 'font-semibold',
    },
    xsmall: {
        fontSize: 'text-sm',
        fontWeight: 'font-normal',
    },

    xxsmall: {
        fontSize: 'text-[8px]',
        fontWeight: 'font-normal',
    },

    xlarge: {
        fontSize: 'text-2xl',
        fontWeight: 'font-medium',
    },
    medium: {
        fontSize: 'text-xl',
        fontWeight: 'font-medium',
    },
    small: {
        fontSize: 'text-lg',
        fontWeight: 'font-medium',
    },
};

function SubHeading({ type, children, className }) {
    switch (type) {
        case 'large':
            return (
                <h3
                    className={`${variations[type].fontSize}     ${variations[type].fontWeight} capitalize  ${className} `}
                >
                    {children}
                </h3>
            );
        case 'medium':
            return (
                <h4
                    className={`${variations[type].fontSize}   ${variations[type].fontWeight} capitalize  ${className} `}
                >
                    {children}
                </h4>
            );
        case 'small':
            return (
                <h5
                    className={`${variations[type].fontSize}  ${variations[type].fontWeight} capitalize  ${className} `}
                >
                    {children}
                </h5>
            );

        case 'xxsmall':
            return (
                <h5
                    className={`${variations[type].fontSize}  ${variations[type].fontWeight} capitalize  ${className} `}
                >
                    {children}
                </h5>
            );
        case 'xsmall':
            return (
                <h6 className={`${variations[type].fontSize}  ${variations[type].fontWeight}  ${className} `}>
                    {children}
                </h6>
            );

        default:
            return (
                <h4
                    className={`${variations[type].fontSize}  ${variations[type].fontWeight} capitalize  ${className} `}
                >
                    {children}
                </h4>
            );
    }
}

export default SubHeading;
