import { useEffect, useState } from 'react';
import { getExploreOrg, getOrganisationApplications, getOrganisations } from '../../apis/oragnisations/organisation';
import Loader from '../../ui/Loader';

import SummaryCard from '../../ui/SummaryCard';
import BackButton from '../../assets/icons/BackButton';
import Heading from '../../ui/Heading';
import { useNavigate, useOutletContext } from 'react-router-dom';
import NoDataAvailable from '../../ui/NoDataAvailable';
import ApplicationCardInAll from './ApplicationCardInAll';

function MyOrganizationsList() {
    const navigate = useNavigate();

    const { getParticularApplication, applications, setApplications, loading } = useOutletContext();

    return (
        <div>
            <div className="w-[50%] mb-9">
                <div className="flex items-center">
                    <BackButton onClick={() => navigate(-1)} />
                    <Heading type={'pageHeading'} className={'text-lightBlack ml-3'}>
                        Organisation Application Status
                    </Heading>
                </div>
            </div>
            <div className={`grid grid-cols-2 gap-6`}>
                {loading ? (
                    <div className="h-40 col-span-2 bg-white flex items-center justify-center">
                        <Loader />
                    </div>
                ) : (
                    <>
                        {applications.length ? (
                            applications.map((organization, index) => (
                                <ApplicationCardInAll
                                    redirectTo={() => {
                                        getParticularApplication(index);
                                        navigate(`/myapplications/${organization._id}`);
                                    }}
                                    className="shadow-md"
                                    key={organization._id + index + Math.random()}
                                    onSettingApp={setApplications}
                                    application={organization}
                                    setParticularApplication={() => getParticularApplication(index, 'summary')}
                                />
                            ))
                        ) : (
                            <div className="h-40 col-span-2 bg-white flex items-center justify-center">
                                <NoDataAvailable />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default MyOrganizationsList;
