function Checkbox({ name, label, value, update, pl = '', disabling, icon }) {
    const handleChange = () => {
        update();
    };

    return (
        <div className={` ${pl}  self-center flex items-center gap-2`}>
            <input
                className={` w-5 h-5 self-center accent-blue-500  `}
                type="checkbox"
                id={`${name}-checkbox`}
                name={name}
                disabled={disabling}
                checked={value}
                onChange={handleChange}
            />
            {label && (
                <label
                    className={`block ${disabling ? 'cursor-not-allowed' : 'cursor-pointer'}
                      px-3 py-1 capitalize rounded-md flex items-center gap-[6px]  hover:bg-gray-100 `}
                    htmlFor={`${name}-checkbox`}
                >
                    {icon && <span>{icon}</span>} {label.split('_').join(' ').toLowerCase()}
                </label>
            )}
        </div>
    );
}

export default Checkbox;
