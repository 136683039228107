import { useEffect, useState } from 'react';
import AvatarImage from '../../ui/AvatarImage';
import { ToDelete, ToRead, ToUnread } from '../../assets/icons/IconsForNotifications';
import { changeReadStatus } from '../../apis/notifications/notifications';
import { formatNotificationDateString } from '../../helpers/dateForNotification';
import { useNavigate } from 'react-router-dom';

import notifBell from '../../assets/notifBellIcon.png';
import Button from '../../ui/Button';
import Modal from '../../ui/Modal';
import RequestModal from '../members/RequestModal';
import { acceptInvitationToJoinOrg, declineInvitationToJoinOrg } from '../../apis/invitations/adminSendInvitation';
import { useNotifications } from '../../contexts/NotificationsContext';
import { joiningString } from '../../helpers/joiningString';

// onClick={() => {
//     if (!readStatus) handleReadInteraction();
//     onToggle();

//     navigate(`/organizations/${data.data.organisationId}/view-proposalsandrequests`);
// }}

const NotificationWrapper = ({
    handleMouseEnter,
    handleMouseLeave,
    showBtns,
    imageURL,
    isRead,
    handleDeleteNotification,
    interactionComponent,
    handleClick,
    timesAgo,
    onMarking,
    children,
}) => {
    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            className={` flex  gap-3  items-start  py-4 px-6 cursor-pointer ${showBtns ? 'bg-lightGray' : ''}  `}
        >
            <div className="w-[50px]  relative">
                <AvatarImage className="object-cover " w="w-[50px]" h="h-[50px]" url={imageURL} />
                {!isRead && (
                    <div
                        className={`w-[10px] h-[10px] bg-foundryBlue rounded-full absolute top-[50%] -left-4 translate-y-[-50%] `}
                    ></div>
                )}
            </div>
            <div className="grid flex-1 grid-cols-6">
                {children}
                {showBtns && (
                    <HoveredComponent
                        isRead={isRead}
                        onMarking={onMarking}
                        handleDeleteNotification={handleDeleteNotification}
                    />
                )}
                <div className="col-span-6 mt-3 flex items-center justify-between">
                    {interactionComponent ? interactionComponent : 'coming'}

                    <p className="text-sm text-[#8E8E8E]">{formatNotificationDateString(timesAgo) || 'coming'}</p>
                </div>
            </div>
        </div>
    );
};

const HoveredComponent = ({ isRead, onMarking, handleDeleteNotification }) => {
    return (
        <div className="flex gap-1 py-2 px-3 border border-borderColor rounded-sm bg-white col-span-1 place-self-start justify-self-end items-center ">
            {isRead ? (
                <ToUnread
                    onClick={(e) => {
                        e.stopPropagation();
                        onMarking();
                    }}
                />
            ) : (
                <ToRead
                    onClick={(e) => {
                        e.stopPropagation();
                        onMarking();
                    }}
                />
            )}
            <ToDelete
                onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteNotification();
                }}
            />
        </div>
    );
};

// refactored
const OrgApplicationUpdateComponent = ({ data, handleDeleteNotification, onToggle }) => {
    const [showBtns, setShowBtns] = useState(false);
    const [response, setResponse] = useState({});
    const { dispatch } = useNotifications();
    const navigate = useNavigate();
    const [readStatus, setReadStatus] = useState(data?.isRead);
    useEffect(
        function () {
            if (Object.keys(response).length) {
                setReadStatus(response.isRead);
            }
        },
        [response]
    );

    const handleMouseEnter = () => setShowBtns(true);
    const handleMouseLeave = () => setShowBtns(false);

    const handleReadInteraction = async () => {
        setReadStatus((initial) => !initial);
        try {
            dispatch({ type: 'read', payload: { notifId: data.id, readStatus: readStatus } });
            const res = await changeReadStatus(data.id, !readStatus);

            setResponse(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <NotificationWrapper
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            showBtns={showBtns}
            isRead={readStatus}
            interactionComponent={<p></p>}
            handleClick={() => {
                if (!readStatus) handleReadInteraction();
                onToggle();

                navigate(`/myapplications/${data.data.orgApplicationId}`);
            }}
            // interactionComponent={
            //     data.data.approved ? (
            //         <Button
            //             onClick={() => {
            //                 navigate('/myapplications');

            //                 onToggle();
            //             }}
            //             variant="tertiary"
            //             size="medium"
            //         >
            //             Create Organisation
            //         </Button>
            //     ) : (
            //         <Button onClick={() => navigate('/createorganization')} variant="tertiary" size="small">
            //             Retry
            //         </Button>
            //     )
            // }
            timesAgo={data.createdAt}
            onMarking={handleReadInteraction}
            handleDeleteNotification={() => handleDeleteNotification(data.id)}
            imageURL={data?.organisationLogo || notifBell}
        >
            <p className="col-span-5 ">
                Your application to create the organisation
                <span className="font-bold "> ’{data.data.organisationName}’</span> has been
                <span className="font-bold">{data.data.approved ? ' accepted' : ' rejected'} </span>
                {/* <span
                    onClick={() => {
                        if (!readStatus) handleReadInteraction();
                        onToggle();

                        navigate(`/myapplications`);
                    }}
                    className="font-bold text-foundryBlue"
                >
                    view application
                </span> */}
            </p>
        </NotificationWrapper>
    );
};

// Receiving of Invitation to Join Organization (ORG_INVITATION) - refactored
const OrgInvitationComponent = ({ data, handleDeleteNotification, onToggle }) => {
    const [showBtns, setShowBtns] = useState(false);
    const [response, setResponse] = useState({});
    const { dispatch } = useNotifications();
    const navigate = useNavigate();
    const [readStatus, setReadStatus] = useState(data?.isRead);
    useEffect(
        function () {
            if (Object.keys(response).length) {
                setReadStatus(response.isRead);
            }
        },
        [response]
    );
    const handleMouseEnter = () => setShowBtns(true);
    const handleMouseLeave = () => setShowBtns(false);

    const handleReadInteraction = async () => {
        setReadStatus((initial) => !initial);
        try {
            dispatch({ type: 'read', payload: { notifId: data.id, readStatus: readStatus } });
            const res = await changeReadStatus(data.id, !readStatus);

            setResponse(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <NotificationWrapper
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            showBtns={showBtns}
            isRead={readStatus}
            handleClick={() => {
                if (!readStatus) handleReadInteraction();
                onToggle();

                navigate(`/organizations/${data.data.organisationId}`);
            }}
            interactionComponent={
                <div className="flex items-center gap-3">
                    <Button
                        onClick={async (e) => {
                            e.stopPropagation();
                            onToggle();
                            const res = await acceptInvitationToJoinOrg(data.data.orgInvitationId);
                            handleDeleteNotification(data.id);
                        }}
                        variant="tertiarySuccess"
                        size="small"
                    >
                        Approve
                    </Button>

                    <Button
                        onClick={async (e) => {
                            e.stopPropagation();
                            onToggle();
                            const res = await declineInvitationToJoinOrg(data.data.orgInvitationId);
                            handleDeleteNotification(data.id);
                        }}
                        variant="tertiaryFail"
                        size="small"
                    >
                        Deny
                    </Button>
                </div>
            }
            timesAgo={data.createdAt}
            onMarking={handleReadInteraction}
            handleDeleteNotification={() => handleDeleteNotification(data.id)}
            imageURL={data?.organisationLogo || notifBell}
        >
            <p className="col-span-5 ">
                You have been invited to join the organisation :
                <span className="font-bold "> ’{data.data.organisationName}’</span> with the roles of{' '}
                {joiningString(data.data.roles.map((role, index) => role.split('_').join(' ').toLowerCase()))}
            </p>
        </NotificationWrapper>
    );
};

// Acceptance / Rejection of Organisation Joining Invitation (ORG_INVITATION_UPDATE) refactored
const OrgInvitationUpdateComponent = ({ data, handleDeleteNotification, onToggle }) => {
    const [showBtns, setShowBtns] = useState(false);
    const [response, setResponse] = useState({});
    const { dispatch } = useNotifications();
    const navigate = useNavigate();
    const [readStatus, setReadStatus] = useState(data?.isRead);
    useEffect(
        function () {
            if (Object.keys(response).length) {
                setReadStatus(response.isRead);
            }
        },
        [response]
    );
    const handleMouseEnter = () => setShowBtns(true);
    const handleMouseLeave = () => setShowBtns(false);

    const handleReadInteraction = async () => {
        setReadStatus((initial) => !initial);
        try {
            dispatch({ type: 'read', payload: { notifId: data.id, readStatus: readStatus } });
            const res = await changeReadStatus(data.id, !readStatus);

            setResponse(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <NotificationWrapper
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            showBtns={showBtns}
            isRead={readStatus}
            handleClick={() => {
                if (data.data.orgInvitationStatus === 'DECLINED') {
                    if (!readStatus) handleReadInteraction();
                    onToggle();
                    navigate(`/organizations/${data.data.organisationId}/view-members/invitations`);
                    return;
                }
                if (data.data.roles.includes('TREASURER')) {
                    if (!readStatus) handleReadInteraction();
                    onToggle();
                    navigate(`/organizations/${data.data.organisationId}/view-members/invitations`);
                } else {
                    if (!readStatus) handleReadInteraction();
                    onToggle();
                    navigate(`/organizations/${data.data.organisationId}/view-members`);
                }
            }}
            interactionComponent={<p className="text-[#8E8E8E] text-sm "></p>}
            // interactionComponent={
            //     <div className="flex items-center gap-3">
            //         {data.data.orgInvitationStatus === 'DECLINED' ? (
            //             <Button
            //                 onClick={async () => {
            //                     onToggle();
            //                     navigate(`/organizations/${data.data.organisationId}`);
            //                 }}
            //                 variant="tertiary"
            //                 size="medium"
            //             >
            //                 Resend Invitation
            //             </Button>
            //         ) : (
            //             data.data.roles.includes('TREASURER') && (
            //                 <Button
            //                     onClick={async () => {
            //                         onToggle();
            //                         navigate(`/organizations/${data.data.organisationId}/view-members/invitations`);
            //                     }}
            //                     variant="tertiary"
            //                     size="medium"
            //                 >
            //                     Add To Organisation
            //                 </Button>
            //             )
            //         )}
            //     </div>
            // }
            timesAgo={data.createdAt}
            onMarking={handleReadInteraction}
            handleDeleteNotification={() => handleDeleteNotification(data.id)}
            imageURL={data?.organisationLogo || notifBell}
        >
            <p className="col-span-5 ">
                <span className="font-bold"> {data.data.userName}</span> has{' '}
                <span className="lowercase font-bold">{data.data.orgInvitationStatus}</span> to join
                <span className="font-bold "> ’{data.data.organisationName}’</span> with the roles of{' '}
                {joiningString(data.data.roles.map((role, index) => role.split('_').join(' ').toLowerCase()))}
            </p>
        </NotificationWrapper>
    );
};
const OrgInvitationUpdateComponent2 = ({ data, handleDeleteNotification, onToggle }) => {
    const [showBtns, setShowBtns] = useState(false);
    const [response, setResponse] = useState({});
    const { dispatch } = useNotifications();
    const navigate = useNavigate();
    const [readStatus, setReadStatus] = useState(data?.isRead);
    useEffect(
        function () {
            if (Object.keys(response).length) {
                setReadStatus(response.isRead);
            }
        },
        [response]
    );
    const handleMouseEnter = () => setShowBtns(true);
    const handleMouseLeave = () => setShowBtns(false);

    const handleReadInteraction = async () => {
        setReadStatus((initial) => !initial);
        try {
            dispatch({ type: 'read', payload: { notifId: data.id, readStatus: readStatus } });
            const res = await changeReadStatus(data.id, !readStatus);

            setResponse(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <NotificationWrapper
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            showBtns={showBtns}
            isRead={readStatus}
            handleClick={() => {
                if (data.data.orgInvitationStatus === 'DECLINED') {
                    if (!readStatus) handleReadInteraction();
                    onToggle();
                    navigate(`/organizations/${data.data.organisationId}/view-members/invitations`);
                    return;
                }
                if (data.data.roles.includes('TREASURER')) {
                    if (!readStatus) handleReadInteraction();
                    onToggle();
                    navigate(`/organizations/${data.data.organisationId}`);
                } else {
                    if (!readStatus) handleReadInteraction();
                    onToggle();
                    navigate(`/organizations/${data.data.organisationId}`);
                }
            }}
            interactionComponent={<p className="text-[#8E8E8E] text-sm "></p>}
            // interactionComponent={
            //     <div className="flex items-center gap-3">
            //         {data.data.orgInvitationStatus === 'DECLINED' ? (
            //             <Button
            //                 onClick={async () => {
            //                     onToggle();
            //                     navigate(`/organizations/${data.data.organisationId}`);
            //                 }}
            //                 variant="tertiary"
            //                 size="medium"
            //             >
            //                 Resend Invitation
            //             </Button>
            //         ) : (
            //             data.data.roles.includes('TREASURER') && (
            //                 <Button
            //                     onClick={async () => {
            //                         onToggle();
            //                         navigate(`/organizations/${data.data.organisationId}/view-members/invitations`);
            //                     }}
            //                     variant="tertiary"
            //                     size="medium"
            //                 >
            //                     Add To Organisation
            //                 </Button>
            //             )
            //         )}
            //     </div>
            // }
            timesAgo={data.createdAt}
            onMarking={handleReadInteraction}
            handleDeleteNotification={() => handleDeleteNotification(data.id)}
            imageURL={data?.organisationLogo || notifBell}
        >
            <p className="col-span-5 ">
                You have been added to the organisation
                <span className="font-bold "> ’{data.data.organisationName}’</span> with the roles of{' '}
                {joiningString(data.data.roles.map((role, index) => role.split('_').join(' ').toLowerCase()))}
            </p>
        </NotificationWrapper>
    );
};

// Update of Organization Member Roles (ROLES_UPDATION) - refactored
const RolesUpdationComponent = ({ data, handleDeleteNotification, onToggle }) => {
    const [showBtns, setShowBtns] = useState(false);
    const [response, setResponse] = useState({});
    const { dispatch } = useNotifications();
    const navigate = useNavigate();
    const [readStatus, setReadStatus] = useState(data?.isRead);
    useEffect(
        function () {
            if (Object.keys(response).length) {
                setReadStatus(response.isRead);
            }
        },
        [response]
    );
    const handleMouseEnter = () => setShowBtns(true);
    const handleMouseLeave = () => setShowBtns(false);

    const handleReadInteraction = async () => {
        setReadStatus((initial) => !initial);
        try {
            dispatch({ type: 'read', payload: { notifId: data.id, readStatus: readStatus } });
            const res = await changeReadStatus(data.id, !readStatus);

            setResponse(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <NotificationWrapper
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            showBtns={showBtns}
            isRead={readStatus}
            onMarking={handleReadInteraction}
            handleDeleteNotification={() => handleDeleteNotification(data.id)}
            handleClick={() => {
                if (!readStatus) handleReadInteraction();
                onToggle();
                navigate(`/organizations/${data.data.organisationId}/view-members`);
            }}
            interactionComponent={<p className="text-[#8E8E8E] text-sm ">{data.data.organisationName}</p>}
            timesAgo={data.createdAt}
            imageURL={data.organisationLogo || notifBell}
        >
            {data.data.roles.length > 0 ? (
                <p className="col-span-5 ">
                    Your role in
                    <span
                        onClick={() => {
                            if (!readStatus) handleReadInteraction();
                            onToggle();

                            navigate(`/organizations/${data.data.organisationId}`);
                        }}
                        className="hover:underline font-bold"
                    >
                        {' '}
                        ’{data.data.organisationName}’
                    </span>{' '}
                    has been updated to{' '}
                    <span className=" font-bold">
                        {' '}
                        {joiningString(
                            data.data.roles.map((role, index) => role.split('_').join(' ').toLowerCase().trim())
                        )}
                    </span>
                </p>
            ) : (
                <p className="col-span-5 ">
                    You have been <span className=" font-bold">removed</span> from the organisation
                    <span
                        onClick={() => {
                            if (!readStatus) handleReadInteraction();
                            onToggle();

                            navigate(`/organizations/${data.data.organisationId}`);
                        }}
                        className="hover:underline font-bold"
                    >
                        {' '}
                        ’{data.data.organisationName}’
                    </span>
                </p>
            )}
        </NotificationWrapper>
    );
};

const OrgJoinRequestComponent = ({ data, handleDeleteNotification, onToggle }) => {
    const [showBtns, setShowBtns] = useState(false);
    const [response, setResponse] = useState({});
    const { dispatch } = useNotifications();
    const navigate = useNavigate();
    const [readStatus, setReadStatus] = useState(data?.isRead);
    useEffect(
        function () {
            if (Object.keys(response).length) {
                setReadStatus(response.isRead);
            }
        },
        [response]
    );
    const handleMouseEnter = () => setShowBtns(true);
    const handleMouseLeave = () => setShowBtns(false);

    const handleReadInteraction = async () => {
        setReadStatus((initial) => !initial);
        try {
            dispatch({ type: 'read', payload: { notifId: data.id, readStatus: readStatus } });
            const res = await changeReadStatus(data.id, !readStatus);

            setResponse(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <NotificationWrapper
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            handleClick={() => {
                if (!readStatus) handleReadInteraction();
                onToggle();
                navigate(
                    `/organizations/${data.data.organisationId}/view-members/invitations?notifScreen=requests&action=accept`
                );
            }}
            showBtns={showBtns}
            isRead={readStatus}
            interactionComponent={<p className="text-[#8E8E8E] text-sm "></p>}
            timesAgo={data.createdAt}
            onMarking={handleReadInteraction}
            handleDeleteNotification={() => handleDeleteNotification(data.id)}
            imageURL={data?.organisationLogo || notifBell}
        >
            <p className="col-span-5 ">
                <span className="font-bold">{data.data.userName}</span> wants to join
                <span className="font-bold "> ’{data.data.organisationName}’</span> with the roles of{' '}
                {joiningString(data.data.roles.map((role, index) => role.split('_').join(' ').toLowerCase().trim()))}
            </p>
        </NotificationWrapper>
    );
};

// Organisation Joining Request Status Update (ORG_JOIN_REQUEST_UPDATE)
const OrgJoinRequestUpdateComponent = ({ data, handleDeleteNotification, onToggle }) => {
    const [showBtns, setShowBtns] = useState(false);
    const [response, setResponse] = useState({});
    const { dispatch } = useNotifications();
    const navigate = useNavigate();
    const [readStatus, setReadStatus] = useState(data?.isRead);
    useEffect(
        function () {
            if (Object.keys(response).length) {
                setReadStatus(response.isRead);
            }
        },
        [response]
    );
    const handleMouseEnter = () => setShowBtns(true);
    const handleMouseLeave = () => setShowBtns(false);

    const handleReadInteraction = async () => {
        setReadStatus((initial) => !initial);
        try {
            dispatch({ type: 'read', payload: { notifId: data.id, readStatus: readStatus } });
            const res = await changeReadStatus(data.id, !readStatus);

            setResponse(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <NotificationWrapper
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            showBtns={showBtns}
            isRead={readStatus}
            handleClick={() => {
                if (!readStatus) handleReadInteraction();
                onToggle();

                navigate(`/organizations/${data.data.organisationId}`);
            }}
            onMarking={handleReadInteraction}
            handleDeleteNotification={() => handleDeleteNotification(data.id)}
            interactionComponent={<p></p>}
            timesAgo={data.createdAt}
            imageURL={data.organisationLogo}
        >
            <p className="col-span-5 ">
                Your request to join
                <span className=" font-bold"> {data.data.organisationName} </span>
                with the roles of{' '}
                {joiningString(
                    data.data.roles.map((role, index) => role.split('_').join(' ').toLowerCase().trim())
                )}{' '}
                has been
                <span className="font-bold">{data.data.approved ? ' accepted' : ' rejected'} </span>
            </p>
        </NotificationWrapper>
    );
};

// Refactored
const OrgRequestComponent = ({ data, handleDeleteNotification, onToggle }) => {
    const [showBtns, setShowBtns] = useState(false);
    const [response, setResponse] = useState({});
    const { dispatch } = useNotifications();
    const [readStatus, setReadStatus] = useState(data?.isRead);
    const navigate = useNavigate();
    useEffect(
        function () {
            if (Object.keys(response).length) {
                setReadStatus(response.isRead);
            }
        },
        [response]
    );
    const handleMouseEnter = () => setShowBtns(true);
    const handleMouseLeave = () => setShowBtns(false);

    const handleReadInteraction = async () => {
        setReadStatus((initial) => !initial);
        try {
            dispatch({ type: 'read', payload: { notifId: data.id, readStatus: readStatus } });
            const res = await changeReadStatus(data.id, !readStatus);

            setResponse(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <NotificationWrapper
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            showBtns={showBtns}
            isRead={readStatus}
            interactionComponent={<p className="text-[#8E8E8E] text-sm ">{data.data.organisationName}</p>}
            timesAgo={data.createdAt}
            onMarking={handleReadInteraction}
            handleDeleteNotification={() => handleDeleteNotification(data.id)}
            handleClick={() => {
                if (!readStatus) handleReadInteraction();
                onToggle();

                navigate(`/organizations/${data.data.organisationId}/view-proposalsandrequests`);
            }}
            imageURL={data?.organisationLogo}
        >
            <p className="col-span-5 ">
                There is a request for{' '}
                <span className="font-bold "> {data.data.requestType.split('_').join(' ').toLowerCase()}</span> of{' '}
                <span className="font-bold"> ’{data.data.bountyTitle}’</span>
                {/* <span
                    onClick={() => {
                        if (!readStatus) handleReadInteraction();
                        onToggle();

                        navigate(`/organizations/${data.data.organisationId}/view-proposalsandrequests`);
                    }}
                    className="font-bold text-foundryBlue"
                >
                    view request
                </span> */}
            </p>
        </NotificationWrapper>
    );
};

// Acceptance of Organisation Request (ORG_REQUEST_ACCEPTANCE) - refactored
const OrgRequestAcceptanceComponent = ({ data, handleDeleteNotification, onToggle }) => {
    const [showBtns, setShowBtns] = useState(false);
    const [response, setResponse] = useState({});
    const { dispatch } = useNotifications();
    const [readStatus, setReadStatus] = useState(data?.isRead);
    const navigate = useNavigate();
    useEffect(
        function () {
            if (Object.keys(response).length) {
                setReadStatus(response.isRead);
            }
        },
        [response]
    );
    const handleMouseEnter = () => setShowBtns(true);
    const handleMouseLeave = () => setShowBtns(false);

    const handleReadInteraction = async () => {
        setReadStatus((initial) => !initial);
        try {
            dispatch({ type: 'read', payload: { notifId: data.id, readStatus: readStatus } });
            const res = await changeReadStatus(data.id, !readStatus);

            setResponse(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <NotificationWrapper
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            showBtns={showBtns}
            isRead={readStatus}
            interactionComponent={<p className="text-[#8E8E8E] text-sm ">{data.data.organisationName}</p>}
            timesAgo={data.createdAt}
            onMarking={handleReadInteraction}
            handleDeleteNotification={() => handleDeleteNotification(data.id)}
            handleClick={() => {
                if (!readStatus) handleReadInteraction();
                onToggle();

                navigate(`/organizations/${data.data.organisationId}/view-proposalsandrequests?for=proposals`);
            }}
            imageURL={data?.organisationLogo}
        >
            <p className="col-span-5 ">
                <span className="font-bold "></span>
                <span className="font-bold ">Proposal</span> for
                {data.data.requestType === 'BOUNTY_CREATION' ? ' creation' : ' validation'} of bounty{' '}
                <span className="font-bold ">’{data.data.bountyTitle}’</span> is available for voting
            </p>
        </NotificationWrapper>
    );
};

// Execution of Organisation Request (ORG_REQUEST_EXECUTION) - refactored
const OrgRequestExecutionComponent = ({ data, handleDeleteNotification, onToggle }) => {
    const [showBtns, setShowBtns] = useState(false);
    const [response, setResponse] = useState({});
    const { dispatch } = useNotifications();
    const [readStatus, setReadStatus] = useState(data?.isRead);

    const navigate = useNavigate();
    useEffect(
        function () {
            if (Object.keys(response).length) {
                setReadStatus(response.isRead);
            }
        },
        [response]
    );
    const handleMouseEnter = () => setShowBtns(true);
    const handleMouseLeave = () => setShowBtns(false);

    const handleReadInteraction = async () => {
        setReadStatus((initial) => !initial);
        try {
            dispatch({ type: 'read', payload: { notifId: data.id, readStatus: readStatus } });
            const res = await changeReadStatus(data.id, !readStatus);

            setResponse(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <NotificationWrapper
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            showBtns={showBtns}
            isRead={readStatus}
            onMarking={handleReadInteraction}
            handleDeleteNotification={() => handleDeleteNotification(data.id)}
            handleClick={() => {
                if (!readStatus) handleReadInteraction();
                onToggle();

                navigate(`/organizations/${data.data.organisationId}/view-bounties/${data.data.bountyId}`);
            }}
            interactionComponent={<p>{data.data?.organisationName}</p>}
            timesAgo={data.createdAt}
            imageURL={data.organisationLogo}
        >
            <p className="col-span-5 ">
                Your proposal for{' '}
                <span className="font-bold">{data.data.requestType.split('_').join(' ').toLowerCase()}</span> of{' '}
                <span className="hover:underline font-bold">’{data.data.bountyTitle}’ </span> has been
                <span className="font-bold"> accepted</span>
            </p>
        </NotificationWrapper>
    );
};

// Attestor Profile Creation Request Status Update (ATTESTOR_PROFILE_UPDATE)
const AttestorProfileUpdateComponent = ({ data, handleDeleteNotification }) => {
    const [showBtns, setShowBtns] = useState(false);
    const [response, setResponse] = useState({});
    const { dispatch } = useNotifications();
    const [readStatus, setReadStatus] = useState(data?.isRead);
    useEffect(
        function () {
            if (Object.keys(response).length) {
                setReadStatus(response.isRead);
            }
        },
        [response]
    );
    const handleMouseEnter = () => setShowBtns(true);
    const handleMouseLeave = () => setShowBtns(false);

    const handleReadInteraction = async () => {
        setReadStatus((initial) => !initial);
        try {
            dispatch({ type: 'read', payload: { notifId: data.id, readStatus: readStatus } });
            const res = await changeReadStatus(data.id, !readStatus);

            setResponse(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <NotificationWrapper
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            showBtns={showBtns}
            isRead={readStatus}
            onMarking={handleReadInteraction}
            handleDeleteNotification={() => handleDeleteNotification(data.id)}
            imageURL="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/510px-Default_pfp.svg.png"
        >
            <p className="col-span-5 ">
                Your attestor profile has been updated. <a href="#">View profile</a>
            </p>
        </NotificationWrapper>
    );
};

// Successful Attestation (ATTESTATION)
const AttestationComponent = ({ data, handleDeleteNotification }) => {
    const [showBtns, setShowBtns] = useState(false);
    const [response, setResponse] = useState({});
    const { dispatch } = useNotifications();
    const [readStatus, setReadStatus] = useState(data?.isRead);
    useEffect(
        function () {
            if (Object.keys(response).length) {
                setReadStatus(response.isRead);
            }
        },
        [response]
    );
    const handleMouseEnter = () => setShowBtns(true);
    const handleMouseLeave = () => setShowBtns(false);

    const handleReadInteraction = async () => {
        setReadStatus((initial) => !initial);
        try {
            dispatch({ type: 'read', payload: { notifId: data.id, readStatus: readStatus } });
            const res = await changeReadStatus(data.id, !readStatus);

            setResponse(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <NotificationWrapper
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            showBtns={showBtns}
            isRead={readStatus}
            onMarking={handleReadInteraction}
            handleDeleteNotification={() => handleDeleteNotification(data.id)}
            imageURL="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/510px-Default_pfp.svg.png"
        >
            <p className="col-span-5 ">
                Your submission has been successfully attested. <a href="#">View attestation</a>
            </p>
        </NotificationWrapper>
    );
};

// Array with components and categories
export const notificationTypeData = [
    {
        type: 'ORG_APPLICATION_UPDATE',
        getComponent: (data, handleDeleteNotification, onToggle) => (
            <OrgApplicationUpdateComponent
                data={data}
                handleDeleteNotification={handleDeleteNotification}
                onToggle={onToggle}
            />
        ),
    },
    {
        type: 'ORG_INVITATION',
        getComponent: (data, handleDeleteNotification, onToggle) => (
            <OrgInvitationComponent
                data={data}
                handleDeleteNotification={handleDeleteNotification}
                onToggle={onToggle}
            />
        ),
    },
    {
        type: 'ORG_INVITATION_UPDATE',
        getComponent: (data, handleDeleteNotification, onToggle) => (
            <OrgInvitationUpdateComponent
                data={data}
                handleDeleteNotification={handleDeleteNotification}
                onToggle={onToggle}
            />
        ),
    },
    {
        type: 'ORG_INVITATION_UPDATE2',
        getComponent: (data, handleDeleteNotification, onToggle) => (
            <OrgInvitationUpdateComponent2
                data={data}
                handleDeleteNotification={handleDeleteNotification}
                onToggle={onToggle}
            />
        ),
    },
    {
        type: 'ROLES_UPDATION',
        getComponent: (data, handleDeleteNotification, onToggle) => (
            <RolesUpdationComponent
                data={data}
                handleDeleteNotification={handleDeleteNotification}
                onToggle={onToggle}
            />
        ),
    },
    {
        type: 'ORG_JOIN_REQUEST',
        getComponent: (data, handleDeleteNotification, onToggle) => (
            <OrgJoinRequestComponent
                data={data}
                handleDeleteNotification={handleDeleteNotification}
                onToggle={onToggle}
            />
        ),
    },
    {
        type: 'ORG_JOIN_REQUEST_UPDATE',
        getComponent: (data, handleDeleteNotification, onToggle) => (
            <OrgJoinRequestUpdateComponent
                data={data}
                handleDeleteNotification={handleDeleteNotification}
                onToggle={onToggle}
            />
        ),
    },
    {
        type: 'ORG_REQUEST',
        getComponent: (data, handleDeleteNotification, onToggle) => (
            <OrgRequestComponent data={data} handleDeleteNotification={handleDeleteNotification} onToggle={onToggle} />
        ),
    },
    {
        type: 'ORG_REQUEST_ACCEPTANCE',
        getComponent: (data, handleDeleteNotification, onToggle) => (
            <OrgRequestAcceptanceComponent
                data={data}
                handleDeleteNotification={handleDeleteNotification}
                onToggle={onToggle}
            />
        ),
    },
    {
        type: 'ORG_REQUEST_EXECUTION',
        getComponent: (data, handleDeleteNotification, onToggle) => (
            <OrgRequestExecutionComponent
                data={data}
                handleDeleteNotification={handleDeleteNotification}
                onToggle={onToggle}
            />
        ),
    },
    {
        type: 'ATTESTOR_PROFILE_UPDATE',
        getComponent: (data, handleDeleteNotification) => (
            <AttestorProfileUpdateComponent data={data} handleDeleteNotification={handleDeleteNotification} />
        ),
    },
    {
        type: 'ATTESTATION',
        getComponent: (data, handleDeleteNotification) => (
            <AttestationComponent data={data} handleDeleteNotification={handleDeleteNotification} />
        ),
    },
];
