import { useContext, useRef } from 'react';
import ArrowLeft from '../../../assets/icons/ArrowLeft';
import ArrowRight from '../../../assets/icons/ArrowRight';
import Button from '../../../ui/Button';
import FlipCard from '../../../ui/FlipCard';
import Heading from '../../../ui/Heading';
import WhiteCard from '../../../ui/WhiteCard';

import UnclaimedBack from './UnclaimedBack';
import UnclaimedCard from './UnclaimedCard';
import { ProfileContext } from '../../../contexts/ProfileContext';
import NoDataAvailable from '../../../ui/NoDataAvailable';

function AllUnclaimedCards() {
    const { state } = useContext(ProfileContext);
    const unclaimedIcs = state.filter((ic) => ic.status === 'UNCLAIMED');
    const scrollCards = useRef();
    function scrollR() {
        scrollCards.current.scrollBy({
            left: 334,
            behavior: 'smooth',
        });
    }
    function scrollL() {
        scrollCards.current.scrollBy({
            left: -334,
            behavior: 'smooth',
        });
    }
    return (
        <div>
            <Heading className={`text-secondaryText leading-10`} type={'medium'}>
                Unclaimed
            </Heading>
            <div className="relative">
                <WhiteCard
                    verticalMargin=""
                    ref={scrollCards}
                    className="p-5  flex items-center overflow-x-auto  flex-nowrap   scrollableCards"
                >
                    {unclaimedIcs.length > 0 ? (
                        unclaimedIcs.map((ic) => {
                            return (
                                <FlipCard
                                    key={ic.id + ic.status}
                                    width="w-[182px]"
                                    height="h-[252px]"
                                    fixW="w-[182px]"
                                    certificateId={ic.id}
                                    FrontCard={UnclaimedCard}
                                    BackCard={UnclaimedBack}
                                />
                            );
                        })
                    ) : (
                        <div className="flex w-full items-center justify-center">
                            <NoDataAvailable imgClassName="h-[252px]" marginTop="mt-3" />
                        </div>
                    )}
                </WhiteCard>

                <Button
                    onClick={scrollL}
                    className="bg-white absolute top-[50%] translate-y-[-50%]  left-4 "
                    variant="tertiary"
                    size="circle"
                >
                    <ArrowLeft />
                </Button>

                <Button
                    onClick={scrollR}
                    className="bg-white absolute top-[50%] translate-y-[-50%]  right-4 "
                    variant="tertiary"
                    size="circle"
                >
                    <ArrowRight />
                </Button>
            </div>
        </div>
    );
}

export default AllUnclaimedCards;
