const DownloadIcon = () => {
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.5 8H2V11.75H14V8H15.5V11.75C15.5 12.5825 14.8325 13.25 14 13.25H2C1.60218 13.25 1.22064 13.092 0.93934 12.8107C0.658035 12.5294 0.5 12.1478 0.5 11.75V8ZM8 10.25L12.1625 6.155L11.0975 5.0975L8.75 7.4375V0.5H7.25V7.4375L4.91 5.0975L3.845 6.1625L8 10.25Z"
                fill="#0355DC"
            />
        </svg>
    );
};

export default DownloadIcon;
