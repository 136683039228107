import { useNavigate } from 'react-router-dom';
import { getOrganisations } from '../apis/oragnisations/organisation';
import { useEffect, useState } from 'react';
import BackButton from '../assets/icons/BackButton';
import Heading from '../ui/Heading';
import Loader from '../ui/Loader';
import SummaryCard from '../ui/SummaryCard';
import { useLoadingState } from '../hooks/useLoader';
import NoDataAvailable from '../ui/NoDataAvailable';
import SummaryCardInAll from '../features/Organization/SummaryCardInAll';

function MyOrganisationsNew() {
    const navigate = useNavigate();
    const [organisations, setOrganisations] = useState([]);
    const { loading, startLoading, stopLoading } = useLoadingState();
    useEffect(function () {
        const fetchData = async () => {
            try {
                startLoading();
                const data = await getOrganisations();

                setOrganisations(data);
            } catch (error) {
                console.log(error);
            }
            stopLoading();
        };
        fetchData();
    }, []);

    return (
        <div className="p-9">
            <div className="w-[50%] mb-6">
                <div className="flex items-center">
                    <BackButton onClick={() => navigate(-1)} />
                    <Heading type={'pageHeading'} className={'text-lightBlack ml-3'}>
                        My Organizations
                    </Heading>
                </div>
                {/* <p className="mt-2">
                    Discover Impact Orgs that match your interest and skill. Click on any of organization of your
                    interest and express a desire to join them, it is that simple to get started on an impactful career.
                </p> */}
            </div>
            <div className={`grid grid-cols-2 gap-6`}>
                {loading ? (
                    <div className="h-40 col-span-2 bg-white flex items-center justify-center">
                        <Loader />
                    </div>
                ) : (
                    <>
                        {organisations.length > 0 ? (
                            organisations.map((organization, index) => (
                                <SummaryCardInAll
                                    redirectTo={() => navigate(`/organizations/${organization._id}`)}
                                    className="shadow-md"
                                    key={organization._id + index + Math.random()}
                                    organization={organization}
                                    from="all"
                                />
                            ))
                        ) : (
                            <div className="h-auto min-h-40 col-span-2 bg-white flex items-center justify-center">
                                <NoDataAvailable marginTop="mt-8" imgClassName="w-36" className="mb-8" />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default MyOrganisationsNew;
