import Table from '../../ui/Table';
import { microtaskTypeData } from '../bounties/microtasktypes/microtaskTypeData';

const MultiSelectMCQSD = ({ dataTask }) => {
    const titleObject = dataTask?.task.find((obj) => Object.keys(obj).includes('title'));
    const questionObject = dataTask?.task.find((obj) => Object.keys(obj).includes('question'));
    const answersObject = dataTask?.task.find((obj) => Object.keys(obj).includes('answers'))?.answers.split(',');

    return (
        <Table.Row>
            <p className=" text-lightBlack flex flex-col   ">
                <span className="font-medium mb-1  "> {titleObject?.title || '-'} </span>
                <span className="text-xs font-medium">{questionObject?.question || '-'}</span>
                <div className="mt-1">
                    {answersObject?.map((answer, i) => (
                        <p key={answer + i} className=" text-lightBlack text-xs mb-1 ">
                            <span>{i + 1}.</span> <span> {answer || '-'}</span>
                        </p>
                    ))}
                </div>
            </p>
            <p className="text-sm justify-self-center">
                {microtaskTypeData.find((ele) => ele?.type === dataTask?.actionType).label}
            </p>

            <div>
                {dataTask?.submission?.answer[0]?.split(',').map((ans, i) => (
                    <p key={ans + i} className=" text-lightBlack mb-1 ">
                        <span>{i + 1}.</span> <span> {ans || '-'}</span>
                    </p>
                ))}
            </div>
        </Table.Row>
    );
};

export default MultiSelectMCQSD;
