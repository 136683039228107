export const getProvider = () => {
    if ('phantom' in window) {
        const provider = window.phantom?.solana;

        if (provider?.isPhantom) {
            return provider;
        }
    }
    // it determines wheteher the phantom is installed or not . If not installed redirecting to this page
    window.open('https://phantom.app/', '_blank');
    return false;
};
