import LeftSection from '../features/Organization/organizationhomepage/LeftSection';
import RightSection from '../features/Organization/organizationhomepage/RightSection';
import RightSectionForExternalUser from '../features/Organization/organizationhomepage/RightSectionForExternalUser';
import LeftSectionForExternalUser from '../features/Organization/organizationhomepage/LeftSectionForExternalUser';
import { useOutletContext, useParams } from 'react-router-dom';

function ParticularOrganization() {
    const { id } = useParams();
    const [particularOrg, orgMembers, roles, stats, fundsAllocated] = useOutletContext();

    return (
        <div>
            <div className=" grid grid-cols-2 gap-6">
                {roles.length > 0 && (
                    <>
                        {/* <p>Right Page</p> */}
                        <LeftSection orgMembers={orgMembers} roles={roles} stats={stats} />
                        <RightSection organisation={particularOrg} fundsAllocated={fundsAllocated} />
                    </>
                )}
                {!roles.length && (
                    <>
                        {/* <div>External here</div> */}
                        <LeftSectionForExternalUser />
                        <RightSectionForExternalUser fundsAllocated={fundsAllocated} />
                    </>
                )}
            </div>
        </div>
    );
}

export default ParticularOrganization;
