import LinkifiedMessage from '../../../ui/LinkifiedMessage';

const InstructionalTextSubDetails = ({ data }) => {
    const { action } = data;

    const titleObject = action?.find((obj) => obj.name === 'Title');
    const messageObject = action?.find((obj) => obj.name === 'message');
    const linksObject = action?.find((obj) => obj.name === 'links');
    const imageObject = action?.find((obj) => obj.name === 'images');

    return (
        <>
            <p className="flex gap-1 text-xs text-secondaryInput mb-2">
                <span>Response type:</span>{' '}
                <span className="font-bold capitalize">{data?.label?.split('_')?.join(' ')?.toLowerCase()}</span>
            </p>
            <p className="font-semibold capitalize text-primaryInput mb-2">
                <span>{titleObject?.value}</span>
            </p>
            <p className="text-sm text-primaryInput mb-2">
                <LinkifiedMessage message={messageObject?.value} links={linksObject?.value} />
            </p>
            {imageObject?.value && <img className="w-32 object-cover h-32 rounded-md" src={imageObject.value} alt="" />}
        </>
    );
};

export default InstructionalTextSubDetails;
