import React from 'react';
import { useController } from 'react-hook-form';
import Input from './Input';

const InputForDragAndDrop = ({
    name,
    control,
    index,
    placeholder = 'criteria',
    error = '',
    width = 'w-full',
    className,
    type = 'text',
    validateObject = {},
    containerClass = '',
}) => {
    const { field } = useController({
        name,
        control,
        rules: validateObject,
    });

    return (
        <Input
            {...field}
            error={error}
            type={type}
            className={` ${width} ${className}`}
            containerClass={containerClass}
            placeholder={`${placeholder} `}
        />
    );
};

export default InputForDragAndDrop;
