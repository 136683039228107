import { Outlet, useOutletContext } from 'react-router-dom';

function ViewMembersList() {
    const [particularOrg, orgMembers, roles] = useOutletContext();

    return (
        <div className="">
            <Outlet context={[particularOrg, orgMembers, roles]} />
        </div>
    );
}

export default ViewMembersList;
