import mobileFrame from '../../../assets/mobileFrame.png';
import Heading from '../../../ui/Heading';
import SubHeading from '../../../ui/SubHeading';
import { CalendarIcon } from '../../../assets/icons/CalendarIcon';
import { ClockIcon } from '../../../assets/icons/ClockIcon';
import Button from '../../../ui/Button';
import { useStepOneInputs } from '../bountiesHooks/useStepOneInputs';
import { useStepFourInputs } from '../bountiesHooks/useStepFourInputs';
import BountyRadiusIcon from '../../../assets/icons/BountyRadiusIcon';
import BountyTakenTimeIcon from '../../../assets/icons/BountyTakenTimeIcon';
import drop from '../../../assets/impactDrop.png';
import LinkRenderer from '../../../ui/LinkRenderer';
import { converttagsstringinstring } from '../../../helpers/conerttagstringinstring';
import trophy from '../../../assets/trophy.png';
import DefaultSymbol from '../../../assets/icons/DefaultSymbol';
import BackerImageIcon from '../../../assets/icons/BackerImageIcon';
import { bountyTypesWithIconData } from '../../../constants/datas';
function PreviewConfig({ control, criterias, descriptionLinkPayload, radiusPreview }) {
    const { titleField, descriptionField, projectIdField, typeField, dominantCoreField, modeField } =
        useStepOneInputs(control);
    const { expirationTimeField, dayField, hoursField, minutesField } = useStepFourInputs(control);

    const dateObject = expirationTimeField.value && new Date(expirationTimeField.value);
    return (
        <div
            className="relative w-[300px] h-[610px]     "
            style={{
                backgroundImage: `url(${mobileFrame})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <main className="absolute  top-28 left-6 right-6 h-[70%] overflow-auto main">
                <div>
                    <Heading type={'xxsmall'} className={'text-lightBlack'}>
                        {titleField.value || '[Bounty Title] appears here '}
                    </Heading>
                    {/* <p className="text-xs  mt-2 break-words ">{descriptionField.value || 'Description'}</p> */}
                    <LinkRenderer text={descriptionField.value} links={descriptionLinkPayload} />
                </div>
                <div className="mt-2 grid grid-cols-2 items-end gap-2  ">
                    <div className="">
                        <SubHeading type={'xxsmall'}>Bounty Type</SubHeading>
                        <div className="flex flex-col gap-1 mt-1">
                            <p className="bg-previewLight border flex gap-[6px] items-center justify-center text-xs py-1 text-center rounded-3xl border-previewMedium text-previewDark ">
                                <span>
                                    {
                                        bountyTypesWithIconData?.find(
                                            (obj) => obj.label?.toLowerCase() === typeField.value?.toLowerCase()
                                        )?.icon
                                    }
                                </span>{' '}
                                <span>{typeField.value || '-'}</span>{' '}
                            </p>
                        </div>
                    </div>

                    <div className="my-2 col-span-2    ">
                        <SubHeading type={'xxsmall'}>Sponsored By</SubHeading>
                        <div className="flex gap-2 mt-1">
                            <BackerImageIcon />

                            <p className="text-xs uppercase font-semibold  text-minerTitle">{'Sponsor Name'}</p>
                        </div>
                    </div>
                </div>{' '}
                <div className="flex gap-2 my-2 ">
                    <div className="border rounded-md border-borderColor py-2 px-3">
                        <p className="text-xs">Token Name</p>
                        <div className="flex items-center gap-1">
                            <DefaultSymbol />
                            <p>00</p>
                        </div>
                    </div>
                    <div className="border rounded-md border-borderColor py-2 px-3 ">
                        <p className="text-xs">Impact Points</p>
                        <div className="flex items-center gap-1">
                            <img className="w-3 h-3" src={trophy} alt="" />
                            <p>00</p>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <div className="flex items-center justify-between gap-2 my-2 ">
                        <img className="w-3 h-3 object-contain " src={drop} alt="" />

                        <SubHeading className={'w-[90%]'} type={'xxsmall'}>
                            {' '}
                            Impact Certificate _ _ _ _
                        </SubHeading>
                    </div>
                    <div className="flex items-center justify-between gap-2 my-2 ">
                        <span>
                            <CalendarIcon />
                        </span>
                        <SubHeading className={'w-[90%]'} type={'xxsmall'}>
                            {' '}
                            Bounty available till{' '}
                            <span>
                                {dateObject?.toLocaleDateString()
                                    ? dateObject?.toLocaleDateString([], {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                          hour12: true, // Use 12-hour format with AM/PM
                                      })
                                    : ' _ '}
                            </span>
                        </SubHeading>
                    </div>

                    {modeField.value === 'physical' && (
                        <div className="flex items-center justify-between gap-2 my-2 ">
                            <span>
                                <BountyRadiusIcon />
                            </span>

                            <SubHeading className={'w-[90%]'} type={'xxsmall'}>
                                Bounty Radius is {radiusPreview ? radiusPreview + ' Kms' : '_ _ _ _'}
                            </SubHeading>
                        </div>
                    )}
                    <div className="flex items-center justify-between gap-2 my-2  ">
                        <span>
                            <BountyTakenTimeIcon />
                        </span>
                        <SubHeading className={'w-[90%]'} type={'xxsmall'}>
                            {' '}
                            Typically this bounty takes
                            {dayField?.value ? <span>{` ${dayField?.value} days `}</span> : ' _ '}
                            {hoursField?.value ? <span>{` ${hoursField?.value} hours `}</span> : ' _ '}
                            {minutesField?.value ? <span>{` ${minutesField?.value} minutes `}</span> : ' _ '} to
                            complete
                        </SubHeading>
                    </div>
                </div>
                <div className="flex gap-2 my-2 border border-borderColor px-1 py-2 rounded-md ">
                    <BackerImageIcon />

                    <p className="text-xs text-minerSecondary">{'Enter sponsor message (optional)'}</p>
                </div>
                <div>
                    <Heading type={'xxsmall'} className={'text-lightBlack my-1'}>
                        Criteria for completion
                    </Heading>
                    <ul className="flex flex-col gap-1">
                        {criterias
                            .filter((i) => i.criteriaName)
                            .map((criteria, index) => {
                                return (
                                    <li key={criteria + index} className="text-xs flex items-start gap-2 break-words ">
                                        <span className="bg-previewLight font-medium rounded-full w-5 h-5 flex items-center justify-center border border-previewMedium text-previewDark">
                                            {index + 1}
                                        </span>
                                        <p className="break-words w-[80%]  ">{criteria.criteriaName}</p>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </main>

            {/* <footer className="absolute bottom-6 left-6 right-6">
                <Button variant="preview" size="roundPreview">
                    Add Deliverables & Tips
                </Button>
            </footer> */}
        </div>
    );
}

export default PreviewConfig;
