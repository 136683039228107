/* global BigInt */
import * as multisig from '@sqds/multisig';
import { PublicKey, SystemProgram, TransactionMessage, Transaction, VersionedTransaction } from '@solana/web3.js';

import { Program, BN } from '@coral-xyz/anchor';
import * as token from '@solana/spl-token';
import { BountyManager, IDL } from '../types/bounty_manager';
import { BOUNTY_MANAGER_PROGRAM_ID } from '../constants/apiPaths';
import { getProvider } from '../helpers/getProvider';

const bountyMangerProgramId = new PublicKey(BOUNTY_MANAGER_PROGRAM_ID);

export const executorForCreatingProposalForBountyCreation = async (
    connection,
    orgMultisig,
    walletAddress,
    bountyDetails
) => {
    const { title, description, rewardTokenAddress, totalRewardAmount, expirationTimestamp } = bountyDetails;

    const unlockTime = Math.floor(expirationTimestamp.getTime() / 1000);
    const provider = getProvider();
    const program = new Program(/*<BountyManager>*/ IDL, bountyMangerProgramId.toBase58(), provider);

    const multisigPda = new PublicKey(orgMultisig);

    const instructions = [];
    const finalInstructions = [];
    const multisigInfo = await multisig.accounts.Multisig.fromAccountAddress(connection, multisigPda);

    const currentTransactionIndex = Number(multisigInfo.transactionIndex);

    const newTransactionIndex = BigInt(currentTransactionIndex + 1);
    const transactionPda = multisig.getTransactionPda({
        multisigPda: multisigPda,
        index: newTransactionIndex,
    })[0];
    const createKey = multisig.getEphemeralSignerPda({
        transactionPda,
        ephemeralSignerIndex: 0,
    })[0];

    const bounty = PublicKey.findProgramAddressSync(
        [Buffer.from('bounty'), createKey.toBuffer()],
        bountyMangerProgramId
    )[0];
    const [vaultPda] = multisig.getVaultPda({
        multisigPda: multisigPda,
        index: 0,
    });
    let rewardTokenAmountRaw = null;

    if (rewardTokenAddress) {
        const rewardTokenMint = await token.getMint(connection, rewardTokenAddress);
        rewardTokenAmountRaw = new BN(totalRewardAmount * 10 ** rewardTokenMint.decimals);
    }

    const createBountyIx = await program.methods
        .createBounty(title, description, rewardTokenAmountRaw)
        .accounts({
            createKey,
            creator: vaultPda,
            bounty,
            rewardMint: rewardTokenAddress,
            systemProgram: SystemProgram.programId,
        })
        .instruction();
    instructions.push(createBountyIx);
    let sendTokenAccount = null;
    let receiveTokenAccount = null;
    if (rewardTokenAddress) {
        sendTokenAccount = token.getAssociatedTokenAddressSync(rewardTokenAddress, vaultPda, true);
        receiveTokenAccount = token.getAssociatedTokenAddressSync(rewardTokenAddress, bounty, true);
        const fundBountyIx = await program.methods
            .fundBounty(rewardTokenAmountRaw)
            .accounts({
                bounty,
                funder: vaultPda,
                rewardMint: rewardTokenAddress,
                sendTokenAccount,
                receiveTokenAccount,
                tokenProgram: token.TOKEN_PROGRAM_ID,
                associatedTokenProgram: token.ASSOCIATED_TOKEN_PROGRAM_ID,
                systemProgram: SystemProgram.programId,
            })
            .instruction();
        instructions.push(fundBountyIx);
    }
    const startBountyIx = await program.methods
        .startBounty(new BN(unlockTime))
        .accounts({
            creator: vaultPda,
            bounty,
            rewardMint: rewardTokenAddress,
            tokenAccount: receiveTokenAccount,
        })
        .instruction();
    instructions.push(startBountyIx);

    // create transaction message
    const transactionMessage = new TransactionMessage({
        payerKey: vaultPda,
        recentBlockhash: (await connection.getLatestBlockhash()).blockhash,
        instructions,
    });

    // const transaction = new Transaction();

    const vaultTxCreateInstruction = multisig.instructions.vaultTransactionCreate({
        multisigPda: multisigPda,
        transactionIndex: newTransactionIndex,
        creator: walletAddress,
        vaultIndex: 0,
        ephemeralSigners: 1,
        transactionMessage,
    });

    // transaction.add(vaultTxCreateInstruction);
    finalInstructions.push(vaultTxCreateInstruction);
    const proposalCreateInstruction = multisig.instructions.proposalCreate({
        multisigPda: multisigPda,
        creator: walletAddress,
        transactionIndex: newTransactionIndex,
    });

    // transaction.add(proposalCreateInstruction);
    finalInstructions.push(proposalCreateInstruction);

    let blockhash = await connection.getLatestBlockhash().then((res) => res.blockhash);

    const message = new TransactionMessage({
        payerKey: walletAddress,
        recentBlockhash: blockhash,
        instructions: finalInstructions,
    }).compileToV0Message();

    const transaction = new VersionedTransaction(message);

    const proposalAddress = multisig.getProposalPda({
        multisigPda: multisigPda,
        transactionIndex: newTransactionIndex,
    })[0];

    return { transaction, proposalAddress: proposalAddress.toBase58(), bountyAddress: bounty.toBase58() };
};
