import { useEffect, useRef, useState } from 'react';
import Overlay from '../../ui/Overlay';
import Button from '../../ui/Button';
import Feedbackclose from '../../assets/icons/Feedbackclose';
import { toast } from 'sonner';
import { acceptOrDeclineRequestToJoinOrg } from '../../apis/oragnisations/organisation';
import { useWallet } from '@solana/wallet-adapter-react';
import OwnButton from '../walletconnectUI/OwnButton';
import { clusterApiUrl, Connection } from '@solana/web3.js';
import { executorToAddMemberToOrg } from '../../blockchainInteraction/addMembers';
import { getProvider } from '../../helpers/getProvider';
import WalletConnectCustomButton from '../walletconnectUI/WalletCustomText';
import { useLoadingState } from '../../hooks/useLoader';
import Loader from '../../ui/Loader';
import { RPC_ENDPOINT } from '../../constants/apiPaths';

function RequestModal({ close, member, onAction, text, multiSig }) {
    const { publicKey } = useWallet();
    const myRef = useRef({ close });
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [isAnotherModalOpen, setIsAnotherModalOpen] = useState(false);
    useEffect(
        function () {
            function handleClick(e) {
                if (myRef.current && !myRef.current.contains(e.target) && !isAnotherModalOpen) {
                    close();
                }
            }

            document.addEventListener('click', handleClick, true);

            return () => document.removeEventListener('click', handleClick, true);
        },
        [close, isAnotherModalOpen]
    );

    const connectWalletOrNot = [member].filter((member) => {
        return member.roles.includes('TREASURER');
    });
    const address = publicKey;

    return (
        <Overlay>
            <div
                ref={myRef}
                className="absolute rounded-lg left-[50%]  p-6 w-[518px]   bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
            >
                <div>
                    <Button
                        radius="rounded-full"
                        onClick={close}
                        className=" border-0 absolute top-3  right-4 "
                        variant="tertiary"
                        size="circle"
                    >
                        <Feedbackclose />
                    </Button>
                </div>
                <p className="text-xl w-[90%] text-primaryInput">
                    Are you sure you want to{' '}
                    {text === 'Accept' ? (
                        <span className="font-bold">add {member.requesterUsername}</span>
                    ) : (
                        <span className="font-bold">decline the request by {member.requesterUsername} </span>
                    )}
                </p>
                <div className="flex items-center mt-8 gap-3">
                    <div>
                        {connectWalletOrNot.length && !address && text?.toLowerCase() !== 'decline' ? (
                            // <OwnButton setIsAnotherModalOpen={setIsAnotherModalOpen} size="round" text="Submitt" />

                            <div className="w-full flex items-center">
                                <WalletConnectCustomButton
                                    customText={'Submit'}
                                    width="128px"
                                    customStyles={{
                                        backgroundColor: 'var(--primary-color)',
                                        color: '#fff',
                                        border: '1px solid #dcdcdc',
                                        lineHeight: 'normal',
                                        fontWeight: '500',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        height: '40px',
                                        borderRadius: '100px',
                                        flexGrow: 1,
                                    }}
                                    connectedText={'Submit'}
                                    disconnectedText="Submit"
                                    removeIconOnDisconnect={true}
                                    removeIconOnConnect={true}
                                    removeIconOnWalletSelect={true}
                                    removeIconOnConnecting={true}
                                />
                            </div>
                        ) : (
                            <Button
                                radius="rounded-full"
                                onClick={async () => {
                                    try {
                                        if (text === 'Accept') {
                                            if (connectWalletOrNot.length) {
                                                const connection = new Connection(RPC_ENDPOINT, 'confirmed');

                                                const walletAdresses = connectWalletOrNot.map(
                                                    (item) => item.requesterWalletAddress
                                                );

                                                const transaction = await executorToAddMemberToOrg(
                                                    walletAdresses,
                                                    publicKey.toBase58(),
                                                    multiSig,
                                                    connection
                                                );

                                                const { signature } = await getProvider().signAndSendTransaction(
                                                    transaction
                                                );

                                                await connection.getSignatureStatus(signature);
                                            }
                                            startLoading();
                                            const { success } = await acceptOrDeclineRequestToJoinOrg(member._id, true);
                                            if (success) {
                                                toast.success('Invitation request accepted. ');
                                                stopLoading();
                                                close();
                                                onAction(member._id);
                                            }
                                        }
                                        if (text === 'Decline') {
                                            startLoading();
                                            const { success } = await acceptOrDeclineRequestToJoinOrg(
                                                member._id,
                                                false
                                            );
                                            if (success) {
                                                toast.success('Invitation request deleted. ');
                                                stopLoading();
                                                close();
                                                onAction(member._id);
                                            }
                                        }
                                    } catch (error) {
                                        console.log(error);
                                    }
                                }}
                                size="round"
                                variant="primary"
                            >
                                {loading ? <Loader /> : 'Submit'}
                            </Button>
                        )}
                    </div>
                    <Button radius="rounded-full" onClick={close} size="round" variant="tertiary">
                        Cancel
                    </Button>
                </div>
            </div>
        </Overlay>
    );
}

export default RequestModal;
