import { useEffect, useState } from 'react';
import { getExploreOrg } from '../../apis/oragnisations/organisation';
import Loader from '../../ui/Loader';

import SummaryCard from '../../ui/SummaryCard';
import BackButton from '../../assets/icons/BackButton';
import Heading from '../../ui/Heading';
import { useNavigate } from 'react-router-dom';
import SummaryCardInAll from './SummaryCardInAll';

function AllOrganizations() {
    const navigate = useNavigate();
    const [impactOrganizations, setImpactOrganizations] = useState([]);
    useEffect(function () {
        const fetchData = async () => {
            try {
                const data = await getExploreOrg(100);

                setImpactOrganizations(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <div className="w-[50%] mb-6">
                <div className="flex items-center">
                    <BackButton onClick={() => navigate(-1)} />
                    <Heading type={'pageHeading'} className={'text-lightBlack ml-3'}>
                        Explore Impact Organizations
                    </Heading>
                </div>
                <p className="mt-2">
                    Discover Impact Orgs that match your interest and skill. Click on any of organization of your
                    interest and express a desire to join them, it is that simple to get started on an impactful career.
                </p>
            </div>
            <div className={`grid grid-cols-2 gap-6`}>
                {!impactOrganizations.length ? (
                    <div className="h-40 col-span-2 bg-white flex items-center justify-center">
                        <Loader />
                    </div>
                ) : (
                    <>
                        {impactOrganizations.map((organization) => (
                            <SummaryCardInAll
                                redirectTo={() => navigate(`/organizations/${organization._id}`)}
                                className="shadow-md"
                                key={organization.name + organization.tagline}
                                organization={organization}
                            />
                        ))}
                    </>
                )}
            </div>
        </>
    );
}

export default AllOrganizations;
