export const CalendarIcon = () => {
    return (
        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.24649 1.75359H7.73591V0.732422H6.71474V1.75359H2.63007V0.732422H1.6089V1.75359H1.09832C0.531568 1.75359 0.0822543 2.21311 0.0822543 2.77476L0.0771484 9.92293C0.0771484 10.4846 0.531568 10.9441 1.09832 10.9441H8.24649C8.80813 10.9441 9.26766 10.4846 9.26766 9.92293V2.77476C9.26766 2.21311 8.80813 1.75359 8.24649 1.75359ZM8.24649 9.92293H1.09832V4.81709H8.24649V9.92293ZM8.24649 3.79592H1.09832V2.77476H8.24649V3.79592ZM3.14065 6.85943H2.11948V5.83826H3.14065V6.85943ZM5.18299 6.85943H4.16182V5.83826H5.18299V6.85943ZM7.22532 6.85943H6.20415V5.83826H7.22532V6.85943ZM3.14065 8.90176H2.11948V7.88059H3.14065V8.90176ZM5.18299 8.90176H4.16182V7.88059H5.18299V8.90176ZM7.22532 8.90176H6.20415V7.88059H7.22532V8.90176Z"
                fill="#49454F"
            />
        </svg>
    );
};
