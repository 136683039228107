import { useState } from 'react';
import { InfoTooltipIcon } from '../assets/icons/UserProfileIcons';
import Heading from './Heading';

function TextWithTooltipOnClick({ infoColor = '#969696', hoverOverText = '', hoverOverTextColor = 'text-', children }) {
    const [show, setShow] = useState(false);
    return (
        <div className="  flex items-center gap-2 w-fit ">
            <p className={`${hoverOverTextColor}  cursor-pointer font-bold `}>
                <span onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
                    {' '}
                    {hoverOverText}
                </span>
            </p>
            <div className="relative">
                {show && (
                    <div className="absolute  flex  -left-36 top-3   ">
                        <div className="bg-white w-80 text-lightBlack rounded-lg shadow-tooltipShadow p-6">
                            {children}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TextWithTooltipOnClick;
