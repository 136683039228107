function FlipCard({ FrontCard, BackCard, width = 'w-[331px]', height = 'h-[363px]', certificateId, fixW, image }) {
    return (
        <div className={`container rounded ${fixW} mr-5`}>
            <div className={`card  relative ${width} ${height}  `}>
                <div className="absolute top-0 left-0   h-[100%] front">{<FrontCard image={image} />}</div>
                <div className="h-[100%] absolute  top-0 left-0 back">
                    {<BackCard image={image} certificateId={certificateId} />}
                </div>
            </div>
        </div>
    );
}

export default FlipCard;
