import React from 'react';
import AvatarImage from '../../ui/AvatarImage';
import Tag from '../../ui/Tag';

function OrgProfileCard({
    position = '',
    className = '',
    organization,

    redirectTo,
}) {
    const { name, tagline, logo, cores, roles } = organization;
    const isAdmin = roles.includes('ADMIN');
    const isBountyValidator = roles.includes('BOUNTY_VALIDATOR');
    const isBountyManager = roles.includes('BOUNTY_MANAGER');
    const isTreasurer = roles.includes('TREASURER');

    return (
        <div
            onClick={redirectTo}
            className={` bg-white  cursor-pointer first:pt-0 last:pb-0    last:border-b-0  py-4 border-b border-borderColor ${className} `}
        >
            <div className="flex items-center ">
                <AvatarImage w="w-12" h="h-12" url={logo} />

                <div className="px-4 ">
                    <p className="font-medium  text-primaryInput ">{name}</p>
                    <p className="text-sm text-secondaryInput ">{tagline}</p>

                    <div className="flex items-center  gap-[10px] mt-2 ">
                        <>
                            {isAdmin && (
                                <Tag
                                    rounded="rounded-lg "
                                    className="px-2 py-1 leading-3   "
                                    textClassName="text-xs font-semibold"
                                    bg="bg-foundryPurpleL"
                                    textColor="text-foundryPurple"
                                    text={'Admin'}
                                />
                            )}
                            {isBountyValidator && (
                                <Tag
                                    rounded="rounded-lg "
                                    className="px-2 py-1 leading-3   "
                                    textClassName="text-xs font-semibold"
                                    bg="bg-foundryGreenL"
                                    textColor="text-foundryGreen"
                                    text={'Validator'}
                                />
                            )}
                            {isBountyManager && (
                                <Tag
                                    rounded="rounded-lg"
                                    className="px-2 py-1 leading-3   "
                                    textClassName="text-xs font-semibold"
                                    bg="bg-foundryRedL"
                                    textColor="text-foundryRed"
                                    text={'Bounty Manager'}
                                />
                            )}
                            {isTreasurer && (
                                <Tag
                                    rounded="rounded-lg"
                                    className="px-2 py-1 leading-3   "
                                    textClassName="text-xs font-semibold"
                                    bg="bg-treasurerBg"
                                    textColor="text-treasurerText"
                                    text={'Treasurer'}
                                />
                            )}
                        </>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrgProfileCard;
