import React, { useContext } from 'react';
import AvatarImage from '../../ui/AvatarImage';
import Status from '../../ui/Status';
import { coresWithIconData } from '../../constants/datas';
import { AuthContext } from '../../contexts/AuthContext';
import Tag from '../../ui/Tag';

function SummaryCardInAll({
    position = '',
    className = '',
    organization,

    redirectTo,
}) {
    const { state } = useContext(AuthContext);
    const { user } = state;
    const userProfile = user[0];

    const { name, tagline, logo, cores, createdAt, adminUsername, roles } = organization;

    const createdByString = userProfile?.userName === adminUsername ? 'Me' : adminUsername;
    const isAdmin = roles?.includes('ADMIN');
    const isBountyValidator = roles?.includes('BOUNTY_VALIDATOR');
    const isBountyManager = roles?.includes('BOUNTY_MANAGER');
    const isTreasurer = roles?.includes('TREASURER');

    return (
        <div
            onClick={redirectTo}
            className={` bg-white  cursor-pointer p-6 rounded-lg  border border-borderColor ${className} `}
        >
            <div className="flex items-center ">
                <AvatarImage w="w-12" h="h-12" url={logo} />

                <div className="px-4 ">
                    <p className="font-medium  text-primaryInput ">{name}</p>
                    <p className="text-sm text-secondaryInput ">{tagline}</p>
                </div>
            </div>
            <div className=" flex  items-center gap-2 mt-4 ">
                <p className="text-sm font-bold text-thirdGray">Impact Cores: </p>
                <div className="flex items-center gap-2">
                    {cores.map(
                        (text) =>
                            text && (
                                <p key={text} className="flex items-center gap-3">
                                    <img
                                        className={` ${
                                            text.toLowerCase() === 'water' || text.toLowerCase() === 'social'
                                                ? 'w-4 mr-1'
                                                : 'w-5 h-5'
                                        }  `}
                                        src={
                                            coresWithIconData?.find(
                                                (obj) => obj.label.toLowerCase() === text.toLowerCase()
                                            )?.icon
                                        }
                                        alt="image"
                                    />
                                </p>
                            )
                    )}{' '}
                </div>
            </div>
            <div className="flex items-center gap-2 mt-2">
                <Status bg="bg-secondGray" text={new Date(createdAt).toLocaleDateString()} />
                <Status bg="bg-secondGray" text={'Created by ' + createdByString} />
            </div>
            <div className="flex items-center  gap-[10px] mt-2 ">
                <>
                    {isAdmin && (
                        <Tag
                            rounded="rounded-lg "
                            className="px-2 py-1 leading-3   "
                            textClassName="text-xs font-semibold"
                            bg="bg-foundryPurpleL"
                            textColor="text-foundryPurple"
                            text={'Admin'}
                        />
                    )}
                    {isBountyValidator && (
                        <Tag
                            rounded="rounded-lg "
                            className="px-2 py-1 leading-3   "
                            textClassName="text-xs font-semibold"
                            bg="bg-foundryGreenL"
                            textColor="text-foundryGreen"
                            text={'Validator'}
                        />
                    )}
                    {isBountyManager && (
                        <Tag
                            rounded="rounded-lg"
                            className="px-2 py-1 leading-3   "
                            textClassName="text-xs font-semibold"
                            bg="bg-foundryRedL"
                            textColor="text-foundryRed"
                            text={'Bounty Manager'}
                        />
                    )}
                    {isTreasurer && (
                        <Tag
                            rounded="rounded-lg"
                            className="px-2 py-1 leading-3   "
                            textClassName="text-xs font-semibold"
                            bg="bg-treasurerBg"
                            textColor="text-treasurerText"
                            text={'Treasurer'}
                        />
                    )}
                </>
            </div>
        </div>
    );
}

export default SummaryCardInAll;
