import { useEffect, useState } from 'react';
import DeleteIcon from '../../assets/icons/Delete';
import { invitationMembers } from '../../constants/invitationMembers';
import Button from '../../ui/Button';
import Dropdown from '../../ui/Dropdown';
import Heading from '../../ui/Heading';
import Input from '../../ui/Input';
import Table from '../../ui/Table';
import Checkbox from '../../ui/Checkbox';
import NoMemberAdded from './NoMemberAdded';
import { containsObjectWithKeyAndString } from '../../helpers/isObjectContainsKeyAndString';
import { checkIfAlreadyInvited, getAllInvitations, searchUsers, sendingInvitation } from '../../apis/user/user';
import AvatarImage from '../../ui/AvatarImage';
import { toast } from 'sonner';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import SendInvitation from '../Organization/organizationhomepage/SendInvitation';
import { findUsersWithSameRoles } from '../../helpers/findUsersWithSameRoles';
let filteredMembers = invitationMembers;
const data = ['BOUNTY_MANAGER', 'BOUNTY_VALIDATOR'];

function InviteMembers() {
    const [searchQuery, setSearchQuery] = useState(''); // search text

    const [userSuggestions, setUserSuggestions] = useState([]); // search suggestions
    const [membersToBeInvited, setMembersToBeInvited] = useState([]); // members added in list
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [{ memberList }] = useOutletContext();
    const [selected, setSelected] = useState([]); // checkbox selection
    const { id: orgId } = useParams();
    const y = useLocation();

    useEffect(() => {
        // If searchText is empty, clear suggestions
        if (!searchQuery) {
            setUserSuggestions([]);
            return;
        }

        // Set a timeout to debounce the API call
        const delayDebounceFn = setTimeout(async () => {
            const response = await searchUsers(searchQuery);
            // const data = await response.json();

            setUserSuggestions(response);
        }, 300); // 300ms debounce time

        // Clear the timeout if searchText changes before the delay is over
        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery]); // Dependency array to trigger the effect when searchText changes

    const addMemberToListForInvitation = async (invitedMember) => {
        const shouldInvite = !membersToBeInvited.some((invited) => invited.uid === invitedMember.uid);

        // If user is already part of organization
        const isPartOfOrg = Boolean(memberList.find((userToBeInvited) => userToBeInvited.uid === invitedMember.uid));

        // the case when user is already present in list
        if (isPartOfOrg) {
            toast.error('This user is already part of this organization !');
            return;
        }
        if (shouldInvite) setMembersToBeInvited((initial) => [...initial, invitedMember]);
        else toast.error('This user is already present in the list !');
    };

    function updateValue(value, member) {
        if (value) setSelected([...selected, member]);
        else setSelected(selected.filter((item) => item.uid !== member.uid));
    }
    function updateOptions(value, name, member) {
        if (value) {
            const isPresent = selectedRoles.findIndex((i) => Object.keys(i).includes(member.uid));

            const ar = selectedRoles.map((op) => {
                if (Object.keys(op).includes(member.uid)) return { [member.uid]: [...op[member.uid], name] };
                return op;
            });

            if (isPresent < 0) setSelectedRoles([...selectedRoles, { [member.uid]: [name] }]);
            else setSelectedRoles(ar);
        } else {
            const filteredArr = selectedRoles.map((op) => {
                if (Object.keys(op).includes(member.uid))
                    return { [member.uid]: op[member.uid].filter((item) => item !== name) };
                return op;
            });

            setSelectedRoles(filteredArr);
        }
    }
    function selectAll(value) {
        if (value) setSelected(membersToBeInvited.map((member) => member));
        else setSelected([]);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const selectedWithRoles = selected.map((user) => {
            const roleObj = selectedRoles.find((role) => role[user.uid]);
            user.roles = roleObj ? roleObj[user.uid] : [];
            return user;
        });

        const isEveryMemberAssignedRole = selectedWithRoles.every((user) => user.roles.length > 0);
        if (!selected.length > 0) {
            toast.error('Please select atleast one user for invitation');
            return;
        }
        if (!isEveryMemberAssignedRole) {
            toast.error('Please assign atleast one role to every user');
            return;
        }

        const invitedUsers = await getAllInvitations(orgId);
        const invitedUsersWithSENTORACCEPTEDSTATUS = invitedUsers.filter(
            (invitedUser) => invitedUser.status === 'SENT' || invitedUser.status === 'ACCEPTED'
        );

        const usersToBeInvitedWithSameRole = findUsersWithSameRoles(
            invitedUsersWithSENTORACCEPTEDSTATUS,
            selectedWithRoles
        );

        if (usersToBeInvitedWithSameRole.length > 0) {
            const uids = usersToBeInvitedWithSameRole.map((user) => user.uid);

            toast.error(`Please change role for the users having uid ${uids.join(' and ')} `);
            return;
        }

        const invitationsData = selectedWithRoles.map((invite) => ({ uid: invite.uid, roles: invite.roles }));
        const dataToSend = { organisationId: orgId, invitations: invitationsData };
        // const responseInvited = await checkIfAlreadyInvited(orgId);
        const { success } = await sendingInvitation(dataToSend);
        if (success) toast.success('User is successfully invited!');
        setMembersToBeInvited([]);
    };

    return (
        <div>
            <div className="w-[50%] relative">
                <Heading className={'text-black font-semibold mb-2 '} type={'xxsmall'}>
                    Invite to your Organization
                </Heading>

                <Input
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full"
                    placeholder="Type Usernames of member you want to add"
                />
                <div
                    id="dropdown"
                    className={`z-[2000] w-full top-[105%]  ${
                        searchQuery ? 'absolute' : 'hidden'
                    }  bg-white divide-y divide-gray-100 rounded-lg  shadow-md  `}
                >
                    <ul class="  " aria-labelledby="dropdownDefaultButton">
                        {userSuggestions.map((member, index) => (
                            <li
                                onClick={() => {
                                    addMemberToListForInvitation(member);
                                    setSearchQuery('');
                                }}
                                key={member._id}
                                className=" flex items-center border-b border-borderColor  gap-3 cursor-pointer p-4 hover:bg-gray-100 "
                            >
                                <AvatarImage w="w-[32px]" h="h-[32px]" url={member.profileImage} />
                                <div className="flex flex-col ">
                                    <span className="text-lightBlack capitalize ">{member.userName}</span>
                                    <span className="text-sm">{member.uid}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            {membersToBeInvited.length === 0 ? (
                <NoMemberAdded />
            ) : (
                <form onSubmit={handleSubmit} action="">
                    <Table cols="grid-cols-tableInvite gap-2">
                        <Table.Description
                            description={`${membersToBeInvited.length} Members`}
                            secondaryBtn="Remove"
                            clickOnSecondary={() => {
                                setMembersToBeInvited([]);
                            }}
                        >
                            {/* <div className="relative">
                            <Dropdown w="w-72" btnText="Assign Roles" />
                            
                        </div> */}
                            {/* <div className="relative">
                            <Dropdown
                                w="w-72"
                                btnText="Assign Roles"
                                data={data}
                                selectedOptions={selectedOptions}
                                render={(listItem, index) => (
                                    <Checkbox
                                        name={listItem}
                                        value={selectedOptions.includes(listItem)}
                                        label={listItem}
                                        update={() => updateOptions(!selectedOptions.includes(listItem))}
                                    />
                                )}
                                withCheckBox={true}
                            />
                        </div> */}
                        </Table.Description>

                        <Table.Header>
                            <Checkbox
                                name="all"
                                value={selected.length === membersToBeInvited.length}
                                update={() => selectAll(!(selected.length === membersToBeInvited.length))}
                            />
                            <p className="text-sm w- self-center font-semibold text-secondaryText ">Citizen ID</p>
                            <p className="text-sm self-center font-semibold text-secondaryText ">Member Username</p>
                            <p className="text-sm self-center  font-semibold text-secondaryText ">Roles</p>
                            <p className="text-sm  self-center font-semibold text-secondaryText ">Remove</p>
                        </Table.Header>

                        {membersToBeInvited.map((member, i) => {
                            return (
                                <Table.Row key={member._id}>
                                    <Checkbox
                                        name={member.userName}
                                        value={selected.map((user) => user.uid).includes(member.uid)}
                                        update={() => {
                                            updateValue(!selected.map((user) => user.uid).includes(member.uid), member);
                                        }}
                                    />
                                    <p className="truncate w-28 self-center ">{member.uid}</p>
                                    <p className="self-center">{member.userName}</p>
                                    <div className="relative">
                                        <Dropdown
                                            w="w-full"
                                            data={data}
                                            selected={selectedRoles}
                                            id={member.uid}
                                            render={(listItem, index) => {
                                                return (
                                                    <Checkbox
                                                        name={member.uid + listItem}
                                                        value={containsObjectWithKeyAndString(
                                                            selectedRoles,
                                                            member.uid,
                                                            listItem
                                                        )}
                                                        label={listItem}
                                                        update={() =>
                                                            updateOptions(
                                                                !containsObjectWithKeyAndString(
                                                                    selectedRoles,
                                                                    member.uid,
                                                                    listItem
                                                                ),
                                                                listItem,
                                                                member
                                                            )
                                                        }
                                                    />
                                                );
                                            }}
                                            withCheckBox={true}
                                        />
                                    </div>

                                    <p
                                        onClick={() => {
                                            setMembersToBeInvited(
                                                membersToBeInvited.filter((z) => z.uid !== member.uid)
                                            );

                                            filteredMembers.push(member);
                                        }}
                                        className=" place-self-center"
                                    >
                                        <DeleteIcon />
                                    </p>
                                </Table.Row>
                            );
                        })}
                    </Table>
                    <div className="flex justify-end">
                        <Button type={'submit'} className={``} size="small">
                            Send Invites
                        </Button>
                    </div>
                </form>
            )}
        </div>
    );
}

export default InviteMembers;
