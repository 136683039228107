import { Outlet, useNavigate } from 'react-router-dom';
import BackButton from '../assets/icons/BackButton';
import Heading from '../ui/Heading';
import { useEffect } from 'react';
import { useElementHider } from '../contexts/ElementHider';

function AttestInorganisation() {
    const navigate = useNavigate();
    const { handleSetHiderClass } = useElementHider();
    useEffect(
        function () {
            handleSetHiderClass('hideOnSomePage');

            return () => handleSetHiderClass('');
        },
        [handleSetHiderClass]
    );
    return (
        <div className="pt-7">
            <div>
                <div className="w-[65%] mb-9">
                    <div className="flex items-center">
                        <BackButton onClick={() => navigate(-1)} />
                        <Heading type={'pageHeading'} className={'text-lightBlack ml-3'}>
                            Attest Impact Claims
                        </Heading>
                    </div>
                    <p className="mt-2">
                        In the table below you will find Impact Claims waiting to be evaluated for their authenticity,
                        if found to be a credible impact claim in your impartial evaluation, then attest the impact.
                    </p>
                </div>
            </div>
            <Outlet />
        </div>
    );
}

export default AttestInorganisation;
