import { useEffect, useRef } from 'react';
import Overlay from '../../ui/Overlay';
import Feedbackclose from '../../assets/icons/Feedbackclose';
import Button from '../../ui/Button';
import { createPortal } from 'react-dom';

function SelectWalletModal({ close, children }) {
    const myRef = useRef({ close });
    useEffect(
        function () {
            function handleClick(e) {
                if (myRef.current && !myRef.current.contains(e.target)) {
                    close();
                }
            }

            document.addEventListener('click', handleClick, true);

            return () => document.removeEventListener('click', handleClick, true);
        },
        [close]
    );
    return createPortal(
        <Overlay>
            <div
                ref={myRef}
                className="absolute rounded-lg left-[50%] w-[625px]  p-14   bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
            >
                <div>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            close();
                        }}
                        className=" border-0 absolute top-3  right-4 "
                        variant="tertiary"
                        size="circle"
                    >
                        <Feedbackclose />
                    </Button>
                </div>
                {children}
            </div>
        </Overlay>,
        document.body
    );
}

export default SelectWalletModal;
