import { useNavigate } from 'react-router-dom';
import BackButton from '../assets/icons/BackButton';
import Heading from '../ui/Heading';
import EditProfileDetails from '../features/profile/editprofile/EditProfileDetails';

function EditProfile() {
    const navigate = useNavigate();

    return (
        <>
            <div className="flex items-center">
                <BackButton onClick={() => navigate(-1)} />
                <Heading type={'pageHeading'} className={'text-lightBlack ml-3'}>
                    Edit Profile
                </Heading>
            </div>
            <EditProfileDetails />
        </>
    );
}

export default EditProfile;
