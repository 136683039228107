import { useFieldArray } from 'react-hook-form';
import DeleteIcon from '../../../assets/icons/Delete';
import InputForDragAndDrop from '../../../ui/InputForDragAndDrop';
import Input from '../../../ui/Input';
import Button from '../../../ui/Button';
import IsSkippable from './IsSkippable';

const TestMCQ = ({ register, index, errors, control, setValue, getValues, microtaskFields }) => {
    const {
        fields: testMCQ_Answers_Fields,
        append: appendAnswer,
        remove: removeAnswer,
    } = useFieldArray({
        name: `microtasks[${index}].testMCQ_answers`,
        control,
    });

    if (testMCQ_Answers_Fields.length === 0) {
        appendAnswer({ answer: '' }); // Append default value if no fields exist
    }

    return (
        <div>
            <Input
                {...register(`microtasks.${index}.testMCQ_Title`, {
                    required: 'Please enter a title',
                })}
                type="transparent"
                error={errors?.microtasks && errors?.microtasks[index]?.testMCQ_Title?.message}
                placeholder={'Title *'}
                transparentTextSize="text-base"
                className={'w-[100%] mb-1 '}
            />
            <Input
                {...register(`microtasks.${index}.testMCQ_question`, {
                    required: 'Please enter a question ',
                })}
                type="transparent"
                error={errors?.microtasks && errors?.microtasks[index]?.testMCQ_question?.message}
                placeholder={'Question *'}
                transparentTextSize="text-sm"
                transparentFontWeight={'font-normal'}
                className={'w-[100%] mb-1 mt-2 '}
            />

            <div className="mt-2  items-center">
                <div className="flex items-center justify-end  gap-3  w-full">
                    <p>Correct Answer</p>
                </div>
                {testMCQ_Answers_Fields?.map((answerField, i) => {
                    return (
                        <div key={answerField.id} className="flex  gap-3 mb-3 w-full">
                            <p className="relative top-3">
                                <span className="text-primaryInput">Option {i + 1} :</span>
                            </p>
                            <div className="flex-1">
                                <InputForDragAndDrop
                                    name={`microtasks[${index}].testMCQ_answers[${i}].answer`}
                                    control={control}
                                    index={i}
                                    width="w-full"
                                    placeholder="Enter an option"
                                    error={
                                        errors?.microtasks
                                            ? errors?.microtasks[index]?.testMCQ_answers
                                                ? errors?.microtasks[index]?.testMCQ_answers[i]?.answer?.message
                                                : ''
                                            : ''
                                    }
                                    validateObject={{
                                        required: 'Please enter an option',
                                        // maxLength: {
                                        //     value: 200,
                                        //     message: 'The maximum length is 200 characters',
                                        // },
                                    }}
                                />
                            </div>
                            <div className="relative top-3">
                                <div className="flex  items-center  justify-between w-[70px]">
                                    <DeleteIcon
                                        className={`${i > 0 ? 'cursor-pointer ' : 'invisible'}`}
                                        onClick={() => removeAnswer(i)}
                                    />
                                    <input
                                        type="radio"
                                        name={`microtasks[${index}].testMCQ_correctAnswer`}
                                        value={i}
                                        {...register(`microtasks.${index}.testMCQ_correctAnswer`, {
                                            required: 'Please select the correct answer.',
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}

                <Button
                    type="button"
                    onClick={() => appendAnswer({ answer: '' })}
                    size="none"
                    className="  font-semibold !text-lightBlack text-sm"
                    variant="transparent"
                >
                    + Add Option
                </Button>
                <div>
                    {errors?.microtasks && (
                        <p className="text-foundryRed">{errors?.microtasks[index]?.testMCQ_answers?.root?.message}</p>
                    )}
                </div>
            </div>

            {/* 
            <Input
                {...register(`microtasks.${index}.testMCQ_instructionHeading`, {
                    required: 'Please enter heading for Test MCQ.',
                })}
                type="transparent"
                error={
                    errors?.microtasks && errors?.microtasks[index]?.testMCQ_instructionHeading?.message
                }
                placeholder={'Heading for Test MCQ...'}
                transparentTextSize="text-base"
                className={'w-[100%] mb-1 mt-2 '}
            />

            <div className="mt-2  items-center">
                {microtaskFields?.map((field, i) => {
                    return (
                        <div key={field.id} className="flex items-center gap-3 mb-3 w-full">
                            <p>
                                <span>Instruction :</span> <span>{i + 1}</span>
                            </p>
                            <InputForDragAndDrop
                                name={`microtasks[${index}].testMCQ_instructions[${i}].instruction`}
                                control={control}
                                index={i}
                                width="w-[50%]"
                                placeholder="instruction"
                            />
                            <DeleteIcon onClick={() => removeAnswer(i)} />
                        </div>
                    );
                })}

                <Button
                    type="button"
                    onClick={() => appendAnswer({ instruction: '' })}
                    size="none"
                    className="  font-semibold !text-lightBlack text-sm"
                    variant="transparent"
                >
                    + Add Instruction
                </Button>
                <div>
                    {errors?.microtasks && (
                        <p className="text-foundryRed">{errors?.microtasks[index]?.testMCQ_instructions?.root?.message}</p>
                    )}
                </div>
            </div> 
            */}

            {/* Skippable Field */}
            {/* <div className="flex gap-9 my-2 items-center ">
                <IsSkippable
                    selectedOption={skippableField.value}
                    onSelectOption={(value) => {
                        skippableField.onChange(value);
                    }}
                    options={['Yes', 'No']}
                />
            </div> */}
        </div>
    );
};

export default TestMCQ;
