import RouteNav from './routes/Routes';
import { useEffect, useContext } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
// import { AuthContext } from './contexts/AuthContext';
import WalletContextProvider from './contexts/WalletContextProvider';
import { DataProvider } from './contexts/DataContext';

const { WalletAdapterNetwork } = require('@solana/wallet-adapter-base');
const { ConnectionProvider, WalletProvider } = require('@solana/wallet-adapter-react');
const { WalletModalProvider, WalletMultiButton } = require('@solana/wallet-adapter-react-ui');
const { UnsafeBurnerWalletAdapter } = require('@solana/wallet-adapter-wallets');
const { clusterApiUrl } = require('@solana/web3.js');
const React = require('react');

require('./App.css');
require('@solana/wallet-adapter-react-ui/styles.css');

const App = () => {
    // const{setAuthenticated} =  useContext(AuthContext)

    return (
        // <Content>
        <WalletContextProvider>
            <AuthProvider>
                <DataProvider>
                    <RouteNav />
                </DataProvider>
            </AuthProvider>
        </WalletContextProvider>
        // </Content>
    );
};
export default App;
