import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.bubble.css'; // Import Quill Bubble theme styles
import Heading from './Heading';
import CharacterLimit from './CharacterLimit';
import TextWithTooltip from './TextWithTooltip';
import { createPortal } from 'react-dom';
import { removeAllDuplicates, textToHtml } from '../helpers/plainText';
let Abc;

const RichTextEditorBubbleLinkOnly = ({ descriptionField, handleDescriptionLinkPayload, descriptionLinkPayload }) => {
    const modules = {
        toolbar: [['link']],
    };
    const quillRef = useRef(null);
    // Local state to manage HTML content with tags for the editor
    const [htmlContent, setHtmlContent] = useState(textToHtml(descriptionField.value, descriptionLinkPayload) || '');

    useEffect(function () {
        handleDescriptionLinkPayload(descriptionLinkPayload);
    }, []);

    useEffect(() => {
        // Access the Quill editor instance
        const quillInstance = quillRef.current.getEditor();

        // Function to move the tooltip to the portal
        const moveTooltipToPortal = () => {
            const tooltip = document.querySelector('.ql-bubble .ql-tooltip');

            if (tooltip) {
                Abc = createPortal(tooltip, document.getElementById('tooltip-root'));
            }
        };

        // Initial call to move tooltip to the portal if it exists
        moveTooltipToPortal();

        // Listen to any changes that might make the tooltip visible
        quillInstance.on('selection-change', () => {
            moveTooltipToPortal();
        });

        // Cleanup: If the component unmounts, move the tooltip back to prevent issues

        return () => {
            const tooltip = document.querySelector('.ql-bubble .ql-tooltip');
            if (tooltip && document.getElementById('tooltip-root').contains(tooltip)) {
                document.body.appendChild(tooltip); // Move back to the body if necessary
            }
        };
    }, []);

    const extractLinkPayload = () => {
        const quillEditor = document.querySelector('.ql-editor');

        // Get the full plain text from the editor, trimmed and stripped of punctuation
        const fullText = quillEditor.innerText.trim();

        // Split the text into words and remove punctuation using a regex
        const words = fullText.split(/\s+/).map((word) => word.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, ''));

        // Find all <a> elements (links) in the editor content
        const links = Array.from(quillEditor.querySelectorAll('a'));

        // Generate the payload for each link found
        const payload = removeAllDuplicates([
            ...descriptionLinkPayload,
            ...links.map((linkElement) => {
                const linkedText = linkElement.innerText; // Get the linked word(s) inside the <a> tag
                const link = linkElement.getAttribute('href'); // Get the link URL

                // Remove punctuation from the linked words
                const linkedWordsArray = linkedText
                    .split(/\s+/)
                    .map((word) => word.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, ''));

                // Use a manual search for the startIndex, which accounts for edge cases and punctuation
                let startIndex = -1;
                let wordIndex = 0;

                for (let i = 0; i < words.length; i++) {
                    if (words[i] === linkedWordsArray[wordIndex]) {
                        if (wordIndex === 0) {
                            startIndex = i; // Found the start of the link
                        }
                        wordIndex++;
                        if (wordIndex === linkedWordsArray.length) {
                            break; // Found the full linked text
                        }
                    } else {
                        // Only reset if we have already matched part of the word sequence
                        if (startIndex !== -1) {
                            startIndex = -1;
                            wordIndex = 0;
                        }
                    }
                }

                const endIndex = startIndex + linkedWordsArray.length - 1;

                return {
                    startIndex: startIndex,
                    endIndex: endIndex,
                    link: link,
                };
            }),
        ]);

        handleDescriptionLinkPayload(payload);
    };

    const handleContentChange = (newHtmlValue) => {
        setHtmlContent(newHtmlValue); // Update local state with HTML content

        // Remove HTML tags to get plain text
        // const plainTextValue = newHtmlValue.replace(/<\/?[^>]+(>|$)/g, '').trim();

        const plainTextValue = newHtmlValue
            .replace(/<p><br\s*\/?><\/p>/gi, '\n') // Replace empty paragraphs with a single newline
            .replace(/<br\s*\/?>/gi, '\n') // Replace <br> or <br/> with newline
            .replace(/<\/p>/gi, '\n') // Replace closing </p> with a single newline for paragraph separation
            .replace(/<p[^>]*>/gi, '') // Remove opening <p> tags
            .replace(/<\/?[^>]+(>|$)/g, '') // Remove any remaining HTML tags
            .trim();
        // Update the form field with plain text content only

        descriptionField.onChange(plainTextValue);

        // Extract link payload whenever content changes
        extractLinkPayload();
    };

    return (
        <div>
            {/* ReactQuill editor with Bubble theme */}
            <ReactQuill
                ref={quillRef}
                value={htmlContent} // HTML content with tags for the editor
                onChange={handleContentChange}
                className="border border-borderColor shadow-sm bg-white rounded-md px-3 main"
                theme="bubble"
                onBlur={descriptionField.onBlur}
                modules={modules} // Pass custom toolbar with link only
                placeholder="Please provide a short description"
            />
            <div></div>
        </div>
    );
};

const FormWithDescriptionField = ({ errors, field, handleDescriptionLinkPayload, descriptionLinkPayload }) => {
    return (
        <div className="my-6">
            <TextWithTooltip
                heading={
                    <Heading type="xsmall" className="capitalize text-primaryInput">
                        Bounty Description<sup className="text-lg relative top-[1px]">*</sup>
                    </Heading>
                }
            >
                <p className="text-sm">
                    Bounties are climate-positive tasks. The description should briefly describe what the bounty is
                    about, which will help users on Impact Miner.
                </p>
                <p className="mt-3 text-sm">
                    Bounties can range from providing proof of composting setup at home to building smart contracts that
                    can help fight climate change.
                </p>
            </TextWithTooltip>

            {/* Use RichTextEditorBubbleLinkOnly and pass the descriptionField */}
            <RichTextEditorBubbleLinkOnly
                handleDescriptionLinkPayload={handleDescriptionLinkPayload}
                descriptionField={field}
                descriptionLinkPayload={descriptionLinkPayload}
            />

            <div className="flex items-center justify-between mt-1">
                <p className="text-xs">Highlight text to add links</p>
                {/* Character limit uses plain text length */}
                <CharacterLimit min={field.value ? field.value.length : 0} max={1000} />
            </div>

            {errors?.bountyDescription && <p className="text-red-500">{errors.bountyDescription.message}</p>}
        </div>
    );
};

export default FormWithDescriptionField;
