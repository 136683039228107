import Tag from '../../../ui/Tag';
import SubHeading from '../../../ui/SubHeading';
import { useOutletContext } from 'react-router-dom';

const CategoryWiseSuperpowers = ({ powersObject, selectionFn }) => {
    const { selectedPowers } = useOutletContext();
    return (
        <div className="mt-8">
            <SubHeading className={'text-[#262626]'} type={'large'}>
                {powersObject?.category}
            </SubHeading>
            <div className="flex flex-wrap mt-3 gap-2">
                {powersObject?.values?.map((item) => (
                    <Tag
                        key={powersObject?._id + item}
                        onClick={() => selectionFn(item)}
                        textColor="text-[#303B47]"
                        className={`border cursor-pointer text-sm capitalize ${
                            selectedPowers.includes(item)
                                ? '!text-[#1A56DBD6] !bg-[#E1EFFE] font-semibold border-[#1A56DBD6]'
                                : 'border-[#B3BAC7]'
                        }  px-[10px] py-[6px]  `}
                        text={item}
                    />
                ))}
            </div>
        </div>
    );
};

export default CategoryWiseSuperpowers;
