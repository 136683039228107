import * as multisig from '@sqds/multisig';
import {
    Connection,
    Keypair,
    Transaction,
    TransactionMessage,
    VersionedTransaction,
    clusterApiUrl,
} from '@solana/web3.js';
// import wallet from '/Users/altar/.config/solana/id.json';

const { Permissions } = multisig.types;
// const connection = new Connection(RPC_ENDPOINT, 'confirmed');

export const executor = async (walletAddress, connection) => {
    // const creator = Keypair.fromSecretKey(new Uint8Array(wallet));
    const createKey = Keypair.generate();

    // Derive the multisig account PDA
    const [multisigPda] = multisig.getMultisigPda({
        createKey: createKey.publicKey,
    });
    const programConfigPda = multisig.getProgramConfigPda({})[0];

    const programConfig = await multisig.accounts.ProgramConfig.fromAccountAddress(connection, programConfigPda);

    const configTreasury = programConfig.treasury;

    const obj = {
        treasury: configTreasury,
        creator: walletAddress,
        multisigPda,
        configAuthority: walletAddress,
        threshold: 1,
        members: [
            {
                key: walletAddress, // have to replace with connected wallet address
                permissions: Permissions.all(),
            },
        ],
        timeLock: 0,
        createKey: createKey.publicKey,
        rentCollector: walletAddress,
    };
    const instruction = multisig.instructions.multisigCreateV2(obj);

    let blockhash = await connection.getLatestBlockhash().then((res) => res.blockhash);

    const message = new TransactionMessage({
        payerKey: walletAddress,
        recentBlockhash: blockhash,
        instructions: [instruction],
    }).compileToV0Message();

    const transaction = new VersionedTransaction(message);

    transaction.sign([createKey]);

    return { transaction, multisigPda };
};
