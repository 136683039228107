export const maxDate = (dateStrings) => {
    // Convert the date strings to Date objects
    const dates = dateStrings.map((dateStr) => new Date(dateStr));

    // Find the maximum date using Math.max and the getTime method
    const latestDate = new Date(Math.max(...dates.map((date) => date.getTime())));

    // Format the result (optional) - ISO string representation
    const latestDateString = latestDate.toISOString();
    return latestDateString;
};
