import { useState, useEffect } from 'react';

function useLocalStorage(key, initialValue) {
    // Read from localStorage if available, otherwise use the initialValue
    const readFromLocalStorage = () => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error('Error reading from localStorage', error);
            return initialValue;
        }
    };

    // Initialize state with either the value from localStorage or the initialValue
    const [value, setValue] = useState(() => readFromLocalStorage());

    // Sync changes to localStorage whenever the `value` changes
    useEffect(() => {
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error('Error saving to localStorage', error);
        }
    }, [key, value]);

    // Custom set function that accepts a callback for partial updates
    const setStoredValue = (newValue) => {
        setValue((prevValue) => {
            // Check if newValue is a function (callback), otherwise directly set it
            const valueToStore = typeof newValue === 'function' ? newValue(prevValue) : newValue;
            try {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            } catch (error) {
                console.error('Error saving to localStorage', error);
            }
            return valueToStore;
        });
    };

    return [value, setStoredValue];
}

export default useLocalStorage;
