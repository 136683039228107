import axios from 'axios';
import { BASE_URL } from '../../constants/apiPaths';

export const verifyWallet = async (obj) => {
    try {
        const url = `${BASE_URL}/auth/login-with-wallet`;

        const response = await axios.post(url, obj);

        // Check the response status
        if (response.status === 200 || response.status == 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error.response.data;
    }
};
