const QuestionAndAnswerSubDetails = ({ data }) => {
    const { action } = data;

    const titleObject = action.find((obj) => obj.name === 'Title');
    const questionObject = action.find((obj) => obj.name === 'question');
    const headingObject = action.find((obj) => obj.name === 'heading');
    const imageObject = action.find((obj) => obj.name === 'image');
    return (
        <>
            <p className="flex gap-1 text-xs text-secondaryInput mb-2  ">
                <span>Response type : </span>{' '}
                <span className="font-bold capitalize "> {data?.label?.split('_')?.join(' ')?.toLowerCase()}</span>
            </p>
            <p className="font-semibold capitalize text-primaryInput mb-2">
                <span>{titleObject?.value}</span>
            </p>
            <p className="text-sm capitalize text-primaryInput mb-2">
                <span>{questionObject?.value}</span>
            </p>
            <p className="text-sm capitalize text-primaryInput mb-2">
                <span>{headingObject?.value}</span>
            </p>
            {imageObject?.image && <img className="w-[100%] h-32 rounded-md" src={imageObject.image} alt="" />}
        </>
    );
};

export default QuestionAndAnswerSubDetails;
