import { useContext } from 'react';
import { BountiesContext } from '../../../contexts/BountiesContext';
import { useBountyFields } from '../../../hooks/useBountyFields';
import mobileFrame from '../../../assets/mobileFrame.png';
import Heading from '../../../ui/Heading';
import SubHeading from '../../../ui/SubHeading';
import { CalendarIcon } from '../../../assets/icons/CalendarIcon';
import { ClockIcon } from '../../../assets/icons/ClockIcon';
import Button from '../../../ui/Button';
import LeftPreviewArrow from '../../../assets/icons/LeftPreviewArrow';
import { converttagsstringinstring } from '../../../helpers/conerttagstringinstring';
import { linkArray } from '../../../helpers/linkArrayForPreview';
import LinkRenderer from '../../../ui/LinkRenderer';
import { plainTextValue } from '../../../helpers/plainText';
import { useInstructionTextMessage } from '../bountiesHooks/useInstructionTextMessage';
function PreviewInstructionalText({ data, instructionLinkPayload, control, index }) {
    const { state } = useContext(BountiesContext);
    const { titleField } = useBountyFields(control, index);
    const { instructionMessageField } = useInstructionTextMessage(control, index);

    return (
        <div
            className="relative w-[300px] h-[610px]     "
            style={{
                backgroundImage: `url(${mobileFrame})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <main className="absolute  top-28 left-6 right-6 ">
                <div className="my-4">
                    <Heading className={'text-minerTitle'} type={'xsmall'}>
                        {data.instruction_title || 'Title'}{' '}
                    </Heading>

                    {data?.instruction_images && (
                        <div className="overflow-hidden">
                            <img
                                className="w-full h-40 object-contain rounded-md my-2"
                                src={data?.instruction_images}
                                alt=""
                            />
                        </div>
                    )}
                    <SubHeading className={'!text-xs font-semibold '} type="xsmall">
                        {/* <LinkRenderer
                            dummyText="Description"
                            text={data.instruction_message || ''}
                            links={linkArray(instructionLinkPayload)}
                        /> */}
                        <LinkRenderer text={instructionMessageField.value} links={instructionLinkPayload} />
                    </SubHeading>
                </div>
            </main>

            <footer className="absolute bottom-6 left-6 right-6">
                <div className="flex mb-2 items-center justify-between">
                    <Button
                        className=" flex !text-[#1A56DB] text-xs items-center justify-center gap-1 text-end"
                        size="none"
                        variant="transparent"
                    >
                        <span>{'<-'}</span> <span> Back</span>
                    </Button>
                    {data.skippable && (
                        <Button className=" !text-[#1A56DB] text-xs  text-end" size="none" variant="transparent">
                            Skip {'->'}
                        </Button>
                    )}
                </div>
                <Button variant="miner" size="largeMiner" className="py-2 text-xs " radius="rounded-full">
                    Next
                </Button>
            </footer>
        </div>
    );
}

export default PreviewInstructionalText;
