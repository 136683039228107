import LinkifiedMessage from '../../ui/LinkifiedMessage';
import Slider from '../../ui/Slider';
import Table from '../../ui/Table';
import { microtaskTypeData } from '../bounties/microtasktypes/microtaskTypeData';

const InstructionalTextSD = ({ dataTask }) => {
    const titleObject = dataTask.task.find((obj) => Object.keys(obj).includes('title'));
    const messageObject = dataTask.task.find((obj) => Object.keys(obj).includes('message'))?.message;
    const imageObject = dataTask.task.find((obj) => Object.keys(obj).includes('images'));
    const linksObject = dataTask.task.find((obj) => Object.keys(obj).includes('links'));

    return (
        <Table.Row>
            <p className=" text-lightBlack flex flex-col   ">
                <span className="font-medium mb-1  "> {titleObject?.title || '-'} </span>

                {imageObject?.images && (
                    <img className="w-[100%] h-32 rounded-md mb-1" src={imageObject.images} alt="" />
                )}
                <span className="text-xs font-medium ">
                    <LinkifiedMessage message={messageObject} links={linksObject?.links} />
                </span>
            </p>
            <p className="text-sm justify-self-center">
                {microtaskTypeData.find((ele) => ele.type === dataTask.actionType).label}
            </p>

            {/* <div>
                <p className=" text-lightBlack mb-1 ">{dataTask.submission.answer.join(' ')}</p>
            </div> */}
        </Table.Row>
    );
};

export default InstructionalTextSD;
