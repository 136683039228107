import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import * as walletAdapterWallets from '@solana/wallet-adapter-wallets';
import * as web3 from '@solana/web3.js';

import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { RPC_ENDPOINT } from '../constants/apiPaths';
// require('@solana/wallet-adapter-react-ui/styles.css');

const WalletContextProvider = ({ children }) => {
    const endpoint = RPC_ENDPOINT;

    const walletList = [
        new walletAdapterWallets.PhantomWalletAdapter(),
        new walletAdapterWallets.WalletConnectWalletAdapter(),
        // new walletAdapterWallets.SolflareWalletAdapter()
    ];

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={walletList} autoConnect={true}>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export default WalletContextProvider;
