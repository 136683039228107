import OrganizationInput from '../features/Organization/OrganizationInput';
import { useElementHider } from '../contexts/ElementHider';
import { useOutletContext } from 'react-router-dom';

const EditOrganisation = () => {
    const { hiderClass, handleSetHiderClass } = useElementHider();
    const [{ tagline, description, handles, logo }, , , , , setParticularOrg] = useOutletContext();

    const editData = {
        orgTagline: tagline,
        orgDescription: description,
        ...(handles[0] && { link1: handles[0] }),
        ...(handles[1] && { link2: handles[1] }),
        ...(handles[2] && { link3: handles[2] }),
        orgLogo: logo,
    };

    return (
        <div>
            <OrganizationInput edit={true} prefilledData={editData} setParticularOrg={setParticularOrg} />
        </div>
    );
};

export default EditOrganisation;
