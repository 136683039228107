import * as multisig from '@sqds/multisig';

import { PublicKey, TransactionMessage, VersionedTransaction } from '@solana/web3.js';

const { Permissions } = multisig.types;

export const executorToAddMemberToOrg = async (memberWalletAdresses, adminWalletAdress, orgMultisig, connection) => {
    const instructions = [];

    let instruction;

    for (let i = 0; i < memberWalletAdresses.length; ++i) {
        instruction = multisig.instructions.multisigAddMember({
            multisigPda: new PublicKey(orgMultisig),
            configAuthority: new PublicKey(adminWalletAdress),
            rentPayer: new PublicKey(adminWalletAdress),
            newMember: {
                key: new PublicKey(memberWalletAdresses[i]),
                permissions: Permissions.all(),
            },
        });
        instructions.push(instruction);
    }

    let blockhash = await connection.getLatestBlockhash().then((res) => res.blockhash);

    const message = new TransactionMessage({
        payerKey: new PublicKey(adminWalletAdress),
        recentBlockhash: blockhash,
        instructions,
    }).compileToV0Message();

    const transaction = new VersionedTransaction(message);

    return transaction;
};
