const NoTokenAvailable = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.1" width="24" height="24" rx="12" fill="#5C5C5C" />
            <path
                d="M19 5.707L18.293 5L5 18.293L5.707 19L6.707 18H17C17.2651 17.9996 17.5192 17.8941 17.7067 17.7067C17.8941 17.5192 17.9996 17.2651 18 17V6.707L19 5.707ZM17 17H7.707L11.6035 13.1035L12.793 14.293C12.9805 14.4805 13.2348 14.5858 13.5 14.5858C13.7652 14.5858 14.0195 14.4805 14.207 14.293L15 13.5L17 15.4985V17ZM17 14.084L15.707 12.791C15.5195 12.6035 15.2652 12.4982 15 12.4982C14.7348 12.4982 14.4805 12.6035 14.293 12.791L13.5 13.584L12.3115 12.3955L17 7.707V14.084ZM7 15V13.5L9.5 11.0015L10.1865 11.6885L10.8945 10.9805L10.207 10.293C10.0195 10.1055 9.76516 10.0002 9.5 10.0002C9.23484 10.0002 8.98053 10.1055 8.793 10.293L7 12.086V7H15V6H7C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7V15H7Z"
                fill="black"
            />
        </svg>
    );
};

export default NoTokenAvailable;
