import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { getExploreOrg, getOrganisationApplications } from '../apis/oragnisations/organisation';
import { useLoadingState } from '../hooks/useLoader';
// import AllOrganizations from '../features/Organization/AllOrganizations';

function MyOrganizations() {
    const [applications, setApplications] = useState([]);
    const [particularApplication, setParticularApplication] = useState([]);
    const { loading, startLoading, stopLoading } = useLoadingState();

    const getParticularApplication = (index, from) => {
        if (from === 'summary') {
            setParticularApplication({ ...applications[index], status: 'EXECUTED' });
            return;
        }
        setParticularApplication(applications[index]);
    };

    useEffect(function () {
        const fetchData = async () => {
            try {
                // const data = await getExploreOrg(5);
                startLoading();
                const applicationData = await getOrganisationApplications();

                // setImpactOrganizations(data);
                setApplications(applicationData);
                stopLoading();
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);
    return (
        <div className="p-9">
            {/* <AllOrganizations /> */}
            <Outlet
                context={{
                    applications,
                    setApplications,

                    particularApplication,
                    getParticularApplication,
                    setParticularApplication,
                    loading,
                }}
            />
        </div>
    );
}

export default MyOrganizations;
