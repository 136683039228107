import React, { cloneElement, createContext, isValidElement, useContext, useEffect, useRef, useState } from 'react';
import Button from './Button';
import { ThreeDotsIcon, ThreeDotsVertical } from '../assets/icons/UserProfileIcons';

export const MenusContext = createContext();

function Menus({ children }) {
    const [openId, setOpenId] = useState('');
    const [position, setPosition] = useState(null);
    const [isMenuToggling, setIsMenuToggling] = useState(false); // Track menu toggle action

    const closeMenu = () => setOpenId('');
    const openMenu = setOpenId;

    return (
        <MenusContext.Provider
            value={{ openId, closeMenu, openMenu, position, setPosition, isMenuToggling, setIsMenuToggling }}
        >
            {children}
        </MenusContext.Provider>
    );
}

function Toggle({ id, vertical = false, verticalOffset = 12, horizontalOffest = 15 }) {
    const { openId, openMenu, closeMenu, setPosition, setIsMenuToggling } = useContext(MenusContext);

    const handleToggle = function (e) {
        e.stopPropagation(); // Prevent propagation to the document click handler

        const rect = e.target.closest('button').getBoundingClientRect();

        // Set flag to true to indicate we're toggling the menu (to avoid triggering the outside click handler)
        setIsMenuToggling(true);

        if (openId === id) {
            closeMenu(); // Close the menu
        } else {
            openMenu(id); // Open the menu
            setPosition({
                x: rect.width + horizontalOffest,
                y: rect.height + verticalOffset,
            });
        }

        // Reset flag after a small delay
        setTimeout(() => setIsMenuToggling(false), 100);
    };

    return (
        <Button onClick={handleToggle} variant="tertiary" className="pt-4 place-self-center pb-4 pl-3 pr-3 " size="fit">
            {vertical ? <ThreeDotsVertical /> : <ThreeDotsIcon />}
        </Button>
    );
}

const StyledList = React.forwardRef(({ position, children, ...props }, ref) => {
    return (
        <div
            {...props}
            ref={ref} // Attach the ref to the actual DOM element
            style={{ top: `${position.y}px`, right: `${position.x}px` }}
            className="absolute overflow-hidden bg-white z-50 border-borderColor  flex flex-col items-start rounded-md shadow-md"
        >
            {children}
        </div>
    );
});

function List({ id, children }) {
    const { openId, position, closeMenu, isMenuToggling } = useContext(MenusContext);
    const listRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isMenuToggling) {
                // If we are in the middle of toggling the menu, do not close it
                return;
            }
            if (listRef.current && !listRef.current.contains(event.target)) {
                closeMenu(); // Close the menu if the click is outside
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [closeMenu, isMenuToggling]);

    if (id !== openId) return null;

    return (
        <StyledList ref={listRef} position={position}>
            {children}
        </StyledList>
    );
}

function MenuButton({
    icon,
    children,
    onClick,
    shouldCloseMenu = true,
    menuBtnClassName = '',
    menuIconClassName = '',
    menuTextClassName = '',
    ...props
}) {
    const { closeMenu } = useContext(MenusContext);

    const handleClick = () => {
        // if (shouldCloseMenu) {
        closeMenu(); // Close the menu if `shouldCloseMenu` is true
        // }
    };

    return (
        <button
            className={`px-3 flex items-center flex-1 gap-2 py-2 w-[100%] text-start hover:bg-gray-100 ${menuBtnClassName} `}
            onClick={() => {
                onClick(handleClick);
            }} // Conditionally close menu
            {...props}
        >
            <span className={`w-[20%] ${menuIconClassName} `}>{icon}</span>{' '}
            <span className={`flex-1 w-[full] ${menuTextClassName}`}>{children}</span>
        </button>
    );
}

// function MenuButton({ icon, children, shouldCloseMenu = true, ...props }) {
//     const { closeMenu } = useContext(MenusContext);

//     const handleClick = () => {
//         if (shouldCloseMenu) {
//             closeMenu(); // Close the menu if `shouldCloseMenu` is true
//         }
//     };

//     // If the button is used within another wrapper (like Modal.Button), handle it accordingly
//     if (isValidElement(children)) {
//         // Clone the children and pass the correct props
//         return cloneElement(children, {
//             onClick: handleClick, // Attach the close menu behavior
//             ...props, // Spread the props
//         });
//     }

//     // Default button rendering when used alone
//     return (
//         <button
//             className="px-3 flex items-center gap-2 py-1 w-[100%] text-start hover:bg-gray-100"
//             onClick={handleClick}
//             {...props}
//         >
//             <span>{icon}</span> <span>{children}</span>
//         </button>
//     );
// }
Menus.Toggle = Toggle;
Menus.List = List;
Menus.Button = MenuButton;

export default Menus;
