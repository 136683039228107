function MultiColorProgressBar({ sections, className, totalCorePoints, total }) {
    // Calculate total percentage of all color sections
    const totalPercentage = sections.reduce((sum, section) => sum + section.percentage, 0);
    // Calculate gray section width

    return (
        <div className={` h-4 bg-gray-100 rounded-xl flex overflow-hidden ${className} `}>
            {totalCorePoints === 0 ? (
                <div className="h-full bg-gray-200" style={{ width: `${100}%` }} />
            ) : (
                sections.map((section, index) => (
                    <div
                        key={index}
                        className={`h-full ${section.color} `}
                        style={{
                            width: `${(section.percentage / totalCorePoints) * 100}%`,
                            // backgroundColor: section.color,
                        }}
                    />
                ))
            )}
        </div>
    );
}

export default MultiColorProgressBar;
