import React, { useEffect } from 'react';
import bountyRequestDone from '../../assets/bountyRequestDone.png';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
const RequestCreated = () => {
    const navigate = useNavigate();
    useEffect(function () {
        const timeoutId = setTimeout(() => {
            navigate(-1);
            toast.success('Request for Creating a Bounty is successful. ');
        }, '1000');
        return () => clearTimeout(timeoutId);
    }, []);
    return (
        <div className="flex items-center h-[90vh] justify-center">
            <div className="">
                <p className="text-3xl font-semibold text-lightBlack">Bounty is being sent for treasury approval</p>
                <div className="flex items-center justify-center">
                    <img src={bountyRequestDone} alt="" />
                </div>
            </div>
        </div>
    );
};

export default RequestCreated;
