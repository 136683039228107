import { useContext } from 'react';
import Button from '../../ui/Button';
import Heading from '../../ui/Heading';
import Tag from '../../ui/Tag';
import TextWithTooltip from '../../ui/TextWithTooltip';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate, useOutletContext } from 'react-router-dom';

function ProfileBio() {
    const { userProfile, yourImpacts } = useOutletContext();
    const navigate = useNavigate();
    const impactDetails = yourImpacts[0];
    const { selectedPowers } = useOutletContext();

    return (
        <>
            <div className="mb-4">
                <Heading className={'text-secondaryText'} type={'medium'}>
                    Bio
                </Heading>
                <p className="text-primaryInput">{userProfile?.bio || ''}</p>
            </div>
            <div className="mb-6">
                <TextWithTooltip hoverOverText="Superpowers" expandedTextWidth="w-[332px]">
                    <p className="text-[#404040]">
                        Superpowers are the skills you are good at. Setup your superpowers accurately to get improved
                        matching with earning opportunities.
                    </p>
                </TextWithTooltip>
                <div className="flex items-center flex-wrap mt-2 gap-2">
                    {selectedPowers.length > 0 ? (
                        selectedPowers.map((impact) => (
                            <Tag
                                key={impact}
                                className="font-semibold border  px-4 py-1  border-tagColor"
                                text={impact}
                            />
                        ))
                    ) : (
                        <p className="text-primaryInput">
                            Set up your Superpowers!{' '}
                            <span onClick={() => navigate('editprofile')} className="text-primaryColor cursor-pointer">
                                Add Superpowers{'->'}
                            </span>
                        </p>
                    )}
                </div>
            </div>
        </>
    );
}

export default ProfileBio;
