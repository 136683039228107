export const formatNotificationDateString = (dateString) => {
    // Parse the input date string
    const inputDate = new Date(dateString);
    const currentDate = new Date();

    // Calculate the difference in milliseconds and convert to days
    const timeDifference = currentDate - inputDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    // If the difference is less than or equal to 6 days, return "X days ago"
    if (daysDifference <= 6) {
        if (daysDifference === 0) {
            return 'Today';
        } else if (daysDifference === 1) {
            return '1 day ago';
        } else {
            return `${daysDifference} days ago`;
        }
    }

    // Otherwise, return the date in "Month Day" format
    const options = { month: 'short', day: 'numeric' };
    return inputDate.toLocaleDateString('en-US', options);
};
