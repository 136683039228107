import LayoutWithoutSidebar from '../ui/LayoutWithoutSidebar';
import SideLogo from '../features/profilecreation/SideLogo';

import ProfileInput from '../features/profilecreation/ProfileInput';
function CreateProfile() {
    return (
        <div className="flex justify-between w-4/5 mx-auto mt-[53px] mb-[11px] ">
            <ProfileInput />
            <SideLogo />
        </div>
    );
}

export default CreateProfile;
