export const deepEqual = function (obj1, obj2) {
    // Check if both objects are the same instance
    if (obj1 === obj2) return true;

    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the objects have the same number of keys
    if (keys1.length !== keys2.length) return false;

    // Check if both objects have the same key
    if (keys1[0] !== keys2[0]) return false;

    // Get the array values associated with the key
    const arr1 = obj1[keys1[0]];
    const arr2 = obj2[keys2[0]];

    // Check if both arrays have the same length
    if (arr1.length !== arr2.length) return false;

    // Check if all elements in the arrays are the same
    // for (let i = 0; i < arr1.length; i++) {
    //     return arr2.includes[arr1[i]];
    //     // if (arr1[i] !== arr2[i]) return false;
    // }

    // return true;

    return new Set(arr1).size === new Set(arr2).size && [...new Set(arr1)].every((item) => new Set(arr2).has(item));
};
