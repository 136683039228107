import { useState } from 'react';
import { useActive } from '../../../hooks/useActive';
import TabSwitching from '../../../ui/TabSwitching';
import Table from '../../../ui/Table';
import VotingCardForBountyCreation from '../voting/VotingCardForBountyCreation';
import VotingCardForBountyValidation from '../voting/VotingCardForBountyValidation';

import BountyCreationProposals from './BountyCreationProposals';
import BountyValidationProposals from './BountyValidationProposals';
import { initial } from 'lodash';

function ProposalsCreatedByTreasurer({ screen = 'bounty proposal', organisation }) {
    const [activeTab, handleActiveTab] = useActive(screen);
    const [creationProposals, setCreationProposals] = useState([]);
    const [fetchedValues, setFetchedValues] = useState({});
    const [fetchedValuesValidation, setFetchedValuesValidation] = useState({});
    const [validationProposals, setValidationProposals] = useState([]);
    const [activeVotingCard, setActiveVotingCard] = useState({
        bountyCreationVotingCard: 0,
        bountyValidationVotingCard: 0,
        createReqId: '',
        validateReqId: '',
    });
    const changeVotingStatusForCreation = (vote, proposalAddress) => {
        setFetchedValues((prevValues) => ({
            ...prevValues,
            [proposalAddress]: vote,
        }));
    };
    const changeVotingStatusForValidation = (vote, proposalAddress) => {
        setFetchedValuesValidation((prevValues) => ({
            ...prevValues,
            [proposalAddress]: vote,
        }));
    };
    const handleSetCreationProposals = (data) => {
        setCreationProposals(data);
    };
    const handleSetValidationProposals = (data) => {
        setValidationProposals(data);
    };

    const onExecutionFilterData = (id) => {
        if (activeTab.toLowerCase() === 'bounty proposal') {
            const filterCreationData = creationProposals.filter((proposal) => proposal._id !== id);

            const setReqId = filterCreationData[activeVotingCard.bountyCreationVotingCard]?._id || '';

            setActiveVotingCard((initial) => ({ ...initial, createReqId: setReqId }));

            handleSetCreationProposals(filterCreationData);
        }
        if (activeTab.toLowerCase() === 'validation proposal') {
            const filterValidationData = validationProposals.filter((proposal) => proposal._id !== id);

            const setReqId = filterValidationData[activeVotingCard.bountyValidationVotingCard]?._id || '';

            setActiveVotingCard((initial) => ({ ...initial, validateReqId: setReqId }));

            handleSetValidationProposals(filterValidationData);
        }
    };

    const handleVotingCardSelection = (index, id) => {
        if (activeTab.toLowerCase() === 'bounty proposal') {
            setActiveVotingCard((initial) => ({ ...initial, bountyCreationVotingCard: index, createReqId: id }));
        }

        if (activeTab.toLowerCase() === 'validation proposal') {
            setActiveVotingCard((initial) => ({ ...initial, bountyValidationVotingCard: index, validateReqId: id }));
        }
    };

    return (
        <div className="grid grid-cols-3  gap-5">
            <div className="col-span-2">
                <Table className=" items-center gap-8 " cols="grid-cols-tableProposals">
                    <div className="flex  mb-3  ">
                        <TabSwitching
                            activeTab={activeTab}
                            onSelectingActive={handleActiveTab}
                            firstTab={'Bounty Proposal'}
                            secondTab={'Validation Proposal'}
                            activeStyling={'text-lightBlack border-primaryColor  '}
                            firstTabStyle={'rounded-e-none  '}
                            secondTabStyle={'rounded-s-none '}
                            className={
                                'text-sm   font-medium border-2 border-borderColor p-1 leading-6  px-4 rounded-md '
                            }
                        />
                    </div>
                    {activeTab.toLowerCase() === 'bounty proposal' && (
                        <BountyCreationProposals
                            creationProposals={creationProposals}
                            handleSetCreationProposals={handleSetCreationProposals}
                            onRowSelection={handleVotingCardSelection}
                            activeCreationProposal={activeVotingCard.bountyCreationVotingCard}
                            fetchedValues={fetchedValues}
                            setFetchedValues={setFetchedValues}
                            multiSig={organisation?.treasurerMultisig}
                        />
                    )}
                    {activeTab.toLowerCase() === 'validation proposal' && (
                        <BountyValidationProposals
                            validationProposals={validationProposals}
                            onRowSelection={handleVotingCardSelection}
                            handleSetValidationProposals={handleSetValidationProposals}
                            activeValidationProposal={activeVotingCard.bountyValidationVotingCard}
                            fetchedValuesValidation={fetchedValuesValidation}
                            setFetchedValuesValidation={setFetchedValuesValidation}
                            multiSig={organisation?.treasurerMultisig}
                        />
                    )}
                </Table>
            </div>

            <div className="col-span-1 my-5  ">
                {activeTab.toLowerCase() === 'bounty proposal' && (
                    <VotingCardForBountyCreation
                        multiSig={organisation?.treasurerMultisig}
                        reqId={activeVotingCard.createReqId}
                        activeCreationProposal={activeVotingCard.bountyCreationVotingCard}
                        afterExecutionOperation={onExecutionFilterData}
                        changeVotingStatusForCreation={changeVotingStatusForCreation}
                    />
                )}
                {activeTab.toLowerCase() === 'validation proposal' && (
                    <VotingCardForBountyValidation
                        multiSig={organisation?.treasurerMultisig}
                        reqId={activeVotingCard.validateReqId}
                        activeValidationProposal={activeVotingCard.bountyValidationVotingCard}
                        afterExecutionOperation={onExecutionFilterData}
                        changeVotingStatusForValidation={changeVotingStatusForValidation}
                    />
                )}
            </div>
        </div>
    );
}

export default ProposalsCreatedByTreasurer;
