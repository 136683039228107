/* global BigInt */
import * as multisig from '@sqds/multisig';
import { Connection, Keypair, clusterApiUrl, PublicKey } from '@solana/web3.js';
import { VersionedTransaction } from '@solana/web3.js';
import { TransactionMessage, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { Transaction } from '@solana/web3.js';
import * as token from '@solana/spl-token';
import { executorToFetchThreshold } from './fetchThreshold';
export const executorToReadVotingCount = async (connection, proposalAddress) => {
    const proposal = await multisig.accounts.Proposal.fromAccountAddress(connection, proposalAddress);
    const yesVoteCount = proposal.approved.length;
    const noVoteCount = proposal.rejected.length;

    return { yesVoteCount, noVoteCount };
};

// possible return values 'voted yes', 'voted no', 'not voted'
export const executorToFindIfUserHasVoted = async (connection, proposalAddress, walletAddress, multiSig) => {
    const proposal = await multisig.accounts.Proposal.fromAccountAddress(connection, proposalAddress);
    const approved = proposal.approved.map((ele) => ele.toBase58());
    const rejected = proposal.rejected.map((ele) => ele.toBase58());
    const wallet = walletAddress.toBase58();
    const fetchedThreshold = await executorToFetchThreshold(multiSig, connection);

    return approved.length >= fetchedThreshold
        ? 'ready to execute'
        : approved.includes(wallet)
        ? 'voted yes'
        : rejected.includes(wallet)
        ? 'voted no'
        : 'not voted';
};

export const executorToVoteOnProposal = async (connection, orgMultisig, proposalAddress, walletAddress, approve) => {
    const transactionIndex = await multisig.accounts.Proposal.fromAccountAddress(connection, proposalAddress).then(
        (info) => Number(info.transactionIndex)
    );

    const instruction = approve
        ? multisig.instructions.proposalApprove({
              multisigPda: orgMultisig,
              transactionIndex: BigInt(transactionIndex),
              member: walletAddress,
          })
        : multisig.instructions.proposalReject({
              multisigPda: orgMultisig,
              transactionIndex: BigInt(transactionIndex),
              member: walletAddress,
          });

    let blockhash = await connection.getLatestBlockhash().then((res) => res.blockhash);

    const message = new TransactionMessage({
        payerKey: walletAddress,
        recentBlockhash: blockhash,
        instructions: [instruction],
    }).compileToV0Message();

    const transaction = new VersionedTransaction(message);

    return transaction;
};

export const executorToCheckSufficientSOLBalance = async (connection, walletAddress, orgMultisig, proposalAddress) => {
    const transactionIndex = await multisig.accounts.Proposal.fromAccountAddress(connection, proposalAddress).then(
        (info) => Number(info.transactionIndex)
    );

    const { instruction } = await multisig.instructions.vaultTransactionExecute({
        connection,
        multisigPda: orgMultisig,
        transactionIndex: BigInt(transactionIndex),
        member: new PublicKey(walletAddress),
    });

    let blockhash = await connection.getLatestBlockhash().then((res) => res.blockhash);

    const message = new TransactionMessage({
        payerKey: new PublicKey(walletAddress),
        recentBlockhash: blockhash,
        instructions: [instruction],
    }).compileToV0Message();

    const result = await connection.getFeeForMessage(message);

    const balance = await connection.getBalance(new PublicKey(walletAddress), 'confirmed');

    return balance < result.value;
};

export const executorToCheckTreasuryTokenBalance = async (
    connection,
    orgMultisig,
    rewardTokenAddress,
    maxTotalRewardAmount
) => {
    const [treasurerMultisig] = multisig.getVaultPda({
        multisigPda: orgMultisig,
        index: 0,
    });
    const tokenAccountAddress = token.getAssociatedTokenAddressSync(rewardTokenAddress, treasurerMultisig, true);

    const result = await connection.getAccountInfo(tokenAccountAddress);

    if (!result) return true;
    const info = await connection.getTokenAccountBalance(tokenAccountAddress);

    return info.value.uiAmount === null || info.value.uiAmount < maxTotalRewardAmount;
};

/*
    Fetch the bounty account size and token account size
    Fetch rent amount for bounty account and token account
    Fetch the treasury address
    Fetch the balance of the treasury
    If treasury balance is less than the combined rent, throw error
*/

export const executorToCheckTreasurySOLBalance = async (connection, orgMultisig, bountyTitle, bountyDescription) => {
    const bountyAccountSize =
        8 + 32 + 32 + 4 + bountyTitle.length + 4 + bountyDescription.length + 8 + 8 + 1 + 1 + 32 + 1 + 8 + 8 + 1;
    let rentAmount = await connection.getMinimumBalanceForRentExemption(bountyAccountSize);
    rentAmount += await connection.getMinimumBalanceForRentExemption(token.ACCOUNT_SIZE);
    const [treasurerMultisig] = multisig.getVaultPda({
        multisigPda: orgMultisig,
        index: 0,
    });
    const balance = await connection.getBalance(treasurerMultisig, 'confirmed');

    return balance < rentAmount;
};

export const executorToExecuteProposal = async (connection, walletAddress, orgMultisig, proposalAddress) => {
    const transactionIndex = await multisig.accounts.Proposal.fromAccountAddress(connection, proposalAddress).then(
        (info) => Number(info.transactionIndex)
    );

    const { instruction } = await multisig.instructions.vaultTransactionExecute({
        connection,
        multisigPda: orgMultisig,
        transactionIndex: BigInt(transactionIndex),
        member: new PublicKey(walletAddress),
    });

    let blockhash = await connection.getLatestBlockhash().then((res) => res.blockhash);

    const message = new TransactionMessage({
        payerKey: new PublicKey(walletAddress),
        recentBlockhash: blockhash,
        instructions: [instruction],
    }).compileToV0Message();

    const transaction = new VersionedTransaction(message);

    return transaction;
};
