import Input from '../../ui/Input';
import Heading from '../../ui/Heading';
import Button from '../../ui/Button';
import { useController, useForm } from 'react-hook-form';
import AddProfileImage from '../../assets/icons/AddProfileImage';
import Info from '../../assets/icons/Info';
import BackButton from '../../assets/icons/BackButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { createOrganizationAtDb, editOrganisation } from '../../apis/oragnisations/organisation';
import Loader from '../../ui/Loader';
import { useLoadingState } from '../../hooks/useLoader';
import { uploader } from '../../helpers/uploader';
import { toast } from 'sonner';
import Feedbackclose from '../../assets/icons/Feedbackclose';
import RemoveLogo from '../../assets/icons/RemoveLogo';
import EditLogo from '../../assets/icons/EditLogo';
import { deepEqual } from '../../helpers/deepEqual';
import CharacterLimit from '../../ui/CharacterLimit';
const urlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9#?&%=_\-]*)*$/;
function OrganizationInput({ edit = false, prefilledData, setParticularOrg }) {
    const {
        register,
        handleSubmit,
        getValues,
        control,
        formState: { errors },
    } = useForm(
        edit && {
            defaultValues: prefilledData,
        }
    );
    const { id } = useParams();
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [uploadStatus, setUploadStatus] = useState('');
    const { loading: uploading, startLoading: startUploading, stopLoading: stopUploading } = useLoadingState();
    const [imageObject, setImage] = useState(() => {
        return edit ? { image: null, imageURL: prefilledData?.orgLogo } : { image: null, imageURL: '' };
    });
    const navigate = useNavigate();
    const { field: orgTaglineField } = useController({
        name: 'orgTagline',
        control,
        rules: {
            required: 'Please enter a tagline.',
            maxLength: {
                value: 50,
                message: 'The maximum length is 50 characters.',
            },
        },
    });
    const { field: orgNameField } = useController({
        name: 'orgName',
        control,
        rules: {
            required: 'Please enter a title',
            maxLength: {
                value: 100,
                message: 'The maximum length is 100 characters',
            },
        },
    });
    const { field: orgDescriptionField } = useController({
        name: 'orgDescription',
        control,
        rules: {
            required: 'Please enter a brief description',
            maxLength: {
                value: 1000,
                message: 'The maximum length is 1,000 characters.',
            },
        },
    });
    const { image, imageURL } = imageObject;
    const onSubmit = async (data) => {
        data.orgLogo = imageObject.image;
        const { link1, link2, link3, ...dataToSend } = data;
        const handles = [link1, link2, link3];
        if (!edit) {
            dataToSend['handles[0]'] = handles[0];
            if (handles[1]) dataToSend['handles[1]'] = handles[1];
            if (handles[2]) dataToSend['handles[2]'] = handles[2];
        }

        if (edit) {
            dataToSend.orgLogo = imageObject.imageURL;
            dataToSend.handles = handles.filter((link) => link);
            const { link1, link2, link3, ...prevObj } = prefilledData;
            const prevHandles = [link1, link2, link3];
            prevObj.handles = prevHandles.filter((link) => link);

            const isDetailsSame = deepEqual(dataToSend, prevObj);

            const payloadObject = {
                logo: dataToSend.orgLogo,
                description: dataToSend.orgDescription,
                tagline: dataToSend.orgTagline,
                handles: dataToSend.handles,
            };
            startLoading();
            const response = await editOrganisation(payloadObject, id);

            setParticularOrg((initial) => ({ ...initial, ...payloadObject }));
            stopLoading();
            return;
        }

        const orgToSend = new FormData();

        for (let [key, value] of Object.entries(dataToSend)) {
            orgToSend.append(key, value);
        }
        startLoading();
        const response = await createOrganizationAtDb(orgToSend);

        stopLoading();
        if (response.status === 'SUBMITTED') navigate(-1);
        // dispatch({ type: 'updateAfterProfileCreation', payload: response });
        // if (Object.keys(response).length) navigate('/', { replace: true });
    };
    const handleFileChange = async (event) => {
        const file = event.target.files[0]; // Get the selected file

        if (file) {
            // setImage({
            //     image: file,
            //     imageURL: URL.createObjectURL(event.target.files[0]),
            // });

            await uploadFile(file);
        } else {
            setUploadStatus('Please select a valid  file.');
        }
    };

    const uploadFile = async (file) => {
        try {
            startUploading();

            const res = await uploader(file);

            if (res.files.length) {
                setImage({
                    image: file,
                    imageURL: res.files[0],
                });

                toast.success('Image is successfully uploaded.');
            } else {
                toast.error('Sorry unable to upload logo');
            }
            stopUploading();
        } catch (error) {
            console.error('Error uploading file:', error);
            setUploadStatus('File upload failed.');
        }
    };

    return (
        <div className="w-8/12 mx-auto flex gap-3 items-start py-6   ">
            {!edit && <BackButton className={'relative top-2'} onClick={() => navigate(-1)} />}
            <form onSubmit={handleSubmit(onSubmit)} className=" w-[100%] ">
                {!edit && (
                    <div className="mb-7 ">
                        <Input
                            type="transparent"
                            {...orgNameField}
                            error={errors?.orgName?.message}
                            placeholder={'Organization Title*...'}
                            className={'w-[100%]'}
                        />
                        <p className=" text-secondaryInput text-xs">
                            <span className="font-semibold ">({'character limit'}</span> :{' '}
                            <span>{orgNameField.value ? orgNameField.value.length : 0}/100)</span>
                        </p>
                    </div>
                )}
                <div className="mb-7">
                    <Heading type="medium" className="capitalize text-primaryInput  mb-2">
                        Tagline <sup className="text-lg relative top-[1px]">*</sup>
                    </Heading>
                    <Input
                        {...orgTaglineField}
                        error={errors?.orgTagline?.message}
                        placeholder={'Describe your organisation’s purpose in 1 line'}
                        className={'w-[100%]'}
                    />
                    <CharacterLimit min={orgTaglineField?.value?.length} max={50} />
                </div>
                <div className="mb-7">
                    <Heading type="medium" className="capitalize text-primaryInput mb-2">
                        Brief Description <sup className="text-lg relative top-[1px]">*</sup>
                    </Heading>

                    <Input
                        {...orgDescriptionField}
                        error={errors?.orgDescription?.message}
                        type="textArea"
                        name="orgDescription"
                        placeholder={'What does this organization do ?'}
                        className={'w-[100%]'}
                    />
                    <CharacterLimit min={orgDescriptionField?.value?.length} max={1000} />
                </div>
                <div className="mb-7">
                    <Heading type="medium" className="capitalize text-primaryInput mb-2">
                        Organization Social Links
                    </Heading>
                    <p className="font-medium mb-2 text-secondaryInput ">
                        Link to your organization’s Website, Twitter, LinkedIn or Instagram. <br /> Ensure to add
                        atleast one link for verification.
                    </p>
                    <div className="flex flex-col gap-2">
                        <Input
                            {...register('link1', {
                                required: 'Please enter a social link',
                                pattern: {
                                    value: urlRegex,
                                    message: 'Please enter valid url.', // JS only: <p>error message</p> TS only support string
                                },
                            })}
                            type="text"
                            error={errors?.link1?.message}
                            placeholder={'Link 1 *'}
                            className={'w-[100%]'}
                        />

                        <Input
                            {...register('link2', {
                                pattern: {
                                    value: urlRegex,
                                    message: 'Please enter valid url.', // JS only: <p>error message</p> TS only support string
                                },
                                validate: (value) => {
                                    // Check if link1 is empty when link2 has a value
                                    if (value && !getValues('link1')) {
                                        return 'You cannot enter Link 2 if Link 1 is not entered.';
                                    }
                                    return true;
                                },
                            })}
                            type="text"
                            placeholder={'Link 2(optional)'}
                            error={errors?.link2?.message}
                            className={'w-[100%]'}
                        />
                        <Input
                            {...register('link3', {
                                pattern: {
                                    value: urlRegex,
                                    message: 'Please enter valid url.', // JS only: <p>error message</p> TS only support string
                                },
                                validate: (value) => {
                                    // Check if link1 is empty when link2 has a value
                                    if (value && !(getValues('link1') && getValues('link2'))) {
                                        return 'You cannot enter Link 3 if Link 1 and Link 2 is not entered.';
                                    }
                                    return true;
                                },
                            })}
                            type="text"
                            placeholder={'Link 3(optional)'}
                            error={errors?.link3?.message}
                            className={'w-[100%]'}
                        />
                    </div>
                </div>
                <div className="mb-7">
                    <Heading type="medium" className="capitalize text-primaryInput mb-2">
                        Upload Organization Logo <sup className="text-lg relative top-[1px]">*</sup>
                    </Heading>
                    <div className="p-6 bg-white  flex flex-col text-uploadText items-center shadow-sm rounded-md ">
                        <div className="border flex flex-col gap-2  border-borderColor border-dashed w-full p-6 rounded-md">
                            {!imageURL && (
                                <>
                                    {' '}
                                    <label className="flex flex-col gap-2 items-center" for="avatar">
                                        <AddProfileImage />
                                        <p>Click to choose files</p>
                                    </label>
                                    <Input
                                        type="file"
                                        {...register('orgLogo', {
                                            required: 'Please upload a  organization logo.',
                                        })}
                                        onChange={(e) =>
                                            setImage({
                                                image: e.target.files[0],
                                                imageURL: URL.createObjectURL(e.target.files[0]),
                                            })
                                        }
                                    />
                                </>
                            )}
                            {!edit ? (
                                imageURL && (
                                    <div>
                                        <img
                                            className="w-20 h-20 rounded-md object-center object-cover "
                                            src={imageURL}
                                            alt="profile"
                                        />
                                    </div>
                                )
                            ) : (
                                <div className="relative flex items-center justify-center">
                                    <img
                                        className="w-20 h-20 rounded-full object-center object-cover "
                                        src={imageURL}
                                        alt="profile"
                                    />
                                    <div className="flex items-center gap-3 absolute top-0 right-0 ">
                                        <div>
                                            <label className="cursor-pointer" for="avatar">
                                                <EditLogo />
                                            </label>
                                            <Input
                                                type="file"
                                                {...register('orgLogo')}
                                                onChange={(e) => handleFileChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!imageURL && (
                                <div className="flex gap-2 justify-center items-center">
                                    <Info />
                                    <p className="font-semibold">Max file size: 2 MB ; Ideal dimensions: 75x75 px</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <p className="text-foundryRed mt-1">{errors?.orgLogo?.message}</p>
                </div>

                <div>
                    {edit ? (
                        <div type={'submit'} className="flex items-center justify-end">
                            <Button variant="primary" className="" size="small">
                                {loading ? <Loader /> : 'Save'}
                            </Button>
                        </div>
                    ) : (
                        <Button type="submit" variant="secondary" size="large" width="w-[50%]">
                            {!loading ? ' Submit Application' : <Loader />}
                        </Button>
                    )}
                </div>
            </form>
        </div>
    );
}

export default OrganizationInput;
