const EditLogo = () => {
    return (
        <div>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="19.8729" cy="19.8729" r="19.3729" fill="white" stroke="#E9E9E9" />
                <path
                    d="M28.811 12.3872L27.3513 10.9268C26.4103 9.98535 24.8782 9.98535 23.9372 10.9268L20.7041 14.1614L10.2207 24.6497V29.5258H15.0945L25.6382 18.9771L28.811 15.8028C29.764 14.8614 29.764 13.3286 28.811 12.3872ZM14.0932 27.1119H12.6335V25.6515L23.0807 15.1994L24.5404 16.6598L14.0932 27.1119Z"
                    fill="#969696"
                />
            </svg>
        </div>
    );
};

export default EditLogo;
