import { useEffect, useState } from 'react';
import { getOrganisations } from '../../apis/oragnisations/organisation';
import SubHeading from '../../ui/SubHeading';
import SummaryCard from '../../ui/SummaryCard';
import WhiteCard from '../../ui/WhiteCard';
import Loader from '../../ui/Loader';
import { useNavigate } from 'react-router-dom';
import Button from '../../ui/Button';
import NoDataAvailable from '../../ui/NoDataAvailable';
// import { unsubscribe } from '../../firebase';

function MyOrganizations() {
    const [organizations, setOrganizations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(function () {
        const fetchData = async () => {
            try {
                const data = await getOrganisations();
                setOrganizations(data);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();

        // return () => unsubscribe();
    }, []);

    return (
        <div>
            <div className="flex justify-between items-center">
                <SubHeading type="large" className={'text-lightBlack'}>
                    My Organisations
                </SubHeading>

                {organizations.length > 0 && (
                    <p className="cursor-pointer underline" onClick={() => navigate('/myorganizations')}>
                        View All
                    </p>
                )}
            </div>
            <WhiteCard verticalMargin="" className="mt-1 px-5 py-[18px] ">
                {isLoading ? (
                    <div className="h-40 flex items-center justify-center">
                        <Loader />
                    </div>
                ) : (
                    <div className="">
                        {organizations.length ? (
                            organizations
                                .slice(0, 3)
                                .map((organization) => (
                                    <SummaryCard
                                        redirectTo={() => navigate(`/organizations/${organization._id}`)}
                                        key={organization.name + organization.tagline}
                                        organization={organization}
                                    />
                                ))
                        ) : (
                            <div className="h-auto min-h-40 flex items-center justify-center">
                                <NoDataAvailable marginTop="mt-8" imgClassName="w-36" className="mb-8" />
                            </div>
                        )}
                    </div>
                )}
            </WhiteCard>
        </div>
    );
}

export default MyOrganizations;
