import { useEffect, useRef, useState } from 'react';
import Overlay from '../../ui/Overlay';
import Button from '../../ui/Button';
import Feedbackclose from '../../assets/icons/Feedbackclose';
import { deleteProject, getBountiesInProject } from '../../apis/projects/projects';
import { toast } from 'sonner';
import { useLoadingState } from '../../hooks/useLoader';
import Loader from '../../ui/Loader';
import ModalBox from '../../ui/ModalBox';

function DeleteProjectModal({ close, projectName, projectId, onDeletion }) {
    const modalRef = useRef(null); // Reference for modal content
    const [bountiesInProject, setBountiesInProject] = useState([]);
    const { loading, startLoading, stopLoading } = useLoadingState();

    // Handle outside click to close modal
    useEffect(() => {
        function handleClickOutside(e) {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                close(); // Close modal when clicked outside
            }
        }

        // Add event listener for outside clicks
        document.addEventListener('mousedown', handleClickOutside, true);

        return () => {
            // Clean up the event listener
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [close]);

    // Fetch bounties in the project when component mounts
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getBountiesInProject(projectId);
                setBountiesInProject(data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [projectId]);

    return (
        <Overlay>
            <div
                ref={modalRef} // Attach ref to modal content
                className="absolute rounded-lg left-[50%]     bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
                onClick={(e) => e.stopPropagation()} // Prevent clicks inside modal from closing it
            >
                <ModalBox
                    close={close}
                    onSubmit={async (e) => {
                        e.stopPropagation(); // Prevent button click from closing modal

                        if (bountiesInProject.length) {
                            toast.error('A project can only be deleted if it has no active bounties. .');
                            close();
                            return;
                        }
                        startLoading();
                        const { success } = await deleteProject(projectId);
                        if (success) {
                            onDeletion(projectId);

                            toast.success('Project is now deleted.');
                            stopLoading();
                            close();
                        }
                    }}
                    primaryBtnText={'Submit'}
                    loading={loading}
                    secondaryBtnText={'Cancel'}
                >
                    <>
                        Are you sure you want to <span className="font-bold">Delete the Project {projectName}</span>?
                    </>
                </ModalBox>
            </div>
        </Overlay>
    );
}

export default DeleteProjectModal;
