import React from 'react';

const TrophyIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.7778 2.22222H15.5556V0H4.44444V2.22222H2.22222C1 2.22222 0 3.22222 0 4.44444V5.55556C0 8.38889 2.13333 10.7 4.87778 11.0444C5.57778 12.7111 7.07778 13.9667 8.88889 14.3333V17.7778H4.44444V20H15.5556V17.7778H11.1111V14.3333C12.9222 13.9667 14.4222 12.7111 15.1222 11.0444C17.8667 10.7 20 8.38889 20 5.55556V4.44444C20 3.22222 19 2.22222 17.7778 2.22222ZM2.22222 5.55556V4.44444H4.44444V8.68889C3.15556 8.22222 2.22222 7 2.22222 5.55556ZM10 12.2222C8.16667 12.2222 6.66667 10.7222 6.66667 8.88889V2.22222H13.3333V8.88889C13.3333 10.7222 11.8333 12.2222 10 12.2222ZM17.7778 5.55556C17.7778 7 16.8444 8.22222 15.5556 8.68889V4.44444H17.7778V5.55556Z"
                fill="#1BA77B"
            />
        </svg>
    );
};

export default TrophyIcon;
