import { useEffect, useRef } from 'react';
import Overlay from '../../ui/Overlay';
import Button from '../../ui/Button';
import Feedbackclose from '../../assets/icons/Feedbackclose';
import { useNavigate, useParams } from 'react-router-dom';
import { sendRequestForVoting } from '../../apis/bounties/bounties';
import { useLoadingState } from '../../hooks/useLoader';
import Loader from '../../ui/Loader';
import ModalBox from '../../ui/ModalBox';

function ValidationCreationModal({ close, bountyName, infoRequired }) {
    const myRef = useRef({ close });
    const { id, bountyId } = useParams();
    const { loading, startLoading, stopLoading } = useLoadingState();
    const navigate = useNavigate();
    useEffect(
        function () {
            function handleClick(e) {
                if (myRef.current && !myRef.current.contains(e.target)) {
                    close();
                }
            }

            document.addEventListener('click', handleClick, true);

            return () => document.removeEventListener('click', handleClick, true);
        },
        [close]
    );

    const onRequest = async () => {
        const validationPayload = {
            organisationId: id,
            requestType: 'BOUNTY_VALIDATION',
            bountyId: bountyId,
            lastUpdateTime: infoRequired.lastUpdateTime,
        };
        startLoading();
        const responseRequest = await sendRequestForVoting(validationPayload);

        stopLoading();
        close();
        navigate(-2);
    };

    return (
        <Overlay>
            <div
                ref={myRef}
                className="absolute rounded-lg left-[50%]     bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
            >
                <ModalBox
                    close={close}
                    onSubmit={onRequest}
                    primaryBtnText={'Submit'}
                    loading={loading}
                    secondaryBtnText={'Cancel'}
                >
                    <>
                        Are you sure you want to{' '}
                        <span className="font-bold"> Create the request for bounty {bountyName} validation ? </span>
                    </>
                </ModalBox>
            </div>
        </Overlay>
    );
}

export default ValidationCreationModal;
