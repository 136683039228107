import { useState } from 'react';
import { BountiesProvider } from '../contexts/BountiesContext';
import BountiesMain from '../features/bounties/BountiesMain';
import BountiesPreview from '../features/bounties/BountiesPreview';
import BountiesSideBar from '../features/bounties/BountiesSideBar';
import RequestCreated from '../features/bounties/RequestCreated';

function AddBounty() {
    const [step, setStep] = useState(1);
    const [completedSteps, setCompletedSteps] = useState([]);
    return (
        <div
            className={`grid ${
                step === 7 ? 'grid-cols-1  ' : 'grid-cols-createBounty'
            }  overflow-y-hidden overflow-x-visible   pt-8  px-9 pr-0   `}
        >
            <BountiesProvider>
                {step === 7 ? (
                    <RequestCreated />
                ) : (
                    <>
                        <BountiesSideBar
                            completedSteps={completedSteps}
                            setCompletedSteps={setCompletedSteps}
                            step={step}
                            setStep={setStep}
                        />
                        <BountiesMain
                            completedSteps={completedSteps}
                            setCompletedSteps={setCompletedSteps}
                            step={step}
                            setStep={setStep}
                        />
                    </>
                )}
            </BountiesProvider>
        </div>
    );
}

export default AddBounty;
