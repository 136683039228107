function Feedbackclose() {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="18" fill="#F3F3F3" />
            <path d="M24.708 12L12.7076 24.0004" stroke="#B2B2B2" strokeWidth="2" strokeLinecap="round" />
            <path d="M24 24.001L11.9996 12.0005" stroke="#B2B2B2" strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
}

export default Feedbackclose;
