import { useNavigate } from 'react-router-dom';
import BackButton from '../../assets/icons/BackButton';
import Button from '../../ui/Button';
import Heading from '../../ui/Heading';
import useMetaMask from '../../hooks/useMetaMask';
import { useEffect } from 'react';

function AttesterInputStepFour({ setStep, handleManualValueSetting }) {
    const navigate = useNavigate();
    const { accounts, isConnected, connect, disconnect } = useMetaMask();
    useEffect(
        function () {
            if (accounts.length) handleManualValueSetting(undefined, 'evmAdress', accounts);
        },
        [accounts]
    );
    return (
        <div>
            <div className="w-[70%] mb-9">
                <div className="flex items-center">
                    <BackButton onClick={() => navigate(-1)} />
                    <Heading type={'pageHeading'} className={'text-lightBlack ml-3'}>
                        Connect Wallet
                    </Heading>
                </div>
                <p className="mt-2">
                    In the table below you will find Impact Claims waiting to be evaluated for their authenticity, if
                    found to be a credible impact claim in your impartial evaluation, then attest the impact.
                </p>
            </div>

            <div>
                <h1>MetaMask Integration</h1>
                {isConnected ? (
                    <div>
                        <p>Connected Account: {accounts[0]}</p>
                        <button
                            type="button"
                            onClick={() => {
                                disconnect();
                                handleManualValueSetting(undefined, 'evmAdress', ['']);
                            }}
                        >
                            Disconnect
                        </button>
                    </div>
                ) : (
                    <button
                        type="button"
                        onClick={async () => {
                            await connect();
                        }}
                    >
                        Connect MetaMask
                    </button>
                )}
            </div>

            <div className="flex justify-between items-center mt-16">
                <Button onClick={() => setStep(3)} className="bg-white" variant="tertiary" size="small">
                    Back
                </Button>
                <Button type="submit" variant="secondary" size="xsmall">
                    Submit Application
                </Button>
            </div>
        </div>
    );
}

export default AttesterInputStepFour;
