import { useState } from 'react';
import { superpowers } from '../../constants/powersData';

import SuperPowersInAttesterInput from './SuperPowersInAttesterInput';
import Button from '../../ui/Button';
import BackButton from '../../assets/icons/BackButton';
import Heading from '../../ui/Heading';
import { useNavigate } from 'react-router-dom';

function AttesterInputStepOne({ setStep, powers, setPowers, selectedPowers, setSelectedPowers }) {
    // const [selectedPowers, setSelectedPowers] = useState([]);
    // const [powers, setPowers] = useState(superpowers);
    const navigate = useNavigate();
    const categories = Object.keys(powers);

    return (
        <div>
            <div className="w-[70%] mb-9">
                <div className="flex items-center">
                    <BackButton type={'button'} onClick={() => navigate(-1)} />
                    <Heading type={'pageHeading'} className={'text-lightBlack ml-3'}>
                        Choose your Superpowers
                    </Heading>
                </div>
                <p className="mt-2">
                    In the table below you will find Impact Claims waiting to be evaluated for their authenticity, if
                    found to be a credible impact claim in your impartial evaluation, then attest the impact.
                </p>
            </div>
            <SuperPowersInAttesterInput
                powers={powers}
                setPowers={setPowers}
                categories={categories}
                selectedPowers={selectedPowers}
                setSelectedPowers={setSelectedPowers}
            />
            <div className="flex justify-between items-center mt-16">
                {/* <Button className="bg-white" variant="tertiary" size="small">
                    Back
                </Button> */}
                <Button onClick={() => setStep(2)} size="small">
                    Next
                </Button>
            </div>
        </div>
    );
}

export default AttesterInputStepOne;
