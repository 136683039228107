import React from 'react';

const TestMCQSubDetails = ({ data }) => {
    const { action } = data;

    const titleObject = action.find((obj) => obj.name === 'Title');
    const questionObject = action.find((obj) => obj.name === 'question');
    const answersObject = action.find((obj) => obj.name === 'answers')?.value?.split(',');
    const correctAnswerObject = action.find((obj) => obj.name === 'correctAnswer');
    return (
        <>
            <p className="flex gap-1 text-xs text-secondaryInput mb-2  ">
                <span>Response type : </span>{' '}
                <span className="font-bold capitalize ">{data?.label?.split('_')?.join(' ')?.toLowerCase()}</span>
            </p>
            <p className="font-semibold capitalize text-primaryInput mb-2">
                <span>{titleObject?.value}</span>
            </p>
            <p className="text-sm capitalize text-primaryInput mb-2">
                <span>{questionObject?.value}</span>
            </p>
            <ol>
                {answersObject?.map((answer, index) => {
                    return (
                        <p key={answer} className="text-sm flex items-center gap-1 capitalize text-primaryInput mb-2">
                            <span>{index + 1}.</span> <span>{answer}</span>
                        </p>
                    );
                })}
            </ol>
            <p className="text-sm capitalize text-primaryInput mb-2">
                <span>Correct Answer - {correctAnswerObject?.value}</span>
            </p>
        </>
    );
};

export default TestMCQSubDetails;
