import React, { useState, useEffect } from 'react';
import ReactMapboxGl, { Feature, Layer, Marker, Source } from 'react-mapbox-gl';
import { EarthIcon, FistIcon, SunIcon, WaterIcon } from '../../assets/icons/ProgressBarIcons';

import defaultMarker from '../../assets/defaultMarker.png';
// {cores[core.toLowerCase()]?.icon || cores['water'].icon}
const cores = {
    earth: {
        color: '#36B67A',
        icon: <EarthIcon color="bg-foundryGreenL" />,
    },
    water: {
        color: '#76C8E8',
        icon: <WaterIcon color="bg-foundryBlueL" />,
    },
    energy: {
        color: '#FBCF4E',
        icon: <SunIcon color="bg-foundryYellowL" />,
    },
    social: {
        color: '#BA2C2C',
        icon: <FistIcon color="bg-foundryRedL" />,
    },
};

// Initialize Map with Access Token
const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoiZmFpc2FsZHJvcGNoYWluIiwiYSI6ImNsdXJnOWF2czA3NmYyam4xZWkzbmltaHAifQ.IK0wrkOv-H-C57rLCcMBMA',
});

function PhysicalBountyMap({ searchQuery, setSearchText, circleRadius, core, setCoordinates, hasStartedTyping }) {
    const [suggestions, setSuggestions] = useState([]);
    const [markerPosition, setMarkerPosition] = useState({ lng: 77.5115746, lat: 12.972048 });
    const [citySelected, setCitySelected] = useState(false);
    const [mapCenter, setMapCenter] = useState([77.5115746, 12.972048]);
    const [zoom, setZoom] = useState(8);

    const radius = circleRadius * 1000;

    // Fetch search suggestions based on query
    useEffect(() => {
        // If searchText is empty, clear suggestions
        if (searchQuery) {
            setCitySelected(false);
        }

        if (!searchQuery || citySelected) {
            setSuggestions([]);
            return;
        }

        // Set a timeout to debounce the API call
        const delayDebounceFn = setTimeout(async () => {
            const response = await fetch(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchQuery}.json?access_token=pk.eyJ1IjoiZmFpc2FsZHJvcGNoYWluIiwiYSI6ImNsdXJnOWF2czA3NmYyam4xZWkzbmltaHAifQ.IK0wrkOv-H-C57rLCcMBMA`
            );
            const data = await response.json();
            setSuggestions(data.features);
        }, 300); // 300ms debounce time

        // Clear the timeout if searchText changes before the delay is over
        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [searchQuery]); // Dependency array to trigger the effect when searchText changes

    // Handle selecting a suggestion
    const handleSelectSuggestion = (lng, lat, city) => {
        setMarkerPosition({ lng, lat });
        setMapCenter([lng, lat]);
        setSuggestions([]);

        setCoordinates({ coordinates: [lat, lng] });
        setCitySelected(true);
        setSearchText(city);
    };

    const calculateCircleRadius = (zoom, latitude) => {
        const earthCircumference = 40075016.686; // Earth's circumference in meters
        const latitudeAdjustment = Math.cos((latitude * Math.PI) / 180); // Latitude distortion factor
        const metersPerPixel = (earthCircumference * latitudeAdjustment) / Math.pow(2, zoom + 8); // Meters per pixel

        const radiusInMeters = circleRadius * 1000; // Circle radius in meters
        let radiusInPixels = radiusInMeters / metersPerPixel; // Convert meters to pixels

        const adjustmentFactor = 0.15 * zoom; // Adjust by 10% (or a fixed value like 20 pixels)
        radiusInPixels += radiusInPixels * adjustmentFactor;

        return radiusInPixels;
    };

    // Handle map click to set the marker position
    const handleMapClick = (map, event) => {
        const { lng, lat } = event.lngLat;
        setMarkerPosition({ lng, lat });
        setCoordinates({ coordinates: [lat, lng] });
    };

    return (
        <div>
            {hasStartedTyping && suggestions.length > 0 && (
                <ul className="suggestions">
                    {suggestions?.map((suggestion) => (
                        <li
                            className="cursor-pointer px-2 py-2 bg-white rounded-md hover:bg-gray-100"
                            key={suggestion.id}
                            onClick={() =>
                                handleSelectSuggestion(
                                    suggestion.geometry.coordinates[0],
                                    suggestion.geometry.coordinates[1],
                                    suggestion.place_name
                                )
                            }
                        >
                            {suggestion.place_name}
                        </li>
                    ))}
                </ul>
            )}

            {/* Map Container */}
            <div className="my-6 overflow-hidden bg-transparent rounded-2xl">
                <Map
                    style="mapbox://styles/mapbox/streets-v9"
                    containerStyle={{
                        height: '30vh',
                        width: '100%',
                    }}
                    center={mapCenter}
                    zoom={[zoom]}
                    onZoomEnd={(map) => setZoom(map.getZoom())}
                    onClick={handleMapClick} // Set marker on map click
                >
                    {/* Marker positioned by clicking on map */}
                    {markerPosition && (
                        <Marker coordinates={[markerPosition.lng, markerPosition.lat]} anchor="center">
                            <img className="w-5" src={defaultMarker} alt="" />
                        </Marker>
                    )}

                    {/* Circle around the marker */}

                    <Layer
                        type="circle"
                        id="circle"
                        paint={{
                            // 'circle-radius': {
                            //     stops: [
                            //         [0, 0],
                            //         [20, calculateCircleRadius(zoom, markerPosition.lat)],
                            //     ],
                            // },
                            'circle-radius': calculateCircleRadius(zoom, markerPosition.lat),
                            'circle-color': cores[core.toLowerCase()]?.color || cores['water'].color,
                            'circle-opacity': 0.25,
                            'circle-stroke-width': 0.5,
                            'circle-stroke-color': cores[core.toLowerCase()]?.color || cores['water'].color,
                        }}
                    >
                        <Feature coordinates={[markerPosition.lng, markerPosition.lat]} />
                    </Layer>
                </Map>
            </div>
        </div>
    );
}

export default PhysicalBountyMap;

// Calculate circle radius based on zoom level
// const calculateCircleRadius = (zoom) => {
//     const earthCircumference = 40075016.686;
//     const metersPerPixel = earthCircumference / Math.pow(2, zoom + 8);
//     return radius / metersPerPixel;
// };
// const calculateCircleRadius = (zoom, latitude) => {
//     const earthCircumference = 40075016.686; // in meters at the equator
//     const latitudeAdjustment = Math.cos((latitude * Math.PI) / 180); // adjusts for latitude distortion
//     const metersPerPixel = (earthCircumference * latitudeAdjustment) / Math.pow(2, zoom + 8); // adjust for latitude
//     const radiusInPixels = (circleRadius * 1000) / metersPerPixel; // convert km to meters, then calculate pixels
//     return radiusInPixels;
// };
