import {
    BTCode,
    BTCommunity,
    BTContent,
    BTDataScience,
    BTDesign,
    BTFunding,
    BTGardenening,
    BTGeneral,
    BTGHGRemoval,
    BTHarvest,
    BTLearn,
    BTPromotion,
    BTRecycle,
    BTRegenerate,
    BTRegistration,
    BTResearch,
    BTRestoration,
    BTScouting,
    BTSurvey,
    BTTraining,
    BTValidate,
    BTVolunteer,
    BTWriting,
} from '../assets/icons/bountyTypeIcons';
import { EarthIcon, FistIcon, SunIcon, WaterIcon } from '../assets/icons/ProgressBarIcons';
import waterDrop from '../assets/waterdrop.png';
import sun from '../assets/sun.png';
import fist from '../assets/fist.png';
import earth from '../assets/earth.png';
import nopoverty from '../assets/sdgs/E-WEB-Goal-01.png';
import zerohunger from '../assets/sdgs/E-WEB-Goal-02.png';
import goodhealth from '../assets/sdgs/E-WEB-Goal-03.png';
import education from '../assets/sdgs/E-WEB-Goal-04.png';
import genderequality from '../assets/sdgs/E-WEB-Goal-05.png';
import cleanwater from '../assets/sdgs/E-WEB-Goal-06.png';
import cleanenergy from '../assets/sdgs/E-WEB-Goal-07.png';
import economicgrowth from '../assets/sdgs/E-WEB-Goal-08.png';
import industry from '../assets/sdgs/E-WEB-Goal-09.png';
import inequalities from '../assets/sdgs/E-WEB-Goal-10.png';
import sustainablecities from '../assets/sdgs/E-WEB-Goal-11.png';
import consumptionandproduction from '../assets/sdgs/E-WEB-Goal-12.png';
import climateaction from '../assets/sdgs/E-WEB-Goal-13.png';
import lifebelowwater from '../assets/sdgs/E-WEB-Goal-14.png';
import lifeonland from '../assets/sdgs/E-WEB-Goal-15.png';
import peace from '../assets/sdgs/E-WEB-Goal-16.png';
import partnershipgoals from '../assets/sdgs/E-WEB-Goal-17.png';
export const bountyTypesData = [
    'Design',
    'Code',
    'Survey',
    'Scouting',
    'Writing',
    'Validate',
    'Volunteer',
    'Content',
    'Research',
    'Learning',
    'Harvesting',
    'GHG Removal',
    'Recycle',
    'Restoration',
    'Gardening',
    'Data Science',
    'Promotion',
    'Regenerate',
    'Training',
    'General',
    'Funding',
    'Registration',
    'Community',
];
export const bountyTypesWithIconData = [
    { label: 'Design', icon: <BTDesign /> },
    { label: 'Code', icon: <BTCode /> },
    { label: 'Survey', icon: <BTSurvey /> },
    { label: 'Scouting', icon: <BTScouting /> },
    { label: 'Writing', icon: <BTWriting /> },
    { label: 'Validate', icon: <BTValidate /> },
    { label: 'Volunteer', icon: <BTVolunteer /> },
    { label: 'Content', icon: <BTContent /> },
    { label: 'Research', icon: <BTResearch /> },
    { label: 'Learning', icon: <BTLearn /> },
    { label: 'Harvesting', icon: <BTHarvest /> },
    { label: 'GHG Removal', icon: <BTGHGRemoval /> },
    { label: 'Recycle', icon: <BTRecycle /> },
    { label: 'Restoration', icon: <BTRestoration /> },
    { label: 'Gardening', icon: <BTGardenening /> },
    { label: 'Data Science', icon: <BTDataScience /> },
    { label: 'Promotion', icon: <BTPromotion /> },
    { label: 'Regenerate', icon: <BTRegenerate /> },
    { label: 'Training', icon: <BTTraining /> },
    { label: 'General', icon: <BTGeneral /> },
    { label: 'Funding', icon: <BTFunding /> },
    { label: 'Registration', icon: <BTRegistration /> },
    { label: 'Community', icon: <BTCommunity /> },
];

export const coresData = ['Water', 'Earth', 'Energy', 'Social'];

export const coresWithIconData = [
    {
        label: 'Water',
        icon: waterDrop,
    },
    {
        label: 'Earth',
        icon: earth,
    },
    {
        label: 'Energy',
        icon: sun,
    },
    {
        label: 'Social',
        icon: fist,
    },
];

export const SUSTAINABLE_DEVELOPMENT_GOALS = [
    'No poverty',
    'Zero hunger',
    'Good health & well-being',
    'Quality education',
    'Gender equality',
    'Clean water & sanitation',
    'Affordable & clean energy',
    'Decent work & economic growth',
    'Industry, innovation & infrastructure',
    'Reduced inequalities',
    'Sustainable cities & communities',
    'Responsible consumption & production',
    'Climate action',
    'Life below water',
    'Life on land',
    'Peace, justice & strong institutions',
    'Partnerships for the goals',
];
export const DOUGHNUT_METRICS = [
    'water',
    'freshwater withdrawals',
    'ocean acidification',
    'housing',
    'food',
    'climate change',
    'biodiversity loss',
    'land conversion',
    'energy',
    'air pollution',
    'ozone layer depletion',
    'education',
    'income & work',
    'gender equality',
    'networks',
    'political voice',
    'health',
    'social equity',
    'peace & justice',
    'fertilizer use',
    'chemical pollution',
];

// ‘Draft', 'Creation Requested', 'Live and Published‘, 'Unvalidated', ‘Live and Unpublished’, ’Validation Requested', 'Completed'

export const BOUNTY_STATUS_TAG_COLOR = {
    Draft: { bg: 'bg-foundryYellowL', text: 'text-foundryYellow', border: 'ring-foundryYellow' },
    'Creation Requested': {
        bg: 'bg-foundryBlueL',
        text: 'text-foundryBlue',
        border: 'ring-foundryBlue',
    },
    'Live and Published': { bg: 'bg-foundryGreenL', text: 'text-foundryGreen', border: 'ring-foundryGreen' },
    'Live and Unpublished': { bg: 'bg-foundryYellowL', text: 'text-foundryYellow', border: 'ring-foundryYellow' },
    'Validation Requested': { bg: 'bg-foundryBlueL', text: 'text-foundryBlue', border: 'ring-foundryBlue' },
    Unvalidated: { bg: 'bg-foundryYellowL', text: 'text-foundryYellow', border: 'ring-foundryYellow' },
    Completed: { bg: 'bg-foundryGreenL', text: 'text-foundryGreen', border: 'ring-foundryGreen' },
};

export const VOTING_STATUS_COLOR = {
    'voted yes': { bg: 'bg-foundryGreenL', text: 'text-foundryGreen' },
    'voted no': { bg: 'bg-foundryRedL', text: 'text-foundryRed' },
    'not voted': {
        bg: 'bg-foundryYellowL',
        text: 'text-foundryYellow',
    },
    'ready to execute': { bg: 'bg-foundryGreenL', text: 'text-foundryGreen' },
};

export const SDG_IMAGES = {
    'No poverty': nopoverty,
    'Zero hunger': zerohunger,
    'Good health & well-being': goodhealth,
    'Quality education': education,
    'Gender equality': genderequality,
    'Clean water & sanitation': cleanwater,
    'Affordable & clean energy': cleanenergy,
    'Decent work & economic growth': economicgrowth,
    'Industry, innovation & infrastructure': industry,
    'Reduced inequalities': inequalities,
    'Sustainable cities & communities': sustainablecities,
    'Responsible consumption & production': consumptionandproduction,
    'Climate action': climateaction,
    'Life below water': lifebelowwater,
    'Life on land': lifeonland,
    'Peace, justice & strong institutions': peace,
    'Partnerships for the goals': partnershipgoals,
};

// export const MICROTASK_RESPONSE_TYPE_DATA = [

// {
//      "QUESTION_ANSWER":"Question Answer"
//      'Short Answer',
//      'Long Answer',
//      'Upload Media',
//      'Instructional Youtube',
//      'Instructional Navigateion',
//      'Get Location',
//      'Q-A Multiple Choice',
//      'Q-A Chechboxes',
//      'Rating',
// }

// ];
