import { useEffect, useRef, useState } from 'react';
import Overlay from '../../ui/Overlay';
import Button from '../../ui/Button';
import Table from '../../ui/Table';
import AvatarImage from '../../ui/AvatarImage';
import Legend from '../../ui/Legend';
import { updateMembers } from '../../apis/oragnisations/organisation';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import OwnButton from '../walletconnectUI/OwnButton';
import { useWallet } from '@solana/wallet-adapter-react';
import { executorToAddMemberToOrg } from '../../blockchainInteraction/addMembers';
import { clusterApiUrl, Connection } from '@solana/web3.js';
import { getProvider } from '../../helpers/getProvider';
import Feedbackclose from '../../assets/icons/Feedbackclose';
import { useLoadingState } from '../../hooks/useLoader';
import Loader from '../../ui/Loader';
import WhiteCard from '../../ui/WhiteCard';
import StatsCard from '../../ui/StatsCard';
import { joiningString } from '../../helpers/joiningString';
import { RPC_ENDPOINT } from '../../constants/apiPaths';

function ConfirmUpdateModal({
    close,
    allMembers,
    membersGoingToBeEdited,
    beforeEditing,
    membersGoingToBeRemoved,
    multiSig,
}) {
    const myRef = useRef();
    const { publicKey } = useWallet();
    const [isAnotherModalOpen, setIsAnotherModalOpen] = useState(false);
    const { id } = useParams();
    const { loading, startLoading, stopLoading } = useLoadingState();
    const navigate = useNavigate();
    useEffect(
        function () {
            function handleClick(e) {
                if (myRef.current && !myRef.current.contains(e.target) && !isAnotherModalOpen) {
                    close();
                }
            }

            document.addEventListener('click', handleClick, true);

            return () => document.removeEventListener('click', handleClick, true);
        },
        [close, isAnotherModalOpen]
    );

    const beforeModification = membersGoingToBeEdited.map((obj) => {
        const memberNeeded = allMembers.find((beforeEditingMember) => beforeEditingMember.uid === Object.keys(obj)[0]);

        return memberNeeded;
    });

    const afterModification = membersGoingToBeEdited.map((obj) => {
        const memberNeeded = allMembers.find((afterEditingMember) => afterEditingMember.uid === Object.keys(obj)[0]);
        memberNeeded.roles = obj[Object.keys(obj)[0]];

        return memberNeeded;
    });
    const beforeRemoval = membersGoingToBeRemoved.map((id) => {
        const memberNeeded = allMembers.find((beforeremovingMember) => beforeremovingMember.uid === id);

        return memberNeeded;
    });
    const afterRemoval = membersGoingToBeRemoved.map((id) => {
        const memberNeeded = allMembers.find((beforeremovingMember) => beforeremovingMember.uid === id);
        memberNeeded.roles = [];

        return memberNeeded;
    });

    const modifyMembers = async () => {
        const payload = {
            organisationId: id,
            members: [
                ...afterModification.map((member) => ({ uid: member.uid, roles: member.roles })),
                ...afterRemoval.map((member) => ({ uid: member.uid, roles: member.roles })),
            ],
        };

        try {
            if (connectWalletOrNot.length) {
                const connection = new Connection(RPC_ENDPOINT, 'confirmed');

                const walletAdresses = connectWalletOrNot.map((item) => item.walletAddress);
                const transaction = await executorToAddMemberToOrg(
                    walletAdresses,
                    publicKey.toBase58(),
                    multiSig,
                    connection
                );

                const { signature } = await getProvider().signAndSendTransaction(transaction);

                await connection.getSignatureStatus(signature);
            }
            startLoading();
            const { success } = await updateMembers(payload);
            if (success) {
                stopLoading();
                toast.success('Roles have successfully been updated. ');

                navigate(-1);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const connectWalletOrNot = afterModification.filter(
        (member) => !member.isTreasurer && member.roles.includes('TREASURER')
    );
    const address = publicKey;

    const previousInitialCount = {
        totalMembers: beforeModification.length,
        bountyManagers: 0,
        bountyValidators: 0,
        treasurer: 0,
    };
    const previousMembersCount = beforeModification.reduce((acc, item, index) => {
        return {
            ...acc,
            bountyManagers: item.isBountyManager ? acc.bountyManagers + 1 : acc.bountyManagers,
            bountyValidators: item.isBountyValidator ? acc.bountyValidators + 1 : acc.bountyValidators,
            treasurer: item.isTreasurer ? acc.treasurer + 1 : acc.treasurer,
        };
    }, previousInitialCount);
    const newInitialCount = {
        totalMembers: afterModification.length,
        bountyManagers: 0,
        bountyValidators: 0,
        treasurer: 0,
    };
    const newMembersCount = afterModification.reduce((acc, item, index) => {
        return {
            ...acc,
            bountyManagers: item.roles.includes('BOUNTY_MANAGER') ? acc.bountyManagers + 1 : acc.bountyManagers,
            bountyValidators: item.roles.includes('BOUNTY_VALIDATOR') ? acc.bountyValidators + 1 : acc.bountyValidators,
            treasurer: item.roles.includes('TREASURER') ? acc.treasurer + 1 : acc.treasurer,
        };
    }, newInitialCount);
    return (
        <Overlay>
            <div
                ref={myRef}
                className="absolute rounded-lg left-[50%] w-[1427px]  grid grid-cols-2    py-6   bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
            >
                <div className=" border-r-2  border-borderColor ">
                    <div className="px-6">
                        <p className="text-xl text-lightBlack font-medium">Previous</p>
                        <WhiteCard shadow="" className="w-fit border border-borderColor p-6 flex gap-6">
                            <StatsCard countHeading={'Total Members'} count={previousMembersCount.totalMembers} />
                            <StatsCard countHeading={'Bounty Managers'} count={previousMembersCount.bountyManagers} />
                            <StatsCard
                                countHeading={'Bounty Validators'}
                                count={previousMembersCount.bountyValidators}
                            />
                            <StatsCard countHeading={'Treasurer'} count={previousMembersCount.treasurer} />
                        </WhiteCard>
                    </div>

                    <Table whiteCardClassName="shadow-none min-h-[350px]" cols="grid-cols-7 gap-2">
                        <Table.Header>
                            <p className="text-sm self-center font-semibold text-secondaryText ">Citizen ID</p>
                            <p className="text-sm self-center col-span-2 font-semibold text-secondaryText ">
                                Member Username
                            </p>
                            <p className="text-sm self-center col-span-4  font-semibold text-secondaryText ">Roles</p>
                        </Table.Header>

                        {beforeModification.map((member) => {
                            return (
                                <Table.Row key={member._id}>
                                    <p className="truncate w-full  self-center ">{member.uid}</p>
                                    <div className="flex col-span-2 items-center gap-1 ">
                                        <AvatarImage w="w-8" h="h-8" url={member.profileImage} />
                                        <p className="text-lightBlack font-medium ">{member.userName}</p>
                                    </div>
                                    <p className=" col-span-4 capitalize self-center ">
                                        {/* {member.isBountyManager && 'Bounty Manager,'}
                                        {member.isBountyValidator && 'Bounty Validator,'}
                                        {member.isTreasurer && 'Treasurer'} */}
                                        {joiningString(
                                            [
                                                member.isBountyManager && 'Bounty Manager',
                                                member.isBountyValidator && 'Bounty Validator,',
                                                member.isTreasurer && 'Treasurer',
                                            ].filter(Boolean)
                                        )}
                                    </p>
                                </Table.Row>
                            );
                        })}
                        {beforeRemoval.map((member) => {
                            return (
                                <Table.Row key={member._id}>
                                    <p className="truncate w-full  self-center ">{member.uid}</p>
                                    <div className="flex col-span-2 items-center gap-1 ">
                                        <AvatarImage w="w-8" h="h-8" url={member.profileImage} />
                                        <p className="text-lightBlack font-medium ">{member.userName}</p>
                                    </div>
                                    <p className=" col-span-4 self-center ">
                                        {/* {member.isBountyManager && 'Bounty Manager,'}
                                        {member.isBountyValidator && 'Bounty Validator,'}
                                        {member.isTreasurer && 'Treasurer'} */}
                                        {joiningString(
                                            [
                                                member.isBountyManager && 'Bounty Manager',
                                                member.isBountyValidator && 'Bounty Validator,',
                                                member.isTreasurer && 'Treasurer',
                                            ].filter(Boolean)
                                        )}
                                    </p>
                                </Table.Row>
                            );
                        })}
                    </Table>
                </div>
                <div className=" ">
                    <div className="px-6">
                        <p className="text-xl text-lightBlack font-medium">New</p>
                        <WhiteCard shadow="" className="w-fit border border-borderColor p-6 flex gap-6">
                            <StatsCard countHeading={'Total Members'} count={newMembersCount.totalMembers} />
                            <StatsCard countHeading={'Bounty Managers'} count={newMembersCount.bountyManagers} />
                            <StatsCard countHeading={'Bounty Validators'} count={newMembersCount.bountyValidators} />
                            <StatsCard countHeading={'Treasurer'} count={newMembersCount.treasurer} />
                        </WhiteCard>
                    </div>
                    <Table whiteCardClassName="shadow-none min-h-[350px]" cols="grid-cols-7 gap-2">
                        <Table.Header>
                            <p className="text-sm self-center font-semibold text-secondaryText ">Citizen ID</p>
                            <p className="text-sm self-center col-span-2 font-semibold text-secondaryText ">
                                Member Username
                            </p>
                            <p className="text-sm self-center col-span-4  font-semibold text-secondaryText ">Roles</p>
                        </Table.Header>

                        {afterModification.map((member) => {
                            return (
                                <Table.Row rowClassName={`bg-update`} key={member._id}>
                                    <p className="truncate w-full self-center ">{member.uid}</p>
                                    <div className="flex col-span-2 items-center gap-1 ">
                                        <AvatarImage w="w-8" h="h-8" url={member.profileImage} />
                                        <p className="text-lightBlack font-medium ">{member.userName}</p>
                                    </div>
                                    <p className=" self-center capitalize  col-span-4 ">
                                        {joiningString(
                                            member.roles.map((role) => role.split('_').join(' ').toLowerCase())
                                        )}
                                    </p>
                                </Table.Row>
                            );
                        })}
                        {afterRemoval.map((member) => {
                            return (
                                <Table.Row rowClassName={`bg-remove`} key={member._id}>
                                    <p className="truncate w-full self-center ">{member.uid}</p>
                                    <div className="flex col-span-2 items-center gap-1 ">
                                        <AvatarImage w="w-8" h="h-8" url={member.profileImage} />
                                        <p className="text-lightBlack font-medium ">{member.userName}</p>
                                    </div>
                                    <p className=" self-center col-span-4 ">{member.roles.join(',')}</p>
                                </Table.Row>
                            );
                        })}
                    </Table>
                </div>

                <div className="flex col-span-2 h-fit border-t border-borderColor px-9  pt-5 justify-between self-end items-center">
                    <p className="font-semibold  text-xl">Are you sure you want to update these roles ? </p>
                    <div className="flex items-center gap-3">
                        <Button onClick={close} variant="tertiary" size="round">
                            Cancel
                        </Button>

                        <div>
                            {connectWalletOrNot.length && !address ? (
                                <OwnButton setIsAnotherModalOpen={setIsAnotherModalOpen} size="round" text="Submit" />
                            ) : (
                                // <ExecuteButton />

                                <Button onClick={modifyMembers} size="round">
                                    {loading ? <Loader /> : 'Submit'}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Overlay>
    );
}

export default ConfirmUpdateModal;
