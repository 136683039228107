import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Table from '../../ui/Table';
import Tag from '../../ui/Tag';
import Input from '../../ui/Input';
import { useEffect, useState } from 'react';
import SortIcon from '../../assets/icons/SortIcon';
import { getUnvalidatedBounties } from '../../apis/bounties/bounties';
import { dmyFormat } from '../../helpers/dateInDayMonthYearFormat';
import StatsCard from '../../ui/StatsCard';
import WhiteCard from '../../ui/WhiteCard';
import NoDataAvailable from '../../ui/NoDataAvailable';
import Loader from '../../ui/Loader';
import Pagination from '../../ui/Pagination';
import search from '../../assets/search.png';
import { useSearchParams } from 'react-router-dom';
import { BOUNTY_STATUS_TAG_COLOR } from '../../constants/datas';
import useLocalStorage from '../../hooks/useLocalStoarge';
import PopOver from '../../ui/PopOver';
function ValidationOverview() {
    const [unvalidatedBounties, setUnvalidatedBounties] = useState([]);
    const [pageEntries, setPageEntries] = useState(10);
    const [searchParams] = useSearchParams();
    const [bountyDetails, handleBountyDetails] = useOutletContext();
    const [storedValue, setStoredValue] = useLocalStorage('bountySubmission', bountyDetails);
    const [name, setName] = useLocalStorage('name', 'Guest');
    const [isLoading, setIsLoading] = useState(true);
    const [searchString, setSearchString] = useState('');
    const { id } = useParams();

    const navigate = useNavigate();
    let filteredData = unvalidatedBounties;
    const page = !searchParams.get('page') ? 1 : Number(searchParams.get('page'));
    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    const data = await getUnvalidatedBounties(id, page, pageEntries);
                    setUnvalidatedBounties(data);
                    setIsLoading(false);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [id, page]
    );
    if (searchString)
        filteredData = filteredData.filter(
            ({ title, projectTitle }) =>
                title?.toLowerCase()?.includes(searchString?.toLowerCase()) ||
                projectTitle?.toLowerCase()?.includes(searchString?.toLowerCase())
        );

    return (
        <div>
            <div className="flex mt-6 items-center gap-4">
                <WhiteCard verticalMargin="" className="w-fit p-6 ">
                    <p className="font-semibold mb-2">Bounties</p>
                    <div className="flex items-center gap-4 ">
                        <StatsCard countHeading={'Current Total'} count={filteredData.length} />
                        <StatsCard
                            countHeading={'Pending Validation'}
                            count={filteredData.filter((d) => d?.status?.toLowerCase() === 'unvalidated').length}
                        />
                    </div>
                </WhiteCard>

                {/* <WhiteCard verticalMargin="" className="w-fit p-6 ">
                    <p className="font-semibold mb-2">Submissions</p>
                    <div className="flex items-center gap-6">
                        <StatsCard countHeading={'Pending Validation'} count={20} />
                    </div>
                </WhiteCard> */}
            </div>
            <Table className="  gap-8 " cols="grid-cols-tableValidationOverview">
                <div className="flex items-center w-[441px] gap-3 py-2 px-3 mb-4 border border-borderColor rounded-lg ">
                    <img className="w-6 h-6  object-cover" src={search} alt="" />
                    <Input
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        placeholder="Search Bounty Title or Project Title"
                        className=" !w-full !border-0 !px-0 !py-0 outline-none "
                        containerClass="flex-1"
                        type="text"
                    />
                </div>
                <Table.Header>
                    <p className="text-sm   font-semibold text-secondaryText flex items-center gap-1   ">
                        {' '}
                        <span>Bounty ID</span>{' '}
                        {/* <span>
                            <SortIcon />
                        </span>{' '} */}
                    </p>
                    <p className="text-sm   font-semibold text-secondaryText flex items-center gap-1    ">
                        {' '}
                        <span> Bounty Title </span>{' '}
                        {/* <span>
                            <SortIcon />
                        </span>{' '} */}
                    </p>
                    <p className="text-sm   font-semibold text-secondaryText flex items-center gap-1    ">
                        {' '}
                        <span> Bounty Status </span>{' '}
                        {/* <span>
                            <SortIcon />
                        </span>{' '} */}
                    </p>
                    <p className="text-sm   font-semibold text-secondaryText flex items-center gap-1    ">
                        {' '}
                        <span> Project </span>{' '}
                        {/* <span>
                            <SortIcon />
                        </span>{' '} */}
                    </p>
                    <p className="text-sm font-semibold text-secondaryText flex items-center gap-1    ">
                        {' '}
                        <span> Validations </span>{' '}
                        {/* <span>
                            <SortIcon />
                        </span>{' '} */}
                    </p>
                    <p className="text-sm  font-semibold text-secondaryText flex items-center gap-1    ">
                        {' '}
                        <span>Start Date </span>{' '}
                        {/* <span>
                            <SortIcon />
                        </span>{' '} */}
                    </p>
                    <p className="text-sm  font-semibold text-secondaryText flex items-center gap-1    ">
                        {' '}
                        <span>Expiry Date </span>{' '}
                        {/* <span>
                            <SortIcon />
                        </span>{' '} */}
                    </p>
                </Table.Header>
                {isLoading ? (
                    <div className="min-h-[20vh] flex justify-center items-center">
                        <Loader />
                    </div>
                ) : filteredData.length ? (
                    filteredData.map((overviewrow) => (
                        <Table.Row key={overviewrow._id}>
                            <p className="  truncate w-full uppercase justify-self-start text-primaryInput  ">
                                {overviewrow._id}
                            </p>
                            <p
                                onClick={() => {
                                    const payload = {
                                        title: overviewrow.title,
                                        projectTitle: overviewrow.projectTitle,
                                        startDate: overviewrow.startTimestamp,
                                        endDate: overviewrow.expirationTimestamp,
                                        totalSubmissions: unvalidatedBounties.length,
                                        status: overviewrow.status,
                                        hasImpactCertificate: overviewrow?.hasImpactCertificate,
                                        passThreshold: overviewrow?.passThreshold,
                                    };
                                    handleBountyDetails(payload);
                                    setStoredValue(payload);
                                    navigate(`bounty-submissions/${overviewrow._id}`);
                                }}
                                className=" cursor-pointer truncate font-medium text-primaryColor"
                            >
                                {overviewrow.title}
                            </p>

                            <PopOver
                                rounded="rounded-lg "
                                className="px-2 py-1 leading-3 self-center   "
                                textClassName="text-xs font-semibold"
                                bg={BOUNTY_STATUS_TAG_COLOR[overviewrow.status]?.bg}
                                textColor={BOUNTY_STATUS_TAG_COLOR[overviewrow.status]?.text}
                                borderColor={BOUNTY_STATUS_TAG_COLOR[overviewrow.status]?.border}
                                text={overviewrow.status}
                                active={
                                    overviewrow.status === 'Live and Published' ||
                                    overviewrow.status === 'Live and Unpublished'
                                        ? 'Live'
                                        : overviewrow.status
                                }
                            />
                            <p
                                onClick={() => {
                                    navigate(`/organizations/${id}/view-projects/${overviewrow.projectId}`);
                                }}
                                className="cursor-pointer font-medium truncate text-primaryColor"
                            >
                                {overviewrow.projectTitle}
                            </p>
                            <p className=" text-primaryInput  ">
                                {overviewrow.validatedSubmissionCount}/{overviewrow.totalSubmissionCount}
                            </p>
                            <p className=" text-primaryInput  ">{dmyFormat(overviewrow.startTimestamp)}</p>
                            <p className=" text-primaryInput  ">{dmyFormat(overviewrow.expirationTimestamp)}</p>
                        </Table.Row>
                    ))
                ) : (
                    <NoDataAvailable />
                )}

                {/* <Pagination PAGE_SIZE={pageEntries} count={100} /> */}
            </Table>
        </div>
    );
}

export default ValidationOverview;
