import Button from './Button';
import Feedbackclose from '../assets/icons/Feedbackclose';
import Loader from './Loader';

const ModalBox = ({ close, onSubmit, loading, primaryBtnText, secondaryBtnText, children }) => {
    return (
        <div className="p-6 w-[518px]">
            <div>
                <Button
                    radius="rounded-full"
                    onClick={close}
                    className=" border-0 absolute top-3  right-4 "
                    variant="tertiary"
                    size="circle"
                >
                    <Feedbackclose />
                </Button>
            </div>
            <p className="text-xl w-[90%] text-primaryInput">{children}</p>
            <div className="flex items-center mt-8 gap-3">
                <Button
                    className="text-base font-semibold"
                    radius="rounded-full"
                    onClick={onSubmit}
                    size="round"
                    variant="primary"
                >
                    {loading ? <Loader /> : primaryBtnText}
                </Button>
                <Button
                    className="text-base font-medium text-lightBlack"
                    radius="rounded-full"
                    onClick={close}
                    size="round"
                    variant="tertiary"
                >
                    {secondaryBtnText}
                </Button>
            </div>
        </div>
    );
};

export default ModalBox;
