import { createPortal } from 'react-dom';

function Overlay({ children }) {
    return (
        <div className="absolute w-[100%] min-h-screen bg-[rgb(0,0,0,0.3)] top-0 left-0 overflow-x-hidden backdrop-blur-sm transition-all duration-500 z-[1000] ">
            {children}
        </div>
    );
}

export default Overlay;
