function findEqualArrays(a, b) {
    if (a.length !== b.length) {
        return false;
    }

    // Compare each element
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false;
        }
    }

    // If all elements are equal, arrays are equal
    return true;
}

export const findUsersWithSameRoles = (array1, array2) => {
    // roles of array1 should be equal to roles of array2

    const result = array2.filter((item1) => {
        const checkNeedItem = array1.find((item2) => {
            return item2.uid === item1.uid;
        });

        if (item1?.roles?.length > 0 && checkNeedItem?.roles?.length > 0) {
            const isEqual = findEqualArrays(item1?.roles, checkNeedItem?.roles);
            return isEqual;
        }
        return false;
    });

    return result;
};
