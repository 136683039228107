import { useEffect, useRef, useState } from 'react';
import Overlay from '../../ui/Overlay';
import Button from '../../ui/Button';
import Heading from '../../ui/Heading';
import feedback from '../../assets/feedback.png';
import Input from '../../ui/Input';
import Dropdown from '../../ui/Dropdown';
import Feedbackclose from '../../assets/icons/Feedbackclose';

const issuesData = [
    '  General Feedback',
    'Feature Request',
    'Bug Report',
    'User Interface (UI) Suggestions',
    'User Experience (UX) Improvements',
    'Performance Issues',
    'Content Feedback',
    'Integration Issues',
    'Security Concerns',
    'Billing and Payments',
    'Accessibility Suggestions',
    'Other',
];

function FeedbackModal({ close }) {
    const myRef = useRef({ close });
    const [selectedIssue, setSelectedIssue] = useState('');

    const handleSelectIssue = (value) => {
        if (value === null) {
            setSelectedIssue('');
            return;
        }

        if (value.toLowerCase() === selectedIssue.toLowerCase()) {
            setSelectedIssue('');
            return;
        }

        setSelectedIssue(value);
    };
    useEffect(
        function () {
            function handleClick(e) {
                if (myRef.current && !myRef.current.contains(e.target)) {
                    close();
                }
            }

            document.addEventListener('click', handleClick, true);

            return () => document.removeEventListener('click', handleClick, true);
        },
        [close]
    );
    return (
        <Overlay>
            <div
                ref={myRef}
                className="absolute rounded-lg left-[50%] w-[588px]     bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
            >
                <div className="relative modal   overflow-hidden p-7  ">
                    <Button
                        radius="rounded-full"
                        onClick={close}
                        className=" border-0 absolute top-3  right-4 "
                        variant="tertiary"
                        size="circle"
                    >
                        <Feedbackclose />
                    </Button>
                    <div className="rounded-md  overflow-hidden ">
                        <img className="w-[100%]" src={feedback} alt="feedback-banner" />
                    </div>
                    <div className="flex flex-col gap-4 mt-6 ">
                        <div>
                            <Heading className={'font-bold text-modalHeading'} type={'small'}>
                                We'd Love to Hear from you
                            </Heading>
                        </div>
                        <div className="relative">
                            <Dropdown
                                w="w-full"
                                data={issuesData}
                                selected={selectedIssue}
                                onSelected={handleSelectIssue}
                                noSelectedText={
                                    <span className="flex items-center gap-[7px]">
                                        <span>Select issue</span>
                                    </span>
                                }
                            />
                        </div>
                        <div>
                            <Input type="textArea" placeholder="Tell us more about it" className="w-[100%] h-44" />
                        </div>
                        <div className="flex justify-end">
                            <Button radius="rounded-full" size="round">
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Overlay>
    );
}

export default FeedbackModal;
