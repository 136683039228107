import { useContext } from 'react';
import { BountiesContext } from '../../../contexts/BountiesContext';
import { useBountyFields } from '../../../hooks/useBountyFields';
import mobileFrame from '../../../assets/mobileFrame.png';

import { CalendarIcon } from '../../../assets/icons/CalendarIcon';
import { ClockIcon } from '../../../assets/icons/ClockIcon';
import Button from '../../../ui/Button';
import { extractVideoId } from '../../../helpers/extractVideoId';
import Heading from '../../../ui/Heading';
import SubHeading from '../../../ui/SubHeading';
import LeftPreviewArrow from '../../../assets/icons/LeftPreviewArrow';
function PreviewWatchYoutubeVideo({ data }) {
    const { state } = useContext(BountiesContext);
    const { titleField } = useBountyFields();

    const videoIds =
        data?.youtubeVideos_Link?.filter((obj) => obj.link).length > 0
            ? data?.youtubeVideos_Link?.map((obj) => {
                  const videoId = extractVideoId(obj?.link);
                  return videoId;
              })
            : [];

    return (
        <div
            className="relative w-[300px] h-[610px]     "
            style={{
                backgroundImage: `url(${mobileFrame})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <main className="absolute  top-28 left-6 right-6 h-[65%] overflow-auto main ">
                <div className="my-4">
                    <Heading className={'text-minerTitle'} type={'xsmall'}>
                        {data.youtubeVideos_Title || 'Title'}
                    </Heading>
                    <SubHeading className={'!text-xs text-minerDescription font-semibold mt-2'} type="xsmall">
                        {data.youtubeVideos_Description || 'Task Description'}
                    </SubHeading>
                </div>

                {videoIds &&
                    videoIds?.length > 0 &&
                    videoIds?.map((videoId, index) => (
                        <div className=" mb-2" key={videoId}>
                            <div className="w-full rounded-xl overflow-hidden">
                                <iframe
                                    className="w-full h-52"
                                    width="560"
                                    height="315"
                                    src={`https://www.youtube.com/embed/${videoId}`}
                                    title="YouTube Video"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <p className="text-minerDescription mt-1 font-semibold text-xs">
                                {data?.youtubeVideos_Link[index]?.description || ''}
                            </p>
                        </div>
                    ))}
            </main>

            <footer className="absolute bottom-6 left-6 right-6">
                <div className="flex mb-2 items-center justify-between">
                    <Button
                        className=" flex !text-[#1A56DB] text-xs items-center justify-center gap-1 text-end"
                        size="none"
                        variant="transparent"
                    >
                        <span>{'<-'}</span> <span> Back</span>
                    </Button>
                    {data.skippable && (
                        <Button className=" !text-[#1A56DB] text-xs  text-end" size="none" variant="transparent">
                            Skip {'->'}
                        </Button>
                    )}
                </div>
                <Button variant="miner" size="largeMiner" className="py-2 text-xs " radius="rounded-full">
                    Next
                </Button>
            </footer>
        </div>
    );
}

export default PreviewWatchYoutubeVideo;
