import { createContext, useReducer } from 'react';

export const AuthContext = createContext();

const initialState = {
    user: [],
    isLoading: false,
    isAuthenticated: false,
    error: null,
    isAdmin: false,
};

function reducer(state, action) {
    switch (action.type) {
        case 'login':
            localStorage.setItem('myState', JSON.stringify({ ...state, isAuthenticated: true, user: action.payload }));
            return { ...state, isAuthenticated: true, user: action.payload };

        case 'updateAfterProfileCreation':
            const currentState = JSON.parse(localStorage.getItem('myState')) || {};
            const updatedState = { ...currentState, user: [action.payload] };
            localStorage.setItem('myState', JSON.stringify(updatedState));
            return { ...state, user: [action.payload] };
        case 'updateThroughMiner':
            const stateBeforeMiner = JSON.parse(localStorage.getItem('myState')) || {};
            const stateAfterMiner = {
                ...stateBeforeMiner,
                user: [{ ...state.user[0], walletAddress: action.payload }],
            };
            localStorage.setItem('myState', JSON.stringify(stateAfterMiner));
            return { ...stateAfterMiner };
        case 'logout':
            localStorage.clear();
            return { ...state, isAuthenticated: false, user: [] };
        case 'editProfile':
            const beforeEdit = JSON.parse(localStorage.getItem('myState')) || {};
            const afterEdit = { ...beforeEdit, user: [action.payload] };
            console.log(action.payload);
            localStorage.setItem('myState', JSON.stringify(afterEdit));
            return { ...state, user: [action.payload] };

        default:
            return state;
    }
}
const getInitialStateFromLocalStorage = () => {
    const storedState = localStorage.getItem('myState');
    return storedState ? JSON.parse(storedState) : initialState;
};
export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, [], getInitialStateFromLocalStorage);

    return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};
