import { Outlet, useNavigate } from 'react-router-dom';
import BackButton from '../assets/icons/BackButton';
import Heading from '../ui/Heading';
import { useState } from 'react';

function Attestors() {
    const navigate = useNavigate();
    const [attester, setAttester] = useState({});
    return (
        <div className="p-14">
            <div>
                <div className="w-[65%] mb-9">
                    <div className="flex items-center">
                        <BackButton onClick={() => navigate(-1)} />
                        <Heading type={'pageHeading'} className={'text-lightBlack ml-3'}>
                            Attest Impact Claims
                        </Heading>
                    </div>
                    <p className="mt-2">
                        In the table below you will find Impact Claims waiting to be evaluated for their authenticity,
                        if found to be a credible impact claim in your impartial evaluation, then attest the impact.
                    </p>
                </div>
            </div>
            <Outlet context={[attester, setAttester]} />
        </div>
    );
}

export default Attestors;
