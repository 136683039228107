import { useContext } from 'react';
import { BountiesContext } from '../../../contexts/BountiesContext';
import { useBountyFields } from '../../../hooks/useBountyFields';
import mobileFrame from '../../../assets/mobileFrame.png';
import Heading from '../../../ui/Heading';
import SubHeading from '../../../ui/SubHeading';
import { CalendarIcon } from '../../../assets/icons/CalendarIcon';
import { ClockIcon } from '../../../assets/icons/ClockIcon';
import Button from '../../../ui/Button';
function PreviewMicrotask({ step }) {
    const { state } = useContext(BountiesContext);
    const { titleField } = useBountyFields();

    return (
        <div
            className="relative w-[300px] h-[610px]     "
            style={{
                backgroundImage: `url(${mobileFrame})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            {/* <header className="bg-previewDark absolute text-white  top-[3px] left-[8px] right-[8px] rounded-t-[44px] p-3   h-24  ">
                <div className="flex items-center justify-between px-3 ">
                    <p className="text-sm">9:41</p>
                    <div className="flex gap-1 ">
                        <Networks />
                        <Wifi />
                        <Battery />
                    </div>
                </div>
                <div className="flex gap-2 m-3  items-center justify-between ">
                    <PreviewBack />
                    <p className="flex-1">Bounty</p>
                    <ThreeVerticalDots />
                </div>
            </header> */}
            <main className="absolute  top-28 left-6 right-6 ">
                <div>
                    <Heading type={'xxsmall'} className={'text-lightBlack'}>
                        {titleField.value || '[Bounty Title] appears here '}
                    </Heading>
                    <p className="text-xs italic mt-2  "> Your bounty description appears here</p>
                </div>
                <div className="mt-5 grid grid-cols-2 items-end gap-5  ">
                    <div className="">
                        <SubHeading type={'xxsmall'}>Bounty Type</SubHeading>
                        <div className="flex flex-col gap-1 mt-1">
                            <p className="bg-previewLight border text-center rounded-3xl border-previewMedium text-previewDark ">
                                -
                            </p>
                            <p className="bg-previewLight border text-center rounded-3xl border-previewMedium text-previewDark ">
                                -
                            </p>
                        </div>
                    </div>
                    <div className="">
                        <SubHeading className={'text-end'} type={'xxsmall'}>
                            Sponsored By
                        </SubHeading>
                        <div className="mt-1">
                            <p className="bg-previewLight border text-center rounded-3xl border-previewMedium text-previewDark ">
                                -
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex gap-2 my-2 ">
                    <div className="border rounded-md border-borderColor py-2 px-3">
                        <p>Optimism</p>
                        <div>
                            {/* <img src="" alt="" /> */}
                            <p>-</p>
                        </div>
                    </div>
                    <div className="border rounded-md border-borderColor py-2 px-3 ">
                        <p>Impact Points</p>
                        <div>
                            {/* <img src="" alt="" /> */}
                            <p>-</p>
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-2 my-2 ">
                    <span>
                        <CalendarIcon />
                    </span>
                    <SubHeading type={'xxsmall'}> Bounty available till _ _ _ _</SubHeading>
                </div>
                <div className="flex items-center gap-2 my-2  ">
                    <span>
                        <ClockIcon />
                    </span>
                    <SubHeading type={'xxsmall'}>Typically this bounty takes _ _ _ _ to complete</SubHeading>
                </div>
                <div>
                    <Heading type={'xxsmall'} className={'text-lightBlack my-1'}>
                        Criteria for completion
                    </Heading>
                    <ul className="flex flex-col gap-1">
                        {Array.from({ length: 5 }, (_, i) => {
                            return (
                                <li className="text-xs flex items-center gap-2 ">
                                    <span className="bg-previewLight font-medium rounded-full py-1 px-2  border border-previewMedium text-previewDark">
                                        {i + 1}
                                    </span>{' '}
                                    <span>Criteria{i + 1}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </main>

            <footer className="absolute bottom-6 left-6 right-6">
                <Button variant="preview" size="roundPreview">
                    Add Deliverables & Tips
                </Button>
            </footer>
        </div>
    );
}

export default PreviewMicrotask;
