import React from 'react';

const NewDropIcon = () => {
    return (
        <svg width="29" height="36" viewBox="0 0 29 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.5305 20.0818C21.979 18.7996 22.0368 17.0637 21.6514 15.5555C20.709 15.8288 20.0101 15.8463 19.0834 15.4662C18.4581 15.2087 17.7136 14.6254 17.4789 14.2225C17.9291 13.676 18.9643 12.9893 19.8472 12.5864C18.9293 11.679 16.8465 10.1253 15.6677 9.19515L14.2908 8.18619C12.9893 9.23194 11.7894 10.332 10.537 11.4303C10.4424 11.5932 13.2994 12.0451 14.1735 12.4235C15.9339 13.1872 16.2501 13.7161 17.1864 14.2083C16.5925 14.6024 15.0887 15.7286 14.7121 16.1542C14.4065 16.3831 14.1155 16.6308 13.8407 16.8959C16.9963 17.8803 17.8538 21.0768 21.5305 20.0818Z"
                fill="#D64242"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.2012 14.2064C16.7562 13.8683 16.4497 13.5057 14.3442 12.4477C13.3072 11.9432 11.4679 11.4282 10.5045 11.4282C8.82464 12.7244 7.33921 14.8107 7.05894 16.918C7.59598 16.5983 8.18948 16.3848 8.80712 16.2891C10.1945 16.0421 12.6941 16.3417 13.8344 16.9005C14.9152 15.9493 16.0924 15.0577 17.2012 14.2064Z"
                fill="#A82727"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.6514 15.5561C21.5031 14.9604 21.2479 14.3965 20.8981 13.892C20.6056 13.4803 20.1151 12.8042 19.8471 12.587C18.9238 13.0386 18.0482 13.5821 17.2336 14.209C17.6137 14.5629 18.4756 15.3371 19.0834 15.5491C20.01 15.9292 20.709 15.8294 21.6514 15.5561Z"
                fill="#E39B9B"
            />
            <g filter="url(#filter0_d_5118_21915)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.3205 5.66211C14.1353 5.66228 13.9542 5.60754 13.8001 5.50481C13.6461 5.40207 13.5259 5.25596 13.4549 5.08494C13.3839 4.91393 13.3652 4.72569 13.4011 4.54404C13.4371 4.36239 13.5261 4.19547 13.6569 4.06441C13.7877 3.93335 13.9545 3.84402 14.1361 3.80773C14.3177 3.77143 14.5059 3.7898 14.6771 3.8605C14.8482 3.9312 14.9946 4.05107 15.0976 4.20495C15.2006 4.35882 15.2557 4.53978 15.2559 4.72496C15.2556 4.97312 15.157 5.21106 14.9817 5.3867C14.8064 5.56234 14.5686 5.66137 14.3205 5.66211Z"
                    fill="#850909"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.8408 16.8942C12.8091 16.395 10.2639 15.9728 8.94662 16.2128C8.22143 16.3459 7.50323 16.5684 7.05831 16.9187C6.9975 17.5586 7.00984 18.2032 7.0951 18.8403C9.56672 19.8458 11.9262 18.7019 13.8408 16.8942Z"
                fill="#850909"
            />
            <g filter="url(#filter1_d_5118_21915)">
                <path
                    d="M14.3225 25.0615H14.319L14.2155 25.0597C13.8854 25.0484 13.5561 25.0192 13.2291 24.9722C11.1826 24.6759 9.62768 23.7209 8.6047 22.3893C7.57628 21.0522 7.076 19.3281 7.14099 17.5074C7.15329 17.1919 7.18141 16.8773 7.22525 16.5646C7.62533 13.7381 9.84033 11.9419 12.8627 9.49395C13.3418 9.10665 13.8385 8.70359 14.3211 8.3042C14.802 8.70376 15.2987 9.10682 15.7778 9.49395C18.8002 11.9419 21.0169 13.7381 21.4153 16.5646C21.46 16.8772 21.4881 17.1918 21.4995 17.5074C21.5663 19.3281 21.0642 21.0522 20.0358 22.3893C19.0127 23.7206 17.4593 24.6761 15.4114 24.9722C15.0844 25.0193 14.7551 25.0485 14.425 25.0597L14.3232 25.0615H14.3225ZM14.3208 6.82509L13.94 7.13181C13.2819 7.68201 12.7026 8.15163 12.1515 8.59849C8.9256 11.2111 6.56154 13.1282 6.0998 16.4033C6.05007 16.7559 6.01902 17.111 6.00678 17.4669C5.93128 19.5574 6.51232 21.5445 7.70591 23.0954C8.90284 24.6532 10.7071 25.7695 13.0696 26.1093C13.443 26.1634 13.8192 26.1968 14.1964 26.2092L14.2157 26.2109H14.4245L14.4437 26.2092C14.8215 26.1968 15.1983 26.1634 15.5724 26.1093C17.9347 25.7693 19.7371 24.6532 20.936 23.0954C22.1271 21.5446 22.7105 19.557 22.6351 17.4669C22.6214 17.111 22.5898 16.756 22.5404 16.4033C22.0788 13.1276 19.7146 11.2111 16.4887 8.59849C15.9376 8.15163 15.3583 7.68201 14.7019 7.13181L14.3208 6.82509Z"
                    fill="#850909"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_5118_21915"
                    x="7.38379"
                    y="0.789062"
                    width="13.8721"
                    height="13.873"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5118_21915" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5118_21915" result="shape" />
                </filter>
                <filter
                    id="filter1_d_5118_21915"
                    x="0"
                    y="3.82422"
                    width="28.6416"
                    height="31.3867"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5118_21915" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5118_21915" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};

export default NewDropIcon;
