import Table from '../../ui/Table';
import { microtaskTypeData } from '../bounties/microtasktypes/microtaskTypeData';

const UploadFileSD = ({ dataTask }) => {
    const titleObject = dataTask?.task.find((obj) => Object.keys(obj).includes('Title'));
    const descriptionObject = dataTask?.task.find((obj) => Object.keys(obj).includes('Description'));

    return (
        <Table.Row>
            <p className=" text-lightBlack flex flex-col   ">
                <span className="font-medium mb-1  "> {titleObject?.Title || '-'} </span>
                <span className="text-xs font-medium">{descriptionObject?.Description || '-'}</span>
            </p>
            <p className="text-sm justify-self-center">
                {microtaskTypeData.find((ele) => ele.type === dataTask.actionType).label}
            </p>

            <div>{dataTask?.files?.join('') && <a href={dataTask.files.join('')}>Download File</a>}</div>
        </Table.Row>
    );
};

export default UploadFileSD;
