import { useEffect, useState } from 'react';
import RankOneIcon from '../../../assets/icons/RankOneIcon';
import RankThreeIcon from '../../../assets/icons/RankThreeIcon';
import RankTwoIcon from '../../../assets/icons/RankTwoIcon';
import { useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { getBounty, getValidationPreview } from '../../../apis/bounties/bounties';
import Loader from '../../../ui/Loader';
import Table from '../../../ui/Table';
import IssuedIcon from '../../../assets/icons/IssuedIcon';
import NotIssuedIcon from '../../../assets/icons/NotIssuedIcon';
import Legend from '../../../ui/Legend';
import Modal from '../../../ui/Modal';
import AcceptRequestModal from './AcceptRequestModal';
import Button from '../../../ui/Button';
import { useLoadingState } from '../../../hooks/useLoader';
import { FirstRank, SecondRank, ThirdRank } from '../../../assets/icons/RankIcons';
import WalletConnectCustomButton from '../../walletconnectUI/WalletCustomText';
import { useWallet } from '@solana/wallet-adapter-react';

const rank = {
    1: <FirstRank />,
    2: <SecondRank />,
    3: <ThirdRank />,
};
function ValidationRequestConfirmationScreen() {
    const [preview, setPreview] = useState({});
    const { id } = useParams();
    const [particularOrg] = useOutletContext();
    const [bounty, setBounty] = useState({});
    const [searchParams] = useSearchParams();
    const { loading, startLoading, stopLoading } = useLoadingState();
    const { publicKey } = useWallet();
    const address = publicKey?.toBase58();
    const bountyId = searchParams.get('bountyId');
    const reqId = searchParams.get('reqId');
    const navigate = useNavigate();
    const multiSig = particularOrg?.treasurerMultisig;
    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    startLoading();
                    const [response1, response2] = await Promise.all([
                        getValidationPreview(bountyId),
                        getBounty(bountyId),
                    ]);

                    setPreview(response1);
                    setBounty(response2);
                    stopLoading();
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [bountyId]
    );

    return (
        <div>
            <Table className=" items-center gap-4 " cols="grid-cols-12">
                <Table.Header>
                    <p className="text-sm col-span-2  font-semibold text-secondaryText "> Submission ID </p>
                    <p className="text-sm   font-semibold text-secondaryText ">Score</p>
                    <p className="text-sm  col-span-2 font-semibold text-secondaryText ">Reward Token</p>
                    <p className="text-sm  col-span-2  font-semibold text-secondaryText ">Impact Points</p>
                    <p className="text-sm col-span-3  font-semibold text-secondaryText ">Impact Certificate</p>
                </Table.Header>

                {loading ? (
                    <div className="min-h-[20vh] flex justify-center items-center">
                        <Loader />
                    </div>
                ) : (
                    preview?.rewardDistribution?.map((submission, index) => (
                        <Table.Row>
                            <p
                                onClick={() =>
                                    navigate(
                                        `/organizations/${id}/validation/bounty-submissions/${bountyId}/${submission.submissionId}?bountyTitle=${bounty.title}&projectTitle=${bounty.projectTitle}`
                                    )
                                }
                                className=" col-span-2 text-primaryColor flex items-center cursor-pointer  uppercase justify-self-start font-medium  "
                            >
                                <span className="truncate w-32">{submission.submissionId} </span>{' '}
                                {submission?.accuracy ? <span>{index + 1 <= 3 && rank[index + 1]}</span> : null}
                            </p>
                            <p className=" text-primaryInput  ">
                                {submission.accuracy ? Number(submission.accuracy) * 100 : '-'}
                            </p>
                            <p className=" col-span-2 text-primaryInput   ">{submission?.rewardAmount}</p>
                            <p className=" col-span-2 text-primaryInput ">
                                {submission.impactPoints ? Number(submission.impactPoints).toFixed(2) : '-'}
                            </p>
                            <p className="col-span-3 ">
                                {submission?.issueImpactCertificate ? <IssuedIcon /> : <NotIssuedIcon />}
                            </p>
                        </Table.Row>
                    ))
                )}
                <div className="flex items-center justify-end mt-6">
                    <div className="flex items-center gap-3 ">
                        <p className="text-sm font-bold">Impact Certificate</p>
                        <Legend shield={<NotIssuedIcon />} text={'Not Issued'} />
                        <Legend shield={<IssuedIcon />} text={'Issued'} />
                    </div>
                </div>
            </Table>
            {/* <div className="flex justify-end items-center">
                <Modal>
                    <Modal.Button
                        className="flex gap-2 cursor-pointer items-center"
                        opens={'acceptingvalidationrequest'}
                    >
                        <Button variant="primary" size="xsmall">
                            Accept Request
                        </Button>
                    </Modal.Button>
                    <Modal.Window name={'acceptingvalidationrequest'}>
                        <AcceptRequestModal reqId={reqId} />
                    </Modal.Window>
                </Modal>
            </div> */}

            <div className="flex justify-end items-center">
                <div>
                    {address ? (
                        <Modal>
                            <Modal.Button
                                className="flex gap-2 cursor-pointer items-center"
                                opens={'acceptingvalidationrequest'}
                            >
                                <Button className="!w-[177px]" variant="primary" size="adjustLarge">
                                    Accept Request
                                </Button>
                            </Modal.Button>
                            <Modal.Window name={'acceptingvalidationrequest'}>
                                <AcceptRequestModal
                                    reqFor="validation"
                                    bountyId={bountyId}
                                    multiSig={multiSig}
                                    afterRequestOperation={(id) => {
                                        navigate(-1);
                                    }}
                                    reqId={reqId.trim()}
                                />
                            </Modal.Window>
                        </Modal>
                    ) : (
                        <div className="w-full flex items-center">
                            <WalletConnectCustomButton
                                customText={'Accept Request'}
                                width="177px"
                                customStyles={{
                                    backgroundColor: 'var(--primary-color)',
                                    color: '#fff',
                                    border: '1px solid #dcdcdc',
                                    lineHeight: 'normal',
                                    fontWeight: '500',
                                    paddingLeft: '12px',
                                    paddingRight: '12px',
                                    height: '41.6px',
                                    borderRadius: '8px',
                                    flexGrow: 1,
                                }}
                                connectedText={'Accept Request'}
                                disconnectedText="Accept Request"
                                removeIconOnDisconnect={true}
                                removeIconOnConnect={true}
                                removeIconOnWalletSelect={true}
                                removeIconOnConnecting={true}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ValidationRequestConfirmationScreen;
