import Button from '../../ui/Button';
import Heading from '../../ui/Heading';
import ImpactFields from '../../ui/ImpactFields';
import SummaryCard from '../../ui/SummaryCard';

import WhiteCard from '../../ui/WhiteCard';
import trophy from '../../assets/trophy.png';
import higherTrophy from '../../assets/higherTrophy.png';
import impactDrop from '../../assets/impactDrop.png';
import ImpactCard from './ImpactCard';
import ProfileBio from './ProfileBio';
import TextWithTooltip from '../../ui/TextWithTooltip';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import PointsWithGrayText from '../../ui/PointsWithGrayText';
import { SDG_IMAGES } from '../../constants/datas';
import TrophyIcon from '../../assets/icons/TrophyIcon';
import OrgProfileCard from './OrgProfileCard';
import NoDataAvailable from '../../ui/NoDataAvailable';
import { getCoreWiseDistribution, getYourImpacts } from '../../apis/user/user';
import { useLoadingState } from '../../hooks/useLoader';
import { getOrganisations } from '../../apis/oragnisations/organisation';
function ProfileDetails() {
    const navigate = useNavigate();
    const { loading, yourImpacts, myOrgs, coreWiseDistribution, userProfile, impactCertificatesIssued, impactPoints } =
        useOutletContext();

    return (
        <div className="grid grid-cols-2 pb-9 gap-6">
            <div className="pt-6">
                <ProfileBio />
                <ImpactCard
                    type="POINTS"
                    typeColor="text-impactGreen"
                    heading="Impact Points"
                    img={higherTrophy}
                    stats={impactPoints?.toFixed(2)}
                    rank={yourImpacts[0]?.impactRank}
                    brief={`Gained from network activities,\nmore IP more benefits`}
                    btnText="View Leaderboard"
                    mT="mt-6"
                    mR="mr-3"
                    tooltipText="The Impact Points (IP) are XP earned based on their applied skills and effort.
                    The Impact Points are used to rank citizens on the Global Impact Index, the higher you finish the more rewards you stand to unlock."
                    navigateTo="leaderboard"
                />
                <ImpactCard
                    type="CERTIFICATES"
                    typeColor="text-impactRed"
                    heading="Impact Certificates"
                    img={impactDrop}
                    stats={impactCertificatesIssued}
                    mT="mt-6"
                    mR="mr-3"
                    brief={`${userProfile?.userName}’s\nimmutable proof of impact`}
                    btnText="View Gallery"
                    tooltipText="Impact Certificates are digital proof of impact issued to the network member for their contribution. 
                    Impact certificates are backed by tangible real economic value and can be traded in the form of NFTs "
                    navigateTo="gallery"
                />
            </div>
            <div className="pt-6">
                <div className="mb-6">
                    <TextWithTooltip
                        className="leading-10"
                        hoverOverText=" Your impact on network"
                        expandedTextWidth="w-[332px]"
                    >
                        <p className="text-[#404040]">
                            Visualise your impact through the lens of the 4 key impact cores at Atlantis. The breakup
                            shows the impact you had in each category in dollars.
                        </p>
                    </TextWithTooltip>
                    <WhiteCard verticalMargin="" className="py-3 px-6 ">
                        <div className="flex items-center border-b  border-dashed border-borderColor pb-2 justify-between">
                            <Heading className={'text-lightBlack leading-[58px]  '} type="pageHeading">
                                Your Impact
                            </Heading>
                        </div>
                        <ImpactFields className="pt-6" coreWiseDistribution={coreWiseDistribution} />
                    </WhiteCard>
                </div>
                <div className="mb-6">
                    <div className="flex justify-between items-center">
                        <TextWithTooltip
                            expandedTextWidth="w-[332px]"
                            className="leading-10"
                            hoverOverText=" Organization and Roles"
                        >
                            <p className="text-[#404040]">
                                Highlighting the different organizations and the roles the user plays in those
                                organizations.
                            </p>
                        </TextWithTooltip>
                    </div>
                    <div>
                        <WhiteCard verticalMargin="" className="p-6">
                            <>
                                {myOrgs.length > 0 ? (
                                    myOrgs
                                        .slice(0, 2)
                                        .map((organization, index) => (
                                            <OrgProfileCard
                                                redirectTo={() => navigate(`/organizations/${organization._id}`)}
                                                className=""
                                                key={organization._id + index + Math.random()}
                                                organization={organization}
                                            />
                                        ))
                                ) : (
                                    <div className="h-30 flex items-center justify-center">
                                        <NoDataAvailable marginTop="mt-8" imgClassName="w-36" className="mb-8" />
                                    </div>
                                )}
                            </>
                            {myOrgs.length > 0 && (
                                <div className="px-4">
                                    <Button
                                        onClick={() => navigate('/myorganizations')}
                                        className="flex-1 text-end pb-0 "
                                        variant="transparent"
                                    >
                                        Explore All &gt;{' '}
                                    </Button>
                                </div>
                            )}
                        </WhiteCard>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileDetails;
