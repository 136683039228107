import { useState } from 'react';
import { Outlet } from 'react-router-dom';

function Validation() {
    const [bountyDetails, setBountyDetails] = useState({});

    const handleBountyDetails = (detail) => {
        setBountyDetails(detail);
    };

    return (
        <div className="">
            <Outlet context={[bountyDetails, handleBountyDetails]} />
        </div>
    );
}

export default Validation;
