import CloseCircle from '../../../assets/icons/CloseCircle';
import ShareIcon from '../../../assets/icons/ShareIcon';
import Button from '../../../ui/Button';

function MintedBack({ image }) {
    return (
        <div className="bg-[#F5FAFF] border flex flex-col items-center justify-center relative border-[#EAEAEA] rounded-md  px-[100.7px] h-full w-full  ">
            <Button className="bg-none border-0 absolute top-3  right-3 " variant="tertiary" size="circle">
                <CloseCircle />
            </Button>

            <div>
                <Button
                    variant="approve"
                    className="text-xs flex items-center justify-center gap-2 font-semibold"
                    size="round"
                >
                    <span>
                        {' '}
                        <a href={image}>Share</a>{' '}
                    </span>{' '}
                    <ShareIcon />
                </Button>
            </div>
        </div>
    );
}

export default MintedBack;
