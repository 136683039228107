const RadioButtonComponent = ({ selectedOption, onSelectOption, options, className, disabled, inputClassName }) => {
    // const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
        // setSelectedOption(event.target.value);
        onSelectOption(event.target.value);
    };

    return (
        <div className={`flex gap-3 ${className} `}>
            {options.map((option, i) => (
                <label className="cursor-pointer" key={option + i}>
                    <input
                        type="radio"
                        disabled={disabled}
                        value={option}
                        checked={selectedOption === option}
                        onChange={handleOptionChange}
                        className={`${inputClassName}`}
                    />
                    <span className="capitalize ml-2 ">{option}</span>
                </label>
            ))}
        </div>
    );
};

export default RadioButtonComponent;
