import { useContext, useState } from 'react';
import { BountiesContext } from '../../../contexts/BountiesContext';
import { useBountyFields } from '../../../hooks/useBountyFields';
import mobileFrame from '../../../assets/mobileFrame.png';
import Heading from '../../../ui/Heading';
import SubHeading from '../../../ui/SubHeading';
import Button from '../../../ui/Button';
import LeftPreviewArrow from '../../../assets/icons/LeftPreviewArrow';

function PreviewTestMCQ({ data }) {
    const { state } = useContext(BountiesContext);
    const { titleField } = useBountyFields();
    const [selectedAnswer, setSelectedAnswer] = useState(null); // Track selected radio option

    return (
        <div
            className="relative w-[300px] h-[610px]"
            style={{
                backgroundImage: `url(${mobileFrame})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <main className="absolute top-28 left-6 right-6">
                <div className="mt-4">
                    <Heading type={'xsmall'}>{data.testMCQ_Title || 'Title'}</Heading>
                    <SubHeading className={'!text-xs font-semibold mt-2'} type="xsmall">
                        {data.testMCQ_question || 'Question'}
                    </SubHeading>
                </div>
                <ul>
                    {data?.testMCQ_answers
                        ?.filter((i) => i.answer)
                        .map((answer, i) => (
                            <li
                                key={i}
                                className="flex text-xs text-minerSecondary items-center gap-3 border-b border-borderColor py-3"
                            >
                                <input
                                    className="w-3 h-3 accent-blue-500"
                                    type="radio"
                                    name="mcq_answer"
                                    value={i}
                                    checked={selectedAnswer === i}
                                    onChange={() => setSelectedAnswer(i)}
                                />
                                <span>{answer.answer}</span>
                            </li>
                        ))}
                </ul>
                {/* <div className="mt-8">
                    <SubHeading className={'!text-xs italic mt-2'} type="xsmall">
                        {data.testMCQ_instructionHeading || 'Instruction Heading'}
                    </SubHeading>
                </div> */}

                {/* <ul className="list-disc">
                    {data?.testMCQ_instructions?.map((instruction, i) => {
                        return (
                            <li key={i} className="flex items-center gap-3 border-b border-borderColor py-3">
                                <span>{instruction.instruction}</span>
                            </li>
                        );
                    })}
                </ul> */}
            </main>

            <footer className="absolute bottom-6 left-6 right-6">
                <div className="flex mb-2 items-center justify-between">
                    <Button
                        className=" flex !text-[#1A56DB] text-xs items-center justify-center gap-1 text-end"
                        size="none"
                        variant="transparent"
                    >
                        <span>{'<-'}</span> <span> Back</span>
                    </Button>
                    {data.skippable && (
                        <Button className=" !text-[#1A56DB] text-xs  text-end" size="none" variant="transparent">
                            Skip {'->'}
                        </Button>
                    )}
                </div>
                <Button variant="miner" size="largeMiner" className="py-2 text-xs " radius="rounded-full">
                    Next
                </Button>
            </footer>
        </div>
    );
}

export default PreviewTestMCQ;
