const BackerImageIcon = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="8.30729" cy="8.7041" rx="8.1735" ry="8" fill="#D9D9D9" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8514 5.23874H11.8487V4.25736C11.8487 3.99502 11.6303 3.78125 11.3623 3.78125H11.3474C11.0744 3.78125 10.856 3.99502 10.856 4.25736V5.23874H9.85828C9.59024 5.23874 9.37184 5.4525 9.36688 5.71485V5.72943C9.36688 5.99663 9.58528 6.2104 9.85828 6.2104H10.856V7.18692C10.856 7.44927 11.0744 7.66789 11.3474 7.66303H11.3623C11.6303 7.66303 11.8487 7.44927 11.8487 7.18692V6.2104H12.8514C13.1194 6.2104 13.3378 5.99663 13.3378 5.73429V5.71485C13.3378 5.4525 13.1194 5.23874 12.8514 5.23874ZM10.359 7.18744V6.69675H9.85768C9.59461 6.69675 9.34642 6.59472 9.15781 6.41497C8.97415 6.23035 8.86991 5.98744 8.86991 5.71537C8.86991 5.54047 8.91955 5.38015 9.00393 5.23926H4.89898C4.35298 5.23926 3.90625 5.6765 3.90625 6.21092V12.0409C3.90625 12.5753 4.35298 13.0125 4.89898 13.0125H10.8554C11.4014 13.0125 11.8481 12.5753 11.8481 12.0409V8.01821C11.6992 8.1008 11.5304 8.15424 11.3418 8.15424C10.8008 8.14938 10.359 7.71699 10.359 7.18744ZM10.3383 12.0403H5.3945C5.19099 12.0403 5.07186 11.812 5.19595 11.6517L6.17876 10.3739C6.28299 10.2379 6.4865 10.2476 6.58578 10.3836L7.37996 11.5545L8.67548 9.8638C8.77475 9.73749 8.96834 9.73263 9.06761 9.85894L10.5319 11.6468C10.6609 11.8071 10.5468 12.0403 10.3383 12.0403Z"
                fill="#969696"
            />
        </svg>
    );
};

export default BackerImageIcon;
