export const FirstRank = () => {
    return (
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 19.2618L17.8198 22.7914C18.8856 23.4383 20.1898 22.4821 19.9094 21.2727L18.3667 14.6353L23.5134 10.1635C24.453 9.3479 23.9482 7.80105 22.7141 7.70261L15.9407 7.12606L13.2902 0.854283C12.8134 -0.284761 11.1866 -0.284761 10.7098 0.854283L8.05935 7.112L1.28591 7.68855C0.0518278 7.78699 -0.453025 9.33384 0.486562 10.1494L5.63325 14.6213L4.09065 21.2586C3.81017 22.468 5.11437 23.4242 6.18017 22.7774L12 19.2618Z"
                fill="#F1BF2E"
            />
            <path
                d="M13.3379 8.54545V18H11.0574V10.6783H11.002L8.88765 11.9709V9.99503L11.219 8.54545H13.3379Z"
                fill="white"
            />
        </svg>
    );
};

export const SecondRank = () => {
    return (
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 19.2618L17.8198 22.7914C18.8856 23.4383 20.1898 22.4821 19.9094 21.2727L18.3667 14.6353L23.5134 10.1635C24.453 9.3479 23.9482 7.80105 22.7141 7.70261L15.9407 7.12606L13.2902 0.854283C12.8134 -0.284761 11.1866 -0.284761 10.7098 0.854283L8.05935 7.112L1.28591 7.68855C0.0518278 7.78699 -0.453025 9.33384 0.486562 10.1494L5.63325 14.6213L4.09065 21.2586C3.81017 22.468 5.11437 23.4242 6.18017 22.7774L12 19.2618Z"
                fill="#79747E"
            />
            <path
                d="M8.05207 17V15.3565L11.5006 12.3374C11.7591 12.1035 11.9792 11.8896 12.1607 11.6957C12.3423 11.4987 12.4808 11.3017 12.5762 11.1048C12.6716 10.9047 12.7193 10.6877 12.7193 10.4538C12.7193 10.1922 12.6624 9.9691 12.5485 9.78445C12.4346 9.59671 12.2777 9.45206 12.0776 9.3505C11.8776 9.24893 11.6483 9.19815 11.3898 9.19815C11.1282 9.19815 10.8989 9.25201 10.7019 9.35973C10.505 9.46437 10.3511 9.61671 10.2403 9.81676C10.1326 10.0168 10.0787 10.2599 10.0787 10.5462H7.91357C7.91357 9.90294 8.05822 9.34742 8.34752 8.87962C8.63682 8.41181 9.04307 8.05173 9.56627 7.79936C10.0926 7.54392 10.7035 7.41619 11.399 7.41619C12.1161 7.41619 12.7393 7.53622 13.2687 7.77628C13.798 8.01634 14.2074 8.3518 14.4967 8.78267C14.789 9.21046 14.9352 9.7075 14.9352 10.2738C14.9352 10.6339 14.8629 10.9909 14.7183 11.3448C14.5736 11.6987 14.3136 12.0896 13.9381 12.5174C13.5657 12.9452 13.0363 13.4576 12.35 14.0547L11.219 15.098V15.158H15.0506V17H8.05207Z"
                fill="white"
            />
        </svg>
    );
};

export const ThirdRank = () => {
    return (
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 19.2618L17.8198 22.7914C18.8856 23.4383 20.1898 22.4821 19.9094 21.2727L18.3667 14.6353L23.5134 10.1635C24.453 9.3479 23.9482 7.80105 22.7141 7.70261L15.9407 7.12606L13.2902 0.854283C12.8134 -0.284761 11.1866 -0.284761 10.7098 0.854283L8.05935 7.112L1.28591 7.68855C0.0518278 7.78699 -0.453025 9.33384 0.486562 10.1494L5.63325 14.6213L4.09065 21.2586C3.81017 22.468 5.11437 23.4242 6.18017 22.7774L12 19.2618Z"
                fill="#B04313"
            />
            <path
                d="M11.4579 18.1293C10.7377 18.1293 10.0991 18.0062 9.54204 17.7599C8.98806 17.5107 8.55103 17.1675 8.23096 16.7305C7.91088 16.2934 7.74777 15.7902 7.74161 15.2209H10.0406C10.0498 15.4271 10.116 15.6102 10.2391 15.7702C10.3622 15.9272 10.53 16.0503 10.7423 16.1396C10.9547 16.2288 11.1963 16.2734 11.4671 16.2734C11.7379 16.2734 11.9765 16.2257 12.1827 16.1303C12.3919 16.0318 12.5551 15.898 12.672 15.7287C12.789 15.5563 12.8459 15.3594 12.8428 15.1378C12.8459 14.9162 12.7828 14.7192 12.6535 14.5469C12.5243 14.3745 12.3412 14.2406 12.1042 14.1452C11.8703 14.0498 11.5933 14.0021 11.2732 14.0021H10.3545V12.3771H11.2732C11.5533 12.3771 11.7995 12.331 12.0119 12.2386C12.2273 12.1463 12.395 12.017 12.515 11.8509C12.6351 11.6816 12.6936 11.4877 12.6905 11.2692C12.6936 11.0568 12.6428 10.8706 12.5381 10.7106C12.4366 10.5475 12.2935 10.4213 12.1088 10.332C11.9272 10.2428 11.7164 10.1982 11.4763 10.1982C11.224 10.1982 10.9947 10.2428 10.7885 10.332C10.5854 10.4213 10.4238 10.5475 10.3038 10.7106C10.1837 10.8737 10.1206 11.063 10.1145 11.2784H7.93089C7.93704 10.7152 8.094 10.2197 8.40177 9.7919C8.70953 9.36103 9.12809 9.02403 9.65745 8.78089C10.1899 8.53776 10.7962 8.41619 11.4763 8.41619C12.1534 8.41619 12.7489 8.53468 13.2629 8.77166C13.7769 9.00864 14.177 9.33179 14.4632 9.74112C14.7494 10.1474 14.8925 10.6075 14.8925 11.1214C14.8956 11.6539 14.7217 12.0924 14.3709 12.4371C14.0231 12.7818 13.5753 12.9942 13.0275 13.0742V13.1481C13.76 13.2343 14.3124 13.4712 14.6848 13.859C15.0603 14.2468 15.2465 14.7315 15.2434 15.3132C15.2434 15.861 15.0818 16.3473 14.7587 16.772C14.4386 17.1937 13.9923 17.526 13.4199 17.7692C12.8505 18.0092 12.1965 18.1293 11.4579 18.1293Z"
                fill="white"
            />
        </svg>
    );
};
