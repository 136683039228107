import { useEffect, useRef, useState } from 'react';
import Overlay from '../../ui/Overlay';
import Button from '../../ui/Button';
import { createBounty, getBounty, sendRequestForVoting } from '../../apis/bounties/bounties';
import Feedbackclose from '../../assets/icons/Feedbackclose';
import { useParams } from 'react-router-dom';
import ModalBox from '../../ui/ModalBox';
import { useLoadingState } from '../../hooks/useLoader';

function RequestTreasuryApprovalModal({ close, bountyName, bountyId, updatedTime, handleConfirmAction }) {
    const modalRef = useRef(null); // Reference for the modal content
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [bountyPayload, setBountyPayload] = useState({});
    const { id } = useParams();

    useEffect(() => {
        // Fetch the bounty data asynchronously
        const fetchData = async () => {
            try {
                const data = await getBounty(bountyId);
                setBountyPayload(data);
            } catch (error) {
                console.error('Error fetching bounty:', error);
            }
        };
        fetchData();

        // Handle click outside the modal
        function handleClickOutside(e) {
            // Check if the click was outside the modal content
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                close(); // Close the modal if clicked outside
            }
        }

        // Add event listener in the capture phase to ensure proper handling
        document.addEventListener('mousedown', handleClickOutside, true);

        return () => {
            // Clean up the event listener on unmount
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [close, bountyId]);

    return (
        <Overlay>
            <div
                ref={modalRef} // Attach ref to modal content
                className="absolute rounded-lg left-[50%]     bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
                onClick={(e) => e.stopPropagation()} // Stop propagation to avoid closing the modal when clicked inside
            >
                <ModalBox
                    close={close}
                    onSubmit={async () => {
                        try {
                            if (bountyId) {
                                const creationPayload = {
                                    organisationId: id,
                                    requestType: 'BOUNTY_CREATION',
                                    bountyId: bountyId,
                                    lastUpdateTime: updatedTime,
                                };
                                startLoading();
                                const responseRequest = await sendRequestForVoting(creationPayload);

                                // stopLoading();
                                handleConfirmAction(responseRequest);
                            }
                        } catch (error) {
                            console.log(error);
                        } finally {
                            close(); // Close modal after duplication
                            stopLoading();
                        }
                    }}
                    primaryBtnText={'Submit'}
                    loading={loading}
                    secondaryBtnText={'Cancel'}
                >
                    <>
                        Are you sure you want to{' '}
                        <span className="font-bold">create a request for treasury approval </span>?
                    </>
                </ModalBox>
            </div>
        </Overlay>
    );
}

export default RequestTreasuryApprovalModal;
