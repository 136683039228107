const ToastIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.41699 3.08341H6.58366V4.25008H5.41699V3.08341ZM5.41699 5.41675H6.58366V8.91675H5.41699V5.41675ZM6.00033 0.166748C2.78033 0.166748 0.166992 2.78008 0.166992 6.00008C0.166992 9.22008 2.78033 11.8334 6.00033 11.8334C9.22033 11.8334 11.8337 9.22008 11.8337 6.00008C11.8337 2.78008 9.22033 0.166748 6.00033 0.166748ZM6.00033 10.6667C3.42783 10.6667 1.33366 8.57258 1.33366 6.00008C1.33366 3.42758 3.42783 1.33341 6.00033 1.33341C8.57283 1.33341 10.667 3.42758 10.667 6.00008C10.667 8.57258 8.57283 10.6667 6.00033 10.6667Z"
                fill="#171717"
            />
        </svg>
    );
};

export default ToastIcon;
