import { Outlet } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useLoadingState } from '../hooks/useLoader';
import { getCoreWiseDistribution, getYourImpacts } from '../apis/user/user';
import { getOrganisations } from '../apis/oragnisations/organisation';
function User() {
    const { state } = useContext(AuthContext);
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [yourImpacts, setYourImpacts] = useState([]);
    const [myOrgs, setMyOrgs] = useState([]);
    const [coreWiseDistribution, setCoreWiseDistribution] = useState([]);
    const [selectedPowers, setSelectedPowers] = useState([]);
    const { user } = state;
    const userProfile = user[0];
    // const { impactPoints, impactCertificatesIssued } = userProfile;
    const impactPoints = yourImpacts[0]?.impactPoints;

    const impactCertificatesIssued = yourImpacts[0]?.impactCertificatesIssued;

    useEffect(function () {
        const fetchData = async () => {
            try {
                startLoading();
                const [response1, response2, response3] = await Promise.all([
                    getYourImpacts(userProfile?.uid),
                    getCoreWiseDistribution(),
                    getOrganisations(),
                ]);
                setYourImpacts(response1);
                setCoreWiseDistribution(response2);
                setMyOrgs(response3);

                setSelectedPowers(response1[0]?.primarySkills);
            } catch (error) {
                console.error(error, 'erroe');
            } finally {
                stopLoading();
            }
        };
        fetchData();
    }, []);
    return (
        <div className="pt-9 px-9">
            <Outlet
                context={{
                    loading,
                    yourImpacts,
                    myOrgs,
                    coreWiseDistribution,
                    userProfile,
                    impactCertificatesIssued,
                    impactPoints,
                    selectedPowers,
                    setSelectedPowers,
                }}
            />
        </div>
    );
}

export default User;
