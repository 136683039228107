import { useContext } from 'react';
import CloseCircle from '../../../assets/icons/CloseCircle';
import ShareIcon from '../../../assets/icons/ShareIcon';
import Button from '../../../ui/Button';
import Modal from '../../../ui/Modal';
import MintModal from '../unclaimed/MintModal';
import { ProfileContext } from '../../../contexts/ProfileContext';
import { AuthContext } from '../../../contexts/AuthContext';
import Loader from '../../../ui/Loader';

function MintRequestedBack({ certificateId, image }) {
    const { mintYourIc, claimYourIc, minting, claiming } = useContext(ProfileContext);
    const { state } = useContext(AuthContext);
    const { user } = state;
    const userProfile = user[0];
    return (
        <div className="bg-[#F5FAFF] border flex flex-col items-center justify-center relative border-[#EAEAEA] rounded-md  px-[100.7px] h-full w-full ">
            <Button className="bg-none border-0 absolute top-3  right-3 " variant="tertiary" size="circle">
                <CloseCircle />
            </Button>

            <div>
                {/* <Button
                    variant="approve"
                    className="text-xs flex items-center justify-center gap-2 font-semibold"
                    size="round"
                >
                    <span>
                        {' '}
                        <a href={image} target="_blank" rel="noopener noreferrer"></a> Share
                    </span>{' '}
                    <ShareIcon />
                </Button> */}
                {/* <p className="text-[10px]  text-[#2F2F2F] font-semibold  text-center my-3 ">OR</p> */}

                {/* <Button
                    onClick={() => {
                        mintYourIc(certificateId, userProfile?.walletAddress);
                    }}
                    className="text-xs font-medium"
                    size="round"
                >
                    {minting ? <Loader w="w-4" h="h-4" /> : ' Mint as NFT'}
                </Button> */}
            </div>
            <div>
                <Modal>
                    <Modal.Button
                        className="text-xs cursor-pointer text-center my-3 underline font-medium "
                        opens="whymint"
                    >
                        Why Mint ?
                    </Modal.Button>
                    <Modal.Window name="whymint">
                        <MintModal />
                    </Modal.Window>
                </Modal>
            </div>
        </div>
    );
}

export default MintRequestedBack;
