function ValidationsIcon({ fillColor }) {
    return (
        <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.6545 2.6736H10.0872C9.7288 1.68366 8.79005 0.966797 7.68063 0.966797C6.5712 0.966797 5.63246 1.68366 5.27403 2.6736H1.70681C0.768063 2.6736 0 3.44167 0 4.38041V16.3281C0 17.2668 0.768063 18.0349 1.70681 18.0349H13.6545C14.5932 18.0349 15.3613 17.2668 15.3613 16.3281V4.38041C15.3613 3.44167 14.5932 2.6736 13.6545 2.6736ZM7.67957 2.67383C8.14895 2.67383 8.53298 3.05786 8.53298 3.52723C8.53298 3.9966 8.14895 4.38063 7.67957 4.38063C7.2102 4.38063 6.82617 3.9966 6.82617 3.52723C6.82617 3.05786 7.2102 2.67383 7.67957 2.67383ZM5.36814 14.0143L3.15782 11.804C2.825 11.4712 2.825 10.9336 3.15782 10.6007C3.49065 10.2679 4.02829 10.2679 4.36112 10.6007L5.97405 12.2051L10.9921 7.18712C11.3249 6.85429 11.8625 6.85429 12.1954 7.18712C12.5282 7.51995 12.5282 8.05759 12.1954 8.39042L6.57144 14.0143C6.24714 14.3472 5.70096 14.3472 5.36814 14.0143Z"
                fill={fillColor}
            />
        </svg>
    );
}

export default ValidationsIcon;
