import { useController } from 'react-hook-form';

export const useGetCoordinates = (control) => {
    const { field: placeField } = useController({
        name: 'place',
        control,
        rules: {
            required: 'Please enter a location',
        },
    });

    const { field: placeCoordsField } = useController({
        name: 'placeCoords',
        control,
        defaultValue: { coordinates: [] },
    });

    const { field: radiusField } = useController({
        name: 'radius',
        control,
        defaultValue: 50,
    });

    return {
        placeField,
        placeCoordsField,
        radiusField,
    };
};
