import FormForCreatingProject from '../features/projects/FormForCreatingProject';

function AddProject() {
    return (
        <div className="pt-7">
            <FormForCreatingProject />
        </div>
    );
}

export default AddProject;
