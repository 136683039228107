export const convertMilliseconds = (ms) => {
    if (isNaN(ms)) {
        return 'Invalid input, please pass a valid number.';
    }

    let seconds = Math.floor(ms / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    // Calculate the remaining hours and minutes after extracting days and hours
    hours = hours % 24;
    minutes = minutes % 60;

    return `${days} days, ${hours} hours, and ${minutes} minutes`;
};
