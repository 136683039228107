import { useState } from 'react';
import ArrowLeft from '../assets/icons/ArrowLeft';
import ArrowRight from '../assets/icons/ArrowRight';
import ExpandedImage from './ExpandedImage';
import Button from './Button';
import Feedbackclose from '../assets/icons/Feedbackclose';

const Slider = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const goToPrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const goToIndex = (index) => {
        setCurrentIndex(index);
    };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <div>
            {/* Main Slider */}
            <div className="relative w-[100%] h-32 border rounded-md overflow-hidden">
                <img
                    src={images[currentIndex]}
                    alt={`Slide ${currentIndex}`}
                    className="object-cover w-full h-full cursor-pointer"
                    onClick={openModal}
                />

                <button
                    onClick={goToPrev}
                    className="absolute left-0 top-1/2 transform -translate-y-1/2 px-4 py-2 text-white rounded-full hover:bg-gray-50"
                >
                    <ArrowLeft w="16" h="16" />
                </button>

                <button
                    onClick={goToNext}
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 px-4 py-2 text-white rounded-full hover:bg-gray-50"
                >
                    <ArrowRight w="16" h="16" />
                </button>

                <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
                    {images.map((_, index) => (
                        <button
                            key={index}
                            onClick={() => goToIndex(index)}
                            className={`w-4 h-4 rounded-full ${
                                index === currentIndex ? 'bg-gray-400' : 'bg-white'
                            } border border-black hover:bg-gray-500`}
                        />
                    ))}
                </div>
            </div>

            {isModalOpen && (
                <ExpandedImage close={closeModal}>
                    <div
                        className="relative w-[90%] max-w-4xl h-[80%] bg-white rounded-md overflow-hidden"
                        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
                    >
                        <Button
                            radius="rounded-full"
                            onClick={closeModal}
                            className=" border-0 absolute top-3  right-4 "
                            variant="tertiary"
                            size="circle"
                        >
                            <Feedbackclose />
                        </Button>
                        <img
                            src={images[currentIndex]}
                            alt={`Slide ${currentIndex}`}
                            className="object-contain w-full h-full"
                        />

                        <button
                            onClick={goToPrev}
                            className="absolute left-0 top-1/2 transform -translate-y-1/2 px-4 py-2 text-white rounded-full hover:bg-gray-50"
                        >
                            <ArrowLeft w="16" h="16" />
                        </button>

                        <button
                            onClick={goToNext}
                            className="absolute right-0 top-1/2 transform -translate-y-1/2 px-4 py-2 text-white rounded-full hover:bg-gray-50"
                        >
                            <ArrowRight w="16" h="16" />
                        </button>
                    </div>
                </ExpandedImage>
            )}
        </div>
    );
};

export default Slider;
