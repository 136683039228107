import React from 'react';
import Table from '../../ui/Table';
import { microtaskTypeData } from '../bounties/microtasktypes/microtaskTypeData';
import DownloadIcon from '../../assets/icons/DownloadIcon';

const DownloadPDFSD = ({ dataTask }) => {
    const titleObject = dataTask?.task.find((obj) => Object.keys(obj).includes('Title'));
    const questionObject = dataTask?.task.find((obj) => Object.keys(obj).includes('Description'));
    const { Files } = dataTask.task.find((obj) => Object.keys(obj).includes('Files'));

    const fileEntries = Files?.split(',');

    // Step 2: Create an array of objects with 'fileName' and 'fileLink'
    const files = fileEntries?.map((entry) => {
        // Split each entry by '~' to get file name and link
        const [fileName, fileLink] = entry?.split('~');
        return {
            fileName: fileName?.trim(),
            fileLink: fileLink?.trim(),
        };
    });

    return (
        <Table.Row>
            <p className=" text-lightBlack flex flex-col   ">
                <span className="font-medium mb-1  "> {titleObject?.Title || '-'} </span>
                <span className="text-xs font-medium">{questionObject?.Description || '-'}</span>
                <div className="mt-1">
                    {files.map((fileObj, i) => (
                        <div className="flex items-center gap-2 mb-1">
                            <p key={fileObj?.fileName + i} className="text-xs text-lightBlack  ">
                                {fileObj?.fileName}
                            </p>
                            {fileObj?.fileLink && (
                                <span className="cursor-pointer ">
                                    <a href={fileObj?.fileLink} rel="noopener noreferrer">
                                        <DownloadIcon />
                                    </a>
                                </span>
                            )}
                        </div>
                    ))}
                </div>
            </p>
            <p className="text-sm justify-self-center">
                {microtaskTypeData.find((ele) => ele.type === dataTask?.actionType)?.label}
            </p>

            <div>
                {/* {dataTask.submission.answer[0].split(',').map((ans, i) => (
                    <p key={ans + i} className=" text-lightBlack mb-1 ">
                        <span>{i + 1}.</span> <span> {ans}</span>
                    </p>
                ))} */}
            </div>
        </Table.Row>
    );
};

export default DownloadPDFSD;
