import EditMembersAndRoles from '../features/members/EditMembersAndRoles';

function EditMembers() {
    return (
        <div className="">
            <EditMembersAndRoles />
        </div>
    );
}

export default EditMembers;
