import { useContext } from 'react';
import { BountiesContext } from '../../../contexts/BountiesContext';
import { useBountyFields } from '../../../hooks/useBountyFields';
import mobileFrame from '../../../assets/mobileFrame.png';
import Heading from '../../../ui/Heading';
import SubHeading from '../../../ui/SubHeading';
import { CalendarIcon } from '../../../assets/icons/CalendarIcon';
import { ClockIcon } from '../../../assets/icons/ClockIcon';
import Button from '../../../ui/Button';
import DownloadIcon from '../../../assets/icons/DownloadIcon';
function PreviewDownloadPDF({ data }) {
    const { state } = useContext(BountiesContext);
    const { titleField } = useBountyFields();

    return (
        <div
            className="relative w-[300px] h-[610px]     "
            style={{
                backgroundImage: `url(${mobileFrame})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <main className="absolute  top-28 left-6 right-6 ">
                <div className="my-4">
                    <Heading className={'break-words text-minerTitle'} type={'xsmall'}>
                        {data.downloadPDF_Title || 'Title'}
                    </Heading>
                    <SubHeading className={'!text-xs font-semibold text-minerDescription mt-2'} type="xsmall">
                        {data.downloadPDF_Description || 'Task Description'}
                    </SubHeading>
                </div>
                <div className="flex flex-col  gap-2 mt-3">
                    {data?.downloadPDF_Files?.map((file, i) => {
                        return (
                            <div className="flex items-center justify-between " key={file.fileTitle + i}>
                                <p className="text-xs text-minerDescription font-semibold">{file.fileTitle}</p>
                                {file.link && (
                                    <span className="cursor-pointer ">
                                        <a href={file.link} rel="noopener noreferrer">
                                            <DownloadIcon />
                                        </a>
                                    </span>
                                )}
                            </div>
                        );
                    })}
                </div>
            </main>

            <footer className="absolute bottom-6 left-6 right-6">
                <div className="flex mb-2 items-center justify-between">
                    <Button
                        className=" flex !text-[#1A56DB] text-xs items-center justify-center gap-1 text-end"
                        size="none"
                        variant="transparent"
                    >
                        <span>{'<-'}</span> <span> Back</span>
                    </Button>
                    {data.skippable && (
                        <Button className=" !text-[#1A56DB] text-xs  text-end" size="none" variant="transparent">
                            Skip {'->'}
                        </Button>
                    )}
                </div>
                <Button variant="miner" size="largeMiner" className="py-2 text-xs " radius="rounded-full">
                    Next
                </Button>
            </footer>
        </div>
    );
}

export default PreviewDownloadPDF;
