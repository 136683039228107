import { useContext, useEffect, useState } from 'react';
import Button from '../../ui/Button';
import StepOne from './StepOne';
import { BountiesContext } from '../../contexts/BountiesContext';
import StepTwo from './StepTwo';
import StepFour from './StepFour';
import StepThree from './StepThree';
import StepFive from './StepFive';
import StepSix from './StepSix';
import { dateToIsoString } from '../../helpers/dateToIsoString';
import { addTime, convertToMilliseconds } from '../../helpers/addTime';
import { createBounty, sendRequestForVoting } from '../../apis/bounties/bounties';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { validationRedirect } from './validationRedirect';
import RequestSentForTreasurerApproval from '../treasurer/requests/RequestsSentForTreasurerApproval';
import { useForm, useWatch } from 'react-hook-form';
import BountiesPreview from './BountiesPreview';
import { objectDestructurer } from '../../helpers/objectDestructurer';
import { getMicroTaskFieldsAndValues } from '../../helpers/getMicroTaskFieldsAndValues';
import { useLoadingState } from '../../hooks/useLoader';
import RequestCreated from './RequestCreated';
import { converttagsstringinstring } from '../../helpers/conerttagstringinstring';
import StepCompleted from '../../assets/icons/StepCompleted';
import { plainTextValue } from '../../helpers/plainText';
import { desiredInstructionalLinks } from '../../helpers/desiredInstructionalLinks';

function BountiesMain({ edit, step, setStep, completedSteps, setCompletedSteps }) {
    // const [step, setStep] = useState(1);
    const [descriptionLinkPayload, setDescriptionLinkPayload] = useState([]);
    const [instructionLinkPayload, setInstructionLinkPayload] = useState([]);
    const [activeMicrotask, setActiveMicrotask] = useState(0);
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [created, setCreated] = useState(false);
    const [impactPointsData, setImpactPointsData] = useState(0);
    const [radiusPreview, setRadiusPreview] = useState(50);

    const {
        register,
        handleSubmit,
        control,
        getValues,
        trigger,
        formState: { errors },
        setError,
        reset,
        unregister,
        setValue,
        clearErrors,
    } = useForm(
        edit
            ? {
                  mode: 'onChange',
                  defaultValues: {
                      maxValueString: 'abc',
                  },
              }
            : {
                  mode: 'onChange',
                  defaultValues: {
                      maxValueString: 'abc',
                      mode: 'digital',
                      criteria: [{ criteriaName: '' }],
                      microtasks: [
                          {
                              microtaskType: '',
                          },
                      ],
                      hasImpactCertificate: false,
                      cores: [],
                      SDGs: [],
                      isAttestable: true,
                      hasBacker: true,
                      hasInitiator: false,
                      location: { coordinates: [] },
                      bountyRadius: 50,
                      distributionMode: 'Distribute evenly between all winners',
                  },
              }
    );
    const microtasksValues = useWatch({
        control,
        name: 'microtasks',
    });
    const [icImage, setIcImage] = useState({});
    const {
        loading: icFetching,
        startLoading: startGettingIcImage,
        stopLoading: stopGettingIcImage,
    } = useLoadingState();
    const criteriaValues = useWatch({
        control,
        name: 'criteria',
    });
    const navigate = useNavigate();
    const { id } = useParams();
    // const {} =

    let dataForMicroTask;
    const getDataForMicroTask = (data) => {
        dataForMicroTask = data;
        return dataForMicroTask;
    };

    const handleStepChange = async (nextStep, i, typeMicrotask) => {
        // if (nextStep === 4 && nextStep > step) {
        //     handleManualValueSetting(nextStep, `microtasks[${i}].choose_microTaskType`, typeMicrotask);
        //     return;
        // }
        if (nextStep < step) {
            setCompletedSteps((prev) => prev.filter((s) => s < nextStep));
            setStep(nextStep);
            return;
        }
        const isValid = await trigger(); // Manually trigger validation for all fields
        if (isValid) {
            setStep(nextStep);
            setCompletedSteps((prev) => {
                if (!prev.includes(nextStep - 1)) {
                    return [...prev, nextStep - 1]; // Add the previous step to completedSteps if not already added
                }
                return prev;
            });
        } else {
            console.log('Validation failed');
        }
    };

    const handleDescriptionLinkPayload = (value) => {
        setDescriptionLinkPayload((prev) => [...value]);
    };
    const handleInstructionLinkPayload = (value) => {
        setInstructionLinkPayload(value);
        // You can now store this payload or pass it along for submission
    };
    const handleManualValueSetting = async (nextStep, field, value) => {
        const isValid = value?.length ? true : false;
        if (!isValid) {
            setError(field, {
                type: 'manual',
                message: 'Please select microtask type',
            });
        } else {
            clearErrors(field); // Clear error if validation passes

            const isValid = await trigger(); // Manually trigger validation for all fields
            if (isValid) {
                if (nextStep) setStep(nextStep);
            } else {
                console.log('Validation failed');
            }
        }
    };

    const setFieldValue = (field, value) => {
        setValue(field, value, { shouldValidate: true }); // Set new value and trigger validation
    };

    const onSubmit = async (data) => {
        const getkeys = objectDestructurer(data);
        let distribution = [];
        const {
            mode,
            criteria,
            microtasks,
            hasImpactCertificate,
            cores,
            SDGs,
            isAttestable,
            hasBacker,
            hasInitiator,
            location,
            bountyRadius,
            distributionMode,
            title,
            city,
            bountyDescription,
            projectId,
            type,
            dominantCore,
            maxAcceptancesPerUser,
            backerName,
            backerLogo,
            backerMessage,
            initiatorName,
            initiatorLogo,
            initiatorMessage,
            token,
            totalRewardAmount,
            noOfWinners,
            passThreshold,
            impactBrief,
            dominantSDG,
            expirationTime,
            validationTillDate,
            ranks,
            day,
            hours,
            minutes,
        } = data;
        if (distributionMode === 'Distribute evenly between all winners') {
            console.log(token, 'see the token');
            const rewardPerPerson = Number((totalRewardAmount / noOfWinners).toFixed(token?.decimals));
            distribution = Array.from({ length: noOfWinners }, (_) => rewardPerPerson);
        } else {
            distribution = ranks
                .filter((rank) => rank.amount)
                .map((c) => Number(Number(c.amount).toFixed(token?.decimals)));
            // distribution = [rank1, rank2, rank3].filter((r) => r);
            // distribution = [rank1, rank2, rank3];
        }

        const mappedMicroTasks = microtasks.map((micro, indexMicroTask) => {
            const { microtaskType, skippable, ...filteredObj } = micro;

            // if (microtaskType === 'INSTRUCTION') return {
            //     // filteredObj.instruction_links = obj.link
            //     actions:  [{ actionType: microtaskType, action: getMicroTaskFieldsAndValues(filteredObj) }],
            //     skippable,
            // };
            if (microtaskType === 'INSTRUCTION') {
                filteredObj.instruction_message = filteredObj.instruction_message;
                // filteredObj.instruction_links = '';
                filteredObj.instruction_links = desiredInstructionalLinks(instructionLinkPayload);

                return {
                    actions: [{ actionType: microtaskType, action: getMicroTaskFieldsAndValues(filteredObj) }],
                    skippable: microtasks.length === indexMicroTask + 1 ? false : skippable,
                };
                // instruction_links: '',
            }

            if (microtaskType === 'TEST_MCQ') {
                filteredObj.testMCQ_correctAnswer =
                    filteredObj.testMCQ_answers[filteredObj.testMCQ_correctAnswer].answer;
                filteredObj.testMCQ_answers = filteredObj.testMCQ_answers
                    .map((ele) => {
                        return ele.answer;
                    })
                    .join(',,');
                // testMCQ_correctAnswer: '',
            }

            if (microtaskType === 'DOWNLOAD_PDF') {
                filteredObj.downloadPDF_Files = filteredObj.downloadPDF_Files
                    .map((item) => `${item.fileTitle}~${item.link}`)
                    .join(',');
            }

            if (microtaskType === 'MULTI_SELECT_MULTIPLE_CHOICE_QUESTION') {
                // change the value here for answers like  "answer1,answer2,answer3"

                filteredObj.multiSelect_answers = filteredObj.multiSelect_answers
                    .map((ele) => {
                        return ele.answerName;
                    })
                    .join(',');

                // multiChoiceQuestions_answers;
            }

            if (microtaskType === 'MULTI_CHOICE_QUESTIONs') {
                // change the value here for answers like  "answer1,,answer2,,answer3"

                filteredObj.multiChoiceQuestions_answers = filteredObj.multiChoiceQuestions_answers
                    .map((ele) => ele.answer)
                    .join(',,');
            }

            if (microtaskType === 'YOUTUBE_VIDEO')
                return {
                    actions: filteredObj.youtubeVideos_Link.map((obj, i) => {
                        if (i !== 0) filteredObj.youtubeVideos_Title = '';
                        filteredObj.youtubeVideos_Description = obj.description;
                        filteredObj.youtubeVideos_Link = obj.link;
                        return {
                            actionType: microtaskType,
                            action: getMicroTaskFieldsAndValues(filteredObj),
                        };
                    }),
                    skippable: microtasks.length === indexMicroTask + 1 ? false : skippable,
                };
            return {
                actions: [{ actionType: microtaskType, action: getMicroTaskFieldsAndValues(filteredObj) }],
                skippable: microtasks.length === indexMicroTask + 1 ? false : skippable,
            };
        });
        //descriptionHyperlinks
        const payload = {
            title,
            description: bountyDescription,
            organisationId: id,
            ...(projectId && {
                projectId,
            }),

            type,
            cores: [...new Set(cores)],
            mode,
            ...(mode === 'physical' && {
                city,
                location,
                bountyRadius,
            }),
            ...(descriptionLinkPayload.length > 0 && {
                descriptionHyperlinks: descriptionLinkPayload,
            }),
            criteria: criteria
                .filter((criteria) => {
                    return criteria.criteriaName;
                })
                .map((criteria) => criteria.criteriaName),
            maxAcceptancesPerUser: Number(maxAcceptancesPerUser),
            microtasks: mappedMicroTasks,
            expirationTimestamp: dateToIsoString(new Date(expirationTime)),
            estimatedCompletionTime: convertToMilliseconds(Number(day), Number(hours), Number(minutes)),
            validationTillDate: dateToIsoString(new Date(validationTillDate)),
            ...(hasBacker && {
                backer: {
                    name: backerName,
                    ...(backerMessage && {
                        message: backerMessage,
                    }),
                    // message: backerMessage ? backerMessage : '',
                    image: backerLogo,
                },
            }),
            // ...(hasInitiator && {
            //     name: initiatorName,
            //     message: initiatorMessage,
            //     image: initiatorLogo,
            // }),
            hasImpactCertificate: hasImpactCertificate,
            ...(hasImpactCertificate && {
                impactBrief,
            }),
            rewardToken: {
                // symbol: token.symbol,
                tokenInfoId: token?._id,
                totalRewardAmount: Number(totalRewardAmount),
                distribution,

                // address: token.address,
            },
            SDGs,
            organisationId: id,
            passThreshold: Number(passThreshold) / 100,
            isAttestable,
        };

        startLoading();

        const response = await createBounty(payload);

        if (!response) {
            stopLoading();
            toast.error('Unable to create bounty. Please check with Atlantis customer support. ');
            return;
        }
        if (response?._id) {
            const creationPayload = {
                organisationId: id,
                requestType: 'BOUNTY_CREATION',
                bountyId: response._id,
                lastUpdateTime: response.updatedAt,
            };
            const responseRequest = await sendRequestForVoting(creationPayload);
            if (responseRequest?.bountyId) {
                stopLoading();
                setStep(7);
            }

            stopLoading();
        }
    };

    return (
        <>
            <main className=" overflow-y-auto  px-12 py-[30px] pt-0  overflow-x-hidden main">
                <div className="flex flex-col items-center">
                    <div className="flex gap-9    ">
                        {Array.from({ length: 6 }, (_, i) => i + 1).map((number, index) => {
                            const isIncluded = completedSteps.includes(number);
                            return (
                                <Button
                                    radius="rounded-full" // Ensures the button stays round
                                    className={`${
                                        isIncluded ? '!bg-[#E5F3FF] !border-[#CCE7FF] ' : 'bg-white  '
                                    } w-10 h-10 aspect-square flex !cursor-default items-center justify-center ${
                                        step === index + 1
                                            ? 'border border-primaryColor  text-primaryColor'
                                            : ' border-borderColor '
                                    }`}
                                    variant="tertiary"
                                    size="circle"
                                    key={number + index}
                                >
                                    {isIncluded ? <StepCompleted /> : number}
                                </Button>
                            );
                        })}
                    </div>
                </div>
                <div className="border-l border-r border-borderColor h-[598px] mt-7 rounded-md">
                    <form onSubmit={handleSubmit(onSubmit)} className=" h-[550px]  overflow-y-auto main  ">
                        {step === 1 && (
                            <StepOne
                                register={register}
                                control={control}
                                errors={errors}
                                setStep={handleStepChange}
                                getValues={getValues}
                                clearErrors={clearErrors}
                                setValue={setValue}
                                descriptionLinkPayload={descriptionLinkPayload}
                                handleDescriptionLinkPayload={handleDescriptionLinkPayload}
                                setRadiusPreview={setRadiusPreview}
                            />
                        )}
                        {step === 2 && (
                            <StepTwo register={register} control={control} errors={errors} setStep={handleStepChange} />
                        )}
                        {step === 3 && (
                            <StepThree
                                register={register}
                                setValue={setValue}
                                control={control}
                                errors={errors}
                                setStep={handleStepChange}
                                getValues={getValues}
                                getDataForMicroTask={getDataForMicroTask}
                                activeMicrotask={activeMicrotask}
                                microtasksValues={microtasksValues}
                                setActiveMicrotask={setActiveMicrotask}
                                clearErrors={clearErrors}
                                trigger={trigger}
                                handleManualValueSetting={handleManualValueSetting}
                                handleInstructionLinkPayload={handleInstructionLinkPayload}
                                instructionLinkPayload={instructionLinkPayload}
                            />
                        )}
                        {step === 4 && (
                            <StepFour
                                register={register}
                                control={control}
                                errors={errors}
                                setStep={handleStepChange}
                                getValues={getValues}
                            />
                        )}
                        {step === 5 && (
                            <StepFive
                                register={register}
                                control={control}
                                errors={errors}
                                setStep={handleStepChange}
                                getValues={getValues}
                                setError={setError}
                                clearErrors={clearErrors}
                            />
                        )}
                        {step === 6 && (
                            <StepSix
                                loading={loading}
                                register={register}
                                control={control}
                                errors={errors}
                                setStep={handleStepChange}
                                getValues={getValues}
                                setError={setError}
                                clearErrors={clearErrors}
                                onSubmit={onSubmit}
                                reset={reset}
                                setIcImage={setIcImage}
                                icImage={icImage}
                                impactPointsData={impactPointsData}
                                setImpactPointsData={setImpactPointsData}
                                startGettingIcImage={startGettingIcImage}
                                stopGettingIcImage={stopGettingIcImage}
                            />
                        )}
                    </form>
                </div>
            </main>

            <BountiesPreview
                control={control}
                unregister={unregister}
                step={step}
                allMicrotasks={microtasksValues}
                activeMicrotask={activeMicrotask}
                criterias={criteriaValues}
                descriptionLinkPayload={descriptionLinkPayload}
                instructionLinkPayload={instructionLinkPayload}
                icImage={icImage}
                impactPointsData={impactPointsData}
                icFetching={icFetching}
                radiusPreview={radiusPreview}
            />
        </>
    );
}

export default BountiesMain;
// name => actionType
//fields => details or fields acc. to actionType
