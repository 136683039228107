import { useNavigate, useParams } from 'react-router-dom';
import Table from '../../../ui/Table';
import Button from '../../../ui/Button';
import AvatarImage from '../../../ui/AvatarImage';
import Modal from '../../../ui/Modal';
import AcceptRequestModal from './AcceptRequestModal';
import { getCreationOrValidationRequestsOrProposals } from '../../../apis/treasurer/treasurer';
import { useEffect, useState } from 'react';
import NoDataAvailable from '../../../ui/NoDataAvailable';
import Loader from '../../../ui/Loader';
import { useWallet } from '@solana/wallet-adapter-react';
import { formatIntDateAndThenTime } from '../../../helpers/formatInDateAndThenTime';
import WalletConnectCustomButton from '../../walletconnectUI/WalletCustomText';

function BountyCreationRequests({ multiSig }) {
    const { publicKey } = useWallet();
    const navigate = useNavigate();
    const [creationRequests, setCreationRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();

    const address = publicKey;
    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    const data = await getCreationOrValidationRequestsOrProposals('BOUNTY_CREATION', id, false);
                    setCreationRequests(data);
                    setIsLoading(false);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [id]
    );

    const onRequestingFilterData = (id) => {
        const filterCreationData = creationRequests.filter((request) => request._id !== id);
        setCreationRequests(filterCreationData);
    };

    return (
        <>
            <Table.Header>
                <p className="text-sm   font-semibold text-secondaryText "> Bounty Name </p>
                <p className="text-sm   font-semibold text-secondaryText ">Requestor</p>
                <p className="text-sm   font-semibold text-secondaryText col-span-2 ">Request Time</p>

                <p className="text-sm  font-semibold text-secondaryText ">Accept Request</p>
            </Table.Header>
            {isLoading ? (
                <div className="min-h-[20vh] flex justify-center items-center">
                    <Loader />
                </div>
            ) : creationRequests.length ? (
                creationRequests.map((overviewrow) => (
                    <Table.Row key={overviewrow._id}>
                        <p
                            onClick={() => navigate(`/organizations/${id}/view-bounties/${overviewrow.bountyId}`)}
                            className="text-primaryColor font-medium cursor-pointer "
                        >
                            {overviewrow.bountyTitle}
                        </p>
                        <div className="flex items-center gap-4 cursor-pointer ">
                            <AvatarImage w="w-[32px]" h="h-[32px]" url={overviewrow.requesterProfileImage} />
                            <p className="text-primaryInput">{overviewrow.requesterUsername}</p>
                        </div>
                        <p className="col-span-2 text-primaryInput ">
                            {formatIntDateAndThenTime(overviewrow.createdAt)}
                        </p>

                        <div className="w-[100%]">
                            {address ? (
                                <Modal>
                                    <Modal.Button
                                        className="flex gap-2   cursor-pointer items-center"
                                        opens={'acceptingcreationrequest'}
                                    >
                                        <Button className="text-primaryInput" variant="tertiary" size="adjustLarge">
                                            Accept Request
                                        </Button>
                                    </Modal.Button>
                                    <Modal.Window name={'acceptingcreationrequest'}>
                                        <AcceptRequestModal
                                            bountyId={overviewrow.bountyId}
                                            multiSig={multiSig}
                                            afterRequestOperation={onRequestingFilterData}
                                            reqId={overviewrow._id}
                                        />
                                    </Modal.Window>
                                </Modal>
                            ) : (
                                // <ExecuteButton />
                                // <OwnButton variant="tertiary" size="large" text=" Accept Request" />

                                <div className="w-full flex items-center">
                                    <WalletConnectCustomButton
                                        customText={'Accept Request'}
                                        width="177px"
                                        customStyles={{
                                            backgroundColor: 'white',
                                            color: 'var(--primary-input)',
                                            border: '1px solid #dcdcdc',
                                            lineHeight: 'normal',
                                            fontWeight: '500',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            height: '41.6px',
                                            borderRadius: '8px',
                                            flexGrow: 1,
                                        }}
                                        connectedText={'Accept Request'}
                                        disconnectedText="Accept Request"
                                        removeIconOnDisconnect={true}
                                        removeIconOnConnect={true}
                                        removeIconOnWalletSelect={true}
                                        removeIconOnConnecting={true}
                                    />
                                </div>
                            )}
                        </div>
                    </Table.Row>
                ))
            ) : (
                <NoDataAvailable />
            )}
        </>
    );
}

export default BountyCreationRequests;
