import axios from 'axios';
import { BASE_URL } from '../../constants/apiPaths';
import { auth, getAccessToken } from '../../firebase';

const getConfig = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

export const getAllCertificates = async () => {
    const url = `${BASE_URL}/impactCertificate`;
    try {
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

export const mintIc = async (certfificateId, address) => {
    try {
        const url = `${BASE_URL}/impactCertificate/mint/${certfificateId}?walletAddress=${address}`;
        const accessToken = await getAccessToken();

        const response = await axios.patch(url, {}, getConfig(accessToken));

        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};
export const claimIc = async (certfificateId) => {
    try {
        const url = `${BASE_URL}/impactCertificate/claim/${certfificateId}`;
        const accessToken = await getAccessToken();

        const response = await axios.patch(url, {}, getConfig(accessToken));

        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};
