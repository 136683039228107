export const CopyIcon = () => {
    return (
        <svg width="28" height="33" viewBox="0 0 28 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.2889 0H2.89841C1.30428 0 0 1.33763 0 2.97252V23.7801H2.89841V2.97252H20.2889V0ZM24.6365 5.94503H8.69523C7.1011 5.94503 5.79682 7.28266 5.79682 8.91755V29.7252C5.79682 31.36 7.1011 32.6977 8.69523 32.6977H24.6365C26.2306 32.6977 27.5349 31.36 27.5349 29.7252V8.91755C27.5349 7.28266 26.2306 5.94503 24.6365 5.94503ZM24.6365 29.7252H8.69523V8.91755H24.6365V29.7252Z"
                fill="#969696"
            />
        </svg>
    );
};

export const FileIcon = () => {
    return (
        <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.00016 0.666992C1.0835 0.666992 0.341829 1.41699 0.341829 2.33366L0.333496 15.667C0.333496 16.5837 1.07516 17.3337 1.99183 17.3337H12.0002C12.9168 17.3337 13.6668 16.5837 13.6668 15.667V6.35866C13.6668 5.91699 13.4918 5.49199 13.1752 5.18366L9.15016 1.15866C8.84183 0.841992 8.41683 0.666992 7.97516 0.666992H2.00016ZM7.8335 5.66699V1.91699L12.4168 6.50033H8.66683C8.2085 6.50033 7.8335 6.12533 7.8335 5.66699Z"
                fill="#969696"
            />
        </svg>
    );
};

export const ThreeDotsIcon = () => {
    return (
        <svg className="" width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.00016 0.333008C1.0835 0.333008 0.333496 1.08301 0.333496 1.99967C0.333496 2.91634 1.0835 3.66634 2.00016 3.66634C2.91683 3.66634 3.66683 2.91634 3.66683 1.99967C3.66683 1.08301 2.91683 0.333008 2.00016 0.333008V0.333008ZM12.0002 0.333008C11.0835 0.333008 10.3335 1.08301 10.3335 1.99967C10.3335 2.91634 11.0835 3.66634 12.0002 3.66634C12.9168 3.66634 13.6668 2.91634 13.6668 1.99967C13.6668 1.08301 12.9168 0.333008 12.0002 0.333008V0.333008ZM7.00016 0.333008C6.0835 0.333008 5.3335 1.08301 5.3335 1.99967C5.3335 2.91634 6.0835 3.66634 7.00016 3.66634C7.91683 3.66634 8.66683 2.91634 8.66683 1.99967C8.66683 1.08301 7.91683 0.333008 7.00016 0.333008V0.333008Z"
                fill="#969696"
            />
        </svg>
    );
};

export const ThreeDotsVertical = () => {
    return (
        <svg width="5" height="21" viewBox="0 0 5 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.49967 5.66602C1.85873 5.66602 1.24405 5.4114 0.790833 4.95819C0.33762 4.50498 0.0830071 3.89029 0.0830071 3.24935C0.083007 2.60841 0.33762 1.99372 0.790833 1.54051C1.24405 1.0873 1.85873 0.832683 2.49967 0.832683C3.14061 0.832683 3.7553 1.0873 4.20851 1.54051C4.66173 1.99372 4.91634 2.60841 4.91634 3.24935C4.91634 3.89029 4.66173 4.50498 4.20851 4.95819C3.7553 5.4114 3.14061 5.66602 2.49967 5.66602ZM2.49967 12.916C1.85873 12.916 1.24405 12.6614 0.790833 12.2082C0.33762 11.755 0.0830074 11.1403 0.0830074 10.4993C0.0830074 9.85841 0.33762 9.24372 0.790833 8.79051C1.24405 8.3373 1.85873 8.08268 2.49967 8.08268C3.14061 8.08268 3.7553 8.3373 4.20852 8.79051C4.66173 9.24372 4.91634 9.85841 4.91634 10.4993C4.91634 11.1403 4.66173 11.755 4.20852 12.2082C3.7553 12.6614 3.14061 12.916 2.49967 12.916ZM2.49967 20.166C1.85873 20.166 1.24405 19.9114 0.790834 19.4582C0.337621 19.005 0.0830077 18.3903 0.0830077 17.7493C0.0830077 17.1084 0.33762 16.4937 0.790833 16.0405C1.24405 15.5873 1.85873 15.3327 2.49967 15.3327C3.14061 15.3327 3.7553 15.5873 4.20852 16.0405C4.66173 16.4937 4.91634 17.1084 4.91634 17.7493C4.91634 18.3903 4.66173 19.005 4.20852 19.4582C3.7553 19.9114 3.14061 20.166 2.49967 20.166Z"
                fill="#747474"
            />
        </svg>
    );
};

export const EditIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.4076 1.79556L14.1978 0.585183C13.4179 -0.195061 12.1481 -0.195061 11.3682 0.585183L8.68864 3.26602L0 11.9587V16H4.03937L12.778 7.25727L15.4076 4.62645C16.1975 3.8462 16.1975 2.5758 15.4076 1.79556ZM3.2095 13.9994H1.99969V12.789L10.6583 4.12629L11.8681 5.33667L3.2095 13.9994Z"
                fill="#969696"
            />
        </svg>
    );
};

export const InfoTooltipIcon = ({ color, className = '', hoverOver, hoverDown, ...props }) => {
    return (
        <svg
            onMouseEnter={hoverOver}
            onMouseLeave={hoverDown}
            className={`${className}`}
            width="18"
            height="18"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9.16664 5.10603H11.1969V7.13633H9.16664V5.10603ZM9.16664 9.16664H11.1969V15.2575H9.16664V9.16664ZM10.1818 0.0302734C4.57815 0.0302734 0.0302734 4.57815 0.0302734 10.1818C0.0302734 15.7854 4.57815 20.3333 10.1818 20.3333C15.7854 20.3333 20.3333 15.7854 20.3333 10.1818C20.3333 4.57815 15.7854 0.0302734 10.1818 0.0302734ZM10.1818 18.303C5.70497 18.303 2.06058 14.6586 2.06058 10.1818C2.06058 5.70497 5.70497 2.06058 10.1818 2.06058C14.6586 2.06058 18.303 5.70497 18.303 10.1818C18.303 14.6586 14.6586 18.303 10.1818 18.303Z"
                fill={color}
            />
        </svg>
    );
};
