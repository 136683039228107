import ReactMapboxGl, { Feature, Layer, Marker } from 'react-mapbox-gl';
import { useState } from 'react';
import defaultMarker from '../../../assets/defaultMarker.png';
const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoiZmFpc2FsZHJvcGNoYWluIiwiYSI6ImNsdXJnOWF2czA3NmYyam4xZWkzbmltaHAifQ.IK0wrkOv-H-C57rLCcMBMA',
});

const VisitLocationSubDetails = ({ data }) => {
    const { action } = data;

    const titleObject = action.find((obj) => obj.name === 'Title');
    const descriptionObject = action.find((obj) => obj.name === 'Description');
    const coordinateObject = action.find((obj) => obj.name === 'coordinate');

    const [lat, long] = coordinateObject?.value?.split(',');

    const [mtCenter, setMtCenter] = useState([long, lat]);
    const [zoomMt, setZoomMt] = useState(14);

    return (
        <>
            <p className="flex gap-1 text-xs text-secondaryInput mb-2  ">
                <span>Response type : </span>{' '}
                <span className="font-bold capitalize "> {data?.label?.split('_')?.join(' ')?.toLowerCase()}</span>
            </p>
            <p className="font-semibold capitalize text-primaryInput mb-2">
                <span>{titleObject?.value}</span>
            </p>
            <p className="text-sm capitalize text-primaryInput mb-2">
                <span>{descriptionObject?.value}</span>
            </p>
            {lat && long && (
                <div className=" overflow-hidden bg-white rounded-2xl">
                    <Map
                        style="mapbox://styles/mapbox/streets-v9"
                        containerStyle={{
                            height: '20vh',
                            width: '100%',
                            zIndex: '0',
                        }}
                        center={mtCenter}
                        zoom={[zoomMt]}
                        onZoomEnd={(map) => {
                            setZoomMt(map.getZoom());
                        }}
                    >
                        {lat && long && (
                            <>
                                {/* Custom Marker with Image */}
                                <Marker anchor="bottom" coordinates={[long, lat]}>
                                    <img src={defaultMarker} alt="marker" />
                                </Marker>
                            </>
                        )}
                    </Map>
                </div>
            )}
        </>
    );
};

export default VisitLocationSubDetails;
