import { clusterApiUrl, Connection } from '@solana/web3.js';
import { WaterIcon } from '../assets/icons/ProgressBarIcons';
import AvatarImage from './AvatarImage';
import Button from './Button';
import ExecuteButton from './ExecuteButton';
import Status from './Status';
import { executor } from '../blockchainInteraction/createOrganization';
import { getProvider } from '../helpers/getProvider';
import { executeOrgApplication } from '../apis/oragnisations/organisation';
import { useWallet } from '@solana/wallet-adapter-react';
import OwnButton from '../features/walletconnectUI/OwnButton';
import { useState } from 'react';
import WalletConnectCustomButton from '../features/walletconnectUI/WalletCustomText';
import { coresWithIconData } from '../constants/datas';
import { RPC_ENDPOINT } from '../constants/apiPaths';

const statusText = {
    SUBMITTED: 'pending',
    APPROVED: 'accepted',
    REJECTED: 'rejected',
    EXECUTED: 'executed',
};

function SummaryCard({
    position = '',
    className = '',
    organization,
    application,
    redirectTo,
    onSettingApp,
    setParticularApplication,
}) {
    const { publicKey } = useWallet();

    const address = publicKey;
    if (!organization && !application) return <p>coming soon</p>;
    const { name, tagline, logo, cores } = organization;
    // const { orgName, orgTagline, orgLogo } = application;

    const onExecution = async (id) => {
        const connection = new Connection(RPC_ENDPOINT, 'confirmed');

        try {
            const { transaction, multisigPda } = await executor(address, connection);
            const { signature } = await getProvider().signAndSendTransaction(transaction);
            await connection.getSignatureStatus(signature);
            const sendMultiSigPDA = multisigPda.toBase58();
            const res = await executeOrgApplication(application._id, sendMultiSigPDA);

            setParticularApplication();
            onSettingApp((initial) => [
                ...initial.map((app) => {
                    if (app._id === application._id) return { ...app, status: 'EXECUTED' };
                    return app;
                }),
            ]);
            // window.alert(`${signature} <br/> Please Copy this signature and paste it into Solana explorer `);
        } catch (error) {
            console.log(error);
        }
    };

    if (application)
        return (
            <div
                onClick={redirectTo}
                className={` bg-white  cursor-pointer    first:pt-0 last:pb-0    last:border-b-0  py-4 border-b border-borderColor ${className} `}
            >
                <div className="flex  ">
                    <AvatarImage w="w-12" h="h-12" url={application.orgLogo} />

                    <div className="px-4 ">
                        <p className="font-medium text-primaryInput ">{application.orgName}</p>
                        <p className="text-sm text-secondaryInput ">{application.orgTagline}</p>
                        <div className="flex items-center gap-2 mt-2">
                            <Status bg="bg-secondGray" text={statusText[application.status]} />
                            <Status bg="bg-secondGray" text={new Date(application.createdAt).toLocaleDateString()} />
                            {/* {application.status === 'APPROVED' && <Button size="small"> Execute</Button>} */}

                            {address
                                ? application.status === 'APPROVED' && (
                                      <Button
                                          type="button"
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              onExecution(application._id);
                                          }}
                                          size="small"
                                      >
                                          {' '}
                                          Execute
                                      </Button>
                                  )
                                : application.status === 'APPROVED' && (
                                      <div className="w-full flex items-center">
                                          <WalletConnectCustomButton
                                              customText={'Execute'}
                                              width="128px"
                                              customStyles={{
                                                  backgroundColor: 'var(--primary-color)',
                                                  color: '#fff',
                                                  border: '1px solid #dcdcdc',
                                                  lineHeight: 'normal',
                                                  fontWeight: '500',
                                                  paddingLeft: '12px',
                                                  paddingRight: '12px',
                                                  height: '40px',
                                                  borderRadius: '8px',
                                                  flexGrow: 1,
                                              }}
                                              connectedText={'Execute'}
                                              disconnectedText="Execute"
                                              removeIconOnDisconnect={true}
                                              removeIconOnConnect={true}
                                              removeIconOnWalletSelect={true}
                                              removeIconOnConnecting={true}
                                          />
                                      </div>
                                  )}
                        </div>
                    </div>
                </div>
            </div>
        );

    return (
        <div
            onClick={redirectTo}
            className={` bg-white  cursor-pointer first:pt-0 last:pb-0    last:border-b-0  py-4 border-b border-borderColor ${className} `}
        >
            <div className="flex items-center ">
                <AvatarImage w="w-12" h="h-12" url={logo} />

                <div className="px-4 ">
                    <p className="font-medium  text-primaryInput ">{name}</p>
                    <p className="text-sm text-secondaryInput ">{tagline}</p>
                    <div className=" flex  items-center gap-2  ">
                        <p className="text-sm font-bold text-thirdGray">Impact Cores: </p>
                        <div className="flex items-center gap-2">
                            {cores.map(
                                (text) =>
                                    text && (
                                        <p key={text} className="flex items-center gap-3">
                                            <img
                                                className={` ${
                                                    text?.toLowerCase() === 'water' || text?.toLowerCase() === 'social'
                                                        ? 'w-4 mr-1'
                                                        : 'w-5 h-5'
                                                }  `}
                                                src={
                                                    coresWithIconData?.find(
                                                        (obj) => obj.label.toLowerCase() === text.toLowerCase()
                                                    )?.icon
                                                }
                                                alt="image"
                                            />
                                        </p>
                                    )
                            )}{' '}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SummaryCard;
