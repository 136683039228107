import DownloadIcon from '../../../assets/icons/DownloadIcon';

const DownloadPDFSubDetails = ({ data }) => {
    const { action } = data;

    const titleObject = action.find((obj) => obj.name === 'Title');
    const descriptionObject = action.find((obj) => obj.name === 'Description');
    const filesObject = action.find((obj) => obj.name === 'Files');
    const fileEntries = filesObject?.value?.split(',');

    const files = fileEntries?.map((entry) => {
        const [fileName, fileLink] = entry?.split('~');
        return {
            fileName: fileName?.trim(),
            fileLink: fileLink?.trim(),
        };
    });
    return (
        <>
            <p className="flex gap-1 text-xs text-secondaryInput mb-2  ">
                <span>Response type : </span>{' '}
                <span className="font-bold capitalize "> {data?.label?.split('_')?.join(' ')?.toLowerCase()}</span>
            </p>
            <p className="font-semibold capitalize text-primaryInput mb-2">
                <span>{titleObject?.value}</span>
            </p>
            <p className="text-sm capitalize text-primaryInput mb-2">
                <span>{descriptionObject?.value}</span>
            </p>
            <div className="mt-1">
                {files.map((fileObj, i) => (
                    <div className="flex items-center gap-2 mb-1">
                        <p key={fileObj?.fileName + i} className="text-xs text-lightBlack  ">
                            {fileObj?.fileName}
                        </p>
                        {fileObj?.fileLink && (
                            <span className="cursor-pointer ">
                                <a href={fileObj?.fileLink} rel="noopener noreferrer">
                                    <DownloadIcon />
                                </a>
                            </span>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default DownloadPDFSubDetails;
