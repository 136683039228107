import { useWallet } from '@solana/wallet-adapter-react';
import { clusterApiUrl, Connection } from '@solana/web3.js';
import { executor } from '../../blockchainInteraction/createOrganization';
import { getProvider } from '../../helpers/getProvider';
import { executeOrgApplication } from '../../apis/oragnisations/organisation';
import AvatarImage from '../../ui/AvatarImage';
import Status from '../../ui/Status';
import Button from '../../ui/Button';
import WalletConnectCustomButton from '../walletconnectUI/WalletCustomText';
import { RPC_ENDPOINT } from '../../constants/apiPaths';

const statusText = {
    SUBMITTED: 'pending',
    APPROVED: 'accepted',
    REJECTED: 'rejected',
    EXECUTED: 'executed',
};
function ApplicationCardInAll({
    position = '',
    className = '',

    application,
    redirectTo,
    onSettingApp,
    setParticularApplication,
}) {
    const { publicKey } = useWallet();

    const address = publicKey;

    const onExecution = async (id) => {
        const connection = new Connection(RPC_ENDPOINT, 'confirmed');

        try {
            const { transaction, multisigPda } = await executor(address, connection);
            const { signature } = await getProvider().signAndSendTransaction(transaction);
            await connection.getSignatureStatus(signature);
            const sendMultiSigPDA = multisigPda.toBase58();
            const res = await executeOrgApplication(application._id, sendMultiSigPDA);

            setParticularApplication();
            onSettingApp((initial) => [
                ...initial.map((app) => {
                    if (app._id === application._id) return { ...app, status: 'EXECUTED' };
                    return app;
                }),
            ]);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div
            onClick={redirectTo}
            className={` bg-white  cursor-pointer p-6 rounded-lg  border border-borderColor ${className} `}
        >
            <div className="flex  ">
                <AvatarImage w="w-12" h="h-12" url={application.orgLogo} />

                <div className="px-4 ">
                    <p className="font-medium text-primaryInput ">{application.orgName}</p>
                    <p className="text-sm text-secondaryInput ">{application.orgTagline}</p>
                </div>
            </div>
            <div className="flex items-center gap-2 mt-3">
                <Status bg="bg-secondGray" text={statusText[application.status]} />
                <Status bg="bg-secondGray" text={new Date(application.createdAt).toLocaleDateString()} />
                {/* {application.status === 'APPROVED' && <Button size="small"> Execute</Button>} */}

                {address
                    ? application.status === 'APPROVED' && (
                          <Button
                              type="button"
                              onClick={(e) => {
                                  e.stopPropagation();
                                  onExecution(application._id);
                              }}
                              size="small"
                          >
                              {' '}
                              Execute
                          </Button>
                      )
                    : application.status === 'APPROVED' && (
                          <div className="w-full flex items-center">
                              <WalletConnectCustomButton
                                  customText={'Execute'}
                                  width="128px"
                                  customStyles={{
                                      backgroundColor: 'var(--primary-color)',
                                      color: '#fff',
                                      border: '1px solid #dcdcdc',
                                      lineHeight: 'normal',
                                      fontWeight: '500',
                                      paddingLeft: '12px',
                                      paddingRight: '12px',
                                      height: '40px',
                                      borderRadius: '8px',
                                      flexGrow: 1,
                                  }}
                                  connectedText={'Execute'}
                                  disconnectedText="Execute"
                                  removeIconOnDisconnect={true}
                                  removeIconOnConnect={true}
                                  removeIconOnWalletSelect={true}
                                  removeIconOnConnecting={true}
                              />
                          </div>
                      )}
            </div>
        </div>
    );
}

export default ApplicationCardInAll;
