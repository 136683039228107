const DeleteUploadedDataIcon = () => {
    return (
        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4799_29692)">
                <path
                    d="M13.6517 9.73122L12.7678 8.84734L11 10.6151L9.23223 8.84734L8.34835 9.73122L10.1161 11.499L8.34835 13.2668L9.23223 14.1506L11 12.3829L12.7678 14.1506L13.6517 13.2668L11.8839 11.499L13.6517 9.73122ZM15.4194 7.07957C12.9843 4.64447 9.01568 4.64447 6.58058 7.07957C4.14548 9.51467 4.14548 13.4833 6.58058 15.9184C9.01568 18.3535 12.9843 18.3535 15.4194 15.9184C17.8545 13.4833 17.8545 9.51467 15.4194 7.07957ZM7.46447 15.0345C5.5155 13.0856 5.5155 9.91242 7.46447 7.96346C9.41343 6.01449 12.5866 6.01449 14.5355 7.96346C16.4845 9.91242 16.4845 13.0856 14.5355 15.0345C12.5866 16.9835 9.41343 16.9835 7.46447 15.0345Z"
                    fill="#8C8C8C"
                />
            </g>
            <defs>
                <clipPath id="clip0_4799_29692">
                    <rect width="15" height="15" fill="white" transform="translate(11 0.892578) rotate(45)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default DeleteUploadedDataIcon;
