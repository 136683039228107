import { useController, useFieldArray } from 'react-hook-form';

export const useStepTwoInputs = (control) => {
    const { field: maxAcceptancesPerUserField } = useController({
        name: 'maxAcceptancesPerUser',
        control,
        rules: {
            required: 'Please enter the maximum number of attempts',
            min: { value: 1, message: 'Please enter the number greater than or equal to 1' },
        },
    });

    const {
        fields: criteriaFields,
        append,
        prepend,
        remove,
        swap,
        // move,
        // remove,
    } = useFieldArray({
        name: 'criteria',
        control,
        rules: {
            required: 'Please add atleast 1 criteria',
        },
    });
    return { maxAcceptancesPerUserField, criteriaFields, append, remove, swap };
};
