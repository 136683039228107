import { useEffect, useRef, useState } from 'react';
import Overlay from '../../ui/Overlay';
import Button from '../../ui/Button';
import Feedbackclose from '../../assets/icons/Feedbackclose';
import { updateMembers } from '../../apis/oragnisations/organisation';
import { toast } from 'sonner';
import { useLoadingState } from '../../hooks/useLoader';
import Loader from '../../ui/Loader';
import { useNavigate } from 'react-router-dom';
import ModalBox from '../../ui/ModalBox';

function DeleteMemberModal({ close, member, orgId, onDeletion }) {
    const myRef = useRef({ close });
    const [bountiesInProject, setBountiesInProject] = useState([]);
    const { loading, startLoading, stopLoading } = useLoadingState();
    const navigate = useNavigate();
    useEffect(
        function () {
            function handleClick(e) {
                if (myRef.current && !myRef.current.contains(e.target)) {
                    close();
                }
            }

            document.addEventListener('click', handleClick, true);

            return () => document.removeEventListener('click', handleClick, true);
        },
        [close]
    );

    return (
        <Overlay>
            <div
                ref={myRef}
                className="absolute rounded-lg left-[50%]     bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
            >
                <ModalBox
                    close={close}
                    onSubmit={async () => {
                        const payload = {
                            organisationId: orgId,
                            members: [
                                {
                                    uid: member.uid,
                                    roles: [],
                                },
                            ],
                        };
                        startLoading();
                        const { success } = await updateMembers(payload);

                        if (success) {
                            onDeletion(member.uid);
                            stopLoading();
                            toast.success('Member removed successfully. ');
                            navigate(-1);
                            close();
                        }
                    }}
                    primaryBtnText={'Remove'}
                    loading={loading}
                    secondaryBtnText={'Cancel'}
                >
                    <>
                        Are you sure you want to <span className="font-bold"> remove the member {member.userName}</span>
                    </>
                </ModalBox>
            </div>
        </Overlay>
    );
}

export default DeleteMemberModal;
