function ShareIcon() {
    return (
        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.33333 8.48193C7.91111 8.48193 7.53333 8.66265 7.24445 8.94578L3.28333 6.44578C3.31111 6.30723 3.33333 6.16867 3.33333 6.0241C3.33333 5.87952 3.31111 5.74096 3.28333 5.60241L7.2 3.12651C7.5 3.42771 7.89444 3.61446 8.33333 3.61446C9.25556 3.61446 10 2.80723 10 1.80723C10 0.807229 9.25556 0 8.33333 0C7.41111 0 6.66667 0.807229 6.66667 1.80723C6.66667 1.95181 6.68889 2.09036 6.71667 2.22892L2.8 4.70482C2.5 4.40361 2.10556 4.21687 1.66667 4.21687C0.744445 4.21687 0 5.0241 0 6.0241C0 7.0241 0.744445 7.83133 1.66667 7.83133C2.10556 7.83133 2.5 7.64458 2.8 7.34337L6.75556 9.8494C6.72778 9.9759 6.71111 10.1084 6.71111 10.241C6.71111 11.2108 7.43889 12 8.33333 12C9.22778 12 9.95556 11.2108 9.95556 10.241C9.95556 9.27108 9.22778 8.48193 8.33333 8.48193ZM8.33333 1.20482C8.63889 1.20482 8.88889 1.4759 8.88889 1.80723C8.88889 2.13855 8.63889 2.40964 8.33333 2.40964C8.02778 2.40964 7.77778 2.13855 7.77778 1.80723C7.77778 1.4759 8.02778 1.20482 8.33333 1.20482ZM1.66667 6.62651C1.36111 6.62651 1.11111 6.35542 1.11111 6.0241C1.11111 5.69277 1.36111 5.42169 1.66667 5.42169C1.97222 5.42169 2.22222 5.69277 2.22222 6.0241C2.22222 6.35542 1.97222 6.62651 1.66667 6.62651ZM8.33333 10.8554C8.02778 10.8554 7.77778 10.5843 7.77778 10.253C7.77778 9.92169 8.02778 9.6506 8.33333 9.6506C8.63889 9.6506 8.88889 9.92169 8.88889 10.253C8.88889 10.5843 8.63889 10.8554 8.33333 10.8554Z"
                fill="#F9FAFB"
            />
        </svg>
    );
}

export default ShareIcon;
