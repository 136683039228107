import { EarthIcon, FistIcon, SunIcon, WaterIcon } from '../assets/icons/ProgressBarIcons';
import { toNormalRepresentation } from '../helpers/convertIntoDecimals';
import ProgressBar from './ProgressBar';

const colors = {
    Earth: 'bg-[#00BA34]',
    Energy: 'bg-[#FBCF4E]',
    Social: 'bg-[#BA2C2C]',
    Water: 'bg-[#76C8E8]',
};

const coreIcons = {
    Earth: <EarthIcon key="earth-icon" color="bg-foundryGreenL" />,
    Energy: <SunIcon key="sun-icon" color="bg-foundryYellowL" />,
    Social: <FistIcon key="fist-icon" color="bg-foundryRedL" />,
    Water: <WaterIcon key="wayer-icon" color="bg-foundryBlueL" />,
};
function ImpactFields({ className = '', coreWiseDistribution, isDollar = false }) {
    const total = coreWiseDistribution?.reduce((acc, obj) => acc + obj?.impactPoints, 0);
    return (
        <div className={`${className}`}>
            {coreWiseDistribution?.map((obj) => {
                return (
                    <div key={obj?.core} className="flex w-full  items-center gap-6">
                        <div className="pb-5">{coreIcons[obj?.core]}</div>
                        <div className="flex flex-1 items-center gap-4 pb-5 border-b border-borderColor ">
                            <p className="text-primaryInput w-14   ">{obj?.core}</p>
                            <ProgressBar color={colors[obj?.core]} width={(obj?.impactPoints / total) * 100 + '%'} />
                            <p className="w-20 text-right text-primaryInput font-medium  ">
                                {isDollar
                                    ? `$${toNormalRepresentation(obj?.impactPoints)} `
                                    : toNormalRepresentation(obj?.impactPoints) + ' IP'}
                            </p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default ImpactFields;
