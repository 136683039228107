import { Outlet } from 'react-router-dom';
// import AllOrganizations from '../features/Organization/AllOrganizations';

function Organizations() {
    return (
        <div className="p-9">
            <Outlet />
        </div>
    );
}

export default Organizations;
