import { createContext, useContext, useState } from 'react';

const ElementHiderContext = createContext();

const ElementHiderProvider = ({ children }) => {
    const [hiderClass, setHiderClass] = useState('');
    // const [centerTopHeader, setCenterTopHeader] = useState(false);
    const handleSetHiderClass = (value) => {
        setHiderClass(value);
    };
    // const handleSetTopHeader=(value)
    return (
        <ElementHiderContext.Provider value={{ hiderClass, handleSetHiderClass }}>
            {children}
        </ElementHiderContext.Provider>
    );
};

const useElementHider = () => {
    return useContext(ElementHiderContext);
};

export { useElementHider, ElementHiderProvider };
