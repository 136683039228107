import { useOutletContext } from 'react-router-dom';
import LeftSectionForExternalUser from '../features/Organization/organizationhomepage/LeftSectionForExternalUser';
import RightSectionForExternalUser from '../features/Organization/organizationhomepage/RightSectionForExternalUser';

const OrganisationPublicView = () => {
    const [, , , , fundsAllocated] = useOutletContext();
    return (
        <div className=" grid grid-cols-2 gap-9">
            <LeftSectionForExternalUser />
            {/* <div>Right view is coming</div> */}
            <RightSectionForExternalUser fundsAllocated={fundsAllocated} />
        </div>
    );
};

export default OrganisationPublicView;
