function ArrowRight({ w = '28', h = '28' }) {
    return (
        <svg width={w} height={h} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.13454 4.39801L9.19954 2.33301L20.8662 13.9997L9.19954 25.6663L7.13454 23.6013L16.7362 13.9997L7.13454 4.39801Z"
                fill="#B8B8B8"
            />
        </svg>
    );
}

export default ArrowRight;
