import React, { useState, useEffect } from 'react';
import AddProfileImage from '../../assets/icons/AddProfileImage';
import Input from '../../ui/Input';
import Info from '../../assets/icons/Info';
import { uploader } from '../../helpers/uploader';
import { toast } from 'sonner';
import { useLoadingState } from '../../hooks/useLoader';
import Loader from '../../ui/Loader';
import Button from '../../ui/Button';
import Feedbackclose from '../../assets/icons/Feedbackclose';

const UploadLogo = ({ fieldName, errorField, errors, isUploadEnable = true, outerPadding = 'p-6' }) => {
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [uploadPicture, setUploadPicture] = useState({
        image: null,
        imageURL: '',
    });

    useEffect(() => {
        // Clean up the URL when component unmounts or when image changes
        return () => {
            if (uploadPicture.imageURL) {
                URL.revokeObjectURL(uploadPicture.imageURL);
            }
        };
    }, [uploadPicture.imageURL]);

    useEffect(() => {
        // When isUploadEnable is false, directly update the field after setting the picture
        if (!isUploadEnable && uploadPicture.image) {
            fieldName.onChange(uploadPicture.image);
        }
    }, [isUploadEnable, uploadPicture.image, fieldName]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageURL = URL.createObjectURL(file);

            // Update the state with the selected image and URL to trigger a re-render
            setUploadPicture({
                image: file,
                imageURL: imageURL,
            });
        } else {
            // Reset state if no file is selected
            setUploadPicture({ image: null, imageURL: '' });
            fieldName.onChange(''); // Clear field if no file selected
        }
    };

    const handleUploadClick = async () => {
        startLoading();
        const res = await uploader(uploadPicture.image);
        if (res.files.length) {
            fieldName.onChange(res.files[0]); // Update field with the uploaded file
            toast.success('Logo uploaded. ');
        } else {
            toast.error('Sorry unable to upload logo');
        }
        stopLoading();
    };

    return (
        <div>
            <div className={`${outerPadding} bg-white flex flex-col text-uploadText items-center shadow-sm rounded-md`}>
                <div className="border relative flex flex-col gap-2 border-borderColor border-dashed w-full p-6 rounded-md">
                    {uploadPicture.imageURL && (
                        <Button
                            onClick={() => {
                                fieldName.onChange('');
                                setUploadPicture({ image: '', imageURL: '' });
                            }}
                            className="border-0 absolute top-3 right-4"
                            variant="tertiary"
                            size="circle"
                        >
                            <Feedbackclose />
                        </Button>
                    )}
                    {!uploadPicture.imageURL && (
                        <>
                            <label className="flex flex-col gap-2 items-center" htmlFor="avatar">
                                <AddProfileImage />
                                <p>Click to choose file</p>
                            </label>
                            <Input {...fieldName} onChange={handleFileChange} type="file" />
                        </>
                    )}
                    {uploadPicture.imageURL && (
                        <img
                            className="w-20 h-20 rounded-md object-center object-cover"
                            src={uploadPicture.imageURL}
                            alt="profile preview"
                            onError={() => setUploadPicture((prev) => ({ ...prev, imageURL: '' }))}
                        />
                    )}
                    {!uploadPicture.imageURL && (
                        <div className="flex gap-2 justify-center items-center">
                            <Info />
                            <p className="font-semibold">Max file size: 8 MB; Ideal dimensions: 75x75 px</p>
                        </div>
                    )}
                    {isUploadEnable && uploadPicture.image && (
                        <Button
                            className={`mt-4 ${fieldName.value ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                            variant="tertiary"
                            type="button"
                            disabled={fieldName.value}
                            onClick={handleUploadClick}
                        >
                            {fieldName.value ? 'Uploaded' : loading ? <Loader /> : 'Upload'}
                        </Button>
                    )}
                </div>
            </div>

            {errors[errorField]?.message && <p className="text-foundryRed mt-1">{errors[errorField]?.message}</p>}
        </div>
    );
};

export default UploadLogo;
