import Slider from '../../ui/Slider';
import Table from '../../ui/Table';
import { microtaskTypeData } from '../bounties/microtasktypes/microtaskTypeData';

const QuestionAndAnswerSD = ({ dataTask }) => {
    const titleObject = dataTask?.task.find((obj) => Object.keys(obj).includes('Title'));
    const questionObject = dataTask?.task.find((obj) => Object.keys(obj).includes('heading'));
    const imageObject = dataTask?.task.find((obj) => Object.keys(obj).includes('image'));

    return (
        <Table.Row>
            <p className=" text-lightBlack flex flex-col   ">
                <span className="font-medium mb-1  "> {titleObject?.Title || '-'} </span>
                <span className="text-xs font-medium mb-1">{questionObject?.heading || '-'}</span>
                {imageObject?.image && <img className="w-[100%] h-32 rounded-md" src={imageObject.image} alt="" />}
            </p>
            <p className="text-sm justify-self-center">
                {microtaskTypeData.find((ele) => ele.type === dataTask.actionType)?.label}
            </p>

            <div>
                <p className=" text-lightBlack mb-1 ">{dataTask?.submission?.answer.join(' ')}</p>
            </div>
        </Table.Row>
    );
};

export default QuestionAndAnswerSD;
