import { useContext, useRef } from 'react';
import ArrowLeft from '../../../assets/icons/ArrowLeft';
import ArrowRight from '../../../assets/icons/ArrowRight';
import Button from '../../../ui/Button';
import FlipCard from '../../../ui/FlipCard';
import Heading from '../../../ui/Heading';
import WhiteCard from '../../../ui/WhiteCard';

import { ProfileContext } from '../../../contexts/ProfileContext';
import NoDataAvailable from '../../../ui/NoDataAvailable';
import MintRequestedCertificate from './MintRequestedFront';
import MintRequestedBack from './MintRequestedBack';
import UnclaimedCard from '../unclaimed/UnclaimedCard';

function AllMintRequested() {
    const { state } = useContext(ProfileContext);
    const mintRequestedIcs = state.filter((ic) => ic.status === 'MINT_REQUESTED');
    const scrollCards = useRef();
    function scrollR() {
        scrollCards.current.scrollBy({
            left: 334,
            behavior: 'smooth',
        });
    }
    function scrollL() {
        scrollCards.current.scrollBy({
            left: -334,
            behavior: 'smooth',
        });
    }
    return (
        <div className="mt-8">
            <Heading className={`text-secondaryText leading-10`} type={'medium'}>
                Mint Requested
            </Heading>
            <div className="relative">
                <WhiteCard
                    verticalMargin=""
                    ref={scrollCards}
                    className="p-5  flex items-center overflow-auto   scrollableCards"
                >
                    {mintRequestedIcs.length > 0 ? (
                        mintRequestedIcs.map((ic) => {
                            return (
                                <FlipCard
                                    width="w-[346px]"
                                    height="h-[378px]"
                                    fixW="w-[346px]"
                                    key={ic.id + ic.status}
                                    image={ic.image}
                                    certificateId={ic.id}
                                    FrontCard={ic.image ? MintRequestedCertificate : UnclaimedCard}
                                    BackCard={MintRequestedBack}
                                />
                            );
                        })
                    ) : (
                        <div className="flex w-full items-center justify-center">
                            <NoDataAvailable marginTop="mt-3" />
                        </div>
                    )}
                </WhiteCard>

                <Button
                    onClick={scrollL}
                    className="bg-white absolute top-[50%] translate-y-[-50%]  left-4 "
                    variant="tertiary"
                    size="circle"
                >
                    <ArrowLeft />
                </Button>

                <Button
                    onClick={scrollR}
                    className="bg-white absolute top-[50%] translate-y-[-50%]  right-4 "
                    variant="tertiary"
                    size="circle"
                >
                    <ArrowRight />
                </Button>
            </div>
        </div>
    );
}

export default AllMintRequested;
