function IssuedIcon() {
    return (
        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.19 0.1725L1.19 3.2825C0.47 3.6025 0 4.3225 0 5.1125V9.8125C0 15.3625 3.84 20.5525 9 21.8125C14.16 20.5525 18 15.3625 18 9.8125V5.1125C18 4.3225 17.53 3.6025 16.81 3.2825L9.81 0.1725C9.3 -0.0575 8.7 -0.0575 8.19 0.1725ZM6.29 15.1025L3.7 12.5125C3.31 12.1225 3.31 11.4925 3.7 11.1025C4.09 10.7125 4.72 10.7125 5.11 11.1025L7 12.9825L12.88 7.1025C13.27 6.7125 13.9 6.7125 14.29 7.1025C14.68 7.4925 14.68 8.1225 14.29 8.5125L7.7 15.1025C7.32 15.4925 6.68 15.4925 6.29 15.1025Z"
                fill="#36B67A"
            />
        </svg>
    );
}

export default IssuedIcon;
