export const ToRead = ({ ...props }) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="3" width="20" height="20" rx="1" stroke="#747474" strokeWidth="2" />
            <circle cx="20" cy="4" r="3.5" fill="#747474" stroke="white" />
            <mask
                id="path-3-outside-1_4917_22577"
                maskUnits="userSpaceOnUse"
                x="3"
                y="7"
                width="15"
                height="12"
                fill="black"
            >
                <rect fill="white" x="3" y="7" width="15" height="12" />
                <path d="M17.125 9.0575L8.125 18.0575L4 13.9325L5.0575 12.875L8.125 15.935L16.0675 8L17.125 9.0575Z" />
            </mask>
            <path
                d="M17.125 9.0575L8.125 18.0575L4 13.9325L5.0575 12.875L8.125 15.935L16.0675 8L17.125 9.0575Z"
                fill="#747474"
            />
            <path
                d="M17.125 9.0575L17.3371 9.26963C17.4543 9.15247 17.4543 8.96253 17.3371 8.84537L17.125 9.0575ZM8.125 18.0575L7.91287 18.2696L8.125 18.4818L8.33713 18.2696L8.125 18.0575ZM4 13.9325L3.78787 13.7204C3.67071 13.8375 3.67071 14.0275 3.78787 14.1446L4 13.9325ZM5.0575 12.875L5.26937 12.6626C5.15217 12.5457 4.96242 12.5458 4.84537 12.6629L5.0575 12.875ZM8.125 15.935L7.91313 16.1474L8.12516 16.3589L8.33703 16.1472L8.125 15.935ZM16.0675 8L16.2796 7.78787C16.1625 7.67075 15.9726 7.6707 15.8555 7.78777L16.0675 8ZM16.9129 8.84537L7.91287 17.8454L8.33713 18.2696L17.3371 9.26963L16.9129 8.84537ZM8.33713 17.8454L4.21213 13.7204L3.78787 14.1446L7.91287 18.2696L8.33713 17.8454ZM4.21213 14.1446L5.26963 13.0871L4.84537 12.6629L3.78787 13.7204L4.21213 14.1446ZM4.84563 13.0874L7.91313 16.1474L8.33687 15.7226L5.26937 12.6626L4.84563 13.0874ZM8.33703 16.1472L16.2795 8.21223L15.8555 7.78777L7.91297 15.7228L8.33703 16.1472ZM15.8554 8.21213L16.9129 9.26963L17.3371 8.84537L16.2796 7.78787L15.8554 8.21213Z"
                fill="#747474"
                mask="url(#path-3-outside-1_4917_22577)"
            />
        </svg>
    );
};

export const ToUnread = ({ ...props }) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="3" width="20" height="20" rx="1" stroke="#747474" strokeWidth="2" />
            <circle cx="20" cy="4" r="3.5" fill="#747474" stroke="white" />
        </svg>
    );
};

export const ToDelete = ({ ...props }) => {
    return (
        <svg {...props} width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.21429 19.5556C1.21429 20.2039 1.47015 20.8256 1.9256 21.284C2.38104 21.7425 2.99876 22 3.64286 22H13.3571C14.0012 22 14.619 21.7425 15.0744 21.284C15.5298 20.8256 15.7857 20.2039 15.7857 19.5556V4.88889H1.21429V19.5556ZM3.64286 7.33333H13.3571V19.5556H3.64286V7.33333ZM12.75 1.22222L11.5357 0H5.46429L4.25 1.22222H0V3.66667H17V1.22222H12.75Z"
                fill="#747474"
            />
        </svg>
    );
};
