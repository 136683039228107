export const formatIntDateAndThenTime = (dateString) => {
    const date = new Date(dateString);

    // Get the formatted date (e.g., "Jun 24, 2024")
    const formattedDate = date.toLocaleDateString('en-US', {
        month: 'short', // "Jun"
        day: 'numeric', // "24"
        year: 'numeric', // "2024"
    });

    // Get the formatted time (e.g., "11:30 AM")
    const formattedTime = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true, // Ensure 12-hour format with AM/PM
    });

    // Combine date and time with separator
    return `${formattedDate} | ${formattedTime}`;
};
