import Overlay from '../../ui/Overlay';
import ModalBox from '../../ui/ModalBox';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const CancelBountyModal = ({ close }) => {
    const myRef = useRef({ close });
    const navigate = useNavigate();

    useEffect(
        function () {
            function handleClick(e) {
                if (myRef.current && !myRef.current.contains(e.target)) {
                    close();
                }
            }

            document.addEventListener('click', handleClick, true);

            return () => document.removeEventListener('click', handleClick, true);
        },

        [close]
    );

    return (
        <Overlay>
            <div
                ref={myRef}
                className="absolute rounded-lg left-[50%]     bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
            >
                <ModalBox
                    close={close}
                    onSubmit={() => {
                        navigate(-1);
                    }}
                    primaryBtnText={'Yes'}
                    loading={false}
                    secondaryBtnText={'Cancel'}
                >
                    <>
                        Are you sure you want to <span className="font-bold">discard this bounty</span>? Progress will
                        not be saved.
                    </>
                </ModalBox>
            </div>
        </Overlay>
    );
};

export default CancelBountyModal;
