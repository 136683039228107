export const copyToClipboard = (text) => {
    navigator.clipboard
        .writeText(text)
        .then(() => {
            console.log('Text copied to clipboard');
        })
        .catch((error) => {
            console.error('Failed to copy text: ', error);
        });
};
