export const superpowers = {
    engineering: [
        'Cartographer',
        'Civil Engineer',
        'Environmental Engineer',
        'Green Architect',
        'Green Mobility Specialist',
        'Mechanical Engineer',
        'Renewable Energy Engineer',
        'Software Engineer',
        'Waste Management Expert',
        'Water Specialist',
    ],
    media: [
        'Artist, Musician And Performer',
        'Content Creator',
        'Cultural Preservation Specialist',
        'Event Planner',
        'Historian',
        'Journalist',
        'Librarian',
        'Writer And Documentarian',
    ],
    business: [
        'Dipolomat And International Relation Expert',
        'Eco-entrepreneur',
        'Economist',
        'Ecotourism Specialist',
        'Environmental Lawyer',
        'Financial Analyst',
        'Fundraiser',
        'Grant/proposal Writer',
        'Hospitality Professional',
        'Human Rights Advocate',
        'Lobbyist',
        'Logistics Professional',
        'Marketer',
        'Policy Analyst',
        'Retailer',
        'Supply Chain Manager',
    ],
};
