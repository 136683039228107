function TopInLead({ color = '#C27803', className = '' }) {
    return (
        <svg
            className={`${className}`}
            width="17"
            height="23"
            viewBox="0 0 17 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.08366 12.7396L8.50033 10.9062L10.9066 12.7396L9.98991 9.77083L12.4066 7.85417H9.44824L8.50033 4.92708L7.55241 7.85417H4.59408L7.00033 9.77083L6.08366 12.7396ZM16.8337 8.89583C16.8337 4.29167 13.1045 0.5625 8.50033 0.5625C3.89616 0.5625 0.166992 4.29167 0.166992 8.89583C0.166992 11.0104 0.958659 12.9271 2.25033 14.3958V22.4375L8.50033 20.3542L14.7503 22.4375V14.3958C16.042 12.9271 16.8337 11.0104 16.8337 8.89583ZM8.50033 2.64583C11.9482 2.64583 14.7503 5.44792 14.7503 8.89583C14.7503 12.3437 11.9482 15.1458 8.50033 15.1458C5.05241 15.1458 2.25033 12.3437 2.25033 8.89583C2.25033 5.44792 5.05241 2.64583 8.50033 2.64583ZM8.50033 18.2708L4.33366 19.3333V16.1042C5.56283 16.8125 6.97949 17.2292 8.50033 17.2292C10.0212 17.2292 11.4378 16.8125 12.667 16.1042V19.3333L8.50033 18.2708Z"
                fill={color}
            />
        </svg>
    );
}
export default TopInLead;
