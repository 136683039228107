import { useEffect, useState } from 'react';
import SortIcon from '../../assets/icons/SortIcon';
import Loader from '../../ui/Loader';
import NoDataAvailable from '../../ui/NoDataAvailable';
import StatsCard from '../../ui/StatsCard';
import Table from '../../ui/Table';
import WhiteCard from '../../ui/WhiteCard';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Tag from '../../ui/Tag';
import { dmyFormat } from '../../helpers/dateInDayMonthYearFormat';
import { getAttester } from '../../apis/attester/attester';

function AttestationOverview() {
    const [attestations, setAttestations] = useState([]);
    // const [isShowProfile, setIsShowProfile] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [attester, setAttester] = useOutletContext();
    const { id } = useParams();
    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    setIsLoading(true);
                    const data = await getAttester();

                    if (Object.keys(data).length) {
                        setAttester(data);
                    }
                    if (data === 404 || data?.status !== 'APPROVED') navigate('createAttester');
                    // setUnvalidatedBounties(data);
                    setIsLoading(false);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [id]
    );

    return (
        <div>
            {isLoading ? (
                <WhiteCard className="min-h-[20vh] flex justify-center items-center">
                    <Loader />
                </WhiteCard>
            ) : (
                <>
                    <div className="flex items-center gap-4">
                        <WhiteCard className="w-fit p-8 ">
                            <p className="font-semibold mb-2">Bounties</p>
                            <div className="flex items-center gap-4 ">
                                <StatsCard countHeading={'Current Toral'} count={20} />
                                <StatsCard countHeading={'Pending Validation'} count={20} />
                            </div>
                        </WhiteCard>

                        <WhiteCard className="w-fit p-8 ">
                            <p className="font-semibold mb-2">Submissions</p>
                            <div className="flex items-center gap-6">
                                <StatsCard countHeading={'Pending Validation'} count={20} />
                            </div>
                        </WhiteCard>
                    </div>
                    <Table className=" place-items-center gap-4 " cols="grid-cols-tableValidationOverview">
                        {/* <Input
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                    placeholder="Search"
                    className="w-[40%]  mb-5 "
                    type="text"
                /> */}
                        <Table.Header>
                            <p className="text-sm   font-semibold text-secondaryText flex items-center gap-1   ">
                                {' '}
                                <span>Bounty ID</span>{' '}
                                <span>
                                    <SortIcon />
                                </span>{' '}
                            </p>
                            <p className="text-sm   font-semibold text-secondaryText flex items-center gap-1    ">
                                {' '}
                                <span> Bounty Title </span>{' '}
                                <span>
                                    <SortIcon />
                                </span>{' '}
                            </p>
                            <p className="text-sm   font-semibold text-secondaryText flex items-center gap-1    ">
                                {' '}
                                <span> Bounty Status </span>{' '}
                                <span>
                                    <SortIcon />
                                </span>{' '}
                            </p>
                            <p className="text-sm   font-semibold text-secondaryText flex items-center gap-1    ">
                                {' '}
                                <span> Project </span>{' '}
                                <span>
                                    <SortIcon />
                                </span>{' '}
                            </p>
                            <p className="text-sm font-semibold text-secondaryText flex items-center gap-1    ">
                                {' '}
                                <span> Validations </span>{' '}
                                <span>
                                    <SortIcon />
                                </span>{' '}
                            </p>
                            <p className="text-sm  font-semibold text-secondaryText flex items-center gap-1    ">
                                {' '}
                                <span>Start Date </span>{' '}
                                <span>
                                    <SortIcon />
                                </span>{' '}
                            </p>
                            <p className="text-sm  font-semibold text-secondaryText flex items-center gap-1    ">
                                {' '}
                                <span>Expiry Date </span>{' '}
                                <span>
                                    <SortIcon />
                                </span>{' '}
                            </p>
                        </Table.Header>
                        {attestations.length ? (
                            attestations.map((overviewrow) => (
                                <Table.Row>
                                    <p className="  truncate w-full uppercase justify-self-start   ">
                                        {overviewrow._id}
                                    </p>
                                    <p
                                        // onClick={() => {
                                        //     handleBountyDetails({
                                        //         title: overviewrow.title,
                                        //         projectTitle: overviewrow.projectTitle,
                                        //         startDate: overviewrow.startTimestamp,
                                        //         endDate: overviewrow.expirationTimestamp,
                                        //         totalSubmissions: unvalidatedBounties.length,
                                        //         status: overviewrow.status,
                                        //     });
                                        // navigate(`bounty-submissions/${overviewrow._id}`);
                                        // }}
                                        className=" cursor-pointer text-primaryColor"
                                    >
                                        {overviewrow.title}
                                    </p>
                                    <Tag
                                        rounded="rounded-lg "
                                        className="px-2 py-1 w-fit leading-3   "
                                        textClassName="text-xs  font-semibold"
                                        bg="bg-foundryYellowL"
                                        textColor="text-foundryYellow"
                                        text={overviewrow.status}
                                    />
                                    <p
                                        onClick={() => {
                                            navigate(`/organizations/${id}/view-projects/${overviewrow.projectId}`);
                                        }}
                                        className="cursor-pointer text-primaryColor"
                                    >
                                        {overviewrow.projectTitle}
                                    </p>
                                    <p className=" text-lightBlack  ">{overviewrow.validatedSubmissionCount}/233</p>
                                    <p className=" text-lightBlack  ">{dmyFormat(overviewrow.startTimestamp)}</p>
                                    <p className=" text-lightBlack  ">{dmyFormat(overviewrow.expirationTimestamp)}</p>
                                </Table.Row>
                            ))
                        ) : (
                            <NoDataAvailable />
                        )}
                    </Table>
                </>
            )}
        </div>
    );
}

export default AttestationOverview;
