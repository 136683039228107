import { useController } from 'react-hook-form';

export const useStepOneInputs = (control) => {
    const { field: titleField } = useController({
        name: 'title',
        control,
        rules: {
            required: 'Please enter a title',
            maxLength: {
                value: 40,
                message: 'The maximum length is 40 characters',
            },
        },
    });
    const { field: descriptionField } = useController({
        name: 'bountyDescription',
        control,
        rules: {
            required: 'Please enter a description',
            maxLength: {
                value: 1000,
                message: 'The maximum length is 1,000 characters',
            },
        },
    });
    const { field: projectIdField } = useController({
        name: 'projectId',
        control,
        // rules: { required: 'Project is required' },
    });
    const { field: typeField } = useController({
        name: 'type',
        control,
        rules: { required: 'Please choose a bounty type' },
    });

    const { field: dominantCoreField } = useController({
        name: 'dominantCore',
        control,
        rules: { required: 'Please choose a dominant core' },
    });
    const { field: modeField } = useController({
        name: 'mode',
        control,
    });
    return { titleField, descriptionField, projectIdField, typeField, dominantCoreField, modeField };
};
