import React, { useState } from 'react';
import { useController } from 'react-hook-form';
import { toast } from 'sonner';
import { useLoadingState } from '../hooks/useLoader';
import DeleteUploadedDataIcon from '../assets/icons/DeleteUploadedDataIcon';
import { uploader } from '../helpers/uploader';

const InputForUploadingPDFWithLimit = ({
    name,
    control,
    errors,
    error,
    placeholder = 'criteria',
    width = 'w-full',
    type = 'file',
    uploadError,
    setUploadError,
    isUploaded,
    validateObject = {},
}) => {
    const [selectedFile, setSelectedFile] = useState(null);

    const { loading, startLoading, stopLoading } = useLoadingState();
    const { field } = useController({
        name,
        control,
        rules: validateObject,
    });

    const handleFileChange = async (event) => {
        const file = event.target.files[0]; // Get the selected file
        const maxFileSize = 8 * 1024 * 1024; // 8MB in bytes

        // Validate file size
        if (file && file.size > maxFileSize) {
            setUploadError('The maximum file size you can upload is 8MB');
            return; // Prevent upload
        }

        setUploadError(''); // Clear previous errors

        // Validate file type
        if (file && file.type === 'application/pdf') {
            setSelectedFile(file);
            await uploadFile(file);
        } else {
            setUploadError('Please select a valid PDF file.');
        }
    };

    const uploadFile = async (file) => {
        try {
            startLoading();
            const res = await uploader(file, 'doc'); // Assuming `uploader` handles file uploads
            if (res.files.length) {
                field.onChange(res.files[0]); // Update the field value with the file link
                toast.success('File is successfully uploaded');
            } else {
                toast.error('Sorry, unable to upload the file');
            }
            stopLoading();
        } catch (error) {
            console.error('Error uploading file:', error);
            setUploadError('File upload failed.');
        }
    };

    return (
        <>
            {isUploaded ? (
                <button
                    onClick={() => field.onChange('')}
                    type="button"
                    className="bg-white w-32 flex items-center border border-dashed rounded-md border-borderColor shadow-sm px-4 py-2"
                >
                    <span className="truncate w">{isUploaded}</span>
                    <span>
                        <DeleteUploadedDataIcon />
                    </span>
                </button>
            ) : (
                <>
                    <label className="flex flex-col gap-2 items-center" htmlFor="docFile">
                        <p className="bg-white border cursor-pointer border-dashed rounded-md border-borderColor shadow-sm px-4 py-2">
                            Click to Upload
                        </p>
                    </label>
                    <input
                        {...field}
                        value={field.value}
                        onChange={handleFileChange} // Updated to include file size validation
                        type="file"
                        id="docFile"
                        name="docFile"
                        accept="application/pdf"
                        className="hidden"
                    />

                    {error && <p className="text-foundryRed">{error}</p>}
                </>
            )}
        </>
    );
};

export default InputForUploadingPDFWithLimit;
