import { useContext, useState } from 'react';
import Heading from '../../ui/Heading';
import SubHeading from '../../ui/SubHeading';
import { BountiesContext } from '../../contexts/BountiesContext';

const navData = ['Brief', 'Criteria', 'Microtasks', 'Config', 'Backer', 'Rewards'];

function BountiesSideBar({ step, setStep, completedSteps, setCompletedSteps }) {
    const handleStepClick = (targetStep) => {
        // Allow navigation only to completed steps or the current step
        if (completedSteps.includes(targetStep) || targetStep === step) {
            setStep(targetStep);

            // If going back to a previous step, remove steps after it from completedSteps
            if (targetStep < step) {
                setCompletedSteps((prev) => prev.filter((completedStep) => completedStep < targetStep));
            }
        }
    };
    return (
        <aside className="pt-14">
            {/* <Heading className={'text-lightBlack font-medium mb-10 '} type={'large'}>
                Project
            </Heading> */}

            <SubHeading className={'uppercase text-secondaryText mb-4 mt-14  '} type={'xsmall'}>
                Bounty Steps
            </SubHeading>

            <ul>
                {navData.map((nav, index) => {
                    const targetStep = index + 1;
                    const isStepAccessible = completedSteps.includes(targetStep) || targetStep === step;
                    return (
                        <li
                            onClick={() => handleStepClick(targetStep)}
                            className={`text-sm cursor-pointer mb-1 ${
                                step === index + 1 ? 'text-primaryColor' : 'text-secondaryText'
                            }  font-bold capitalize `}
                            key={nav + index}
                        >
                            <span>{index + 1}.</span> <span>{nav}</span>
                        </li>
                    );
                })}
            </ul>
        </aside>
    );
}

export default BountiesSideBar;
