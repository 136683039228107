import { useNavigate } from 'react-router-dom';
import BackButton from '../assets/icons/BackButton';
import Heading from '../ui/Heading';
import AllClaimedCards from '../features/gallery/claimed/AllClaimedCards';
import AllUnclaimedCards from '../features/gallery/unclaimed/AllUnclaimedCards';
import AllMintedCards from '../features/gallery/minted/AllMintedCards';
import { useContext, useEffect, useState } from 'react';
import { getAllCertificates } from '../apis/impactCertificates/impactCertificates';
import { useLoadingState } from '../hooks/useLoader';
import { ProfileContext } from '../contexts/ProfileContext';
import AllMintRequested from '../features/gallery/mintRequested/AllMintRequested';

const displayComponents = [<AllUnclaimedCards />, <AllClaimedCards />, <AllMintedCards />, <AllMintRequested />];
let filterComponents = displayComponents;
function Gallery() {
    const navigate = useNavigate();
    const [viewGallery, setViewGallery] = useState('all');
    const { state } = useContext(ProfileContext);
    const [sortBy, setSortBy] = useState('latest');
    const handleSetViewGallery = (gallery) => {
        setViewGallery(gallery);
    };

    if (viewGallery === 'all') {
        filterComponents = displayComponents.slice(0, displayComponents.length);
    }
    if (viewGallery === 'claimed') {
        filterComponents = displayComponents.slice(1, 2);
    }
    if (viewGallery === 'unclaimed') {
        filterComponents = displayComponents.slice(0, 1);
    }
    if (viewGallery === 'minted') {
        filterComponents = displayComponents.slice(2, 3);
    }
    if (viewGallery === 'mintrequested') {
        filterComponents = displayComponents.slice(3, 4);
    }

    return (
        <>
            <div className="flex items-center">
                <BackButton onClick={() => navigate(-1)} />
                <Heading type={'pageHeading'} className={'text-lightBlack ml-3'}>
                    Impact Certificate
                </Heading>
            </div>
            <p className="text-lg text-secondaryText mt-5">
                Your personal gallery of impact on <span className="font-semibold">Humanity</span> and planet{' '}
                <span className="font-semibold"> Earth</span>.{' '}
            </p>
            <div className="flex items-center gap-9 my-8 ">
                {/* <div className="flex">
                    <p className="font-semibold">Sort By : </p> &nbsp;
                    <p
                        className="cursor-pointer "
                        onClick={() => {
                            setSortBy('latest');
                        }}
                    >
                        <span className={` ${sortBy === 'latest' ? 'text-primaryColor' : ''} `}>Latest</span>&nbsp;
                    </p>{' '}
                    <span>| &nbsp;</span>
                    <p
                        className="cursor-pointer"
                        onClick={() => {
                            setSortBy('oldest');
                        }}
                    >
                        <span className={` ${sortBy === 'oldest' ? 'text-primaryColor' : ''} `}>Oldest</span>
                        &nbsp;
                    </p>
                </div> */}
                <div className="flex">
                    <p className="font-semibold">View : </p> &nbsp;
                    <p className="cursor-pointer " onClick={() => handleSetViewGallery('all')}>
                        <span className={` ${viewGallery === 'all' ? 'text-primaryColor' : ''} `}>All</span>&nbsp;
                    </p>{' '}
                    <span>| &nbsp;</span>
                    <p className="cursor-pointer" onClick={() => handleSetViewGallery('unclaimed')}>
                        <span className={` ${viewGallery === 'unclaimed' ? 'text-primaryColor' : ''} `}>Unclaimed</span>
                        &nbsp;
                    </p>
                    <span>| &nbsp;</span>
                    <p className="cursor-pointer" onClick={() => handleSetViewGallery('claimed')}>
                        <span className={` ${viewGallery === 'claimed' ? 'text-primaryColor' : ''} `}> Claimed </span>
                    </p>
                    <span> &nbsp; | &nbsp;</span>
                    <p className="cursor-pointer" onClick={() => handleSetViewGallery('minted')}>
                        <span className={` ${viewGallery === 'minted' ? 'text-primaryColor' : ''} `}> Minted</span>
                    </p>
                    <span> &nbsp; | &nbsp;</span>
                    <p className="cursor-pointer" onClick={() => handleSetViewGallery('mintrequested')}>
                        <span className={` ${viewGallery === 'mintrequested' ? 'text-primaryColor' : ''} `}>
                            {' '}
                            Mint Requested{' '}
                        </span>
                    </p>
                </div>
            </div>
            {filterComponents}
        </>
    );
}
export default Gallery;
