import { useEffect, useState } from 'react';
import { getRequestsToJoinOrg } from '../../apis/oragnisations/organisation';
import { useParams } from 'react-router-dom';
import NoDataAvailable from '../../ui/NoDataAvailable';
import Modal from '../../ui/Modal';
import Table from '../../ui/Table';
import Loader from '../../ui/Loader';
import AvatarImage from '../../ui/AvatarImage';
import Tag from '../../ui/Tag';

import AcceptRequestIcon from '../../assets/icons/AcceptRequestIcon';
import DeclineRequestIcon from '../../assets/icons/DeclineRequestIcon';
import RequestModal from './RequestModal';

import TextWithTooltipOnClick from '../../ui/TextWithTooltipOnClick';
import { formatIntDateAndThenTime } from '../../helpers/formatInDateAndThenTime';
import { dateToIsoString } from '../../helpers/dateToIsoString';
import { dmyFormat } from '../../helpers/dateInDayMonthYearFormat';

function RequestList({ organisation, user }) {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [requests, setRequests] = useState([]);
    useEffect(function () {
        const fetchData = async () => {
            try {
                const data = await getRequestsToJoinOrg(id);
                setRequests(data);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);
    const onAction = (id) => {
        setRequests((initial) => initial.filter((member) => member._id !== id));
    };

    return (
        <>
            <Table cols="grid-cols-tableRequests">
                <Table.Header>
                    <p className="text-sm font-semibold text-secondaryText ">Member Username</p>
                    <p className="text-sm font-semibold text-secondaryText ">Interested Roles </p>
                    <p className="text-sm  font-semibold text-secondaryText ">Requested On</p>
                    <p className="text-sm  font-semibold text-secondaryText ">Message</p>
                    <p className="text-sm  font-semibold text-secondaryText ">Remove</p>
                </Table.Header>
                {isLoading ? (
                    <div className="min-h-[20vh] flex justify-center items-center">
                        <Loader />
                    </div>
                ) : requests.length ? (
                    requests.map((member) => {
                        return (
                            <Table.Row key={member._id}>
                                <div className="flex items-center gap-1 ">
                                    <AvatarImage w="w-8" h="h-8" url={member.requesterProfileImage} />
                                    <p className="text-lightBlack font-medium ">{member.requesterUsername}</p>
                                </div>
                                <div className="flex items-center  gap-6 ">
                                    {member.roles.includes('BOUNTY_VALIDATOR') && (
                                        <Tag
                                            rounded="rounded-lg "
                                            className="px-2 py-1 leading-3   "
                                            textClassName="text-xs font-semibold"
                                            bg="bg-foundryGreenL"
                                            textColor="text-foundryGreen"
                                            text={'Validator'}
                                        />
                                    )}
                                    {member.roles.includes('BOUNTY_MANAGER') && (
                                        <Tag
                                            rounded="rounded-lg"
                                            className="px-2 py-1 leading-3   "
                                            textClassName="text-xs font-semibold"
                                            bg="bg-foundryRedL"
                                            textColor="text-foundryRed"
                                            text={'Bounty Manager'}
                                        />
                                    )}
                                    {member.roles.includes('TREASURER') && (
                                        <Tag
                                            rounded="rounded-lg"
                                            className="px-2 py-1 leading-3   "
                                            textClassName="text-xs font-semibold"
                                            bg="bg-treasurerBg"
                                            textColor="text-treasurerText"
                                            text={'Treasurer'}
                                        />
                                    )}
                                </div>
                                <p className="self-center">{dmyFormat(member.requestedOn)}</p>
                                <TextWithTooltipOnClick
                                    infoColor="#5B6677"
                                    hoverOverText={'View'}
                                    hoverOverTextColor="text-primaryColor"
                                >
                                    <p className="text-[#404040]">{member.message}</p>
                                </TextWithTooltipOnClick>
                                {/* <p className="text-primaryColor cursor-pointer font-bold ">View</p> */}
                                <div className="flex gap-2 items-center ">
                                    <Modal>
                                        <Modal.Button
                                            className="flex gap-2 cursor-pointer items-center"
                                            opens={'acceptrequest'}
                                        >
                                            <p className=" border border-borderColor p-3 rounded-md bg-[#f6f6f6] place-self-center cursor-pointer ">
                                                <AcceptRequestIcon />
                                            </p>
                                        </Modal.Button>
                                        <Modal.Window name={'acceptrequest'}>
                                            <RequestModal
                                                text={'Accept'}
                                                onAction={onAction}
                                                member={member}
                                                multiSig={organisation?.treasurerMultisig}
                                            />
                                        </Modal.Window>
                                    </Modal>
                                    <Modal>
                                        <Modal.Button
                                            className="flex gap-2 cursor-pointer items-center"
                                            opens={'declinerequest'}
                                        >
                                            <p className=" place-self-center p-3 border border-borderColor rounded-md bg-[#f6f6f6] cursor-pointer ">
                                                <DeclineRequestIcon />
                                            </p>
                                        </Modal.Button>
                                        <Modal.Window name={'declinerequest'}>
                                            <RequestModal text={'Decline'} onAction={onAction} member={member} />
                                        </Modal.Window>
                                    </Modal>
                                </div>
                            </Table.Row>
                        );
                    })
                ) : (
                    <NoDataAvailable />
                )}
            </Table>

            {/* <div className="flex justify-end">
                {address ? (
                    <Button
                        variant="primary"
                        onClick={onExecutionToAddTreasurers}
                        disabled={!selected.length}
                        className={`${selected.length ? 'cursor-pointer' : 'cursor-not-allowed bg-gray-400 '}`}
                        size="small"
                    >
                        Add to Org
                    </Button>
                ) : (
                    <ExecuteButton />
                )}
            </div> */}
        </>
    );
}

export default RequestList;
