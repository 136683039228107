// import { useEffect, useRef } from 'react';
// import Overlay from '../../ui/Overlay';
// import Button from '../../ui/Button';
// import Feedbackclose from '../../assets/icons/Feedbackclose';
// import FormForCreatingProject from './FormForCreatingProject';

// function CreateProjectModal({
//     close,
//     edit = false,
//     projectDetails,
//     onUpdation,
//     startCreating,
//     stopCreating,
//     setProjects,
//     setProjectsAfterCreatingNewProject,
//     selectAfterCreation,
// }) {
//     const myRef = useRef({ close });

//     useEffect(
//         function () {
//             function handleClick(e) {
//                 if (myRef.current && !myRef.current.contains(e.target)) {
//                     close();
//                 }
//             }

//             document.addEventListener('click', handleClick, true);

//             return () => document.removeEventListener('click', handleClick, true);
//         },
//         [close]
//     );
//     return (
//         <Overlay>
//             <div
//                 ref={myRef}
//                 className="absolute rounded-lg left-[50%] w-[625px]  p-14   bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
//             >
//                 <div>
//                     <Button
//                         onClick={close}
//                         className=" border-0 absolute top-3  right-4 "
//                         variant="tertiary"
//                         size="circle"
//                     >
//                         <Feedbackclose />
//                     </Button>
//                 </div>

//                 <FormForCreatingProject
//                     edit={edit}
//                     closeAfterCreation={close}
//                     projectDetails={projectDetails}
//                     onUpdation={onUpdation}
//                     startCreating={startCreating}
//                     stopCreating={stopCreating}
//                     setProjects={setProjects}
//                     setProjectsAfterCreatingNewProject={setProjectsAfterCreatingNewProject}
//                     selectAfterCreation={selectAfterCreation}
//                 />
//             </div>
//         </Overlay>
//     );
// }

// export default CreateProjectModal;

// import { useEffect, useRef } from 'react';
// import Overlay from '../../ui/Overlay';
// import Button from '../../ui/Button';
// import Feedbackclose from '../../assets/icons/Feedbackclose';
// import FormForCreatingProject from './FormForCreatingProject';

// function CreateProjectModal({
//     close,
//     projectDetails,
//     onUpdation,
//     startCreating,
//     stopCreating,
//     setProjects,
//     setProjectsAfterCreatingNewProject,
//     selectAfterCreation,
// }) {
//     const modalRef = useRef(null); // Reference to the modal content

//     useEffect(() => {
//         // Handle click outside the modal
//         function handleClickOutside(e) {
//             if (modalRef.current && !modalRef.current.contains(e.target)) {
//                 close(); // Close the modal if clicked outside
//             }
//         }

//         // Add the event listener for outside clicks
//         document.addEventListener('mousedown', handleClickOutside, true);

//         return () => {
//             // Clean up the event listener on unmount
//             document.removeEventListener('mousedown', handleClickOutside, true);
//         };
//     }, [close]); // Only depend on `close`

//     return (
//         <Overlay>
//             <div
//                 ref={modalRef} // Attach ref to the modal content
//                 className="absolute rounded-lg left-[50%] w-[625px] p-14 bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
//             >
//                 <div>
//                     <Button
//                         onClick={close}
//                         className="border-0 absolute top-3 right-4"
//                         variant="tertiary"
//                         size="circle"
//                     >
//                         <Feedbackclose />
//                     </Button>
//                 </div>

//                 <FormForCreatingProject
//                     closeAfterCreation={close}
//                     projectDetails={projectDetails}
//                     onUpdation={onUpdation}
//                     startCreating={startCreating}
//                     stopCreating={stopCreating}
//                     setProjects={setProjects}
//                     setProjectsAfterCreatingNewProject={setProjectsAfterCreatingNewProject}
//                     selectAfterCreation={selectAfterCreation}
//                 />
//             </div>
//         </Overlay>
//     );
// }

// export default CreateProjectModal;
// import { useEffect, useRef } from 'react';
// import Overlay from '../../ui/Overlay';
// import Button from '../../ui/Button';
// import Feedbackclose from '../../assets/icons/Feedbackclose';
// import FormForCreatingProject from './FormForCreatingProject';

// function CreateProjectModal({
//     close,
//     edit = false,
//     projectDetails,
//     onUpdation,
//     startCreating,
//     stopCreating,
//     setProjects,
//     setProjectsAfterCreatingNewProject,
//     selectAfterCreation,
// }) {
//     const modalRef = useRef(null); // Reference to the modal content

//     useEffect(() => {
//         function handleClickOutside(e) {
//             // Close the modal if the click is outside the modal content
//             if (modalRef.current && !modalRef.current.contains(e.target)) {
//                 close();
//             }
//         }

//         // Add the event listener to close modal on outside click
//         document.addEventListener('mousedown', handleClickOutside, true);

//         // Clean up the event listener when component unmounts
//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside, true);
//         };
//     }, [close]);

//     return (
//         <Overlay>
//             <div
//                 ref={modalRef} // Attach ref to modal content
//                 className="absolute rounded-lg left-[50%] w-[625px] p-14 bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
//             >
//                 <div>
//                     <Button
//                         onClick={close}
//                         className="border-0 absolute top-3 right-4"
//                         variant="tertiary"
//                         size="circle"
//                     >
//                         <Feedbackclose />
//                     </Button>
//                 </div>

//                 <FormForCreatingProject
//                     edit={edit}
//                     closeAfterCreation={close}
//                     projectDetails={projectDetails}
//                     onUpdation={onUpdation}
//                     startCreating={startCreating}
//                     stopCreating={stopCreating}
//                     setProjects={setProjects}
//                     setProjectsAfterCreatingNewProject={setProjectsAfterCreatingNewProject}
//                     selectAfterCreation={selectAfterCreation}
//                 />
//             </div>
//         </Overlay>
//     );
// }

// export default CreateProjectModal;

import { useEffect, useRef } from 'react';
import Overlay from '../../ui/Overlay';
import Button from '../../ui/Button';
import Feedbackclose from '../../assets/icons/Feedbackclose';
import FormForCreatingProject from './FormForCreatingProject';

function CreateProjectModal({
    close,
    edit = false,
    projectDetails,
    onUpdation,
    startCreating,
    stopCreating,
    setProjects,
    setProjectsAfterCreatingNewProject,
    selectAfterCreation,
    from,
}) {
    const modalRef = useRef(null); // Reference to the modal content

    useEffect(() => {
        function handleClickOutside(e) {
            // Close the modal if the click is outside the modal content
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                close();
            }
        }

        // Add the event listener to close modal on outside click
        document.addEventListener('mousedown', handleClickOutside, true);

        // Clean up the event listener when component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [close]);

    return (
        <Overlay>
            <div
                ref={modalRef} // Attach ref to modal content
                className="absolute rounded-lg left-[50%] w-[625px] p-6 bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
                onClick={(e) => e.stopPropagation()} // Prevent clicks inside modal from closing it
            >
                <div>
                    <Button
                        onClick={close}
                        className="border-0 absolute top-3 right-4"
                        variant="tertiary"
                        size="circle"
                        radius="rounded-full"
                    >
                        <Feedbackclose />
                    </Button>
                </div>

                <FormForCreatingProject
                    edit={edit}
                    closeAfterCreation={close}
                    projectDetails={projectDetails}
                    onUpdation={onUpdation}
                    startCreating={startCreating}
                    stopCreating={stopCreating}
                    setProjects={setProjects}
                    setProjectsAfterCreatingNewProject={setProjectsAfterCreatingNewProject}
                    selectAfterCreation={selectAfterCreation}
                    from={from}
                />
            </div>
        </Overlay>
    );
}

export default CreateProjectModal;
