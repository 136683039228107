import BountiesList from '../features/bounties/BountiesList';

function ViewBountiesList() {
    return (
        <div className="">
            <BountiesList />
        </div>
    );
}

export default ViewBountiesList;
