function AddProfileImage() {
    return (
        <>
            <svg
                className="cursor-pointer"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M15.3337 7.33268H12.667V12.666H7.33366V15.3327H12.667V20.666H15.3337V15.3327H20.667V12.666H15.3337V7.33268ZM14.0003 0.666016C6.65366 0.666016 0.666992 6.65268 0.666992 13.9993C0.666992 21.346 6.65366 27.3327 14.0003 27.3327C21.347 27.3327 27.3337 21.346 27.3337 13.9993C27.3337 6.65268 21.347 0.666016 14.0003 0.666016ZM14.0003 24.666C8.12033 24.666 3.33366 19.8793 3.33366 13.9993C3.33366 8.11935 8.12033 3.33268 14.0003 3.33268C19.8803 3.33268 24.667 8.11935 24.667 13.9993C24.667 19.8793 19.8803 24.666 14.0003 24.666Z"
                    fill="#737373"
                />
            </svg>
        </>
    );
}

export default AddProfileImage;
