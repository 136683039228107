import { Outlet, useOutletContext } from 'react-router-dom';
import Heading from '../../ui/Heading';

function BountySubmissions() {
    const [bountyDetails, handleBountyDetails] = useOutletContext();

    return (
        <div>
            <Outlet context={[bountyDetails, handleBountyDetails]} />
        </div>
    );
}

export default BountySubmissions;
