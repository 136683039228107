// export const BASE_URL = 'https://8t45chcptb.execute-api.ap-south-1.amazonaws.com/development/v1';

// export const BOUNTY_MANAGER_PROGRAM_ID = 'HNgRCwJ2qaMSzDsor9b8T76PhF2tkRJ5eiYM3kj98xrS';

// export const API_KEY = 'ab390b6a-1be0-4d57-8195-4ef77538db87';

// export const API_URL = 'https://pro-api.coinmarketcap.com/v2/cryptocurrency/quotes/latest';
// export const RPC_ENDPOINT = 'https://mainnet.helius-rpc.com/?api-key=216323ba-6f1f-4e42-a032-ddff354c0550';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BOUNTY_MANAGER_PROGRAM_ID = process.env.REACT_APP_BOUNTY_MANAGER_PROGRAM_ID;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const API_URL = process.env.REACT_APP_API_URL;
export const RPC_ENDPOINT = process.env.REACT_APP_RPC_ENDPOINT;
