import { useController, useFieldArray } from 'react-hook-form';

export const useStepThreeInputs = (control) => {
    const {
        fields: microtaskFields,
        append,
        prepend,
        remove,
        update,
        swap,
        // move,
        // remove,
    } = useFieldArray({
        name: 'microtasks',
        control,
        rules: {
            required: 'Please add atleast 1 microtask',
        },
    });
    return { microtaskFields, append, remove, swap, update };
};
